import {
  OVERVIEW_STATS_PENDING,
  OVERVIEW_STATS_SUCCESS,
  OVERVIEW_STATS_FAILED,
  OVERVIEW_GRAPH_STATS_PENDING,
  OVERVIEW_GRAPH_STATS_SUCCESS,
  OVERVIEW_GRAPH_STATS_FAILED
} from './constant'
import { IPrintStats, IGraphStatsRes } from './types'

export function getOverviewStats() {
  return {
    type: OVERVIEW_STATS_PENDING
  }
}

export function getOverviewStatsSuccess(printStats: IPrintStats) {
  return {
    type: OVERVIEW_STATS_SUCCESS,
    payload: { printStats }
  }
}

export function getOverviewStatsFailure(
  message = 'Failed to fetch overview stats'
) {
  return {
    type: OVERVIEW_STATS_FAILED,
    payload: { message }
  }
}

export function getOverviewGraphStats(duration = 'day', type?: string) {
  return {
    type: OVERVIEW_GRAPH_STATS_PENDING,
    payload: { duration, type }
  }
}

export function getOverviewGraphStatsSuccess(
  stats: IGraphStatsRes[],
  duration: string,
  type?: string
) {
  return {
    type: OVERVIEW_GRAPH_STATS_SUCCESS,
    payload: { stats, duration, type }
  }
}

export function getOverviewGraphStatsFailure(
  message = 'Failed to fetch overview graph stats'
) {
  return {
    type: OVERVIEW_GRAPH_STATS_FAILED,
    payload: { message }
  }
}
