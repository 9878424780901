import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IExportJobFormState, ShowExportJobFormAction } from './types'

export const initialState: IExportJobFormState = {
  visible: false,
  printerName: undefined
}

export const exportJobFormSlice = createSlice({
  name: 'exportJobForm',
  initialState,
  reducers: {
    showExportJobForm: (
      state,
      action: PayloadAction<ShowExportJobFormAction>
    ) => {
      state.visible = true
      state.printerName = action.payload.printerName
    },
    hideExportJobForm: state => {
      state.visible = false
      state.printerName = undefined
    }
  }
})

export const {
  showExportJobForm,
  hideExportJobForm
} = exportJobFormSlice.actions

export default exportJobFormSlice.reducer
