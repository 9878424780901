import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'

import store from './store'
import history from './history'
import {
  ChipsProvider,
  DateRangeProvider
} from './components/Filters/FiltersContext'

interface IProviderWrapper {
  children: React.ReactChild
  store: typeof store
}

const ProviderWrapper = ({ children, store }: IProviderWrapper) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ComponentsProviderWrapper>{children}</ComponentsProviderWrapper>
    </ConnectedRouter>
  </Provider>
)

const ComponentsProviderWrapper = ({
  children
}: {
  children: React.ReactChild
}) => (
  <DateRangeProvider>
    <ChipsProvider>{children}</ChipsProvider>
  </DateRangeProvider>
)

export default ProviderWrapper
