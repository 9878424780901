import React from 'react'
import styled from 'styled-components'
import { DropdownArrowIcon, DeleteIcon } from 'backpack'

export const JobListTable = styled.div`
  .blank-row {
    display: none;
  }
  .no-jobs {
    text-align: center;
    height: 80px;
    opacity: 0.7;
    font-size: var(--medium);
  }
  .desktop-view {
    border-radius: 10px;
    overflow: auto;
    box-shadow: 0 4px 8px 0px hsla(0, 0%, 0%, 0.25);
    position: relative;
    border: 1px solid var(--color-border-primary);
  }
  .desktop-view table {
    width: 100%;
    text-align: left;
  }
  .overflow-visible {
    overflow: visible;
  }
  tr {
    cursor: pointer;
  }
  .collapse-pointer {
    position: absolute;
    background: var(--p-color-1);
    width: 5px;
    height: 30px;
    left: 0;
    border-radius: 0 5px 5px 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    opacity: 0;
    transform: translateY(-50%) scaleX(0);
    transform-origin: left;
  }
  .queued-job-item.collapsed .collapse-pointer {
    opacity: 1;
    transform: translateY(-50%) scaleX(1);
  }
  .file-name {
    font-family: D-DIN Exp;
    font-weight: bold;
    transition: opacity 0.3s ease;
  }
  .queued-job-item.collapsed .file-name {
    opacity: 1;
  }
  .printer-name {
    color: var(--color-brand-text-primary);
    font-size: var(--medium) !important;
  }
  .printer-name > * {
    opacity: 0.8;
  }
  .action-column {
    padding: unset;
  }
  thead th {
    background: var(--color-bg-canvas);
  }
  thead th:first-child {
    border-top-left-radius: 10px;
  }
  thead th:last-child {
    border-top-right-radius: 10px;
  }
  .desktop-view thead th {
    font-size: var(--medium);
    font-family: D-DIN Exp;
    font-weight: bold;
    color: var(--color-brand-text-primary);
  }
  th,
  td {
    box-sizing: border-box;
    padding: 20px;
    position: relative;
  }

  tbody td:nth-child(3),
  thead th:nth-child(3) {
    text-align: center;
  }
  tbody td:nth-child(4),
  thead th:nth-child(4) {
    text-align: right;
  }
  tbody tr:nth-child(odd) td {
    background: var(--color-bg-canvas-inset);
  }
  tbody tr:nth-child(even) td {
    background: var(--color-bg-canvas);
  }
  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  tbody {
    font-size: var(--medium);
    font-family: D-DIN;
  }
  td.status.READY {
    color: #20f562;
  }
  td.status.PROCESSING {
    color: var(--color-text-primary);
  }
  td.status.FAILED {
    color: #cc3333;
  }

  && .td-collapsed-content {
    padding: 15px 10px;
    background: var(--table-collapsed-content-background);
    box-shadow: var(--table-collapsed-content-box-shadow);
  }

  .content-item {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-item__label {
    opacity: 0.7;
  }
  .content-item__value {
    background: hsl(0 0% 100% / 0.1);
    padding: 5px 10px;
    border-radius: 5px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content-item__value.capitalize {
    text-transform: capitalize;
  }

  .content-item__value.successful {
    color: #20f562;
  }
  .content-item__value.failed,
  .content-item__value.cancelled {
    color: #cc3333;
  }
  .collapsed-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .collapsed-content > * {
    margin: 5px 10px;
  }
  .content-item__value svg {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .content-item__value svg path {
    fill: #f58220;
  }

  .mobile-view {
    display: none;
  }

  .skeleton-row {
    height: 56px;
  }
  .skeleton.loading {
    overflow: hidden;
  }

  @media (max-width: 480px) {
    margin-bottom: 15px;
    thead th:nth-child(2),
    thead th:nth-child(3),
    tbody td:nth-child(2),
    tbody td:nth-child(3) {
      display: none;
    }
    .desktop-view table {
      table-layout: fixed;
    }
    .desktop-view thead th {
      font-size: var(--medium);
    }
    td {
      font-size: var(--small);
      overflow: hidden;
    }
    th,
    td {
      padding: 15px;
    }
    .file-name-container,
    .cell-overflow-hidden {
      width: max-content;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    td.action-column {
      width: 30px;
      overflow: visible;
    }
    .collapsed-content > * {
      margin: 10px 5px;
      width: 100%;
    }
    .td-collapsed-content {
      padding: 5px 10px;
    }
    .content-item__value {
      background: unset;
      color: var(--p-color-1);
      padding: 0;
      border-radius: unset;
    }
    .file-name.READY,
    .content-item__value.status.READY {
      color: #20f562;
    }
    .content-item__value.status.PROCESSING {
      opacity: 0.7;
      color: var(--color-text-primary);
    }
    thead th:nth-child(5) {
      width: 50px;
    }
  }
`

interface ITHeader extends React.HTMLAttributes<HTMLTableHeaderCellElement> {
  label?: string
  sortOrder?: string
  width?: number
}

export const THeader = styled(
  ({ label, children, width, className, sortOrder, ...props }: ITHeader) => (
    <th
      className={`${className} ${sortOrder === undefined ? '' : sortOrder}`}
      {...props}
    >
      <div className="label-container">
        {children || label}
        <DropdownArrowIcon className="header-arrow" />
      </div>
    </th>
  )
)`
  overflow: visible;
  ${({ width }) => (width ? `width: ${width}px;` : '')}
  .label-container {
    position: relative;
    width: fit-content;
    display: inline-block;
  }
  .header-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    width: 8px;
    opacity: 0;
    transition: all 0.3s ease;
  }
  &.desc .header-arrow {
    opacity: 0.7;
  }
  &.asc .header-arrow {
    transform: translateY(-50%) rotate(180deg);
    opacity: 0.7;
  }
  @media (max-width: 480px) {
    width: auto;
    .header-arrow {
      right: -15px;
    }
  }
`

export const DeleteButton = styled(props => (
  <div {...props}>
    <DeleteIcon />
    <div className="circle-grow" />
  </div>
))`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: auto;
  svg {
    z-index: 1;
  }
  .circle-grow {
    border-radius: 1000px;
    height: 45px;
    width: 45px;
    position: absolute;
    opacity: 0;
    background: hsl(0deg 0% 50% / 15%);
    pointer-events: none;
    transition: all 0.3s ease;
    transform: scale(0);
  }
  &:hover .circle-grow {
    opacity: 1;
    transform: scale(1);
  }
`

export function LoadingRow({ colSpan }: { colSpan?: number }) {
  return (
    <tr className="loading-row">
      <td colSpan={colSpan}>
        <div className={`skeleton loading`}>
          <div className="skeleton-shine" />
        </div>
      </td>
    </tr>
  )
}

export const StyledOverlayLoader = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: hsl(0deg 0% 0% / 70%);
  border-radius: 10px;
  top: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  opacity: 0;
  > span {
    animation: fadeInOutPartial 2.2s infinite ease forwards;
    font-weight: bold;
    font-size: var(--large);
  }
  &.visible {
    pointer-events: auto;
    opacity: 1;
  }
`

export function getOverlayLoader(visible: boolean) {
  return (
    <StyledOverlayLoader
      key={'joblist-overlay-loader'}
      className={`${visible ? 'visible' : ''}`}
    >
      <span>Fetching records...</span>
    </StyledOverlayLoader>
  )
}
