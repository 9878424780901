import React from 'react'
import styled from 'styled-components'
import {
  PrinterTypes,
  ProgressBar,
  isArrayEqual,
  convertMStoText,
  VeloxIcon,
  DentaformIcon
} from 'backpack'

import PrintCount from '../../components/PrintCount'

const PrinterListContainer = styled.div`
  width: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: var(--margin-xl);
  padding: 0;
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`

export interface IPrinterListItem extends React.HTMLAttributes<HTMLDivElement> {
  customPrinterName?: string
  name: string
  fileName?: string
  printerType?: PrinterTypes
  duration?: number
  status?: string
  progress?: number
  inactive?: boolean
  lastSeen?: number
  index?: number
  searchText?: string
  printCount?: {
    total: number
    success: number
    failure: number
  }
}

export const PrinterListItem = styled(
  ({
    onClick,
    customPrinterName,
    name,
    fileName,
    printerType,
    duration,
    status,
    progress,
    inactive,
    lastSeen,
    className,
    searchText,
    printCount,
    index
  }: IPrinterListItem) => {
    const timeLeft =
      typeof duration === 'number' && typeof progress === 'number'
        ? convertMStoText(((100 - progress) / 100) * Number(duration || 0))
        : undefined
    const lastSeenDiff = lastSeen ? Date.now() - lastSeen : null

    const statusStr = status ? status.toLowerCase() : ''

    const label = customPrinterName || name

    const translucent =
      searchText && searchText !== '' && label.indexOf(searchText) === -1
        ? true
        : false

    function getPrinterName() {
      if (!searchText || searchText === '') return label

      const startIndex = label.indexOf(searchText)
      if (startIndex === -1) return label

      const prepend = label.substr(0, startIndex)
      const append = label.substr(startIndex + searchText.length, label.length)

      return (
        <span>
          {prepend}
          <mark>{searchText}</mark>
          {append}
        </span>
      )
    }

    return (
      <div
        className={`${className} printer-list-item${
          statusStr === 'idle' ? ' idle' : ''
        }${inactive ? ' inactive' : ''}${translucent ? ' translucent' : ''}`}
        onClick={onClick}
        data-tut={index === 0 ? 'printers-printer' : undefined}
      >
        <div
          className={`printer-list-item__status-icon`}
          data-tut={
            index === 0 ? 'printers-printer-status-indicator' : undefined
          }
        />
        <div className="printer-list-item__bg" />
        <div
          className={'printer-list-item__printer-name'}
          data-tut={index === 0 ? 'printers-printer-name' : undefined}
        >
          {getPrinterName() || 'Unknown'}
        </div>
        <div
          className={'printer-list-item__printer-type'}
          data-tut={index === 0 ? 'printers-printer-type' : undefined}
        >
          {printerType === PrinterTypes.dentaform ? (
            <DentaformIcon />
          ) : (
            <VeloxIcon />
          )}
        </div>
        <div
          className={'printer-list-item__file-name'}
          data-tut={
            index === 0 && fileName ? 'printers-printer-file-name' : undefined
          }
        >
          {fileName || ''}
        </div>
        <div
          className={`printer-list-item__status${
            statusStr === 'idle' || progress === undefined || inactive
              ? ' no-progress'
              : ''
          }`}
          data-tut={index === 0 ? 'printers-printer-status' : undefined}
        >
          <div className={'printer-list-item__status-name'} title={status}>
            {inactive ? (
              lastSeen ? (
                <>
                  <div>Last seen</div>
                  <div>
                    <span>
                      {lastSeenDiff
                        ? convertMStoText(lastSeenDiff, {
                            day: true,
                            month: true,
                            hour: lastSeenDiff < 86400000,
                            minute: lastSeenDiff < 86400000
                          })
                        : null}
                    </span>
                    <span>ago</span>
                  </div>
                </>
              ) : (
                'Offline'
              )
            ) : (
              status || ''
            )}
          </div>
          <div className={'printer-list-item__progress'}>
            {progress ? `${progress}%` : ''}
          </div>
          {// show progress and time remaining only when progress is available
          progress ? (
            <React.Fragment>
              <div className={'printer-list-item__progress-bar'}>
                <ProgressBar value={progress || 0} />
              </div>
              <div className={'printer-list-item__time-remaining'}>
                {(timeLeft && `${timeLeft} remaining`) || ''}
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
        </div>
        {// show printcount
        printCount && (
          <PrintCount
            total={printCount.total}
            success={printCount.success}
            failure={printCount.failure}
          />
        )}
      </div>
    )
  }
)`
  --highlight-color: #f58220;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: D-DIN;
  background: var(--color-bg-canvas-inset);
  cursor: pointer;
  height: 275px;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid var(--color-border-primary);
  border-radius: 10px;
  &.translucent {
    opacity: 0.6;
  }
  .printer-list-item__status-icon {
    transition: all 0.3s ease;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: var(--fill-p-color-1);
    border-radius: 100%;
    opacity: 0.7;
  }
  &.idle .printer-list-item__status-icon {
    background: lime;
  }
  &.inactive .printer-list-item__status-icon {
    background: grey;
  }
  .printer-list-item__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 0.1s ease;
    pointer-events: none;
    border-radius: 10px;
    border: 1px solid #f58220;
    opacity: 0;
  }
  &:hover .printer-list-item__bg {
    opacity: 1;
  }
  .printer-list-item__printer-name {
    font-size: var(--large);
    color: var(--highlight-color);
    margin-bottom: 10px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .printer-list-item__printer-type {
    width: auto;
    margin-bottom: 10px;
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  .printer-list-item__printer-type svg {
    height: 100px;
    width: auto;
  }
  .printer-list-item__file-name {
    font-size: var(--medium);
    color: var(--color-text-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    opacity: 0.7;
    margin-bottom: 10px;
  }
  .printer-list-item__status {
    position: relative;
    flex: 1;
    width: 100%;
    color: var(--highlight-color);
    margin-bottom: 35px;
  }
  .printer-list-item__status > * {
    font-size: var(--medium);
    transition: all 0.3s ease;
    position: absolute;
  }
  .printer-list-item__status-name {
    text-transform: capitalize;
    left: 0;
  }
  .printer-list-item__progress {
    right: 0;
  }
  .printer-list-item__progress-bar {
    top: 20px;
    width: 100%;
    animation: fadeInOutPartial 2.2s infinite ease forwards;
  }
  .printer-list-item__progress-bar > div {
    height: 5px;
  }
  .printer-list-item__time-remaining {
    opacity: 0.7;
    color: var(--color-text-primary);
    bottom: 0;
    font-size: var(--small);
  }
  .printer-list-item__status.no-progress
    > *:not(.printer-list-item__status-name) {
    animation: unset;
    opacity: 0;
  }
  .printer-list-item__status.no-progress > .printer-list-item__status-name {
    font-size: var(--medium);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    color: var(--color-text-primary);
    opacity: 0.75;
  }
  &.idle .printer-list-item__status-name {
    font-size: var(--medium);
  }
  &.inactive .printer-list-item__status-name {
    transform: translate(-50%, -50%) scale(1) !important;
  }
  &.inactive .printer-list-item__printer-type,
  &.inactive
    .printer-list-item__status.no-progress
    > .printer-list-item__status-name {
    opacity: 0.4;
  }
  &.inactive .printer-list-item__file-name,
  &.idle .printer-list-item__file-name {
    display: none;
  }
  &.inactive .printer-list-item__status-name {
    text-transform: initial;
  }
  &:not(.inactive) .printer-list-item__status-name {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
  }
`

interface IPrinterListProps {
  searchText: string
  printers: IPrinterListItem[]
  onPrinterClick: (printerName: string) => void
}

type StringMap = {
  [key: string]: boolean
}

interface IPrinterListStates {
  cachedPrinters: IPrinterListItem[]
  printerNamesMap: StringMap
}

function getPrinterNamesMap(printers: IPrinterListItem[]) {
  return printers.length
    ? printers.reduce((keyMap: StringMap, printerProps: IPrinterListItem) => {
        keyMap = { ...keyMap, [printerProps.name]: true }
        return keyMap
      }, {} as StringMap)
    : {}
}

class PrinterList extends React.Component<
  IPrinterListProps,
  IPrinterListStates
> {
  constructor(props: IPrinterListProps) {
    super(props)
    this.state = {
      cachedPrinters: props.printers,
      printerNamesMap: getPrinterNamesMap(props.printers)
    }
  }

  static getDerivedStateFromProps(
    props: IPrinterListProps,
    state: IPrinterListStates
  ) {
    if (props.printers.length !== state.cachedPrinters.length) {
      return {
        printerNamesMap: getPrinterNamesMap(props.printers)
      }
    }
    return null
  }

  componentDidUpdate(
    prevProps: IPrinterListProps,
    prevState: IPrinterListStates
  ) {
    if (!isArrayEqual(prevProps.printers, prevState.cachedPrinters)) {
      // This allow printer to fade out seamlessly without flickering
      this.setState({ cachedPrinters: prevProps.printers })
    }
  }
  render() {
    const { cachedPrinters, printerNamesMap } = this.state
    return (
      <PrinterListContainer>
        {cachedPrinters &&
          cachedPrinters.map(({ name, className, ...rest }, index) => (
            <PrinterListItem
              index={index}
              onClick={() => this.props.onPrinterClick(name)}
              key={name}
              className={!printerNamesMap[name] ? 'hidden' : ''}
              name={name}
              searchText={this.props.searchText}
              {...rest}
            />
          ))}
      </PrinterListContainer>
    )
  }
}

export { PrinterList }
