import React from 'react'
import styled from 'styled-components'
import { Input, SearchIcon } from 'backpack'

type ISearchInputProps = React.ComponentProps<typeof Input>

export const SearchInput = styled((props: ISearchInputProps) => (
  <Input icon={<SearchIcon />} {...props} />
))`
  background: hsl(0deg 0% 0% / 40%);
  border-radius: 10px;
  transition: border-color 0.2s ease;
  color: var(--color-text-primary);
  font-family: D-DIN;
  .input-area {
    background: var(--color-bg-primary-dark);
    border-radius: 10px;
  }
  && .input-area input {
    font-family: D-DIN;
    color: var(--color-text-primary);
  }
  width: 250px;
  @media (max-width: 480px) {
    width: 100%;
  }
`
