import {
  JOBS_GET_LIST_PENDING,
  JOBS_GET_LIST_SUCCESS,
  JOBS_GET_LIST_FAILED,
  PRINT_FEEDBACK_PENDING,
  PRINT_FEEDBACK_SUCCESS,
  PRINT_FEEDBACK_FAILED,
  EXPORT_JOBS,
  EXPORT_JOBS_FAILURE,
  EXPORT_JOBS_SUCCESS,
  ADD_NEW_JOB,
  ADD_NEW_JOB_FAILURE,
  ADD_NEW_JOB_SUCCESS
} from './constant'
import { IExportDates, IPastPrintFilter, IPrintFeedback } from './types'
import { IPrintLog } from '../printer/types'

export function getAllPastPrints(filter: IPastPrintFilter) {
  return {
    type: JOBS_GET_LIST_PENDING,
    payload: { filter }
  }
}

export function getAllPastPrintsSuccess(
  pastPrints: IPrintLog[],
  totalCount: number
) {
  return {
    type: JOBS_GET_LIST_SUCCESS,
    payload: { pastPrints, totalCount }
  }
}

export function getAllPastPrintsFailed(
  message = 'Failed to fetch past prints'
) {
  return {
    type: JOBS_GET_LIST_FAILED,
    payload: { message }
  }
}

export function printFeedback(
  feedback: IPrintFeedback,
  jobId: string,
  printerName: string
) {
  return {
    type: PRINT_FEEDBACK_PENDING,
    payload: { feedback, jobId, printerName }
  }
}

export function printFeedbackSuccess(jobId: string, feedback: IPrintFeedback) {
  return {
    type: PRINT_FEEDBACK_SUCCESS,
    payload: { jobId, feedback }
  }
}

export function printFeedbackFailure(jobId: string) {
  return {
    type: PRINT_FEEDBACK_FAILED,
    payload: { jobId }
  }
}

export function exportJobs(
  printersType: string,
  printers: string[],
  dates: IExportDates
) {
  return {
    type: EXPORT_JOBS,
    payload: { printersType, printers, dates }
  }
}

export const exportJobsSuccess = () => {
  return {
    type: EXPORT_JOBS_SUCCESS
  }
}

export const exportJobsFailure = () => {
  return {
    type: EXPORT_JOBS_FAILURE
  }
}

export const addNewJob = (fileStoreKey: string, printerName: string) => {
  return {
    type: ADD_NEW_JOB,
    payload: { fileStoreKey, printerName }
  }
}

export const addNewJobFailure = () => {
  return {
    type: ADD_NEW_JOB_FAILURE
  }
}

export const addNewJobSuccess = () => {
  return {
    type: ADD_NEW_JOB_SUCCESS
  }
}
