import { IGraphStatsRes } from '../stats/types'
import { ITeflon } from '../teflons/types'
import {
  PRINTERS_GET_LIST_PENDING,
  PRINTERS_GET_LIST_SUCCESS,
  PRINTERS_GET_LIST_FAILED,
  PRINTER_UPLOAD_JOB_PENDING,
  PRINTER_UPLOAD_JOB_SUCCESS,
  PRINTER_UPLOAD_JOB_FAILED,
  PRINTER_DELETE_JOB_PENDING,
  PRINTER_DELETE_JOB_SUCCESS,
  PRINTER_DELETE_JOB_FAILED,
  PRINTER_GET_ALL_QUEUED_JOBS,
  PRINTER_CHANGE_PRINTER_NAME_SUCCESS,
  PRINTER_CHANGE_PRINTER_NAME_PENDING,
  PRINTER_CHANGE_PRINTER_NAME_FAILED,
  PRINTER_GET_PENDING_FILES,
  PRINTER_GET_PENDING_FILES_FAILED,
  PRINTER_GET_PENDING_FILES_SUCCESS,
  PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS,
  GET_JOBS_FROM_IOT_SUCCESS,
  PRINTER_GRAPH_STATS_PENDING,
  PRINTER_GRAPH_STATS_SUCCESS,
  PRINTER_GRAPH_STATS_FAILED,
  PRINTER_GET_LATEST_TEFLON,
  PRINTER_GET_LATEST_TEFLON_SUCCESS,
  PRINTER_GET_LATEST_TEFLON_FAILURE,
  PRINTER_MULTIPLE_UPLOAD_JOB_PENDING,
  PRINTER_MULTIPLE_UPLOAD_JOB_FAILURE,
  PRINTER_MULTIPLE_UPLOAD_JOB_SUCCESS,
  PRINTER_GET_LATEST_LCD,
  PRINTER_GET_LATEST_LCD_SUCCESS,
  PRINTER_GET_LATEST_LCD_FAILURE,
  PRINTER_CREATE_GROUP,
  PRINTER_CREATE_GROUP_SUCCESS,
  PRINTER_CREATE_GROUP_FAILURE,
  PRINTER_UPDATE_GROUP,
  PRINTER_UPDATE_GROUP_SUCCESS,
  PRINTER_UPDATE_GROUP_FAILURE,
  PRINTER_GET_GROUPS,
  PRINTER_GET_GROUPS_SUCCESS,
  PRINTER_GET_GROUPS_FAILURE,
  PRINTER_DELETE_GROUP,
  PRINTER_DELETE_GROUP_SUCCESS,
  PRINTER_DELETE_GROUP_FAILURE,
  PRINTER_START_REMOTE_PRINT,
  PRINTER_START_REMOTE_PRINT_SUCCESS,
  PRINTER_START_REMOTE_PRINT_FAILURE
} from './constant'
import {
  IJob,
  ILCD,
  IPendingFilesRes,
  IPrinterRes,
  IQueuedJobsFilter
} from './types'

export function getPrinters() {
  return {
    type: PRINTERS_GET_LIST_PENDING
  }
}

export function getPrintersSuccess(
  printers: IPrinterRes[],
  printersType = 'velox'
) {
  return {
    type: PRINTERS_GET_LIST_SUCCESS,
    payload: { printers, printersType }
  }
}

export function getPrintersFailure(message = 'Fetch printers failed') {
  return {
    type: PRINTERS_GET_LIST_FAILED,
    payload: { message }
  }
}

// Uploading job
export function uploadFile(file: File, printerName: string) {
  return {
    type: PRINTER_UPLOAD_JOB_PENDING,
    payload: { file, printerName }
  }
}

export function uploadFileSuccess(printerName: string) {
  return {
    type: PRINTER_UPLOAD_JOB_SUCCESS,
    payload: { printerName }
  }
}

export function uploadFileFailed(
  message = 'Failed to upload file',
  printerName: string
) {
  return {
    type: PRINTER_UPLOAD_JOB_FAILED,
    payload: { message, printerName }
  }
}

// Deleting job
export function deleteJob(jobId: string, printerName: string) {
  return {
    type: PRINTER_DELETE_JOB_PENDING,
    payload: { jobId, printerName }
  }
}

export function deleteJobSuccess(jobId: string, printerName: string) {
  return {
    type: PRINTER_DELETE_JOB_SUCCESS,
    payload: { jobId, printerName }
  }
}

export function deleteJobFailed(jobId: string, printerName: string) {
  return {
    type: PRINTER_DELETE_JOB_FAILED,
    payload: { jobId, printerName }
  }
}

export function getAllQueuedJobs(filter?: IQueuedJobsFilter) {
  return {
    type: PRINTER_GET_ALL_QUEUED_JOBS,
    payload: { filter }
  }
}

export function getAllQueuedJobsSuccess(filter?: IQueuedJobsFilter) {
  return {
    type: PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS,
    payload: { filter }
  }
}

export function changePrinterName(printerName: string, customName: string) {
  return {
    type: PRINTER_CHANGE_PRINTER_NAME_PENDING,
    payload: {
      printerName,
      customName
    }
  }
}

export function changePrinterNameSuccess(
  printerName: string,
  customName: string
) {
  return {
    type: PRINTER_CHANGE_PRINTER_NAME_SUCCESS,
    payload: {
      printerName,
      customName
    }
  }
}

export function changePrinterNameFailure(printerName: string) {
  return {
    type: PRINTER_CHANGE_PRINTER_NAME_FAILED,
    payload: {
      printerName
    }
  }
}

export function getPendingFiles(printers: string[]) {
  return {
    type: PRINTER_GET_PENDING_FILES,
    payload: {
      printers
    }
  }
}

export function getPendingFilesSuccess(filesData: IPendingFilesRes[]) {
  return {
    type: PRINTER_GET_PENDING_FILES_SUCCESS,
    payload: {
      filesData
    }
  }
}

export function getPendingFilesFailure(error) {
  return {
    type: PRINTER_GET_PENDING_FILES_FAILED,
    payload: {
      error
    }
  }
}

export function getJobsFromIOTSuccess(printerName: string, jobs: IJob[]) {
  return {
    type: GET_JOBS_FROM_IOT_SUCCESS,
    payload: { printerName, jobs }
  }
}

export function getPrinterGraphStats(
  printerName: string,
  duration = 'day',
  type?: string
) {
  return {
    type: PRINTER_GRAPH_STATS_PENDING,
    payload: { printerName, duration, type }
  }
}

export function getPrinterGraphStatsSuccess(
  printerName: string,
  stats: IGraphStatsRes[],
  duration: string,
  type?: string
) {
  return {
    type: PRINTER_GRAPH_STATS_SUCCESS,
    payload: { printerName, stats, duration, type }
  }
}

export function getPrinterGraphStatsFailure(
  message = 'Failed to fetch printer graph stats'
) {
  return {
    type: PRINTER_GRAPH_STATS_FAILED,
    payload: { message }
  }
}

export function getLatestTeflon(printerName: string) {
  return {
    type: PRINTER_GET_LATEST_TEFLON,
    payload: { printerName }
  }
}

export function getLatestTeflonSuccess(printerName: string, teflon: ITeflon) {
  return {
    type: PRINTER_GET_LATEST_TEFLON_SUCCESS,
    payload: { printerName, teflon }
  }
}

export function getLatestTeflonFailure() {
  return {
    type: PRINTER_GET_LATEST_TEFLON_FAILURE
  }
}

// Uploading multiple job
export function multipleFileUpload(files: File[], printerName: string) {
  return {
    type: PRINTER_MULTIPLE_UPLOAD_JOB_PENDING,
    payload: { files, printerName }
  }
}

export function multipleFileUploadFailure(fileName: string) {
  return {
    type: PRINTER_MULTIPLE_UPLOAD_JOB_FAILURE,
    payload: { fileName }
  }
}

export function multipleFileUploadSuccess(fileName: string) {
  return {
    type: PRINTER_MULTIPLE_UPLOAD_JOB_SUCCESS,
    payload: { fileName }
  }
}

export function getLatestLCD(printerName: string) {
  return {
    type: PRINTER_GET_LATEST_LCD,
    payload: { printerName }
  }
}

export function getLatestLCDSuccess(printerName: string, lcd: ILCD) {
  return {
    type: PRINTER_GET_LATEST_LCD_SUCCESS,
    payload: { printerName, lcd }
  }
}

export function getLatestLCDFailure() {
  return {
    type: PRINTER_GET_LATEST_LCD_FAILURE
  }
}

// printer create groups
export function createGroup(name: string, printers: string[]) {
  return {
    type: PRINTER_CREATE_GROUP,
    payload: { name, printers }
  }
}

export function createGroupSuccess(name: string, printers: string[]) {
  return {
    type: PRINTER_CREATE_GROUP_SUCCESS,
    payload: { name, printers }
  }
}

export function createGroupFailure() {
  return {
    type: PRINTER_CREATE_GROUP_FAILURE
  }
}

// printer update group
export function updateGroup(
  name: string,
  printers?: string[],
  updatedName?: string
) {
  return {
    type: PRINTER_UPDATE_GROUP,
    payload: { name, printers, updatedName }
  }
}

export function updateGroupSuccess(
  name: string,
  printers?: string[],
  updatedName?: string
) {
  return {
    type: PRINTER_UPDATE_GROUP_SUCCESS,
    payload: { name, printers, updatedName }
  }
}

export function updateGroupFailure() {
  return {
    type: PRINTER_UPDATE_GROUP_FAILURE
  }
}

export function getGroups() {
  return {
    type: PRINTER_GET_GROUPS
  }
}

export function getGroupsSuccess(groups: string[]) {
  return {
    type: PRINTER_GET_GROUPS_SUCCESS,
    payload: { groups }
  }
}

export function getGroupsFailure() {
  return {
    type: PRINTER_GET_GROUPS_FAILURE
  }
}

export function deleteGroup(name: string) {
  return {
    type: PRINTER_DELETE_GROUP,
    payload: { name }
  }
}

export function deleteGroupSuccess(name: string) {
  return {
    type: PRINTER_DELETE_GROUP_SUCCESS,
    payload: { name }
  }
}

export function deleteGroupFailure() {
  return {
    type: PRINTER_DELETE_GROUP_FAILURE
  }
}

// Starting remote print
// preheatDuration is in seconds
export function startRemotePrint(
  printerName: string,
  preheatDuration?: number
) {
  return {
    type: PRINTER_START_REMOTE_PRINT,
    payload: { printerName, preheatDuration }
  }
}

export function startRemotePrintSuccess() {
  return {
    type: PRINTER_START_REMOTE_PRINT_SUCCESS
  }
}

export function startRemotePrintFailure() {
  return {
    type: PRINTER_START_REMOTE_PRINT_FAILURE
  }
}
