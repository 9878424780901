import React from 'react'
import { useDispatch } from 'react-redux'

import { QuestionMark } from '../SVGImages'
import { startTour, StartTourProps } from '../../redux/tour/actions'

const TourButton = (startTourProps: StartTourProps) => {
  const dispatch = useDispatch()

  const onClickStartTour = () => dispatch(startTour(startTourProps))

  return <QuestionMark onClick={onClickStartTour} />
}

export default TourButton
