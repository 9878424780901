import { put, call, select, takeEvery, fork } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { ToastType } from 'backpack'

import { getTeflons, getTeflonsFailure, getTeflonsSuccess } from './slice'
import { addToast } from '../toasts/actions'
import { IAuthState } from '../auth/types'
import { IGetTeflonPayload, IGetTeflonRes } from './types'
import { IPrinter, IPrinterState } from '../printer/types'
import { getTeflonsApi } from './api'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken
export const getPrinters = ({ printerData }: { printerData: IPrinterState }) =>
  printerData.printers

export function* getTeflonsHandler(action: PayloadAction<IGetTeflonPayload>) {
  try {
    const { limit, page } = action.payload
    const token: string = yield select(getToken)
    const res: IGetTeflonRes = yield call(getTeflonsApi, token, page, limit)
    const printers: IPrinter[] = yield select(getPrinters)
    res.results = res.results.map(r => {
      if (!(r.printersUsed && r.printersUsed.length)) {
        r.printersUsed = [r.printer]
      }
      if (r.printersUsed.length) {
        // put customPrinterName instead of name if available
        r.printersUsed = r.printersUsed.map(pu => {
          const printer = printers.find(p => p.name === pu.name)
          pu.name = printer?.customPrinterName || pu.name
          return pu
        })
      }
      return r
    })
    yield put(getTeflonsSuccess(res))
  } catch (e) {
    yield put(getTeflonsFailure())
    yield put(
      addToast({
        title: `Fetch Teflons failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

function* watchGetTeflons() {
  yield takeEvery(getTeflons.toString(), getTeflonsHandler)
}

export default [fork(watchGetTeflons)]
