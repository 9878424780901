import React, { useState } from 'react'
import styled from 'styled-components'

import { FilterDropdownOption } from './Filters'

export type Action = {
  text: string
  callback?: () => void
  title?: string
}

export interface ITableActionsCell
  extends React.HTMLAttributes<HTMLDivElement> {
  actions: Action[]
}

export const TableActions = styled(
  ({ actions = [], className, ...rest }: ITableActionsCell) => {
    const [collapsed, setCollapsed] = useState(false)

    return (
      <div
        className={`${className} table-action${collapsed ? ' collapsed' : ''}`}
        onClick={e => {
          e.stopPropagation()
          setCollapsed(!collapsed)
        }}
        onMouseLeave={e => {
          e.stopPropagation()
          setCollapsed(false)
        }}
        {...rest}
      >
        <div className="dot-button">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
        <div className="filter-options">
          {actions.length > 0 &&
            actions.map(({ text, callback, title }) => (
              <FilterDropdownOption
                key={text}
                onClick={e => {
                  e.stopPropagation()
                  if (callback) callback()
                  setCollapsed(false)
                }}
                title={title}
                className={!Boolean(callback) ? 'disabled' : ''}
              >
                {text}
              </FilterDropdownOption>
            ))}
        </div>
      </div>
    )
  }
)`
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .dot-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background: hsl(0 0% 100% / 0);
    transition: all 0.3s ease;
  }
  &:hover .dot-button {
    background: hsl(0 0% 100% / 0.1);
    transform: scale(1.05);
  }
  .dot {
    background: white;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    display: block;
    opacity: 0.7;
  }
  .filter-options {
    position: absolute;
    right: 10px;
    top: 40px;
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-10%);
    user-select: none;
    cursor: pointer;
    width: max-content;
    max-width: 340px;
    text-overflow: ellipsis;
    background: #232428;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 4px 8px 0px hsla(0, 0%, 0%, 0.25);
  }
  &.collapsed .dropdown-content > * {
    opacity: 1;
  }
  &.collapsed .filter-options {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
`

export interface ITableRow extends React.HTMLAttributes<HTMLTableRowElement> {
  collapsed?: boolean
}

export const TableRow = ({ collapsed, className, ...rest }: ITableRow) => (
  <tr
    className={`${className || ''}${collapsed ? ' collapsed' : ''}`}
    {...rest}
  />
)

export interface ITableCell
  extends React.HTMLAttributes<HTMLTableDataCellElement> {
  firstColumn?: boolean
  overflowHidden?: boolean
  childClassName?: string
}

export const TableCell = ({
  firstColumn,
  overflowHidden,
  className,
  childClassName,
  children,
  ...rest
}: ITableCell) => (
  <td className={className} {...rest}>
    {overflowHidden ? (
      <div className={`cell-overflow-hidden ${childClassName || ''}`}>
        {children}
      </div>
    ) : (
      <span className={childClassName || undefined}>{children}</span>
    )}
    {firstColumn && <span className="collapse-pointer" />}
  </td>
)

export interface ITableDateCell
  extends React.HTMLAttributes<HTMLTableDataCellElement> {
  date: string
  time: string
}

export const TableDateCell = ({ date, time, ...rest }: ITableDateCell) => (
  <td {...rest}>
    <div className="date-time">
      <span className="date-time__date">{date}</span>
      <span className="date-time__time">{time}</span>
    </div>
  </td>
)

export type CollapseItem = {
  label: string
  value?: string
  valueClassName?: string
  mobileOnly?: boolean
  optional?: boolean
  icon?: React.ReactNode
}

export interface ITableCollapseContent {
  collapseItems: CollapseItem[]
  colSpan: number
}

export const TableCollapseContent = ({
  collapseItems,
  colSpan
}: ITableCollapseContent) => (
  <React.Fragment>
    <tr>
      <td colSpan={colSpan} className="td-collapsed-content">
        <div className="collapsed-content">
          {collapseItems.length > 0 &&
            collapseItems.map(
              ({ label, value, valueClassName, mobileOnly, optional, icon }) =>
                (!mobileOnly || (mobileOnly && colSpan === 3)) &&
                (!optional || (optional && value)) && (
                  <div key={label} className="content-item">
                    <span className="content-item__label">{label}:</span>
                    <span
                      className={`content-item__value ${valueClassName || ''}`}
                      title={value}
                    >
                      {icon || null}
                      {value || '-'}
                    </span>
                  </div>
                )
            )}
        </div>
      </td>
    </tr>
    <tr className="blank-row" />
  </React.Fragment>
)
