import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStartJobFormState, ShowStartJobFormAction } from './types'

export const initialState: IStartJobFormState = {
  visible: false,
  preselectedPrinterName: undefined
}

export const startJobFormSlice = createSlice({
  name: 'startJobForm',
  initialState,
  reducers: {
    showStartJobForm: (
      state,
      action: PayloadAction<ShowStartJobFormAction>
    ) => {
      state.visible = true
      state.preselectedPrinterName = action.payload.preselectedPrinterName
    },
    hideStartJobForm: state => {
      state.visible = false
      state.preselectedPrinterName = undefined
    }
  }
})

export const { showStartJobForm, hideStartJobForm } = startJobFormSlice.actions

export default startJobFormSlice.reducer
