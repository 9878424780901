import ReactGA from 'react-ga'
import eventTracking from './event-tracking'

export const trackingMiddleware = (stroe: any) => (next: any) => (
  action: any
) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const { pathname, search } = action.payload.location
    const nextPage = `${pathname}${search}`
    ReactGA.pageview(nextPage)
  }

  if (action.type === 'addJobForm/showAddJobForm') {
    ReactGA.modalview(
      `/add-job/${action.payload?.preselectedPrinterName ||
        'no-selected-printer'}`
    )
  }

  const eventData = eventTracking[action.type]
  if (eventData) {
    let { category, eventAction, label, eventDataFromPayload = '' } = eventData
    if (label && label.includes('#1')) {
      label = label.replace(
        '#1',
        JSON.stringify(action.payload[eventDataFromPayload])
      )
    }

    ReactGA.event({ category, action: eventAction, label })
  }

  return next(action)
}

export default trackingMiddleware
