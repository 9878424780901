export enum ITheme {
  light = 'LIGHT',
  dark = 'DARK'
}

export interface IUserPreferenceState {
  theme: ITheme
}

export interface IGetThemeSuccess {
  theme: ITheme
}

export interface ISetThemeAction {
  theme: ITheme
}
