import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react'
import styled from 'styled-components'
import {
  ToggleButton,
  FilterIcon,
  DropdownArrowIcon,
  isObjectEqual,
  Popup,
  SmallButton,
  BackArrowIcon
} from 'backpack'

import { SearchInput } from '../SearchInput'
import { ChipsContext, DateRangeContext } from './FiltersContext'

export const formatNumber = (number: number) =>
  number < 10 ? `0${number}` : number

export const formatDate = (date: Date, daysDisabled) =>
  `${!daysDisabled ? `${formatNumber(date.getDate())}/` : ''}${formatNumber(
    date.getMonth() + 1
  )}/${date.getFullYear()}`

export const StyledSmallButton = styled(SmallButton)`
  opacity: 0.7;
  transition: all 0.3s ease;
  &.active {
    opacity: 0.8;
  }
  &:hover {
    transform: scale(1.03);
    opacity: 1;
  }
`

export const FiltersBox = styled(
  ({ className, visible, children, ...rest }) => (
    <div
      className={`${className} filter-box${visible ? ' visible' : ''}`}
      {...rest}
    >
      {children}
    </div>
  )
)`
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10%);
  transition: all 0.3s ease;
  padding: 10px;
  box-sizing: border-box;
  background: hsl(0 0% 0% / 0.3);
  border-radius: 10px;
  margin-bottom: 15px;
  display: none;
  z-index: 3;
  position: relative;
  gap: 12px;
  &.visible {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const FilterDropdown = styled(
  ({ className, value, children, onChange, options, fixedValue, ...rest }) => {
    const [collapsed, setCollapsed] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const currentValue =
      (options && options.find(o => o.value === value)) || options[0]

    const HandleClickOutsideCallback = useCallback(
      e => {
        //@ts-ignore
        if (
          collapsed &&
          dropdownRef &&
          !dropdownRef.current?.contains(e.target)
        ) {
          setTimeout(() => {
            if (collapsed) setCollapsed(false)
          }, 50)
        }
      },
      [collapsed, setCollapsed]
    )

    useEffect(() => {
      document.addEventListener('mousedown', HandleClickOutsideCallback)
      return () => {
        document.removeEventListener('mousedown', HandleClickOutsideCallback)
      }
    }, [HandleClickOutsideCallback])

    return (
      <div
        className={`${className} filter-dropdown${
          collapsed ? ' collapsed' : ''
        }`}
        {...rest}
        onClick={() => setCollapsed(!collapsed)}
        ref={dropdownRef}
        // onMouseOver={() => setCollapsed(true)}
        // onMouseLeave={() => setCollapsed(false)}
      >
        <select
          onChange={e => onChange(e.target.value)}
          className="dropdown__mobile"
          value={value}
        >
          {options &&
            options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
        </select>
        <div className="dropdown-content">
          <span
            className={`dropdown-content__label${
              value || fixedValue ? ' has-value' : ''
            }`}
          >
            {fixedValue || currentValue?.label}
          </span>
          <DropdownArrowIcon />
        </div>
        <div className="filter-options">
          {options &&
            options.map(({ label, value }) => (
              <FilterDropdownOption
                key={value}
                onClick={e => {
                  e.stopPropagation()
                  onChange(value)
                  setCollapsed(false)
                }}
              >
                {label}
              </FilterDropdownOption>
            ))}
        </div>
      </div>
    )
  }
)`
  position: relative;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s ease;
  border-radius: 10px;
  .dropdown-content {
    display: flex;
    align-items: center;
  }
  .dropdown-content > * {
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  .dropdown-content__label {
    font-size: var(--medium);
    font-family: D-DIN Exp;
    font-weight: bold;
    color: var(--color-text-primary);
    margin-right: 8px;
  }
  .dropdown-content__label.has-value {
    color: hsl(28 91% 54% / 0.8);
    opacity: 1;
  }
  .filter-options {
    position: absolute;
    top: 35px;
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-10%);
    user-select: none;
    cursor: pointer;
    width: max-content;
    max-width: 340px;
    text-overflow: ellipsis;
    background: var(--color-bg-canvas);
    border-radius: 10px;
    max-height: 300px;
    overflow: auto;
    z-index: 1;
    box-shadow: 0 0 0 1px var(--color-border-primary);
    border: 6px solid transparent;
  }
  &.collapsed .dropdown-content > * {
    opacity: 1;
  }
  &.collapsed .filter-options {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
  .dropdown__mobile {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  &:hover {
    z-index: 1;
    background: hsl(0deg 0% 50% / 15%);
  }

  @media (max-width: 480px) {
    padding: 11px;
    box-sizing: border-box;
    .dropdown__mobile {
      pointer-events: auto;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      cursor: pointer;
    }
    .filter-options {
      display: none;
    }
    &.collapsed .dropdown-content > * {
      opacity: 0.7;
    }
    .dropdown-content__label.has-value {
      opacity: 1;
    }
    &.collapsed .filter-options {
      opacity: unset;
      transform: unset;
      pointer-events: none;
    }
  }
`

export const FilterDateRangeDropdown = styled(
  ({
    onChange,
    onDateRangeSave,
    customDateRangeValue,
    value,
    daysDisabled,
    ...dropdownProps
  }) => {
    const { dateRangeActions } = useContext(DateRangeContext)

    const { editDateRange } = dateRangeActions

    function onChangeDateRangeDropdown(dateRangeValue: string) {
      if (dateRangeValue !== 'custom') {
        return onChange(dateRangeValue)
      }

      let from = new Date()
      let to = new Date()

      if (customDateRangeValue === undefined) {
        from.setDate(from.getDate() - 1)
        from.setHours(0, 0, 0, 0)
      } else {
        to = customDateRangeValue.to
        from = customDateRangeValue.from
      }

      editDateRange({
        daysDisabled: daysDisabled,
        dateRangeName: 'Jobs Date Range',
        dateRangeFrom: from,
        dateRangeTo: to,
        onSaveDateRange: onDateRangeSave
      })
      onChange('custom')
    }

    return (
      <FilterDropdown
        onChange={onChangeDateRangeDropdown}
        value={value}
        {...dropdownProps}
      />
    )
  }
)``

interface IFilterDateRangeTextbox extends React.HTMLAttributes<HTMLDivElement> {
  dateRange: {
    from: Date
    to: Date
  }
  onChangeDateRange: (from: Date, to: Date) => void
  daysDisabled?: boolean
  exposed?: boolean
  disabled?: boolean
  fromLabel?: string
  toLabel?: String
}

export const FilterDateRangeTextbox = styled(
  ({
    onChange,
    dateRange,
    onChangeDateRange,
    daysDisabled,
    className,
    exposed,
    disabled,
    fromLabel,
    toLabel,
    ...rest
  }: IFilterDateRangeTextbox) => {
    const { dateRangeActions } = useContext(DateRangeContext)

    const { editDateRange } = dateRangeActions
    const { from, to } = dateRange

    function onClickEditDateRange() {
      editDateRange({
        daysDisabled: daysDisabled,
        dateRangeName: 'Export Jobs Date Range',
        dateRangeFrom: from,
        dateRangeTo: to,
        onSaveDateRange: onChangeDateRange
      })
    }

    function getDateString(d: Date) {
      const day = d.getDate()
      const year = d.getFullYear()
      const month = d.getMonth() + 1
      return `${!daysDisabled ? `${day}/` : ''}${month}/${year}`
    }

    function onSelectDate(date: Date, dateIsFrom?: boolean) {
      if (dateIsFrom) {
        onChangeDateRange(date, to)
      } else {
        onChangeDateRange(from, date)
      }
    }

    if (exposed) {
      return (
        <div
          className={`${className} exposed${disabled ? ' disabled' : ''}`}
          {...rest}
        >
          <div className="column">
            <div className="label">{fromLabel || 'From'}</div>
            <DatePicker
              daysDisabled={daysDisabled}
              date={from}
              onSelectDate={d => onSelectDate(d, true)}
              disableAfterDate={to}
            />
          </div>
          <div className="column">
            <div className="label">{toLabel || 'To'}</div>
            <DatePicker
              daysDisabled={daysDisabled}
              date={to}
              onSelectDate={d => onSelectDate(d, false)}
              disableBeforeDate={from}
            />
          </div>
        </div>
      )
    }

    return (
      <div
        onClick={onClickEditDateRange}
        className={className}
        {...rest}
      >{`${getDateString(dateRange.from)} - ${getDateString(
        dateRange.to
      )}`}</div>
    )
  }
)`
  &.disabled .column {
    pointer-events: none;
    opacity: 0.6;
    filter: grayscale(0.5);
  }
  &:not(.exposed) {
    font-size: var(--medium);
    font-family: D-DIN Exp;
    color: var(--p-color-1);
    box-sizing: border-box;
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
    background: hsl(0 0% 100% / 0);
    border-radius: 10px;
    transition: all 0.2s ease;
    opacity: 0.8;
  }
  &.exposed {
    display: flex;
    background: var(--color-bg-primary);
    border-radius: 10px;
  }
  &.exposed > * {
    margin: var(--small);
  }
  &.exposed .label {
    margin-bottom: var(--small);
  }
  @media (max-width: 600px) {
    &.exposed {
      background: unset;
      width: 100%;
      justify-content: space-between;
    }
    &.exposed > * {
      margin: 0;
    }
    .date-range-calendar {
      --cell-size: calc((100vw - 60px) / 14.6);
    }
  }
`

export const FilterDropdownOption = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  font-size: var(--medium);
  font-family: D-DIN;
  color: var(--color-text-primary);
  transition: background 0.3s ease;
  &.disabled {
    cursor: default;
    filter: brightness(0.4);
  }
  &:hover {
    background: hsl(0 0% 100% / 0.05);
  }
`

export const FilterButton = styled(({ active, ...rest }) => (
  <ToggleButton
    active={active}
    icon={<FilterIcon />}
    label="filter"
    {...rest}
  />
))`
  margin-bottom: 15px;
`

interface IFilterChips extends React.HTMLAttributes<HTMLDivElement> {
  minimumSelectionCount?: number
  itemLabel: string
  selectedOptions: ISelectionOptions | undefined
  options: { label: string; value: string }[]
  maxToDisplay?: number
  onSave: (saveOptions: string[]) => void
  fetching?: boolean
}

type ISelectionOptions = string[]

export function sortedAscending(
  options: { label: string; value: string }[] | string[]
) {
  return options.sort((a, b) =>
    typeof a === 'string' ? a.localeCompare(b) : a.label.localeCompare(b.label)
  )
}

export const FilterChips = styled(
  ({
    minimumSelectionCount,
    itemLabel,
    maxToDisplay = 5,
    selectedOptions,
    options,
    onSave,
    className,
    fetching,
    ...rest
  }: IFilterChips) => {
    const [scrollX, setScrollX] = useState(0)

    const allIsSelected = isObjectEqual(
      sortedAscending(selectedOptions || []),
      sortedAscending(options.map(a => a.value))
    )

    const { chipsActions } = useContext(ChipsContext)

    const { editChips } = chipsActions

    const noHighlights = allIsSelected || isObjectEqual(selectedOptions, [])

    function showEditChips() {
      if (selectedOptions) {
        editChips(itemLabel, options, selectedOptions, onSave)
      }
    }

    const scrollContainer = useRef<HTMLDivElement>(null)

    function startScrolling() {
      const scrollWidth = scrollContainer.current!.scrollWidth
      const offsetWidth = scrollContainer.current!.offsetWidth
      setScrollX(scrollWidth - offsetWidth)
    }

    function endScrolling() {
      setScrollX(0)
    }

    return (
      <div
        className={`${className} chip-button${
          noHighlights ? ' no-highlights' : ''
        }`}
        onClick={showEditChips}
        onMouseOver={startScrolling}
        onMouseLeave={endScrolling}
        {...rest}
      >
        <div className="scroll-container" ref={scrollContainer}>
          <div
            className={`filter-options`}
            style={{
              transition: `transform ${scrollX * 0.01}s linear`,
              transform: `translateX(-${scrollX}px)`
            }}
          >
            {selectedOptions === undefined ? (
              <span className="no-item">{`${
                fetching ? 'Fetching' : 'No'
              } ${itemLabel}s`}</span>
            ) : (
              (() => {
                let toShowOptions = selectedOptions

                // const moreThanMax = toShowOptions.length > maxToDisplay

                // if (moreThanMax) {
                //   toShowOptions = selectedOptions.slice(0, 5)
                // }

                if (allIsSelected) return `All ${itemLabel}s`
                if (isObjectEqual(selectedOptions, []))
                  return `No ${itemLabel}s`

                return (
                  <React.Fragment>
                    {toShowOptions.length > 0 &&
                      toShowOptions.map(o => (
                        <span className="option" key={o}>
                          {o}
                        </span>
                      ))}
                  </React.Fragment>
                )
              })()
            )}
          </div>
        </div>

        <DropdownArrowIcon />
      </div>
    )
  }
)`
  position: relative;
  width: fit-content;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  opacity: 0.7;
  height: 36px;
  padding: 0 10px;
  border-radius: 10px;
  transition: all 0.3s ease;
  background: hsl(0deg 0% 50% / 15%);
  &.no-highlights {
    background: hsl(0 0% 100% / 0);
  }
  .scroll-container {
    max-width: 300px;
    max-height: 24px;
    overflow: hidden;
    margin-right: 8px;
  }
  &.no-highlights .scroll-container {
    margin-right: unset;
  }
  .filter-options {
    width: max-content;
    margin-right: 8px;
    font-size: var(--medium);
    font-family: D-DIN Exp;
    font-weight: bold;
    color: var(--color-text-primary);
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .filter-options > * {
    padding: 4px 6px;
    border-radius: 100px;
    box-sizing: border-box;
    margin: 0 4px;
    background: var(--p-color-1);
    color: black;
    font-weight: normal;
    width: max-content;
    white-space: nowrap;
    overflow: hidden;
    max-width: 140px;
    flex-shrink: 0;
    display: inline-block;
    text-overflow: ellipsis;
  }
  .filter-options > *:first-child {
    margin-left: 0;
  }
  .option {
    font-size: var(--small);
  }
  .dots {
    border-radius: 2px;
    max-height: 10px;
    padding: 2px 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    opacity: 1;
    margin-right: 0;
    font-size: var(--medium);
    letter-spacing: 1px;
    font-weight: bold;
    color: var(--color-text-primary);
    border: 1px solid var(--p-color-1);
    background: hsl(30 100% 46% / 0.05);
  }
  &:hover {
    background: hsl(0deg 0% 50% / 15%);
    border-radius: 10px;
    opacity: 0.8;
  }
  &:hover .fitler-options {
    color: var(--color-text-primary);
  }
  @media (max-width: 480px) {
    width: 100%;
    box-sizing: border-box;
    .scroll-container {
      max-width: calc(100% - 20px);
    }
  }
`

export const StyledChipsPopup = styled(({ children, ...rest }) => (
  <Popup children={children} {...rest} />
))`
  z-index: 99999999;
  .chips-items {
    display: block;
    background: var(--color-bg-primary-dark);
    border: 1px solid var(--color-border-secondary);
    border-radius: 10px;
    padding: 5px;
    justify-content: flex-start;
    max-height: 350px;
    min-height: 200px;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
  }
  .chip-item {
    border-radius: 100px;
    padding: 5px 10px;
    margin: 4px;
    box-sizing: border-box;
    opacity: 0.6;
    font-size: var(--medium);
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s ease;
    background: black;
    color: var(--color-text-primary);
    border: 1px solid var(--p-color-1);
  }
  .chip-item.active {
    background: var(--p-color-1);
    color: black;
  }
  .chip-item:hover {
    opacity: 1;
    transform: scale(1.05);
  }
  .action-buttons {
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: flex-end;
  }
  .action-buttons > * {
    margin-left: 15px;
  }
`

export interface IChipsProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  selectedOptions: string[] | undefined
  options: { label: string; value: string }[]
  onSaveOptions: (selectedOptions: string[]) => void
  onClose: () => void
  visible: boolean
  title?: string
  placeholder?: string
  prependNode?: React.ReactNode
}

export const Chips = ({
  name,
  onClose,
  visible,
  className,
  selectedOptions,
  options,
  onSaveOptions,
  title,
  placeholder,
  prependNode
}: IChipsProps) => {
  const [search, setSearch] = useState('')

  const defaultSelectedChips = new Set(selectedOptions)

  const [selectedChips, setSelectedChips] = useState(defaultSelectedChips)

  const toggleChipSelection = (value: string) => {
    const newSet = selectedChips
    newSet.has(value) ? newSet.delete(value) : newSet.add(value)
    setSelectedChips(new Set(newSet))
  }

  let sortedOptions = sortedAscending(options) as {
    label: string
    value: string
  }[]
  sortedOptions.sort((a, b) =>
    a.label.includes(search) ? -1 : a.label.localeCompare(b.label) ? 0 : 1
  )

  function saveOption() {
    onSaveOptions(Array.from(selectedChips))
    onClose()
  }

  return (
    <StyledChipsPopup
      title={title || `Select ${name}`}
      onClose={onClose}
      visible={visible}
      width={'500px'}
      className={className}
    >
      {prependNode || null}
      <SearchInput
        placeholder={placeholder || `Search ${name}...`}
        value={search}
        onChange={e => setSearch(e.currentTarget.value)}
      />
      <span className="separator-line" />
      <div className="chips-items">
        {(options.length > 0 &&
          options.map(({ label, value }) => {
            if (label.includes(search) || selectedChips.has(value)) {
              return (
                <span
                  key={value}
                  className={`chip-item${
                    selectedChips.has(value) ? ' active' : ''
                  }`}
                  onClick={() => toggleChipSelection(value)}
                >
                  {value}
                </span>
              )
            } else return null
          })) ||
          null}
      </div>
      <div className="action-buttons">
        <StyledSmallButton
          onClick={() => setSelectedChips(new Set([]))}
          label={'Clear All'}
          className="secondary"
        />
        <StyledSmallButton
          onClick={() =>
            setSelectedChips(new Set(options.map(option => option.value)))
          }
          label={'Select All'}
          className="secondary"
        />
        <StyledSmallButton onClick={saveOption} label={'Save'} active />
      </div>
    </StyledChipsPopup>
  )
}

interface IDateRangerPickerProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string
  fromDate: Date
  toDate: Date
  onSaveDate: (fromDate: Date, toDate: Date) => void
  onClose: () => void
  visible: boolean
  daysDisabled?: boolean
  error?: string
  exposed?: boolean
}

const monthMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

const yearsRange = [] as number[]
const maxYear = 2052
for (let minYear = 2014; minYear <= maxYear; minYear++) {
  yearsRange.push(minYear)
}

function weekCount(
  year: number,
  month_number: number,
  startDayOfWeek?: number
) {
  // month_number is in the range 1..12

  // Get the first day of week week day (0: Sunday, 1: Monday, ...)
  const firstDayOfWeek = startDayOfWeek || 0

  const firstOfMonth = new Date(year, month_number - 1, 1)
  const lastOfMonth = new Date(year, month_number, 0)

  const numberOfDaysInMonth = lastOfMonth.getDate()
  const firstWeekDay = (firstOfMonth.getDay() - firstDayOfWeek + 7) % 7
  const lastOfPreviousMonth = new Date(year, month_number - 1, 0)

  return {
    rows: 6,
    firstOfMonth,
    lastOfMonth,
    lastOfPreviousMonth,
    numberOfDaysInMonth,
    firstWeekDay
  }
}
const StyledDateRangePickerPopup = styled(({ children, ...rest }) => (
  <Popup children={children} {...rest} />
))`
  @media (max-width: 480px) {
    .popup__inner-container {
      width: 100%;
    }
  }
`

export function DateRangePicker({
  name = 'Date Range',
  error = undefined,
  fromDate,
  toDate,
  onSaveDate,
  visible,
  onClose,
  daysDisabled,
  className,
  exposed
}: IDateRangerPickerProps) {
  const updatedFromDate = !daysDisabled
    ? fromDate
    : new Date(fromDate.setDate(1))
  const updatedToDate = !daysDisabled ? toDate : new Date(toDate.setDate(2))
  const [dateRangeFilter, setDateRangeFilter] = useState(
    !daysDisabled ? 'day' : 'month'
  )
  const [selectedDate, setSelectedDate] = useState('from')
  const [fromDateState, setFromDate] = useState(updatedFromDate)
  const [toDateState, setToDate] = useState(updatedToDate)

  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate === 'from'
      ? updatedFromDate.getMonth() + 1
      : updatedToDate.getMonth() + 1
  )

  const [selectedYear, setSelectedYear] = useState(
    selectedDate === 'from'
      ? updatedFromDate.getFullYear()
      : updatedToDate.getFullYear()
  )

  const editingDate = selectedDate === 'from' ? fromDateState : toDateState

  const getSetFunc = () => (selectedDate === 'from' ? setFromDate : setToDate)

  function monthDiff(d1: Date, d2: Date) {
    let months: number | undefined
    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth()
    months += d2.getMonth()
    const diff = months <= 0 ? 0 : months
    return diff
  }

  function saveDateRange() {
    if (!getErrorText()) {
      onSaveDate(fromDateState, toDateState)
      onClose()
    }
  }

  function selectDate(
    date?: number,
    month?: number,
    year?: number,
    preventSwitching = false as boolean
  ) {
    const setFunc = getSetFunc()
    const newDate = new Date(
      year || editingDate.getFullYear(),
      month !== undefined ? month - 1 : editingDate.getMonth(),
      date || editingDate.getDate()
    )

    if (selectedDate === 'from' && newDate.getTime() > toDateState.getTime()) {
      setToDate(newDate)
    } else if (
      selectedDate === 'to' &&
      newDate.getTime() < fromDateState.getTime()
    ) {
      setFromDate(newDate)
    }

    setFunc(newDate)

    if (selectedDate === 'from' && !preventSwitching) {
      setSelectedDate('to')
    } else {
      setSelectedDate('from')
    }
  }

  function getRowsData() {
    const year = selectedYear
    const month = selectedMonth

    const { rows, lastOfMonth, lastOfPreviousMonth, firstWeekDay } = weekCount(
      year,
      month
    )

    type DateCell = {
      value: string
      notSelectedMonth?: boolean
    }

    const dataRows = [] as DateCell[][]

    for (let i = 0; i < rows; i++) {
      dataRows[i] = []
      for (let col = 0; col < 7; col++) {
        // Current cell
        const currentCell = i * 7 + col
        // Cells for previous month
        if (i === 0 && col < firstWeekDay) {
          dataRows[i].push({
            value: `${lastOfPreviousMonth.getDate() -
              (firstWeekDay - col) +
              1}-${month - 1}-${year}`,
            notSelectedMonth: true
          })
          continue
        }

        // Cells for next month
        const daysOverCurrentMonth =
          currentCell - (firstWeekDay + lastOfMonth.getDate() - 1)
        if (daysOverCurrentMonth > 0) {
          dataRows[i].push({
            value: `${daysOverCurrentMonth}-${month + 1}-${year}`,
            notSelectedMonth: true
          })
          continue
        }

        // Cells for current month
        const currentDate = currentCell - firstWeekDay + 1
        dataRows[i].push({
          value: `${currentDate}-${month}-${year}`
        })
      }
    }

    return dataRows
  }

  function previousMonth() {
    // selectDate(undefined, editingDate.getMonth(), undefined, true)
    if (editingDate.getMonth() + 1 === 1) {
      setSelectedMonth(12)
      setSelectedYear(editingDate.getFullYear() - 1)
    } else {
      setSelectedMonth(editingDate.getMonth())
    }
  }

  function nextMonth() {
    // selectDate(undefined, editingDate.getMonth() + 2, undefined, true)
    if (editingDate.getMonth() + 1 === 12) {
      setSelectedMonth(1)
      setSelectedYear(editingDate.getFullYear() + 1)
    } else {
      setSelectedMonth(editingDate.getMonth() + 2)
    }
  }

  function selectMonthFromGrid(month: number) {
    if (daysDisabled) {
      selectDate(undefined, month, undefined, true)
    } else {
      setSelectedMonth(month)
    }
    if (!daysDisabled) setDateRangeFilter('date')
  }

  function selectYearFromGrid(year: number) {
    setSelectedYear(year)
    if (!daysDisabled) setDateRangeFilter('date')
  }

  function getErrorText() {
    const errorText =
      monthDiff(fromDateState, toDateState) > 12
        ? 'Date range should not exceed 1 year'
        : undefined

    return errorText
  }

  function getCalendarContent() {
    const rowsData = getRowsData()

    const editingDateDay = editingDate.getDate()
    const editingDateMonth = editingDate.getMonth() + 1
    const editingDateYear = editingDate.getFullYear()

    const errorText = getErrorText()
    return (
      <DatePickerContainer>
        {errorText && <div className="error-text">{errorText}</div>}
        <div className="date-range-items">
          <div className="date-range-labels">
            <div className="date-range-label">From</div>
            <div
              className={`date-range-value${
                selectedDate === 'from' ? ' active' : ''
              }`}
              onClick={() => setSelectedDate('from')}
            >
              {`${
                !daysDisabled ? `${formatNumber(fromDateState.getDate())}/` : ''
              }${formatNumber(
                fromDateState.getMonth() + 1
              )}/${fromDateState.getFullYear()}`}
            </div>
            <div className="date-range-label">To</div>
            <div
              className={`date-range-value${
                selectedDate === 'to' ? ' active' : ''
              }`}
              onClick={() => setSelectedDate('to')}
            >
              {`${
                !daysDisabled ? `${formatNumber(toDateState.getDate())}/` : ''
              }${formatNumber(
                toDateState.getMonth() + 1
              )}/${toDateState.getFullYear()}`}
            </div>
          </div>
          <div className="date-range-calendar">
            <div className="calendar-header">
              <div className="header-arrow left" onClick={previousMonth}>
                <BackArrowIcon />
              </div>
              <div className="header-date">
                <span
                  className="header-date__month"
                  onClick={() => setDateRangeFilter('month')}
                >
                  {monthMap[selectedMonth]}
                </span>
                <span
                  className="header-date__year"
                  onClick={() => setDateRangeFilter('year')}
                >
                  {selectedYear}
                </span>
              </div>
              <div className="header-arrow right" onClick={nextMonth}>
                <BackArrowIcon />
              </div>
            </div>
            <div className={`calendar${daysDisabled ? ' days-disabled' : ''}`}>
              <div className="row header">
                <span>Su</span>
                <span>Mo</span>
                <span>Tu</span>
                <span>We</span>
                <span>Th</span>
                <span>Fri</span>
                <span>Sa</span>
              </div>
              <div
                className={`months-grid${
                  dateRangeFilter === 'month' ? ' active' : ''
                }`}
              >
                {Object.keys(monthMap).map(key => (
                  <button
                    type="button"
                    key={`grid-month-${monthMap[key]}`}
                    className={`month-item${
                      Number(key) === Number(editingDate.getMonth() + 1)
                        ? ' active'
                        : ''
                    }`}
                    onClick={() => selectMonthFromGrid(Number(key))}
                  >
                    {String(monthMap[key]).substr(0, 3)}
                  </button>
                ))}
              </div>
              <div
                className={`years-grid${
                  dateRangeFilter === 'year' ? ' active' : ''
                }`}
              >
                {yearsRange.map(year => (
                  <button
                    type="button"
                    key={`grid-year-${year}`}
                    className={`year-item${
                      year === editingDate.getFullYear() ? ' active' : ''
                    }`}
                    onClick={() => selectYearFromGrid(year)}
                  >
                    {year}
                  </button>
                ))}
              </div>
              {rowsData &&
                rowsData.map((row, index) => {
                  return (
                    <div className="row" key={`row-${index}`}>
                      {row.map(({ value, notSelectedMonth }) => {
                        const valArray = value.split('-')
                        const day = parseInt(valArray[0])
                        const month = parseInt(valArray[1])
                        const year = parseInt(valArray[2])

                        const active =
                          editingDateMonth === month &&
                          day === editingDateDay &&
                          year === editingDateYear

                        const highlight =
                          fromDateState.getTime() <= toDateState.getTime() &&
                          new Date(year, month - 1, day).getTime() <=
                            toDateState.getTime() &&
                          new Date(year, month - 1, day).getTime() >=
                            fromDateState.getTime()

                        const date = new Date()
                        date.setDate(day)
                        date.setMonth(month)
                        date.setFullYear(year)
                        const disabled = false

                        return (
                          <button
                            type="button"
                            key={value}
                            className={`${
                              notSelectedMonth ? 'not-selected-month' : ''
                            }${highlight ? ' highlight' : ''}${
                              active ? ' active' : ''
                            }${disabled ? ' disabled' : ''}`}
                            onClick={() => selectDate(day, month, year)}
                          >
                            {day}
                          </button>
                        )
                      })}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <StyledSmallButton
            onClick={onClose}
            label={'Cancel'}
            className="secondary cancel"
          />
          <StyledSmallButton onClick={saveDateRange} label={'Save'} active />
        </div>
      </DatePickerContainer>
    )
  }

  if (exposed) return getCalendarContent()
  else
    return (
      <StyledDateRangePickerPopup
        title={name}
        onClose={onClose}
        visible={visible}
        maxWidth={'450px'}
        className={className}
      >
        {getCalendarContent()}
      </StyledDateRangePickerPopup>
    )
}

const DatePickerContainer = styled.div`
  --cell-size: 30px;
  user-select: none;
  z-index: 100;
  .date-range-items {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .date-range-labels {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .date-range-label {
    font-family: D-DIN Exp;
    opacity: 0.7;
    font-weight: bold;
    margin-bottom: 10px;
    user-select: none;
  }
  .date-range-value {
    padding: 12px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background: hsl(0 0% 100% / 0.05);
    margin-right: 38px;
    font-size: var(--medium);
    user-select: none;
    margin-bottom: 20px;
    border: 1px solid transparent;
    color: var(--color-text-primary);
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    width: 100%;
    min-width: 90px;
  }
  .date-range-value:hover {
    opacity: 0.8;
  }
  .date-range-value.active {
    color: var(--p-color-1);
    opacity: 1;
    border-color: var(--p-color-1);
  }
  .action-buttons {
    margin-left: -15px;
    width: calc(100% + var(--cell-size));
    margin-bottom: -15px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
  }
  .action-buttons > * {
    margin-left: 15px;
    min-width: 80px;
  }
  .action-buttons > *:hover {
    background: var(--p-color-1);
    color: var(--color-text-primary);
  }
  .action-buttons > .cancel {
    border: unset;
    opacity: 1;
    color: var(--p-color-red);
  }
  .action-buttons > .cancel:hover {
    background: var(--p-color-red);
    color: var(--color-text-primary);
  }
  .date-range-calendar {
    display: flex;
    flex-direction: column;
    width: 250px;
    min-height: 260px;
    background: var(--color-bg-canvas-inset);
    border-radius: 10px;
    box-sizing: border-box;
    font-family: D-DIN;
    align-items: center;
    overflow: hidden;
    margin: auto;
    border: 1px solid var(--color-border-primary);
  }
  .calendar-header {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    width: calc(var(--cell-size) * 7);
    position: relative;
  }
  .header-date {
    display: flex;
    align-items: center;
  }
  .header-date > * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    cursor: pointer;
    opacity: 0.7;
    text-transform: capitalize;
    transition: all 0.3s ease;
  }
  .header-date > *:hover {
    background: var(--color-bg-canvas);
    opacity: 1;
  }
  .calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    border-top: 1px solid var(--color-border-primary);
  }
  .calendar .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .calendar.days-disabled .row {
    opacity: 0;
    pointer-events: none;
  }
  .calendar .row > * {
    width: var(--cell-size);
    height: var(--cell-size);
    display: flex;
    align-items: center;
    justify-content: center;
    background: hsl(0 0% 100% / 0);
    transition: all 0.3s ease;
    opacity: 1;
  }
  .calendar .row > button {
    color: var(--color-text-primary);
    transition: all 0.15s ease;
  }
  .calendar .row > button:focus {
    outline: unset;
  }
  .calendar .row > button.not-selected-month {
    opacity: 0.4;
  }
  .calendar .row > button:hover {
    background: hsl(0 0% 100% / 0.15);
  }
  .calendar .row > button.highlight {
    background: hsl(0 0% 0% / 0.3);
  }
  .calendar .row > button.active {
    color: var(--p-color-1);
    font-weight: bold;
    background: hsl(0 0% 0% / 0.4);
  }
  .calendar .row > button.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  .calendar .row.header > * {
    opacity: 0.4;
    font-size: var(--small);
  }
  .header-arrow {
    width: var(--cell-size);
    height: var(--cell-size);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .header-arrow.left {
    left: 0;
  }
  .header-arrow.right {
    right: 0;
  }
  .header-arrow.right svg {
    transform: rotate(180deg);
  }
  .header-arrow svg path {
    transition: all 0.3s ease;
  }
  .header-arrow:hover svg path {
    opacity: 1;
  }
  .header-arrow:hover {
    background: var(--color-bg-canvas);
  }
  .months-grid,
  .years-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    position: absolute;
    z-index: 1;
    transition: all 0.3s ease;
    transform: scale(0.5);
    opacity: 0;
    pointer-events: none;
    width: 100%;
  }
  .months-grid.active,
  .years-grid.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
  .months-grid > *,
  .years-grid > * {
    flex-basis: 33.33%;
    background: var(--color-bg-canvas);
    color: var(--color-text-primary);
    cursor: pointer;
    transition: all 0.3s ease;
    border-top: 1px solid var(--border-color-primary);
  }
  .months-grid > *:hover,
  .years-grid > *:hover {
    background: var(--color-bg-canvas-inset);
  }
  .months-grid > *:focus,
  .years-grid > *:focus {
    outline: unset;
  }
  .months-grid > *.active,
  .years-grid > *.active {
    color: var(--p-color-1);
    background: var(--color-bg-primary-dark);
  }
  .years-grid {
    overflow: auto;
  }
  .years-grid > * {
    min-height: 40px;
  }
  .error-text {
    color: var(--p-color-red);
    opacity: 0.8;
    margin-bottom: 10px;
    font-size: var(--medium);
  }
  .month-item.disabled,
  .year-item.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  @media (max-width: 480px) {
    width: 100%;
    .date-range-labels {
      width: 70px;
    }
    .date-range-value {
      width: 100%;
      margin-bottom: 10px;
      min-width: 80px;
      padding: 8px;
    }
    .date-range-value.active {
      background: hsl(0 0% 100% / 0.075);
      border: 1px solid var(--p-color-1);
      transform: unset;
    }
    .date-range-calendar {
      width: 100%;
      min-height: unset;
    }
  }
`

interface IDatePicker {
  date: Date
  onSelectDate: (date: Date) => void
  daysDisabled?: boolean
  disableBeforeDate?: Date
  disableAfterDate?: Date
  className?: string
}

export function DatePicker({
  date,
  onSelectDate,
  daysDisabled,
  disableBeforeDate,
  disableAfterDate,
  className
}: IDatePicker) {
  const [dateRangeFilter, setDateRangeFilter] = useState(
    !daysDisabled ? 'day' : 'month'
  )
  const [dateState, setDate] = useState(date)
  const editingDate = dateState

  function getRowsData() {
    const year = dateState.getFullYear()
    const month = dateState.getMonth() + 1

    const { rows, lastOfMonth, lastOfPreviousMonth, firstWeekDay } = weekCount(
      year,
      month
    )

    type DateCell = {
      value: string
      notSelectedMonth?: boolean
    }

    const dataRows = [] as DateCell[][]

    for (let i = 0; i < rows; i++) {
      dataRows[i] = []
      for (let col = 0; col < 7; col++) {
        // Current cell
        const currentCell = i * 7 + col
        // Cells for previous month
        if (i === 0 && col < firstWeekDay) {
          dataRows[i].push({
            value: `${lastOfPreviousMonth.getDate() -
              (firstWeekDay - col) +
              1}-${month - 1}-${year}`,
            notSelectedMonth: true
          })
          continue
        }

        // Cells for next month
        const daysOverCurrentMonth =
          currentCell - (firstWeekDay + lastOfMonth.getDate() - 1)
        if (daysOverCurrentMonth > 0) {
          dataRows[i].push({
            value: `${daysOverCurrentMonth}-${month + 1}-${year}`,
            notSelectedMonth: true
          })
          continue
        }

        // Cells for current month
        const currentDate = currentCell - firstWeekDay + 1
        dataRows[i].push({
          value: `${currentDate}-${month}-${year}`
        })
      }
    }

    return dataRows
  }

  // function previousMonth() {
  //   selectDate(undefined, editingDate.getMonth(), undefined, true)
  // }

  // function nextMonth() {
  //   selectDate(undefined, editingDate.getMonth() + 2, undefined, true)
  // }

  // function selectMonthFromGrid(month: number) {
  //   selectDate(undefined, month, undefined, true)
  //   if (!daysDisabled) setDateRangeFilter('date')
  // }

  // function selectYearFromGrid(year: number) {
  //   selectDate(undefined, undefined, year, true)
  //   if (!daysDisabled) setDateRangeFilter('date')
  // }

  function previousMonth() {
    selectDate(undefined, editingDate.getMonth(), undefined)
  }

  function nextMonth() {
    selectDate(undefined, editingDate.getMonth() + 2, undefined)
  }

  function selectMonthFromGrid(month: number) {
    selectDate(undefined, month, undefined)
    if (!daysDisabled) setDateRangeFilter('date')
  }

  function selectYearFromGrid(year: number) {
    selectDate(undefined, undefined, year)
    if (!daysDisabled) setDateRangeFilter('date')
  }

  function selectDate(date?: number, month?: number, year?: number) {
    const newDate = new Date(
      year || editingDate.getFullYear(),
      month !== undefined ? month - 1 : editingDate.getMonth(),
      date || editingDate.getDate()
    )
    setDate(newDate)
    onSelectDate(newDate)
  }

  const rowsData = getRowsData()

  const editingDateDay = editingDate.getDate()
  const editingDateMonth = editingDate.getMonth() + 1
  const editingDateYear = editingDate.getFullYear()

  return (
    <DatePickerContainer className={className || ''}>
      <div className="date-range-calendar">
        <div className="calendar-header">
          <div className="header-arrow left" onClick={previousMonth}>
            <BackArrowIcon />
          </div>
          <div className="header-date">
            <span
              className="header-date__month"
              onClick={() => setDateRangeFilter('month')}
            >
              {monthMap[editingDate.getMonth() + 1]}
            </span>
            <span
              className="header-date__year"
              onClick={() => setDateRangeFilter('year')}
            >
              {editingDate.getFullYear()}
            </span>
          </div>
          <div className="header-arrow right" onClick={nextMonth}>
            <BackArrowIcon />
          </div>
        </div>
        <div className={`calendar${daysDisabled ? ' days-disabled' : ''}`}>
          <div className="row header">
            <span>Su</span>
            <span>Mo</span>
            <span>Tu</span>
            <span>We</span>
            <span>Th</span>
            <span>Fri</span>
            <span>Sa</span>
          </div>
          <div
            className={`months-grid${
              dateRangeFilter === 'month' ? ' active' : ''
            }`}
          >
            {Object.keys(monthMap).map(key => {
              let disabled = false
              if (disableBeforeDate) {
                const currentDate = Date.parse(
                  `${date.getFullYear()}/${monthMap[key]}/31`
                )
                if (currentDate < disableBeforeDate.getTime()) {
                  disabled = true
                }
              }

              if (disableAfterDate) {
                const currentDate = Date.parse(
                  `${date.getFullYear()}/${monthMap[key]}/1`
                )
                if (currentDate > disableAfterDate.getTime()) {
                  disabled = true
                }
              }

              return (
                <button
                  type="button"
                  key={`grid-month-${monthMap[key]}`}
                  className={`month-item${
                    Number(key) === Number(editingDate.getMonth() + 1)
                      ? ' active'
                      : ''
                  }${disabled ? ' disabled' : ''}`}
                  onClick={() => selectMonthFromGrid(Number(key))}
                >
                  {String(monthMap[key]).substr(0, 3)}
                </button>
              )
            })}
          </div>
          <div
            className={`years-grid${
              dateRangeFilter === 'year' ? ' active' : ''
            }`}
          >
            {yearsRange.map(year => {
              let disabled = false
              if (disableBeforeDate) {
                if (year < disableBeforeDate.getFullYear()) {
                  disabled = true
                }
              }

              if (disableAfterDate) {
                if (year > disableAfterDate.getFullYear()) {
                  disabled = true
                }
              }

              return (
                <button
                  type="button"
                  key={`grid-year-${year}`}
                  className={`year-item${
                    year === editingDate.getFullYear() ? ' active' : ''
                  }${disabled ? ' disabled' : ''}`}
                  onClick={() => selectYearFromGrid(year)}
                >
                  {year}
                </button>
              )
            })}
          </div>
          {rowsData &&
            rowsData.map((row, index) => {
              return (
                <div className="row" key={`row-${index}`}>
                  {row.map(({ value, notSelectedMonth }) => {
                    const valArray = value.split('-')
                    const day = parseInt(valArray[0])
                    const month = parseInt(valArray[1])
                    const year = parseInt(valArray[2])

                    const active =
                      editingDateMonth === month &&
                      day === editingDateDay &&
                      year === editingDateYear

                    const highlight = false
                    let disabled = false

                    if (disableBeforeDate) {
                      const currentDate = Date.parse(`${year}/${month}/${day}`)
                      if (currentDate < disableBeforeDate.getTime()) {
                        disabled = true
                      }
                    }

                    if (disableAfterDate) {
                      const currentDate = Date.parse(`${year}/${month}/${day}`)
                      if (currentDate > disableAfterDate.getTime()) {
                        disabled = true
                      }
                    }

                    return (
                      <button
                        type="button"
                        key={value}
                        className={`${
                          notSelectedMonth ? 'not-selected-month' : ''
                        }${highlight ? ' highlight' : ''}${
                          active ? ' active' : ''
                        }${disabled ? ' disabled' : ''}`}
                        onClick={() => selectDate(day, month, year)}
                      >
                        {day}
                      </button>
                    )
                  })}
                </div>
              )
            })}
        </div>
      </div>
    </DatePickerContainer>
  )
}

/**
 * Return an integer range within [min, min + total) of given length centered
 * around the current page number.
 */
function getPagingRange(current, { min = 1, total = 20, length = 5 } = {}) {
  if (length > total) length = total

  let start = current - Math.floor(length / 2)
  start = Math.max(start, min)
  start = Math.min(start, min + total - length)

  return Array.from({ length: length }, (el, i) => start + i)
}

interface INavigationBar extends React.HTMLAttributes<HTMLDivElement> {
  onPageSelect: (pageNumber: number) => void
  onLimitChange: (limitCount: number) => void
  maxPage: number
  currentPage: number
}

export const NavigationBar = styled(
  ({
    className,
    onPageSelect,
    onLimitChange,
    maxPage,
    currentPage
  }: INavigationBar) => {
    if (maxPage === 0) return null

    const navigations = getPagingRange(currentPage, {
      min: 1,
      total: maxPage,
      length: 7
    })

    return (
      <div className={className}>
        <span
          className={`arrow previous-page${
            currentPage === 1 ? ' disabled' : ''
          }`}
          onClick={() => onPageSelect(currentPage - 1)}
        >
          <BackArrowIcon />
        </span>
        {navigations.map((pageNumber, index) => {
          if (currentPage > 2 && index === 0) {
            return (
              <span
                key={`nav-${index}`}
                className={`page-number${
                  pageNumber === currentPage ? ' active' : ''
                }`}
                onClick={() => onPageSelect(1)}
              >
                {1}
              </span>
            )
          } else if (
            (currentPage > 3 && index === 1) ||
            (currentPage < maxPage - 3 && index === navigations.length - 2)
          ) {
            return (
              <div key={`nav-${index}`} className="page-number empty">
                ...
              </div>
            )
          } else if (
            currentPage < maxPage - 3 &&
            index === navigations.length - 1
          ) {
            return (
              <span
                key={`nav-${index}`}
                className={`page-number${
                  maxPage === currentPage ? ' active' : ''
                }`}
                onClick={() => onPageSelect(maxPage)}
              >
                {maxPage}
              </span>
            )
          } else {
            return (
              <span
                key={`nav-${index}`}
                className={`page-number${
                  pageNumber === currentPage ? ' active' : ''
                }`}
                onClick={() => onPageSelect(pageNumber)}
              >
                {pageNumber}
              </span>
            )
          }
        })}
        <span
          className={`arrow next-page${
            currentPage === maxPage ? ' disabled' : ''
          }`}
          onClick={() => onPageSelect(currentPage + 1)}
        >
          <BackArrowIcon />
        </span>
      </div>
    )
  }
)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;
  box-sizing: border-box;
  flex-wrap: wrap;
  user-select: none;
  > * {
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  > .page-number {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 5px;
    margin: 0 5px;
  }
  > .page-number.active {
    border: 1px solid var(--p-color-1);
    opacity: 1;
    transform: scale(1.15);
    background: var(--p-color-1);
    color: var(--color-text-primary);
  }
  > *:hover {
    opacity: 1;
    transform: scale(1.05);
  }
  .page-number.empty {
    pointer-events: none;
    border: none;
    margin: 0;
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    opacity: 0.7;
    margin: 0 5px;
  }
  .arrow svg {
    width: 12px;
    height: auto;
  }
  .arrow.next-page svg {
    transform: rotate(180deg);
  }
  .arrow svg path {
    opacity: 1;
  }
  .arrow:hover {
    opacity: 1;
  }
  .arrow.disabled {
    pointer-events: none;
    opacity: 0.1;
  }
`
