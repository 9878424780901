export const PRINTERS_GET_LIST_PENDING = 'PRINTERS_GET_LIST_PENDING'
export const PRINTERS_GET_LIST_SUCCESS = 'PRINTERS_GET_LIST_SUCCESS'
export const PRINTERS_GET_LIST_FAILED = 'PRINTERS_GET_LIST_FAILED'
export const PRINTER_UPLOAD_JOB_PENDING = 'PRINTER_UPLOAD_JOB_PENDING'
export const PRINTER_UPLOAD_JOB_SUCCESS = 'PRINTER_UPLOAD_JOB_SUCCESS'
export const PRINTER_UPLOAD_JOB_FAILED = 'PRINTER_UPLOAD_JOB_FAILED'
export const PRINTER_DELETE_JOB_PENDING = 'PRINTER_DELETE_JOB_PENDING'
export const PRINTER_DELETE_JOB_SUCCESS = 'PRINTER_DELETE_JOB_SUCCESS'
export const PRINTER_DELETE_JOB_FAILED = 'PRINTER_DELETE_JOB_FAILED'
export const PRINTER_GET_ALL_QUEUED_JOBS = 'PRINTER_GET_ALL_QUEUED_JOBS'
export const PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS =
  'PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS'
export const PRINTER_CHANGE_PRINTER_NAME_PENDING =
  'PRINTER_CHANGE_PRINTER_NAME_PENDING'
export const PRINTER_CHANGE_PRINTER_NAME_SUCCESS =
  'PRINTER_CHANGE_PRINTER_NAME_SUCCESS'
export const PRINTER_CHANGE_PRINTER_NAME_FAILED =
  'PRINTER_CHANGE_PRINTER_NAME_FAILED'
export const PRINTER_GET_PENDING_FILES = 'PRINTER_GET_PENDING_FILES'
export const PRINTER_GET_PENDING_FILES_SUCCESS =
  'PRINTER_GET_PENDING_FILES_SUCCESS'
export const PRINTER_GET_PENDING_FILES_FAILED =
  'PRINTER_GET_PENDING_FILES_FAILED'
export const GET_JOBS_FROM_IOT_SUCCESS = 'GET_JOBS_FROM_IOT_SUCCESS'
export const PRINTER_GRAPH_STATS_PENDING = 'PRINTER_GRAPH_STATS_PENDING'
export const PRINTER_GRAPH_STATS_SUCCESS = 'PRINTER_GRAPH_STATS_SUCCESS'
export const PRINTER_GRAPH_STATS_FAILED = 'PRINTER_GRAPH_STATS_FAILED'
export const PRINTER_GET_LATEST_TEFLON = 'PRINTER_GET_LATEST_TEFLON'
export const PRINTER_GET_LATEST_TEFLON_SUCCESS =
  'PRINTER_GET_LATEST_TEFLON_SUCCESS'
export const PRINTER_GET_LATEST_TEFLON_FAILURE =
  'PRINTER_GET_LATEST_TEFLON_FAILURE'

export const PRINTER_MULTIPLE_UPLOAD_JOB_PENDING =
  'PRINTER_MULTIPLE_UPLOAD_JOB_PENDING'
export const PRINTER_MULTIPLE_UPLOAD_JOB_SUCCESS =
  'PRINTER_MULTIPLE_UPLOAD_JOB_SUCCESS'
export const PRINTER_MULTIPLE_UPLOAD_JOB_FAILURE =
  'PRINTER_MULTIPLE_UPLOAD_JOB_FAILURE'

export const PRINTER_GET_LATEST_LCD = 'PRINTER_GET_LATEST_LCD'
export const PRINTER_GET_LATEST_LCD_SUCCESS = 'PRINTER_GET_LATEST_LCD_SUCCESS'
export const PRINTER_GET_LATEST_LCD_FAILURE = 'PRINTER_GET_LATEST_LCD_FAILURE'

export const PRINTER_CREATE_GROUP = 'PRINTER_CREATE_GROUP'
export const PRINTER_CREATE_GROUP_SUCCESS = 'PRINTER_CREATE_GROUP_SUCCESS'
export const PRINTER_CREATE_GROUP_FAILURE = 'PRINTER_CREATE_GROUP_FAILURE'

export const PRINTER_UPDATE_GROUP = 'PRINTER_UPDATE_GROUP'
export const PRINTER_UPDATE_GROUP_SUCCESS = 'PRINTER_UPDATE_GROUP_SUCCESS'
export const PRINTER_UPDATE_GROUP_FAILURE = 'PRINTER_UPDATE_GROUP_FAILURE'

// get list of groups
export const PRINTER_GET_GROUPS = 'PRINTER_GET_GROUPS'
export const PRINTER_GET_GROUPS_SUCCESS = 'PRINTER_GET_GROUPS_SUCCESS'
export const PRINTER_GET_GROUPS_FAILURE = 'PRINTER_GET_GROUPS_FAILURE'

// delete group
export const PRINTER_DELETE_GROUP = 'PRINTER_DELETE_GROUP'
export const PRINTER_DELETE_GROUP_SUCCESS = 'PRINTER_DELETE_GROUP_SUCCESS'
export const PRINTER_DELETE_GROUP_FAILURE = 'PRINTER_DELETE_GROUP_FAILURE'

// start remote print
export const PRINTER_START_REMOTE_PRINT = 'PRINTER_START_REMOTE_PRINT'
export const PRINTER_START_REMOTE_PRINT_SUCCESS =
  'PRINTER_START_REMOTE_PRINT_SUCCESS'
export const PRINTER_START_REMOTE_PRINT_FAILURE =
  'PRINTER_START_REMOTE_PRINT_FAILURE'

export const Materials = {
  surgicalguidefull: 'Surgical Guide Full',
  surgicalguidequad: 'Surgical Guide Quad',
  studymodel: 'Study Model',
  koismodel: 'Kois Model',
  orthoarches: 'Ortho Arches',
  'structo-model': 'Structo Model',
  'pro3dure-guide': 'Pro3dure Guide'
}
