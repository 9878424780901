import React from 'react'
import styled from 'styled-components'

const ReloadHeader = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  z-index: 10;
  background: black;
  color: var(--p-color-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 4px hsl(0deg 0% 0% / 15%);
  width: fit-content;
  max-width: calc(100vw - 40px);
  animation: fadeInReloadOption 0.3s ease forwards;
  span {
    margin-right: 20px;
    font-weight: bold;
    white-space: nowrap;
  }
  button {
    background: var(--p-color-1);
    padding: 6px 8px;
    border-radius: 4px;
    filter: brightness(1.2);
    opacity: 0.88;
    cursor: pointer;
  }
  button: hover {
    opacity: 1;
  }
  &.hidden {
    animation: fadeOutReloadOption 0.3s ease forwards;
  }
  @media (max-width: 480px) {
    font-size: var(--small);
    padding: 15px;
    span {
      margin-right: 10px;
    }
    button {
      font-size: var(--small);
      padding: 6px;
    }
  }
  @keyframes fadeInReloadOption {
    0% {
      transform: translate(-50%, 200px);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
  @keyframes fadeOutReloadOption {
    0% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, 200px);
      opacity: 0;
    }
  }
`

interface IReloadHeaderProps {
  isMounted?: boolean
}

export default function({ isMounted }: IReloadHeaderProps) {
  function reload() {
    window.location.reload(true)
  }

  return (
    <ReloadHeader className={`${!isMounted ? 'hidden' : ''}`}>
      <span>A new version of MyStructo is available</span>
      <button onClick={reload}>REFRESH</button>
    </ReloadHeader>
  )
}
