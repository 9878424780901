import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Popup,
  Dropdown,
  Button,
  Loader,
  VeloxIcon,
  DentaformIcon,
  PlusIcon,
  isEmpty,
  DelayedComponents,
  SpinnerLoader,
  ExclamationIcon,
  CheckIcon,
  DeleteIcon
} from 'backpack'
import isEqual from 'lodash.isequal'

import { FileUpload } from '../FileUpload'
import { addNewJob } from '../../redux/past-prints/actions'
import { IUploadingFiles } from '../../redux/printer/types'

type File = FileList | null

interface Printer {
  name: string
  customPrinterName: string
  isVelox?: boolean
  isOnline?: boolean
}

type PrinterList = Printer[]

const StyledForm = styled.form`
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  .selected-printer__image {
    text-align: center;
    padding: 10px 0 25px 0;
  }
  .selected-printer__image svg {
    height: 120px;
    width: auto;
  }
  &.disabled .dropdown-box {
    pointer-events: none;
    opacity: 0.7;
  }
  @media (max-width: 480px) {
    min-height: unset;
  }
`

const StyledPlusIcon = styled(PlusIcon)`
  path {
    stroke: var(--color-text-primary);
  }
`

const MultipleFileProgress = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fadeIn 0.3s ease forwards;
  .loader-mask {
    background: var(--color-bg-mask);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .file {
    display: flex;
    align-items: center;
    width: 400px;
    max-width: 100%;
    justify-content: space-between;
    background: var(--color-bg-canvas);
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-secondary);
    margin: 5px 0;
  }
  .file .name {
    color: var(--color-text-primary);
    font-weight: bold;
    font-size: 18px;
  }
  .file svg {
    transition: transform 0.2s ease;
  }
  .file.success .name {
    color: var(--color-green-primary);
  }
  .file.success .progress svg {
    fill: var(--color-green-primary);
    transform: scale(0.95);
  }
  .file.failed .name {
    color: var(--color-red-primary);
  }
  .file.failed .progress svg {
    fill: var(--color-red-primary);
    transform: scale(1);
  }
  .progress > * {
    width: 30px;
    height: 30px;
    transform: scale(0.6);
  }
  .progress-status {
    transition: all 0.2s ease;
    margin-top: 15px;
    color: var(--color-brand-text-primary);
    font-weight: bold;
    letter-spacing: 0.5px;
    animation: fadeInOutPartial 2.2s infinite ease forwards;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.3;
  }
  .progress-status.success {
    color: var(--color-green-secondary);
    transform: scale(1.1);
    animation: unset;
  }
  .progress-status.failed {
    color: var(--color-red-secondary);
    animation: shake 150ms 2 linear;
  }

  @keyframes shake {
    0% {
      transform: translate(3px, 0);
    }
    50% {
      transform: translate(-3px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`

const FailedUploadsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 130px;
  overflow: auto;
  padding: var(--small);
  box-sizing: border-box;
  background: var(--color-bg-canvas);
  width: 100%;
  line-height: 1.3;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: center;
  .failed-file {
    color: var(--color-red-secondary);
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;
  }
  .failed-file .delete-icon {
    fill: var(--color-red-secondary);
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }
  .failed-file .delete-icon:hover {
    opacity: 1;
  }
`

export interface AddJobFormStates {
  selectedPrinterValue?: string
  selectedFile?: File
  previousPreselectedFile?: File
  failedUploads?: File
}

export interface AddJobFormProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  printerList: PrinterList
  onClose: () => void
  onAddJob: (file: File, printerName: string) => void
  preselectedFile?: File
  fileStoreKey?: string
  cloneFileName?: string
  selectedPrinterName?: string
  isMounted?: boolean
  submitting?: boolean
  uploadingFiles: IUploadingFiles[]
  actions: {
    addNewJob: typeof addNewJob
  }
}

class AddJobForm extends Component<AddJobFormProps, AddJobFormStates> {
  constructor(props: AddJobFormProps) {
    super(props)
    this.state = {
      selectedPrinterValue:
        props.selectedPrinterName ||
        (props.printerList &&
          props.printerList.length > 0 &&
          props.printerList[0].name) ||
        undefined,
      selectedFile: props.preselectedFile || null,
      previousPreselectedFile: props.preselectedFile || null,
      failedUploads: undefined
    }
  }
  static getDerivedStateFromProps(
    props: AddJobFormProps,
    state: AddJobFormStates
  ) {
    const newState = {} as AddJobFormStates
    if (state.selectedPrinterValue === undefined) {
      newState.selectedPrinterValue =
        (props.printerList &&
          props.printerList.length > 0 &&
          props.printerList[0].name) ||
        undefined
    }

    if (state.previousPreselectedFile !== props.preselectedFile) {
      newState.previousPreselectedFile = props.preselectedFile
      newState.selectedFile = props.preselectedFile
    }

    return isEmpty(newState) ? null : newState
  }

  componentDidUpdate = (prevProps: AddJobFormProps) => {
    if (!isEqual(this.props.uploadingFiles, prevProps.uploadingFiles)) {
      if (!this.props.uploadingFiles) return
      const uploadingInProgress = this.props.uploadingFiles.find(
        f => f.uploadInProgress
      )
      if (!uploadingInProgress) {
        const uploadFails = [
          ...this.props.uploadingFiles.filter(f => f.uploadSuccess === false)
        ]
        if (!uploadFails.length) {
          setTimeout(this.props.onClose, 2000)
        } else {
          const { selectedFile } = this.state
          if (!selectedFile) return

          const newFileList = {}
          for (let i = 0; i < selectedFile.length; i++) {
            for (let x = 0; x < uploadFails.length; x++) {
              if (selectedFile[i].name === uploadFails[x].name) {
                const newFileListLength = Object.keys(newFileList).length
                newFileList[newFileListLength] = selectedFile[i]
              }
            }
          }
          const newFileListLength = Object.keys(newFileList).length
          newFileList['length'] = newFileListLength
          //@ts-ignore
          this.setState({ failedUploads: newFileList, selectedFile: undefined })
        }
      }
    }
  }

  isFormValid = () => {
    const { selectedPrinterValue, selectedFile } = this.state
    if (!selectedPrinterValue || !selectedFile || !this.getSelectedPrinter()) {
      return false
    }
    return true
  }

  getSelectedPrinter = () => {
    const options = this.getPrinterDropdownOptions()
    const { selectedPrinterValue } = this.state
    if (!selectedPrinterValue || !options) return null
    const selectedPrinter = options.find(
      option => option.value === selectedPrinterValue
    )
    return selectedPrinter
  }

  onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { selectedFile, failedUploads } = this.state
    const { onAddJob, fileStoreKey, actions } = this.props

    if (failedUploads && this.state.selectedPrinterValue) {
      onAddJob(failedUploads, this.state.selectedPrinterValue)
    }

    if (fileStoreKey && this.state.selectedPrinterValue) {
      actions.addNewJob(fileStoreKey, this.state.selectedPrinterValue)
    } else {
      if (this.isFormValid()) {
        const selectedPrinter = this.getSelectedPrinter()
        onAddJob(selectedFile || null, selectedPrinter!.value)
      }
    }
  }

  onSelectPrinter = (value: string) =>
    this.setState({ selectedPrinterValue: value })

  onFileSelect = (selectedFile: File | null) => {
    this.setState({ selectedFile, failedUploads: undefined })
  }

  getSelectedPrinterType = () => {
    const { selectedPrinterValue } = this.state
    if (!selectedPrinterValue) return null
    const { printerList } = this.props
    const isVelox = printerList.find(p => p.name === selectedPrinterValue)!
      .isVelox

    return {
      isVelox
    }
  }

  getPrinterDropdownOptions = () => {
    const { printerList } = this.props
    return printerList.map(({ name, customPrinterName }) => ({
      label: customPrinterName,
      value: name
    }))
    // return printerList && printerList.length > 0
    //   ? printerList
    //       .filter(({ isOnline }) => isOnline)
    //       .map(({ name, customPrinterName }) => ({
    //         label: customPrinterName,
    //         value: name
    //       }))
    //   : undefined
  }

  removeFile = (fileName: string) => {
    const { failedUploads } = this.state
    if (failedUploads) {
      const newFileList = {}
      for (let i = 0; i < failedUploads.length; i++) {
        if (failedUploads[i].name !== fileName) {
          newFileList[Object.keys(newFileList).length] = failedUploads[i]
        }
      }
      newFileList['length'] = Object.keys(newFileList).length
      //@ts-ignore
      this.setState({ failedUploads: newFileList })
    }
  }

  render() {
    const { selectedPrinterValue, failedUploads, selectedFile } = this.state
    const { submitting, isMounted, cloneFileName } = this.props
    const options = this.getPrinterDropdownOptions()
    const selectedPrinterType = this.getSelectedPrinterType()

    const extensions =
      !selectedPrinterType || !selectedPrinterType.isVelox ? ['spj'] : ['spj2']

    const hasPrintersAvailable = options && options.length > 0

    const uploadingFiles =
      this.props.uploadingFiles.length > 1 ? this.props.uploadingFiles : null

    const hasMoreThanFive = Boolean(selectedFile && selectedFile.length > 5)

    return (
      <Popup
        title="Add Job"
        onClose={!submitting ? this.props.onClose : () => {}}
        visible={isMounted}
        icon={<StyledPlusIcon />}
        width={'500px'}
      >
        <DelayedComponents isMounted={Boolean(submitting && !uploadingFiles)}>
          <Loader visible={submitting} text={'Adding Job ...'} />
        </DelayedComponents>

        <DelayedComponents
          isMounted={Boolean(submitting && uploadingFiles)}
          delayUnmount={2000}
        >
          <MultipleFileProgress>
            {uploadingFiles &&
              uploadingFiles.length > 1 &&
              uploadingFiles.map(file => (
                <div
                  key={file.name}
                  className={`file${
                    file.uploadSuccess === true
                      ? ' success'
                      : file.uploadSuccess === false
                      ? ' failed'
                      : ''
                  }`}
                >
                  <div className="name">{file.name}</div>
                  <div className="progress">
                    {file.uploadInProgress && <SpinnerLoader />}
                    {file.uploadSuccess === true && <CheckIcon />}
                    {file.uploadSuccess === false && <ExclamationIcon />}
                  </div>
                </div>
              ))}
            {(() => {
              if (!uploadingFiles) return null
              let status = 'uploading'
              const total = uploadingFiles.length
              const fileSuccesses = uploadingFiles.filter(
                f => f.uploadSuccess === true
              ).length
              const filesFailed = uploadingFiles.filter(
                f => f.uploadSuccess === false
              ).length

              if (fileSuccesses === total) {
                status = 'success'
              }
              if (filesFailed > 0) {
                status = 'failed'
              }

              return (
                <div className={`progress-status ${status}`}>
                  {status === 'uploading' &&
                    `Adding multiples print jobs ${fileSuccesses}/${total}`}
                  {status === 'success' &&
                    `All print jobs have been added successfully!`}
                  {status === 'failed' && fileSuccesses > 0 && (
                    <span
                      style={{ color: 'var(--color-text-primary)' }}
                    >{`${fileSuccesses}/${total} jobs has been added successfully`}</span>
                  )}
                  {status === 'failed' && `${filesFailed} failed`}
                </div>
              )
            })()}
            <div className="loader-mask" />
          </MultipleFileProgress>
        </DelayedComponents>

        <StyledForm
          className={!hasPrintersAvailable ? 'disabled' : ''}
          onSubmit={this.onFormSubmit}
          noValidate={true}
        >
          <div className="selected-printer__image">
            {selectedPrinterType === null ? (
              ''
            ) : selectedPrinterType.isVelox ? (
              <VeloxIcon />
            ) : (
              <DentaformIcon />
            )}
          </div>
          <Dropdown
            label="Select Printer:"
            value={
              options && options.length > 0
                ? selectedPrinterValue || options[0].value
                : 'no-printers'
            }
            onSelectOption={this.onSelectPrinter}
            options={
              options && options.length > 0
                ? options
                : [{ label: 'No Printers available', value: 'no-printers' }]
            }
          />
          {hasMoreThanFive && (
            <span className="form-error-text">
              You are only allowed to upload a maximum of 5 files
            </span>
          )}
          <FileUpload
            label={`.${extensions.join(',.')} file`}
            className="add-job__file-upload"
            extensions={extensions}
            onFileSelected={this.onFileSelect}
            name={'file-upload-field'}
            //@ts-ignore
            files={
              failedUploads
                ? { length: 0 }
                : this.state.selectedFile || undefined
            }
            uneditableValue={cloneFileName}
            multiple
            maxFiles={5}
          />
          {(failedUploads && failedUploads.length > 0 && (
            <FailedUploadsContainer>
              {(() => {
                const failedFileNames = [] as string[]
                for (let i = 0; i < failedUploads.length; i++) {
                  failedFileNames.push(failedUploads[i].name)
                }

                return failedFileNames.map(fileName => (
                  <div key={fileName} className="failed-file">
                    <div className="name">{`${fileName} - Upload Failed!`}</div>
                    <div onClick={() => this.removeFile(fileName)}>
                      <DeleteIcon className="delete-icon" />
                    </div>
                  </div>
                ))
              })()}
            </FailedUploadsContainer>
          )) ||
            null}

          <Button
            style={{ marginTop: 'auto' }}
            type="submit"
            label={`${
              this.state.failedUploads && this.state.failedUploads.length > 0
                ? 'Retry'
                : 'Add Job'
            }`}
            fullWidth
            disabled={submitting || !hasPrintersAvailable || hasMoreThanFive}
          />
        </StyledForm>
      </Popup>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        addNewJob
      },
      dispatch
    )
  }
})

export default connect(null, mapDispatchToProps)(AddJobForm)
