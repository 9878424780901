import React, { useState, createContext, useContext } from 'react'
import { DelayedComponents } from 'backpack'

import { Chips, DateRangePicker } from './Filters'

interface IEditDateRangeParams {
  daysDisabled?: boolean
  dateRangeName?: string
  dateRangeFrom: Date
  dateRangeTo: Date
  onSaveDateRange: (fromDate: Date, toDate: Date) => void
}

type IDateRangePicker = {
  dateRangeState: {
    daysDisabled?: boolean
    dateRangeName?: string
    dateRangeVisible: boolean
    dateRangeFrom: Date
    dateRangeTo: Date
    onSaveDateRange: (fromDate: Date, toDate: Date) => void
  }
  dateRangeActions: {
    editDateRange: (editDateRangeParams: IEditDateRangeParams) => void
    closeDateRange: () => void
  }
}

const defaultDateRangeState = {
  dateRangeState: {
    dateRangeName: 'Date Range',
    dateRangeVisible: false,
    dateRangeFrom: new Date(),
    dateRangeTo: new Date(),
    onSaveDateRange: () => {}
  },
  dateRangeActions: {
    editDateRange: () => {},
    closeDateRange: () => {}
  }
} as IDateRangePicker

export const DateRangeContext = createContext<IDateRangePicker>(
  defaultDateRangeState
)

export function DateRangeProvider({ children }) {
  const [dateRangeState, setDateRangeState] = useState(defaultDateRangeState)
  function editDateRange({
    daysDisabled,
    dateRangeName,
    dateRangeFrom,
    dateRangeTo,
    onSaveDateRange
  }: IEditDateRangeParams) {
    setDateRangeState({
      ...dateRangeState,
      dateRangeState: {
        daysDisabled: daysDisabled,
        dateRangeName: dateRangeName,
        dateRangeVisible: true,
        dateRangeFrom,
        dateRangeTo,
        onSaveDateRange
      }
    })
  }

  function closeDateRange() {
    setDateRangeState({
      ...dateRangeState,
      dateRangeState: {
        ...dateRangeState.dateRangeState,
        dateRangeVisible: false,
        daysDisabled: false
      }
    })
  }

  return (
    <DateRangeContext.Provider
      value={{
        dateRangeState: dateRangeState.dateRangeState,
        dateRangeActions: {
          editDateRange: editDateRange,
          closeDateRange: closeDateRange
        }
      }}
    >
      {children}
    </DateRangeContext.Provider>
  )
}

type IChips = {
  chipsState: {
    chipsName: string
    chipsVisible: boolean
    chipOptions: { label: string; value: string }[]
    selectedChipOptions: string[]
    onSaveChip: (saveOptions: string[]) => void
    title?: string
    placeholder?: string
    prependNode?: React.ReactNode
  }
  chipsActions: {
    editChips: (
      chipsName: string,
      chipOptions: { label: string; value: string }[],
      selectedChipOptions: string[],
      onSaveChip: (saveOptions: string[]) => void,
      title?: string,
      placeholder?: string,
      prependNode?: React.ReactNode
    ) => void
    closeChips: () => void
  }
}

const defaultChipsState = {
  chipsState: {
    chipsName: 'Data',
    chipsVisible: false,
    chipOptions: [],
    selectedChipOptions: [],
    onSaveChip: () => {}
  },
  chipsActions: {
    editChips: () => {},
    closeChips: () => {}
  }
} as IChips

export const ChipsContext = createContext<IChips>(defaultChipsState)

export function ChipsProvider({ children }) {
  const [chipsState, setChipsState] = useState(defaultChipsState)

  function editChips(
    chipsName: string,
    chipOptions: { label: string; value: string }[],
    selectedChipOptions: string[],
    onSaveChip: (saveOptions: string[]) => void,
    title?: string,
    placeholder?: string,
    prependNode?: React.ReactNode
  ) {
    setChipsState({
      ...chipsState,
      chipsState: {
        chipsName,
        chipsVisible: true,
        chipOptions,
        selectedChipOptions,
        onSaveChip: onSaveChip,
        title,
        placeholder,
        prependNode
      }
    })
  }

  function closeChips() {
    setChipsState({
      ...chipsState,
      chipsState: defaultChipsState.chipsState
    })
  }

  return (
    <ChipsContext.Provider
      value={{
        chipsState: chipsState.chipsState,
        chipsActions: {
          editChips: editChips,
          closeChips: closeChips
        }
      }}
    >
      {children}
    </ChipsContext.Provider>
  )
}

export function Filters() {
  const { chipsState, chipsActions } = useContext(ChipsContext)

  const {
    chipsName,
    chipsVisible,
    chipOptions,
    selectedChipOptions,
    onSaveChip,
    title,
    placeholder,
    prependNode
  } = chipsState

  const { closeChips } = chipsActions

  const { dateRangeState, dateRangeActions } = useContext(DateRangeContext)

  const {
    daysDisabled,
    dateRangeName,
    dateRangeVisible,
    dateRangeFrom,
    dateRangeTo,
    onSaveDateRange
  } = dateRangeState

  const { closeDateRange } = dateRangeActions

  return (
    <React.Fragment>
      <DelayedComponents isMounted={dateRangeVisible}>
        <DateRangePicker
          daysDisabled={daysDisabled}
          visible={dateRangeVisible}
          name={dateRangeName}
          fromDate={dateRangeFrom}
          toDate={dateRangeTo}
          onSaveDate={onSaveDateRange}
          onClose={closeDateRange}
        />
      </DelayedComponents>
      <DelayedComponents isMounted={chipsVisible}>
        <Chips
          name={chipsName}
          visible={chipsVisible}
          selectedOptions={selectedChipOptions}
          onSaveOptions={onSaveChip}
          options={chipOptions}
          title={title}
          placeholder={placeholder}
          prependNode={prependNode}
          onClose={closeChips}
        />
      </DelayedComponents>
    </React.Fragment>
  )
}
