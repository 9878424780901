import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IQuickAccessState } from './types'

export const initialState: IQuickAccessState = {
  visible: false
}

export const quickAccessSlice = createSlice({
  name: 'quickAccess',
  initialState,
  reducers: {
    showQuickAccess: (state, action: PayloadAction) => {
      state.visible = true
    },
    hideQuickAccess: (state, action: PayloadAction) => {
      state.visible = false
    }
  }
})

export const { showQuickAccess, hideQuickAccess } = quickAccessSlice.actions

export default quickAccessSlice.reducer
