import { put, call, select, takeEvery, fork } from 'redux-saga/effects'
import { ToastType } from 'backpack'

import {
  addNewJob,
  exportJobs,
  getAllPastPrintsDentaForm,
  getAllPastPrintsVelox,
  postPrintFeedback,
  updatePrintFeedback
} from './api'
import {
  getAllPastPrintsSuccess,
  getAllPastPrintsFailed,
  printFeedbackSuccess,
  printFeedbackFailure,
  exportJobsSuccess,
  exportJobsFailure,
  addNewJobSuccess,
  addNewJobFailure
} from './actions'
import { addToast } from '../toasts/actions'
import {
  IAddNewJobAction,
  IExportDataAction,
  IGetAllPastPrintsAction,
  IPastPrintsState,
  IPrintFeedbackAction
} from './types'
import {
  ADD_NEW_JOB,
  EXPORT_JOBS,
  JOBS_GET_LIST_PENDING,
  PRINT_FEEDBACK_PENDING
} from './constant'
import { IAuthState } from '../auth/types'
import { IPrinterState, IPastPrintRes, IPrinter } from '../printer/types'
import { printerTypes } from '../printer'
import { download } from '../../utils/file-download'
export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken
export const getPrintersType = ({
  printerData
}: {
  printerData: IPrinterState
}) => printerData.printersType

export const getPrinters = ({ printerData }: { printerData: IPrinterState }) =>
  printerData.printers

export const hasFiltersChanged = ({
  pastPrints
}: {
  pastPrints: IPastPrintsState
}) => pastPrints.hasFiltersChanged

export function* getAllPastPrintsHandler({
  payload: { filter }
}: IGetAllPastPrintsAction) {
  try {
    const filtersChanged = yield select(hasFiltersChanged)
    if (filtersChanged) {
      let res: IPastPrintRes
      const token: string = yield select(getToken)
      let printersType = filter && filter.printersType

      if (!printersType) {
        printersType = yield select(getPrintersType)
      }

      if (printerTypes.velox === printersType) {
        res = yield call(getAllPastPrintsVelox, token, filter)
      } else {
        res = yield call(getAllPastPrintsDentaForm, token, filter)
      }
      const printers = yield select(getPrinters)
      res.printerLogs = res.printerLogs.map(pl => {
        const printer = printers.find(p => p.name === pl.printer)
        return {
          ...pl,
          customPrinterName: printer ? printer.customPrinterName : ''
        }
      })
      yield put(getAllPastPrintsSuccess(res.printerLogs, res.totalCount || 0))
    }
  } catch (e) {
    yield put(getAllPastPrintsFailed(e.message))
    yield put(
      addToast({
        title: `Fetch Past Prints failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* printFeedbackHandler({
  payload: { feedback, jobId, printerName }
}: IPrintFeedbackAction) {
  try {
    // let res: IPastPrintRes
    const token: string = yield select(getToken)
    let isUpdate = feedback && feedback.isUpdate
    // delete isUpdate property as it is only used to determine the API call. Not passed to server
    delete feedback['isUpdate']
    const printers: IPrinter[] = yield select(getPrinters)
    const printer = printers.find(p => p.name === printerName)
    if (printer) {
      const ptrinterType = printer.isVelox ? 'velox' : 'dentaform'
      if (isUpdate) {
        yield call(
          updatePrintFeedback,
          token,
          feedback,
          jobId,
          printerName,
          ptrinterType
        )
      } else {
        yield call(
          postPrintFeedback,
          token,
          feedback,
          jobId,
          printerName,
          ptrinterType
        )
      }
      yield put(printFeedbackSuccess(jobId, feedback))
      yield put(
        addToast({
          title: `Print feedback Success`,
          description: `Print feedback saved for printer ${printerName}`,
          type: ToastType.success
        })
      )
    }
  } catch (e) {
    yield put(printFeedbackFailure(jobId))
    yield put(
      addToast({
        title: `Print feedback failure`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* exportJobsHandler({
  payload: { printersType, printers, dates }
}: IExportDataAction) {
  try {
    // let res: IPastPrintRes
    const token: string = yield select(getToken)
    const res = yield call(exportJobs, token, printersType, printers, dates)
    const fileName =
      printersType === 'velox' ? 'velox_jobs.csv' : 'dentaform_jobs.csv'
    yield call(download, res, fileName)
    yield put(exportJobsSuccess())
  } catch (e) {
    yield put(exportJobsFailure())
    yield put(
      addToast({
        title: `Export jobs failure`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* addNewJobHandler({
  payload: { fileStoreKey, printerName }
}: IAddNewJobAction) {
  try {
    const token: string = yield select(getToken)
    yield call(addNewJob, token, fileStoreKey, printerName)
    yield put(addNewJobSuccess())
    yield put(
      addToast({
        title: `Add new Job Success`,
        description: `A new job is added to printer ${printerName}`,
        type: ToastType.success
      })
    )
  } catch (e) {
    yield put(addNewJobFailure())
    yield put(
      addToast({
        title: `Add new Job failure`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

function* watchExportJobs() {
  yield takeEvery(EXPORT_JOBS, exportJobsHandler)
}

function* watchAllPastPrints() {
  yield takeEvery(JOBS_GET_LIST_PENDING, getAllPastPrintsHandler)
}

function* watchPrintFeedback() {
  yield takeEvery(PRINT_FEEDBACK_PENDING, printFeedbackHandler)
}

function* watchAddNewJob() {
  yield takeEvery(ADD_NEW_JOB, addNewJobHandler)
}

export default [
  fork(watchAllPastPrints),
  fork(watchPrintFeedback),
  fork(watchExportJobs),
  fork(watchAddNewJob)
]
