import React, { useState } from 'react'
import styled from 'styled-components'
import { Loader } from 'backpack'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import {
  StyledPopup,
  OperationLogsFormContainer,
  StyledPlusIcon,
  Field,
  FieldsContainer,
  FieldContainer,
  InputSection,
  OEESection,
  SubmitButton,
  StyledDatePicker,
  StyledFilterDropdown
} from './components'
import { getOperationStats } from '../components'
import { hideOperationLogsForm } from '../../../redux/operationLogsForm/slice'
import {
  createOperationLog,
  updateOperationLog
} from '../../../redux/oee/slice'
import { IPrinterState } from '../../../redux/printer/types'
import { RootState } from '../../../store'

const LockIcon = styled(props => (
  <svg
    width="21"
    height="25"
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.68182 11.3636H14.7727V7.95455C14.7727 6.69981 14.3288 5.62855 13.441 4.74077C12.5533 3.85298 11.482 3.40909 10.2273 3.40909C8.97254 3.40909 7.90128 3.85298 7.01349 4.74077C6.12571 5.62855 5.68182 6.69981 5.68182 7.95455V11.3636ZM20.4545 13.0682V23.2955C20.4545 23.7689 20.2888 24.1714 19.9574 24.5028C19.6259 24.8343 19.2235 25 18.75 25H1.70455C1.23106 25 0.828598 24.8343 0.497159 24.5028C0.16572 24.1714 0 23.7689 0 23.2955V13.0682C0 12.5947 0.16572 12.1922 0.497159 11.8608C0.828598 11.5294 1.23106 11.3636 1.70455 11.3636H2.27273V7.95455C2.27273 5.77652 3.05398 3.90625 4.61648 2.34375C6.17898 0.78125 8.04924 0 10.2273 0C12.4053 0 14.2756 0.78125 15.8381 2.34375C17.4006 3.90625 18.1818 5.77652 18.1818 7.95455V11.3636H18.75C19.2235 11.3636 19.6259 11.5294 19.9574 11.8608C20.2888 12.1922 20.4545 12.5947 20.4545 13.0682Z" />
  </svg>
))`
  fill: inherit;
  path {
    fill: inherit;
  }
`

const UnlockIcon = styled(props => (
  <svg
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.5455 7.95455V12.5C29.5455 12.8078 29.433 13.0741 29.2081 13.299C28.9832 13.5239 28.7169 13.6364 28.4091 13.6364H27.2727C26.965 13.6364 26.6986 13.5239 26.4737 13.299C26.2488 13.0741 26.1364 12.8078 26.1364 12.5V7.95455C26.1364 6.69981 25.6925 5.62855 24.8047 4.74077C23.9169 3.85298 22.8456 3.40909 21.5909 3.40909C20.3362 3.40909 19.2649 3.85298 18.3771 4.74077C17.4893 5.62855 17.0455 6.69981 17.0455 7.95455V11.3636H18.75C19.2235 11.3636 19.6259 11.5294 19.9574 11.8608C20.2888 12.1922 20.4545 12.5947 20.4545 13.0682V23.2955C20.4545 23.7689 20.2888 24.1714 19.9574 24.5028C19.6259 24.8343 19.2235 25 18.75 25H1.70455C1.23106 25 0.828598 24.8343 0.497159 24.5028C0.16572 24.1714 0 23.7689 0 23.2955V13.0682C0 12.5947 0.16572 12.1922 0.497159 11.8608C0.828598 11.5294 1.23106 11.3636 1.70455 11.3636H13.6364V7.95455C13.6364 5.76468 14.4147 3.89145 15.9712 2.33487C17.5278 0.778291 19.401 0 21.5909 0C23.7808 0 25.654 0.778291 27.2106 2.33487C28.7672 3.89145 29.5455 5.76468 29.5455 7.95455Z"
      fill="var(--color-text-primary)"
    />
  </svg>
))`
  fill: inherit;
  path {
    fill: inherit;
  }
`

const EditIdealCycleButton = styled(props => (
  <span {...props}>
    <LockIcon className="lock-icon" />
    <UnlockIcon className="unlock-icon" />
  </span>
))`
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 20px;
  z-index: 1;
  fill: var(--color-text-disabled);
  &:hover {
    opacity: 0.7;
  }
  &.unlock {
    opacity: 1;
  }
  > * {
    position: absolute;
    height: inherit;
    fill: inherit;
  }
  .unlock-icon {
    display: none;
  }
  &.unlock .unlock-icon {
    display: block;
  }
  &.unlock .lock-icon {
    display: none;
  }
`

function OperationLogsForm() {
  const dispatch = useDispatch()

  const { editingFields, visible, isCreatingOperationLog } = useSelector(
    ({ operationLogsForm, oee }: RootState) => ({
      ...operationLogsForm,
      ...oee
    })
  )

  const { printers } = useSelector(
    ({ printerData }: { printerData: IPrinterState }) => printerData
  )

  const getPrinterOptions = () =>
    printers
      .map(({ name, customPrinterName }) => ({
        label: customPrinterName || name,
        value: name
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

  const [selectedPrinter, setSelectedPrinter] = useState(
    editingFields ? editingFields.printer : getPrinterOptions()[0].value
  )
  const today = new Date()
  const [date, setDate] = useState(
    editingFields ? editingFields.reportedAt : today
  )
  const [dateFocused, setDateFocused] = useState(false)

  const hidePopup = () => dispatch(hideOperationLogsForm())

  const { register, handleSubmit, getValues, watch } = useForm({
    defaultValues: {
      totalProductionTime:
        (editingFields && editingFields.totalProductionTime) || 0,
      plannedDownTime: (editingFields && editingFields.plannedDownTime) || 0,
      unPlannedDownTime:
        (editingFields && editingFields.unPlannedDownTime) || 0,
      totalProductionUnits:
        (editingFields && editingFields.totalProductionUnits) || 0,
      idealCycleTime: (editingFields && editingFields.idealCycleTime) || 2.22,
      rejectedProductionUnits:
        (editingFields && editingFields.rejectedProductionUnits) || 0
    }
  })

  watch()

  const onSubmit = (data, e) => {
    const reportedAt = new Date(date)
    reportedAt.setHours(0, 0, 0, 0)
    const values = {
      ...data,
      printer: selectedPrinter,
      reportedAt: reportedAt
    }
    if (editingFields) {
      dispatch(updateOperationLog(values))
    } else {
      dispatch(createOperationLog(values))
    }
  }

  const getAllFieldValues = () => {
    const totalProductionTime = Number(getValues('totalProductionTime'))
    const plannedDownTime = Number(getValues('plannedDownTime'))
    const unPlannedDownTime = Number(getValues('unPlannedDownTime'))
    const totalProductionUnits = Number(getValues('totalProductionUnits'))
    const rejectedProductionUnits = Number(getValues('rejectedProductionUnits'))
    const idealCycleTime = Number(getValues('idealCycleTime'))
    return {
      totalProductionTime,
      plannedDownTime,
      unPlannedDownTime,
      totalProductionUnits,
      rejectedProductionUnits,
      idealCycleTime
    }
  }

  const getActualProductionTime = () => {
    const {
      totalProductionTime,
      plannedDownTime,
      unPlannedDownTime
    } = getAllFieldValues()
    const actualProductionTime =
      totalProductionTime - plannedDownTime - unPlannedDownTime
    return isNaN(actualProductionTime) ? 0 : actualProductionTime
  }

  const getUsableProductionTime = () => {
    const {
      totalProductionUnits,
      rejectedProductionUnits
    } = getAllFieldValues()
    const usableProductionUnits = totalProductionUnits - rejectedProductionUnits
    return isNaN(usableProductionUnits) ? 0 : usableProductionUnits
  }

  const getOEEBreakdown = () => {
    const { availability, oee, performance, quality } = getOperationStats(
      getAllFieldValues()
    )

    return {
      availability,
      oee,
      performance,
      quality
    }
  }

  const { oee, availability, performance, quality } = getOEEBreakdown()

  const [editIdealCycleTime, setEditIdealCycleTime] = useState(false)

  return (
    <StyledPopup
      title={Boolean(editingFields) ? 'Edit Log' : 'Add Log'}
      onClose={hidePopup}
      visible={Boolean(visible)}
      width={'900px'}
      icon={<StyledPlusIcon />}
    >
      <Loader
        visible={isCreatingOperationLog}
        text={'Adding operation log ...'}
      />
      <OperationLogsFormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputSection>
          <FieldsContainer>
            <FieldContainer>
              <span>Printer:</span>
              <StyledFilterDropdown
                className="field"
                onChange={setSelectedPrinter}
                options={getPrinterOptions()}
                value={selectedPrinter}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                tabIndex={1}
                onChange={() => {}}
                onFocus={() => setDateFocused(true)}
                onBlur={() => setDateFocused(false)}
                label="Date"
                value={`${date.toLocaleDateString()}`}
              />
            </FieldContainer>
            <StyledDatePicker
              date={date}
              onSelectDate={setDate}
              className={`${dateFocused ? 'active' : ''}`}
            />
            <Field
              tabIndex={1}
              label="Total Production Time (mins)"
              name="totalProductionTime"
              ref={register}
              min={0}
              max={99999}
              type="text"
              pattern="[0-9]+"
              required
              autoComplete="off"
            />
            <Field
              tabIndex={1}
              label="Planned Downtime (mins)"
              name="plannedDownTime"
              ref={register}
              min={0}
              max={99999}
              pattern="[0-9]+"
              required
              autoComplete="off"
            />
            <Field
              tabIndex={1}
              label="Unplanned Downtime (mins)"
              name="unPlannedDownTime"
              ref={register}
              min={0}
              max={99999}
              pattern="[0-9]+"
              required
              autoComplete="off"
            />
            <Field
              tabIndex={1}
              label="Actual Production Time (mins)"
              value={getActualProductionTime()}
              disabled
            />
            <Field
              tabIndex={!editIdealCycleTime ? -1 : 1}
              label="Ideal Cycle Time"
              name="idealCycleTime"
              ref={register}
              min={0}
              max={99999}
              pattern="[0-9.]+"
              required
              autoComplete="off"
              className={!editIdealCycleTime ? 'disabled' : ''}
              tooltip={
                'It is  defined as minutes per part for Dentaform using Model X resin.'
              }
            >
              Ideal Cycle Time:
              <br />
              (mins per part)
              {
                <EditIdealCycleButton
                  className={`${editIdealCycleTime ? 'unlock' : 'lock'}`}
                  onClick={() => setEditIdealCycleTime(!editIdealCycleTime)}
                />
              }
            </Field>
            <Field
              tabIndex={1}
              label="Production Units"
              name="totalProductionUnits"
              ref={register}
              min={0}
              max={99999}
              pattern="[0-9]+"
              required
              autoComplete="off"
            />
            <Field
              tabIndex={1}
              label="Rejected Production Units"
              name="rejectedProductionUnits"
              ref={register}
              min={0}
              max={99999}
              pattern="[0-9]+"
              required
            />
            <Field
              label="Usable Production Units"
              value={getUsableProductionTime()}
              disabled
            />
          </FieldsContainer>
          <OEESection
            oee={oee}
            availability={availability}
            performance={performance}
            quality={quality}
          />
        </InputSection>
        <SubmitButton tabIndex={1} type="submit">
          Submit
        </SubmitButton>
      </OperationLogsFormContainer>
    </StyledPopup>
  )
}

export default OperationLogsForm
