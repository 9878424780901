import store from './store'
import { appInit } from './redux/app/actions'
import pwaHandler from './utils/pwa-handler'
import { showAppInstallOption, hideAppInstallOption } from './redux/app/actions'

export default function() {
  // dispatch action to renew the token. Token will be refreshed it is stored in the localstorage.
  // this is to handle the scenario when user reloads page
  store.dispatch(appInit())
  pwaHandler(store, showAppInstallOption, hideAppInstallOption)
  return store
}
