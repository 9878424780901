export const AUTH_LOGIN_PENDING = 'AUTH_LOGIN_PENDING'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED'
export const AUTH_LOGOUT_PENDING = 'AUTH_LOGOUT_PENDING'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_RENEW_TOKEN = 'AUTH_RENEW_TOKEN'
export const AUTH_FORGOT_PASSWORD_PEDNING = 'AUTH_FORGOT_PASSWORD_PENDING'
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS'
export const AUTH_FORGOT_PASSWORD_FAILED = 'AUTH_FORGOT_PASSWORD_FAILED'
export const AUTH_HIDE_BANNER_PENDING = 'AUTH_HIDE_BANNER_PENDING'
export const AUTH_HIDE_BANNER_SUCCESS = 'AUTH_HIDE_BANNER_SUCCESS'
export const AUTH_HIDE_BANNER_FAILED = 'AUTH_HIDE_BANNER_FAILED'
export const AUTH_GET_COMPANY_INFO_SUCCESS = 'AUTH_GET_COMPANY_INFO_SUCCESS'
