import { put, call, select, takeEvery, fork } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { ToastType } from 'backpack'

import { getTimeline, getTimelineFailure, getTimelineSuccess } from './slice'
import { addToast } from '../toasts/actions'
import { IAuthState } from '../auth/types'
import { IGetTimelineAction, IGetTimelineRes, ITimeline } from './types'
import { getTimelineAPI } from './api'
import { IPrinter, IPrinterState } from '../printer/types'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken
export const getPrinters = ({ printerData }: { printerData: IPrinterState }) =>
  printerData.printers

export function* getTimelineHandler(action: PayloadAction<IGetTimelineAction>) {
  try {
    const { printers, date } = action.payload
    const token: string = yield select(getToken)
    const res: IGetTimelineRes = yield call(
      getTimelineAPI,
      token,
      printers || [],
      date
    )
    const printerList: IPrinter[] = yield select(getPrinters)
    const keys = Object.keys(res)
    let printerEvents: ITimeline[] = []
    if (keys.length) {
      printerEvents = keys.map(k => {
        let events = res[k]
        const printer = printerList.find(p => p.name === k)
        // filter prints which does not have end time
        events = events.filter(e => {
          if (e.eventType === 'print') {
            return !!e.endTime
          } else {
            return true
          }
        })
        return {
          printerName: k,
          customPrinterName: printer?.customPrinterName || k,
          events
        }
      })
    }
    yield put(getTimelineSuccess({ printerEvents }))
  } catch (e) {
    yield put(getTimelineFailure())
    yield put(
      addToast({
        title: `Fetch timeline failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

function* watchGetTimeline() {
  yield takeEvery(getTimeline.toString(), getTimelineHandler)
}

export default [fork(watchGetTimeline)]
