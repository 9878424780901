import React from 'react'
import styled from 'styled-components'

interface ISVGProps extends React.HTMLAttributes<HTMLOrSVGElement> {}

export const AnalyticsIcon = styled(props => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M22.4992 13.125H12.3291V2.95488C12.3291 2.82988 12.2269 2.72761 12.1019 2.72761H11.3633C9.87098 2.72517 8.39295 3.01782 7.01419 3.58873C5.63544 4.15963 4.38317 4.99753 3.32945 6.0542C2.29173 7.08872 1.46488 8.31499 0.894873 9.66488C0.301726 11.0649 -0.00263976 12.5703 1.7247e-05 14.0909C-0.00242637 15.5831 0.290223 17.0612 0.86113 18.4399C1.43204 19.8187 2.26993 21.0709 3.32661 22.1247C4.36919 23.1673 5.58505 23.9882 6.93728 24.5592C8.33734 25.1524 9.84274 25.4568 11.3633 25.4541C12.8555 25.4565 14.3336 25.1639 15.7123 24.593C17.0911 24.0221 18.3434 23.1842 19.3971 22.1275C20.4397 21.0849 21.2607 19.8691 21.8317 18.5168C22.4248 17.1168 22.7292 15.6114 22.7265 14.0909V13.3522C22.7265 13.2273 22.6242 13.125 22.4992 13.125ZM18.0022 20.7895C17.1226 21.6623 16.0793 22.353 14.9323 22.8219C13.7853 23.2909 12.557 23.5289 11.3178 23.5224C8.81506 23.511 6.46286 22.5309 4.69304 20.7611C2.91185 18.9799 1.93177 16.6107 1.93177 14.0909C1.93177 11.5711 2.91185 9.20182 4.69304 7.42063C6.24412 5.86955 8.24121 4.92356 10.3974 4.70766V15.0567H20.7465C20.5277 17.2243 19.5732 19.2327 18.0022 20.7895ZM24.9992 11.5455L24.9253 10.7444C24.6838 8.128 23.5219 5.65933 21.6527 3.79576C19.7821 1.92877 17.3185 0.773321 14.687 0.528825L13.8831 0.454964C13.7495 0.443601 13.6359 0.54587 13.6359 0.679389V11.5909C13.6359 11.7159 13.7382 11.8182 13.8632 11.8182L24.7719 11.7898C24.9054 11.7898 25.0105 11.6762 24.9992 11.5455ZM15.562 9.89214V2.60262C17.3515 2.97717 18.9937 3.86342 20.2891 5.15367C21.5873 6.44908 22.4765 8.09675 22.8458 9.87225L15.562 9.89214Z" />
    </g>
  </svg>
))`
  fill: inherit;
  path {
    fill: inherit;
  }
`

export const QuickAccessIcon = styled(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20.005"
    viewBox="0 0 20 20.005"
    {...props}
  >
    <path
      d="M24.265,23.05,18.7,17.436a7.927,7.927,0,1,0-1.2,1.219l5.526,5.578a.856.856,0,0,0,1.208.031A.862.862,0,0,0,24.265,23.05ZM12.474,18.722A6.259,6.259,0,1,1,16.9,16.889,6.221,6.221,0,0,1,12.474,18.722Z"
      transform="translate(-4.5 -4.493)"
    ></path>
  </svg>
))``

export const PlayIcon = styled(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 163.861 163.861"
    {...props}
  >
    <g>
      <path
        d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275
		c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
      ></path>
    </g>
  </svg>
))`
  width: 21px;
  height: 21px;
  margin: -5px;
`

export const NightIcon = styled(props => (
  <svg
    viewBox="0 0 24 24"
    color="text"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.1534 13.6089L4.15362 13.61C4.77322 16.8113 7.42207 19.3677 10.647 19.8853L10.6502 19.8858C13.0412 20.2736 15.2625 19.6103 16.9422 18.2833C11.3549 16.2878 7.9748 10.3524 9.26266 4.48816C5.69846 5.77194 3.35817 9.51245 4.1534 13.6089ZM10.0083 2.21054C4.76622 3.2533 1.09895 8.36947 2.19006 13.9901C2.97006 18.0201 6.28006 21.2101 10.3301 21.8601C13.8512 22.4311 17.0955 21.1608 19.2662 18.8587C19.2765 18.8478 19.2866 18.837 19.2968 18.8261C19.4385 18.6745 19.5757 18.5184 19.7079 18.3581C19.7105 18.355 19.713 18.3519 19.7156 18.3487C19.8853 18.1426 20.0469 17.9295 20.2001 17.7101C20.4101 17.4001 20.2401 16.9601 19.8701 16.9201C19.5114 16.8796 19.1602 16.8209 18.817 16.7452C18.7964 16.7406 18.7758 16.736 18.7552 16.7313C18.6676 16.7114 18.5804 16.6903 18.4938 16.6681C18.4919 16.6676 18.4901 16.6672 18.4882 16.6667C13.0234 15.2647 9.72516 9.48006 11.4542 4.03417C11.4549 4.03214 11.4555 4.03012 11.4562 4.0281C11.4875 3.92954 11.5205 3.83109 11.5552 3.73278C11.5565 3.72911 11.5578 3.72543 11.5591 3.72175C11.6768 3.38921 11.8136 3.05829 11.9701 2.73005C12.1301 2.39005 11.8501 2.01005 11.4701 2.03005C11.1954 2.04379 10.924 2.06848 10.6561 2.10368C10.6517 2.10427 10.6472 2.10486 10.6428 2.10545C10.4413 2.13221 10.2418 2.16492 10.0446 2.2034C10.0325 2.20576 10.0204 2.20814 10.0083 2.21054Z"
    ></path>
  </svg>
))``

export const DayIcon = styled(props => (
  <svg
    viewBox="0 0 24 24"
    color="textDisabled"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.66 4.2L6.05 4.59C6.44 4.97 6.44 5.61 6.05 5.99L6.04 6C5.65 6.39 5.03 6.39 4.64 6L4.25 5.61C3.86 5.23 3.86 4.6 4.25 4.21L4.26 4.2C4.64 3.82 5.27 3.81 5.66 4.2Z"></path>
    <path d="M1.99 10.95H3.01C3.56 10.95 4 11.39 4 11.95V11.96C4 12.51 3.56 12.95 3 12.94H1.99C1.44 12.94 1 12.5 1 11.95V11.94C1 11.39 1.44 10.95 1.99 10.95Z"></path>
    <path d="M12 1H12.01C12.56 1 13 1.44 13 1.99V2.96C13 3.51 12.56 3.95 12 3.94H11.99C11.44 3.94 11 3.5 11 2.95V1.99C11 1.44 11.44 1 12 1Z"></path>
    <path d="M18.34 4.2C18.73 3.82 19.36 3.82 19.75 4.21C20.14 4.6 20.14 5.22 19.75 5.61L19.36 6C18.98 6.39 18.35 6.39 17.96 6L17.95 5.99C17.56 5.61 17.56 4.98 17.95 4.59L18.34 4.2Z"></path>
    <path d="M18.33 19.7L17.94 19.31C17.55 18.92 17.55 18.3 17.95 17.9C18.33 17.52 18.96 17.51 19.35 17.9L19.74 18.29C20.13 18.68 20.13 19.31 19.74 19.7C19.35 20.09 18.72 20.09 18.33 19.7Z"></path>
    <path d="M20 11.95V11.94C20 11.39 20.44 10.95 20.99 10.95H22C22.55 10.95 22.99 11.39 22.99 11.94V11.95C22.99 12.5 22.55 12.94 22 12.94H20.99C20.44 12.94 20 12.5 20 11.95Z"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 11.95C6 8.64 8.69 5.95 12 5.95C15.31 5.95 18 8.64 18 11.95C18 15.26 15.31 17.95 12 17.95C8.69 17.95 6 15.26 6 11.95ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
    ></path>
    <path d="M12 22.9H11.99C11.44 22.9 11 22.46 11 21.91V20.95C11 20.4 11.44 19.96 11.99 19.96H12C12.55 19.96 12.99 20.4 12.99 20.95V21.91C12.99 22.46 12.55 22.9 12 22.9Z"></path>
    <path d="M5.66 19.69C5.27 20.08 4.64 20.08 4.25 19.69C3.86 19.3 3.86 18.68 4.24 18.28L4.63 17.89C5.02 17.5 5.65 17.5 6.04 17.89L6.05 17.9C6.43 18.28 6.44 18.91 6.05 19.3L5.66 19.69Z"></path>
  </svg>
))``

export const CircleStatus = styled(props => <i {...props} />)`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: var(--fill-p-color-1);
  border-radius: 100%;
  opacity: 0.7;
  margin: 10px 10px 10px 0;
  &.idle {
    background-color: lime;
  }
  &.offline {
    opacity: 0.4;
    background: black;
  }
`

export const CircleStatusWithText = styled(
  ({
    text,
    circleClassName,
    className
  }: {
    text: string
    circleClassName?: string
    className?: string
  }) => (
    <div className={className}>
      <CircleStatus className={circleClassName} /> {text}
    </div>
  )
)`
  display: flex;
  align-items: center;
`

export const QuestionMark = styled(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.875"
    height="34.875"
    viewBox="0 0 34.875 34.875"
    {...props}
  >
    <path
      data-name="Icon awesome-question-circle"
      d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.437,17.437,0,0,1,35.438,18ZM18.468,6.328a9.107,9.107,0,0,0-8.195,4.483.845.845,0,0,0,.191,1.143L12.9,13.8a.843.843,0,0,0,1.172-.149c1.256-1.593,2.117-2.517,4.029-2.517,1.436,0,3.213.924,3.213,2.317,0,1.053-.869,1.594-2.288,2.389-1.654.927-3.843,2.081-3.843,4.968v.281a.844.844,0,0,0,.844.844h3.938a.844.844,0,0,0,.844-.844V21c0-2,5.849-2.085,5.849-7.5C26.662,9.422,22.431,6.328,18.468,6.328ZM18,23.766A3.234,3.234,0,1,0,21.234,27,3.238,3.238,0,0,0,18,23.766Z"
      transform="translate(-0.563 -0.563)"
    />
  </svg>
))`
  cursor: pointer;
  opacity: 0.7;
  width: 22px;
  height: auto;
  margin-left: 20px;
  transition: all 0.2s;
  color: hsl(0deg 0% 100% / 20%);
  filter: drop-shadow(0px 0px 2px hsl(0deg 0% 100% / 30%));
  path {
    fill: var(--color-help-button);
  }
  &:hover {
    opacity: 1;
    transform: scale(1.02);
  }
`

export const CopyIcon = styled(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    {...props}
  >
    <g
      id="Icon_feather-copy"
      data-name="Icon feather-copy"
      transform="translate(-1.5 -1.5)"
    >
      <path
        id="Path_43"
        data-name="Path 43"
        d="M16.5,13.5H30a3,3,0,0,1,3,3V30a3,3,0,0,1-3,3H16.5a3,3,0,0,1-3-3V16.5A3,3,0,0,1,16.5,13.5Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M7.5,22.5H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3H19.5a3,3,0,0,1,3,3V7.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
))`
  path {
    stroke: #fff;
  }
`

export const ZoomInIcon = styled(props => (
  <svg viewBox="1 1 59 59" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd">
      <path d="M12.5 22h24v6h-24z"></path>
      <path d="M27.5 13v24h-6V13z"></path>
      <path d="M25 48.5C12.02 48.5 1.5 37.98 1.5 25S12.02 1.5 25 1.5 48.5 12.02 48.5 25 37.98 48.5 25 48.5zm-.12-6.24c9.6 0 17.38-7.78 17.38-17.38 0-9.6-7.78-17.38-17.38-17.38-9.6 0-17.38 7.78-17.38 17.38 0 9.6 7.78 17.38 17.38 17.38z"></path>
      <rect
        width="24"
        height="8"
        rx="4"
        transform="rotate(45 -22.312 67.766)"
      ></rect>
    </g>
  </svg>
))``

export const ZoomOutIcon = styled(props => (
  <svg viewBox="1 1 59 59" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd">
      <path d="M12.5 22h24v6h-24z"></path>
      <path d="M25 48.5C12.02 48.5 1.5 37.98 1.5 25S12.02 1.5 25 1.5 48.5 12.02 48.5 25 37.98 48.5 25 48.5zm-.12-6.24c9.6 0 17.38-7.78 17.38-17.38 0-9.6-7.78-17.38-17.38-17.38-9.6 0-17.38 7.78-17.38 17.38 0 9.6 7.78 17.38 17.38 17.38z"></path>
      <rect
        width="24"
        height="8"
        rx="4"
        transform="rotate(45 -22.312 67.766)"
      ></rect>
    </g>
  </svg>
))``

export const TimelineIcon = styled(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="18"
    viewBox="0 0 33 18"
    {...props}
  >
    <path
      id="Icon_material-timeline"
      data-name="Icon material-timeline"
      d="M34.5,12a3.009,3.009,0,0,1-3,3,2.549,2.549,0,0,1-.765-.1L25.4,20.22a2.65,2.65,0,0,1,.1.78,3,3,0,1,1-6,0,2.65,2.65,0,0,1,.1-.78L15.78,16.4a2.95,2.95,0,0,1-1.56,0L7.4,23.235A2.549,2.549,0,0,1,7.5,24a3,3,0,1,1-3-3,2.549,2.549,0,0,1,.765.1l6.84-6.825A2.65,2.65,0,0,1,12,13.5a3,3,0,0,1,6,0,2.65,2.65,0,0,1-.1.78L21.72,18.1a2.95,2.95,0,0,1,1.56,0l5.325-5.34A2.549,2.549,0,0,1,28.5,12a3,3,0,0,1,6,0Z"
      transform="translate(-1.5 -9)"
    />
  </svg>
))``

export const DashboardIntroImage = styled(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="372.608"
    viewBox="0 0 500 372.608"
    {...props}
  >
    <g
      id="dashboard_intro"
      data-name="dashboard intro"
      transform="translate(0 -54.953)"
    >
      <g id="Background_Complete" data-name="Background Complete">
        <g id="Group_1" data-name="Group 1">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="500"
            height="0.25"
            transform="translate(0 382.398)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="97.597"
            height="0.25"
            transform="translate(332.111 389.208)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="46.667"
            height="0.25"
            transform="translate(289 394.986)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="19.192"
            height="0.25"
            transform="translate(430.709 398.494)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_5"
            data-name="Rectangle 5"
            width="92.527"
            height="0.25"
            transform="translate(126.473 395.111)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_6"
            data-name="Rectangle 6"
            width="19.903"
            height="0.25"
            transform="translate(97.667 395.111)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width="32.862"
            height="0.25"
            transform="translate(57.792 390.888)"
            fill="#ebebeb"
          />
        </g>
        <g id="Group_5" data-name="Group 5">
          <g id="Group_2" data-name="Group 2">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M391.073,373.195H89.034L71.023,168.31h302.04Z"
              fill="#f0f0f0"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M83.7,379.721l5.337-6.527L71.023,168.31l-5.328,6.626Z"
              fill="#e0e0e0"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M93.025,365.416,76.434,176.685a3.4,3.4,0,0,1,3.389-3.7H365.682a3.4,3.4,0,0,1,3.389,3.1L385.662,364.82a3.4,3.4,0,0,1-3.389,3.7H96.414A3.4,3.4,0,0,1,93.025,365.416Z"
              fill="#fafafa"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M131.486,373.195H85.979l-3.218,9.2h45.507Z"
              fill="#e0e0e0"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M131.486,373.195H430.709l-3.218,9.2H128.268Z"
              fill="#f5f5f5"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M254.7,373.195H312.06l-.617,6.026H254.085Z"
              fill="#e0e0e0"
            />
          </g>
          <g id="Group_3" data-name="Group 3">
            <path
              id="Path_9"
              data-name="Path 9"
              d="M341.617,154.864h72.47c6.005,39.756-21.768,72.47-61.523,72.47a80.8,80.8,0,0,1-37.7-9.709Z"
              fill="#e0e0e0"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M341.617,154.864l38.2-55.515a90.306,90.306,0,0,1,22.857,24.888,81.837,81.837,0,0,1,11.416,30.627Z"
              fill="#ebebeb"
            />
            <path
              id="Path_11"
              data-name="Path 11"
              d="M341.617,154.864,330.671,82.395a78.177,78.177,0,0,1,25.446,4.37,87.635,87.635,0,0,1,23.7,12.584Z"
              fill="#e6e6e6"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M341.617,154.864l-26.755,62.761c-24.347-12.93-41.809-36.9-45.715-62.761-6.005-39.755,21.768-72.469,61.524-72.469Z"
              fill="#f0f0f0"
            />
          </g>
          <g id="Group_4" data-name="Group 4">
            <path
              id="Path_13"
              data-name="Path 13"
              d="M252.155,284.19H316.44a62.684,62.684,0,0,1,.323,16.638,56.121,56.121,0,0,1-4.081,15.5Z"
              fill="#ebebeb"
            />
            <path
              id="Path_14"
              data-name="Path 14"
              d="M252.155,284.19l1.6-63.309a77.359,77.359,0,0,1,41.4,21.987c11.461,11.575,19,26.212,21.282,41.322Z"
              fill="#e0e0e0"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              d="M252.155,284.19l-57.087,21.987a71.466,71.466,0,0,1-7.2-21.987c-5.327-35.266,19.309-64.285,54.575-64.285a66.925,66.925,0,0,1,11.31.976Z"
              fill="#f0f0f0"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              d="M252.155,284.19l60.527,32.142c-8.468,19.866-27.879,32.143-50.817,32.143-26.962,0-53.749-16.962-66.8-42.3Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <g id="Background_Simple" data-name="Background Simple">
        <g id="Group_6" data-name="Group 6">
          <path
            id="Path_17"
            data-name="Path 17"
            d="M268.27,162.183c-44.6-34.324-119.543-113.329-165.327-42.129-45.448,70.677,41.666,158.451,96.546,182.391,55.481,24.2,204.7,23.951,191.8-65.53C382.408,175.348,317.533,200.1,268.27,162.183Z"
            fill="#ff7b40"
          />
          <path
            id="Path_18"
            data-name="Path 18"
            d="M268.27,162.183c-44.6-34.324-119.543-113.329-165.327-42.129-45.448,70.677,41.666,158.451,96.546,182.391,55.481,24.2,204.7,23.951,191.8-65.53C382.408,175.348,317.533,200.1,268.27,162.183Z"
            fill="#fff"
            opacity="0.9"
          />
        </g>
      </g>
      <g id="Shadow">
        <ellipse
          id="_Path_"
          data-name="&lt;Path&gt;"
          cx="193.889"
          cy="11.323"
          rx="193.889"
          ry="11.323"
          transform="translate(56.111 404.915)"
          fill="#f5f5f5"
        />
      </g>
      <g id="Setup_analytics" data-name="Setup analytics">
        <g id="Group_19" data-name="Group 19">
          <g id="Group_9" data-name="Group 9">
            <g id="Group_7" data-name="Group 7">
              <path
                id="Path_19"
                data-name="Path 19"
                d="M240.585,239.387H150.51a11.52,11.52,0,0,1-11.24-10.393l-4.509-55.386a9.464,9.464,0,0,1,9.548-10.393h90.075a11.519,11.519,0,0,1,11.239,10.393l4.509,55.386A9.463,9.463,0,0,1,240.585,239.387Z"
                fill="#fff"
              />
              <path
                id="Path_20"
                data-name="Path 20"
                d="M240.585,239.819H150.51a11.937,11.937,0,0,1-11.671-10.79l-4.509-55.385a9.9,9.9,0,0,1,9.979-10.862h90.075a11.939,11.939,0,0,1,11.671,10.791l4.509,55.386a9.895,9.895,0,0,1-9.979,10.86Zm-96.276-76.172a9.031,9.031,0,0,0-9.116,9.926l4.509,55.386a11.057,11.057,0,0,0,10.808,9.995h90.075a9.031,9.031,0,0,0,9.116-9.925l-4.51-55.385a11.056,11.056,0,0,0-10.807-10Z"
                fill="#ff7b40"
              />
            </g>
            <g id="Group_8" data-name="Group 8">
              <path
                id="Path_21"
                data-name="Path 21"
                d="M216.14,223.216a27.926,27.926,0,0,1-13.239,8.02l-1.491-4.27c11.091-3.031,18.6-13.372,17.6-25.662a28.913,28.913,0,0,0-9.307-18.786l2.872-3.127C225.573,191.413,227.183,211.2,216.14,223.216Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_22"
                data-name="Path 22"
                d="M212.572,179.391l-2.872,3.127a28.73,28.73,0,0,0-19.419-7.778,24.345,24.345,0,0,0-18.143,7.786l-3.391-3.135a28.539,28.539,0,0,1,21.174-9.077A33.6,33.6,0,0,1,212.572,179.391Z"
                fill="#263238"
              />
              <path
                id="Path_23"
                data-name="Path 23"
                d="M202.9,231.236c-10.447,2.858-22.129-.207-30.586-8.02-12.991-12.021-14.6-31.8-3.568-43.825l3.391,3.135a24.523,24.523,0,0,0-6.249,18.778c1.194,14.663,14.054,26.555,28.717,26.555a26.016,26.016,0,0,0,6.8-.893Z"
                fill="#ff7b40"
              />
            </g>
          </g>
          <g id="Group_12" data-name="Group 12">
            <path
              id="Path_94"
              data-name="Path 94"
              d="M0,0H7.222V.865H0Z"
              transform="translate(110.666 73.688)"
              fill="#ff7b40"
            />
            <rect
              id="Rectangle_9"
              data-name="Rectangle 9"
              width="16.444"
              height="0.865"
              transform="translate(87.222 73.688)"
              fill="#ff7b40"
            />
            <g id="Group_10" data-name="Group 10">
              <path
                id="Path_24"
                data-name="Path 24"
                d="M180.144,153.294H90.069A11.519,11.519,0,0,1,78.829,142.9L74.32,87.515a9.464,9.464,0,0,1,9.547-10.393h90.076a11.519,11.519,0,0,1,11.239,10.393l4.509,55.385a9.928,9.928,0,0,1-1.5,6.1A9.344,9.344,0,0,1,180.144,153.294Z"
                fill="#fff"
              />
              <path
                id="Path_25"
                data-name="Path 25"
                d="M180.144,153.727H90.068A11.938,11.938,0,0,1,78.4,142.935L73.889,87.55a9.895,9.895,0,0,1,9.978-10.861h90.075a11.939,11.939,0,0,1,11.672,10.79l4.509,55.386a9.9,9.9,0,0,1-9.979,10.862ZM83.867,77.554a9.031,9.031,0,0,0-9.115,9.925l4.509,55.386a11.056,11.056,0,0,0,10.807,10h90.076a9.03,9.03,0,0,0,9.115-9.926L184.751,87.55a11.058,11.058,0,0,0-10.809-10Z"
                fill="#ff7b40"
              />
            </g>
            <g id="Group_11" data-name="Group 11">
              <path
                id="Path_26"
                data-name="Path 26"
                d="M82.467,93.014H130.2l-.384-4.715H82.083Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_27"
                data-name="Path 27"
                d="M177.931,93.014H130.2l-.384-4.715h47.732Z"
                fill="#ff7b40"
              />
              <path
                id="Path_28"
                data-name="Path 28"
                d="M83.466,105.29H97.083l-.384-4.715H83.082Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_29"
                data-name="Path 29"
                d="M178.93,105.29H97.083l-.384-4.715h81.847Z"
                fill="#ff7b40"
              />
              <path
                id="Path_30"
                data-name="Path 30"
                d="M84.465,117.565h42.487l-.384-4.715H84.081Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_31"
                data-name="Path 31"
                d="M179.93,117.565H126.953l-.384-4.715h52.977Z"
                fill="#ff7b40"
              />
              <path
                id="Path_32"
                data-name="Path 32"
                d="M85.465,129.841h72.513l-.384-4.715H85.081Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_33"
                data-name="Path 33"
                d="M180.929,129.841H157.977l-.384-4.715h22.952Z"
                fill="#ff7b40"
              />
              <path
                id="Path_34"
                data-name="Path 34"
                d="M86.464,142.116H120l-.384-4.715H86.08Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_35"
                data-name="Path 35"
                d="M181.928,142.116H120l-.384-4.715h61.927Z"
                fill="#ff7b40"
              />
            </g>
          </g>
          <g id="Group_15" data-name="Group 15">
            <g id="Group_13" data-name="Group 13">
              <path
                id="Path_36"
                data-name="Path 36"
                d="M303.281,153.294H213.206A11.519,11.519,0,0,1,201.967,142.9l-4.509-55.385a9.463,9.463,0,0,1,9.547-10.393H297.08a11.519,11.519,0,0,1,11.239,10.393l4.509,55.385A9.464,9.464,0,0,1,303.281,153.294Z"
                fill="#fff"
              />
              <path
                id="Path_37"
                data-name="Path 37"
                d="M303.281,153.727H213.206a11.939,11.939,0,0,1-11.671-10.792L197.026,87.55A9.895,9.895,0,0,1,207,76.689H297.08a11.937,11.937,0,0,1,11.671,10.79l4.509,55.386a9.9,9.9,0,0,1-9.979,10.862ZM207,77.554a9.031,9.031,0,0,0-9.115,9.925l4.509,55.386a11.058,11.058,0,0,0,10.808,10h90.075a9.031,9.031,0,0,0,9.115-9.926L307.888,87.55a11.057,11.057,0,0,0-10.808-10Z"
                fill="#ff7b40"
              />
            </g>
            <g id="Group_14" data-name="Group 14">
              <path
                id="Path_38"
                data-name="Path 38"
                d="M258.129,142.116h-1.59L252.157,88.3h1.59Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_39"
                data-name="Path 39"
                d="M260.529,119.753H250.5l-1.712-21.027h10.032Z"
                fill="#ff7b40"
              />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M238.385,142.116H236.8L232.414,88.3H234Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_41"
                data-name="Path 41"
                d="M241.166,124.423H231.134l-1.5-18.432h10.032Z"
                fill="#ff7b40"
              />
              <path
                id="Path_42"
                data-name="Path 42"
                d="M218.642,142.116h-1.59L212.671,88.3h1.59Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_43"
                data-name="Path 43"
                d="M220.188,109.259H210.156L209.126,96.6h10.032Z"
                fill="#263238"
              />
              <path
                id="Path_44"
                data-name="Path 44"
                d="M277.872,142.116h-1.59L271.9,88.3h1.59Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_45"
                data-name="Path 45"
                d="M281.244,131.689H271.212l-2.684-32.963H278.56Z"
                fill="#263238"
              />
              <path
                id="Path_46"
                data-name="Path 46"
                d="M297.615,142.116h-1.59L291.644,88.3h1.59Z"
                fill="#e0e0e0"
              />
              <path
                id="Path_47"
                data-name="Path 47"
                d="M301.309,135.639H291.277l-2.149-26.4H299.16Z"
                fill="#ff7b40"
              />
            </g>
          </g>
          <g id="Group_18" data-name="Group 18">
            <g id="Group_16" data-name="Group 16">
              <path
                id="Path_48"
                data-name="Path 48"
                d="M206.889,324.772H116.814a11.519,11.519,0,0,1-11.239-10.393l-4.51-55.386a9.464,9.464,0,0,1,9.548-10.393h90.075a11.519,11.519,0,0,1,11.239,10.393l4.509,55.386A9.463,9.463,0,0,1,206.889,324.772Z"
                fill="#fff"
              />
              <path
                id="Path_49"
                data-name="Path 49"
                d="M206.889,325.2H116.814a11.937,11.937,0,0,1-11.671-10.79l-4.509-55.386a9.9,9.9,0,0,1,9.979-10.861h90.075a11.939,11.939,0,0,1,11.671,10.791l4.509,55.386a9.895,9.895,0,0,1-9.979,10.86Zm-96.276-76.172a9.033,9.033,0,0,0-9.116,9.926l4.509,55.386a11.058,11.058,0,0,0,10.808,9.995h90.075A9.032,9.032,0,0,0,216,314.414L211.5,259.028a11.058,11.058,0,0,0-10.808-10Z"
                fill="#ff7b40"
              />
            </g>
            <g id="Group_17" data-name="Group 17">
              <path
                id="Path_50"
                data-name="Path 50"
                d="M122.8,285.364l19.414,22.431,22.263-34.342L178.244,291.2l3.36-5.182,22.5,26.972-86.432-.032Z"
                fill="#ff7b40"
              />
              <path
                id="Path_51"
                data-name="Path 51"
                d="M187.609,286.756l-9.023,19.321-28.779-45.694L136.255,289.4l-4.343-6.9-14.235,30.482,86.426-.043Z"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Character">
        <g id="Group_27" data-name="Group 27">
          <path
            id="Path_52"
            data-name="Path 52"
            d="M372.88,159.06c2.409,4.522,4.479,8.989,6.428,13.643a126.86,126.86,0,0,1,5.029,14.384c.351,1.241.654,2.519.945,3.8a13.708,13.708,0,0,1,.4,2.712,14.061,14.061,0,0,1-.152,2.713,26.091,26.091,0,0,1-2.974,8.28,55.743,55.743,0,0,1-9.348,12.448,2.829,2.829,0,0,1-4.54-3.271l.071-.136a98.993,98.993,0,0,0,5.7-12.65,19.834,19.834,0,0,0,1.235-5.7,5.4,5.4,0,0,0-.079-.945.946.946,0,0,0-.085-.295l-.185-.588c-.333-1.078-.675-2.155-1.075-3.241-1.515-4.342-3.388-8.7-5.332-13.027s-4.023-8.7-6.084-12.866l-.042-.084a5.673,5.673,0,0,1,10.092-5.182Z"
            fill="#ff7b40"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M369.751,213.213l-4.772,1.5,5.617,6.576a10.936,10.936,0,0,0,2.949-4.67,2.327,2.327,0,0,0-.907-2.5l-.8-.575A2.359,2.359,0,0,0,369.751,213.213Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_54"
            data-name="Path 54"
            d="M358.4,218.77l6.019,4.775,6.172-2.253-5.617-6.577Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_55"
            data-name="Path 55"
            d="M337.851,125.613c-.121.592-.532,1.008-.918.929s-.6-.622-.483-1.213.531-1.008.918-.929S337.971,125.022,337.851,125.613Z"
            fill="#263238"
          />
          <path
            id="Path_56"
            data-name="Path 56"
            d="M337.374,126.632a21.586,21.586,0,0,1-3.855,4.485,3.478,3.478,0,0,0,2.718,1.1Z"
            fill="#630f0f"
          />
          <path
            id="Path_57"
            data-name="Path 57"
            d="M340.684,122.768a.355.355,0,0,0,.261-.6A3.518,3.518,0,0,0,337.928,121a.354.354,0,0,0,.118.7h0a2.816,2.816,0,0,1,2.378.956A.355.355,0,0,0,340.684,122.768Z"
            fill="#263238"
          />
          <path
            id="Path_58"
            data-name="Path 58"
            d="M354.28,136.381c-1.073,5.476-1.721,13.062,2.11,16.716,0,0-1.5,5.555-11.672,5.555-11.188,0-5.382-4.875-5.382-4.875,4.65-1.443,4.61-6.74,3.546-10.995Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M337.374,152.921s1.631-3.865,3.3-4.532,12.277-2.411,14.722-.873,4.1,6.484,4.1,6.484Z"
            fill="#ff7b40"
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M348.588,408.979c-.96,0-1.888-.133-2.2-.564a.671.671,0,0,1,.015-.775.883.883,0,0,1,.562-.415c1.245-.338,3.861,1.152,3.972,1.216a.175.175,0,0,1,.086.182.178.178,0,0,1-.14.146A13.146,13.146,0,0,1,348.588,408.979Zm-1.187-1.452a1.3,1.3,0,0,0-.342.041.533.533,0,0,0-.348.248c-.125.22-.079.329-.034.392.348.483,2.24.495,3.632.3A8.014,8.014,0,0,0,347.4,407.527Z"
            fill="#ff7b40"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M350.849,408.772a.168.168,0,0,1-.072-.016c-.9-.4-2.693-2.024-2.556-2.87.032-.2.173-.446.66-.5a1.3,1.3,0,0,1,.983.3c.952.782,1.153,2.8,1.162,2.884a.177.177,0,0,1-.177.194Zm-1.826-3.034a.941.941,0,0,0-.106.005c-.319.032-.34.158-.346.2-.082.508,1.134,1.78,2.056,2.334a4.17,4.17,0,0,0-.988-2.308A.942.942,0,0,0,349.023,405.738Z"
            fill="#ff7b40"
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M359.08,408.595H351.6l-.746-17.333h7.485Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M351.644,407.728h8.174a.654.654,0,0,1,.638.511l1.478,6.653a1.09,1.09,0,0,1-1.077,1.332c-2.948-.05-7.209-.223-10.915-.223-4.333,0-4.858.236-9.939.236-3.073,0-3.926-3.107-2.64-3.388,5.855-1.281,7.418-1.418,12.478-4.538A3.465,3.465,0,0,1,351.644,407.728Z"
            fill="#263238"
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M369.791,153.123c7.016,1.608,11.644,20.54,11.644,20.54l-11.713,8.8a22.342,22.342,0,0,1-8.69-12.917C358.62,160.158,362.619,151.479,369.791,153.123Z"
            fill="#263238"
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M363.779,166.293c3.811,3.77,6.273,9.721,6.568,15.691l-.621.479a22.371,22.371,0,0,1-8.7-12.92c-.142-.56-.265-1.121-.366-1.671Z"
            opacity="0.2"
          />
          <g id="Group_23" data-name="Group 23">
            <g id="Group_20" data-name="Group 20">
              <rect
                id="Rectangle_10"
                data-name="Rectangle 10"
                width="61.195"
                height="45.511"
                transform="translate(338.184 204) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_11"
                data-name="Rectangle 11"
                width="60.296"
                height="45.511"
                transform="translate(338.184 204) rotate(-21.784)"
                fill="#fff"
                opacity="0.5"
              />
            </g>
            <g id="Group_21" data-name="Group 21" opacity="0.5">
              <rect
                id="Rectangle_12"
                data-name="Rectangle 12"
                width="5.709"
                height="4.046"
                transform="translate(343.141 206.357) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_13"
                data-name="Rectangle 13"
                width="5.709"
                height="4.046"
                transform="translate(351.894 202.859) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_14"
                data-name="Rectangle 14"
                width="5.709"
                height="4.046"
                transform="translate(360.647 199.361) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_15"
                data-name="Rectangle 15"
                width="5.709"
                height="4.046"
                transform="translate(369.4 195.862) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_16"
                data-name="Rectangle 16"
                width="5.709"
                height="4.046"
                transform="translate(378.153 192.364) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_17"
                data-name="Rectangle 17"
                width="5.709"
                height="4.046"
                transform="translate(386.906 188.866) rotate(-21.785)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_18"
                data-name="Rectangle 18"
                width="5.709"
                height="4.047"
                transform="translate(345.62 212.561) rotate(-21.783)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_19"
                data-name="Rectangle 19"
                width="5.709"
                height="4.047"
                transform="translate(354.373 209.063) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_20"
                data-name="Rectangle 20"
                width="5.709"
                height="4.047"
                transform="translate(363.126 205.565) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_21"
                data-name="Rectangle 21"
                width="5.709"
                height="4.046"
                transform="translate(371.879 202.067) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_22"
                data-name="Rectangle 22"
                width="5.709"
                height="4.047"
                transform="translate(380.632 198.569) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_23"
                data-name="Rectangle 23"
                width="5.709"
                height="4.046"
                transform="translate(389.385 195.071) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_24"
                data-name="Rectangle 24"
                width="5.709"
                height="4.046"
                transform="translate(348.1 218.766) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_25"
                data-name="Rectangle 25"
                width="5.709"
                height="4.046"
                transform="translate(356.853 215.267) rotate(-21.783)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_26"
                data-name="Rectangle 26"
                width="5.709"
                height="4.046"
                transform="translate(365.606 211.769) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_27"
                data-name="Rectangle 27"
                width="5.709"
                height="4.046"
                transform="translate(374.359 208.271) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_28"
                data-name="Rectangle 28"
                width="5.709"
                height="4.046"
                transform="translate(383.112 204.773) rotate(-21.785)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_29"
                data-name="Rectangle 29"
                width="5.709"
                height="4.047"
                transform="translate(391.865 201.275) rotate(-21.785)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_30"
                data-name="Rectangle 30"
                width="5.709"
                height="4.047"
                transform="translate(350.579 224.97) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_31"
                data-name="Rectangle 31"
                width="5.709"
                height="4.047"
                transform="translate(359.332 221.472) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_32"
                data-name="Rectangle 32"
                width="5.709"
                height="4.046"
                transform="translate(368.085 217.974) rotate(-21.783)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_33"
                data-name="Rectangle 33"
                width="5.709"
                height="4.047"
                transform="translate(376.838 214.476) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_34"
                data-name="Rectangle 34"
                width="5.709"
                height="4.046"
                transform="translate(385.591 210.978) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_35"
                data-name="Rectangle 35"
                width="5.709"
                height="4.046"
                transform="translate(394.344 207.48) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_36"
                data-name="Rectangle 36"
                width="5.709"
                height="4.046"
                transform="translate(353.059 231.174) rotate(-21.783)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_37"
                data-name="Rectangle 37"
                width="5.709"
                height="4.046"
                transform="translate(361.812 227.676) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_38"
                data-name="Rectangle 38"
                width="5.709"
                height="4.046"
                transform="translate(370.565 224.178) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_39"
                data-name="Rectangle 39"
                width="5.709"
                height="4.046"
                transform="translate(379.318 220.68) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_40"
                data-name="Rectangle 40"
                width="5.709"
                height="4.047"
                transform="translate(388.071 217.182) rotate(-21.785)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_41"
                data-name="Rectangle 41"
                width="5.709"
                height="4.047"
                transform="translate(396.824 213.684) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_42"
                data-name="Rectangle 42"
                width="5.709"
                height="4.047"
                transform="translate(355.538 237.379) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_43"
                data-name="Rectangle 43"
                width="5.709"
                height="4.047"
                transform="translate(364.291 233.881) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_44"
                data-name="Rectangle 44"
                width="5.709"
                height="4.047"
                transform="translate(373.044 230.383) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_45"
                data-name="Rectangle 45"
                width="5.709"
                height="4.046"
                transform="translate(381.797 226.885) rotate(-21.784)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_46"
                data-name="Rectangle 46"
                width="5.709"
                height="4.046"
                transform="translate(390.55 223.387) rotate(-21.783)"
                fill="#ff7b40"
              />
              <rect
                id="Rectangle_47"
                data-name="Rectangle 47"
                width="5.709"
                height="4.046"
                transform="translate(399.304 219.888) rotate(-21.784)"
                fill="#ff7b40"
              />
            </g>
            <g id="Group_22" data-name="Group 22">
              <path
                id="Path_66"
                data-name="Path 66"
                d="M390.31,194.7a1.586,1.586,0,1,1,2.061.884A1.586,1.586,0,0,1,390.31,194.7Z"
                fill="#fff"
              />
              <path
                id="Path_67"
                data-name="Path 67"
                d="M388.995,216.813a1.586,1.586,0,1,1,2.062.884A1.586,1.586,0,0,1,388.995,216.813Z"
                fill="#fff"
              />
              <path
                id="Path_68"
                data-name="Path 68"
                d="M369.01,217.6a1.586,1.586,0,1,1,2.061.885A1.586,1.586,0,0,1,369.01,217.6Z"
                fill="#fff"
              />
              <path
                id="Path_69"
                data-name="Path 69"
                d="M356.463,237.01a1.586,1.586,0,1,1,2.061.884A1.587,1.587,0,0,1,356.463,237.01Z"
                fill="#fff"
              />
              <path
                id="Path_70"
                data-name="Path 70"
                d="M357.935,237.009a.579.579,0,0,1-.319-.1.588.588,0,0,1-.174-.812L369.989,216.7a.584.584,0,0,1,.47-.267l19.454-.772,1.282-21.58a.6.6,0,0,1,.622-.552.589.589,0,0,1,.552.622l-1.314,22.112a.586.586,0,0,1-.563.551l-19.682.781L358.429,236.74A.586.586,0,0,1,357.935,237.009Z"
                fill="#fff"
              />
            </g>
          </g>
          <path
            id="Path_71"
            data-name="Path 71"
            d="M325.461,160.628c-1.53,8.086-2.487,24.691,1.955,57.575H367.45c-.723-5.7-3.586-36.124,2.341-65.08a104.408,104.408,0,0,0-13.4-1.692,146.951,146.951,0,0,0-17.018,0c-1.877.172-3.72.443-5.362.733A10.592,10.592,0,0,0,325.461,160.628Z"
            fill="#263238"
          />
          <path
            id="Path_72"
            data-name="Path 72"
            d="M331.757,173.08l-7.52.6c-.27,9.73.4,23.93,3.18,44.52h.36C332.287,203.37,338.267,182.17,331.757,173.08Z"
            opacity="0.2"
          />
          <g id="Group_24" data-name="Group 24">
            <path
              id="Path_73"
              data-name="Path 73"
              d="M350.851,391.266l.383,8.935h7.488l-.383-8.935Z"
              opacity="0.2"
            />
          </g>
          <path
            id="Path_74"
            data-name="Path 74"
            d="M357.838,128.37c-1.923,7.175-2.684,11.473-7.111,14.456-6.661,4.488-14.953-.662-15.213-8.268-.233-6.845,2.951-17.429,10.651-18.875A10.131,10.131,0,0,1,357.838,128.37Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_75"
            data-name="Path 75"
            d="M341.039,128.417l-3.889,1.011,3.619-9.386a10.583,10.583,0,0,1-.333-7.24,2.13,2.13,0,0,1,1.983-1.459c8.062-.157,16.488,4.368,20.735,7.79a2.088,2.088,0,0,1,.471,2.716,24.147,24.147,0,0,1-3.651,4.231c-.08,2.628-4.372,14.5-6.747,15.837s-6.917.791-8.125-1.375S339.81,135.5,341.039,128.417Z"
            fill="#263238"
          />
          <path
            id="Path_76"
            data-name="Path 76"
            d="M344.638,128.566a8.4,8.4,0,0,1-2.9,4.547c-1.907,1.5-3.4.115-3.322-2.092.07-1.987,1.148-5.162,3.28-5.809C343.8,124.576,345.138,126.4,344.638,128.566Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_77"
            data-name="Path 77"
            d="M413.082,406.395c-.006-.961.12-1.889.55-2.2a.672.672,0,0,1,.775.01.884.884,0,0,1,.418.559c.347,1.244-1.127,3.869-1.19,3.98a.177.177,0,0,1-.328-.051A13.087,13.087,0,0,1,413.082,406.395Zm1.444-1.2a1.31,1.31,0,0,0-.043-.343.532.532,0,0,0-.25-.345c-.221-.124-.33-.078-.392-.032-.482.351-.481,2.243-.275,3.633A8.016,8.016,0,0,0,414.526,405.2Z"
            fill="#ff7b40"
          />
          <path
            id="Path_78"
            data-name="Path 78"
            d="M413.3,408.655a.184.184,0,0,1,.015-.073c.4-.906,2.007-2.706,2.854-2.574.2.031.447.17.5.657a1.293,1.293,0,0,1-.3.985c-.776.957-2.79,1.171-2.876,1.18a.177.177,0,0,1-.195-.175Zm3.023-1.846c0-.035,0-.071-.006-.106-.035-.319-.161-.339-.2-.345-.508-.079-1.773,1.145-2.321,2.071a4.156,4.156,0,0,0,2.3-1A.942.942,0,0,0,416.326,406.809Z"
            fill="#ff7b40"
          />
          <path
            id="Path_79"
            data-name="Path 79"
            d="M413.416,408.7l-7.3.517-4.1-17.472,7.3-.517Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_80"
            data-name="Path 80"
            d="M413.392,407.026l-8.143-.72a.652.652,0,0,0-.679.453l-2.06,6.5a1.089,1.089,0,0,0,.956,1.421c2.941.21,7.2.413,10.892.74,4.317.382,1.521.372,6.583.82,3.061.271,4.184-2.748,2.928-3.142-5.72-1.792-6.756-3.03-8.733-5.329A2.56,2.56,0,0,0,413.392,407.026Z"
            fill="#263238"
          />
          <g id="Group_25" data-name="Group 25">
            <path
              id="Path_81"
              data-name="Path 81"
              d="M402.012,391.754l2.114,9.006,7.307-.517-2.114-9.006Z"
              opacity="0.2"
            />
          </g>
          <path
            id="Path_82"
            data-name="Path 82"
            d="M341.617,218.2s17.217,57.493,23.75,78.835c7.153,23.37,34.372,100.9,34.372,100.9l13.2-2.9s-18.644-66.615-26.376-99.208c-5.938-25.035-19.11-77.63-19.11-77.63Z"
            fill="#ff7b40"
          />
          <path
            id="Path_83"
            data-name="Path 83"
            d="M397.421,393.86c-.06.007,1.3,5.155,1.3,5.155l15.874-1.705-.073-4.717Z"
            fill="#263238"
          />
          <path
            id="Path_84"
            data-name="Path 84"
            d="M351.154,244.849c9.526,1.256,13.32,34.457,14.746,53.941-.18-.617-.37-1.2-.536-1.756-3.565-11.652-10.316-34.105-15.714-52.078A3.338,3.338,0,0,1,351.154,244.849Z"
            opacity="0.3"
          />
          <path
            id="Path_85"
            data-name="Path 85"
            d="M327.416,218.2s5.774,55.994,8.262,78.788c2.587,23.71,12.3,101.479,12.3,101.479h12.679s-1.537-76.35-2.047-99.666c-.557-25.42-4.879-80.6-4.879-80.6Z"
            fill="#ff7b40"
          />
          <path
            id="Path_86"
            data-name="Path 86"
            d="M346.005,393.42c-.058,0,.715,5.264.715,5.264h15.424l.417-4.7Z"
            fill="#263238"
          />
          <g id="Group_26" data-name="Group 26">
            <path
              id="Path_87"
              data-name="Path 87"
              d="M273.266,270.883a22.439,22.439,0,1,0,7.015-30.949A22.439,22.439,0,0,0,273.266,270.883Zm34.959-22.039a18.888,18.888,0,1,1-26.05-5.907A18.887,18.887,0,0,1,308.225,248.844Z"
              fill="#263238"
            />
            <path
              id="Path_88"
              data-name="Path 88"
              d="M309.127,262.743a17.309,17.309,0,1,1-13.053-20.708A17.309,17.309,0,0,1,309.127,262.743Z"
              fill="#407bff"
              opacity="0.2"
            />
            <path
              id="Path_89"
              data-name="Path 89"
              d="M311.845,244.229l2.1,3.336a1.085,1.085,0,0,1-.339,1.5h0a1.085,1.085,0,0,1-1.5-.34l-2.1-3.336a1.085,1.085,0,0,1,.34-1.5h0A1.084,1.084,0,0,1,311.845,244.229Z"
              fill="#263238"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M325.453,234.745q-3.646,2.49-7.227,5.087c-2.382,1.739-4.744,3.511-7.036,5.392a1.491,1.491,0,0,0,1.578,2.5c2.686-1.257,5.3-2.624,7.9-4.023s5.161-2.85,7.707-4.328q3.251-1.892,6.454-3.854a1.3,1.3,0,0,0,.42-1.795l-1.733-2.748a1.3,1.3,0,0,0-1.8-.4Q328.563,232.632,325.453,234.745Z"
              fill="#263238"
            />
          </g>
          <path
            id="Path_91"
            data-name="Path 91"
            d="M336.6,163.429c-1.224,6-2.538,11.985-3.9,17.953l-4.148,17.855c-1.341,5.821-2.49,11.724-3.543,17.71l-3.147,18-.007.041a2.823,2.823,0,0,1-5.6-.389c-.216-6.25-.12-12.431.23-18.652a183.023,183.023,0,0,1,1.967-18.682c.974-6.119,2.07-12.168,3.242-18.211s2.438-12.055,3.823-18.043a5.673,5.673,0,0,1,11.086,2.413Z"
            fill="#ff7b40"
          />
          <path
            id="Path_92"
            data-name="Path 92"
            d="M321.006,233.326a2.075,2.075,0,0,0-1.984-.587l-1.626.388a2.063,2.063,0,0,0-1.568,1.74l-.47,3.611a4.6,4.6,0,0,0,.625,2.976l2.232,3.694a2.512,2.512,0,0,0,2.527.943l2.327-.494a2.514,2.514,0,0,0,1.87-3.233l-.61-1.882A11.546,11.546,0,0,0,321.006,233.326Z"
            fill="#7f3e3b"
          />
          <path
            id="Path_93"
            data-name="Path 93"
            d="M344.394,159.958c3.639,9.654-8.948,23.421-8.948,23.421l-15.927-5.268s1.219-13.172,6.747-20.6C333.1,148.34,341.624,152.61,344.394,159.958Z"
            fill="#263238"
          />
        </g>
      </g>
    </g>
  </svg>
))``
