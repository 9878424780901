import { parseJsonResponse } from '../../utils/post-fetch'
import { getFetchOpts } from '../../utils/pre-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function generateKey(token: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/generate-api-key`,
    getFetchOpts('post', {}, token)
  ).then(parseJsonResponse)
}

export function getAPIKey(token: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/list-api-key`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function saveWebhookUrl(token: string, url: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/save-webhook`,
    getFetchOpts('post', { webhook: url }, token)
  ).then(parseJsonResponse)
}

export function getWebhookUrl(token: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-webhook`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}
