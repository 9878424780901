import styled from 'styled-components'
import { Container } from 'backpack'

export const MainAppContainer = styled(Container)`
  .customer-info-overlay {
    position: fixed;
    bottom: 0;
    right: var(--small);
    z-index: 999999999;
    padding: 6px;
    background: hsl(217deg 8% 32% / 30%);
    font-size: var(--small);
    opacity: 0.8;
    color: teal;
    font-weight: bold;
  }
  .customer-info-overlay span {
    color: yellow;
    font-weight: bold;
  }
  .dropzone {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: all 0.3s ease;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999999;
    pointer-events: none;
    background: hsl(0 0% 0% / 0.85);
    border: 11px dashed hsl(30 100% 46% / 0.8);
  }
  .dropzone-message {
    font-weight: bold;
    font-size: var(--xx-large);
    opacity: 0.9;
    pointer-events: none;
  }
  .dropzone-filetypes {
    font-weight: bold;
    font-size: var(--large);
    opacity: 0.6;
    margin-top: 5px;
    pointer-events: none;
  }
  .dropzone-filetypes__values,
  .dropdown-content__label {
    font-weight: normal;
  }
  .dropzone.dragging {
    pointer-events: auto;
    opacity: 1;
  }
  &.light {
    --table-collapsed-content-background: hsl(27deg 100% 99%);
    --table-collapsed-content-box-shadow: inset 0 0 8px 1px
      rgb(255 127 28 / 15%);
    --table-collapsed-content-table-header: hsl(180deg 100% 25% / 10%);
    --color-text-primary: hsl(0deg 0% 8%);
    --color-text-secondary: hsl(0deg 0% 50%);
    --color-bg-canvas-inset: #ffffff;
    --color-bg-canvas: #f4f6fa;
    --color-bg-primary: var(--color-bg-canvas-inset);
    --color-sidebar-button-text: var(--color-text-primary);
    --color-sidebar-button-highlight: #f09b48;
    --color-button-bg: var(--color-sidebar-button-highlight);
    --color-header-bg: var(--color-bg-canvas-inset);
    --color-label-text: #505050;
    --color-input-text: black;
    --color-link-button-text: hsl(224deg 60% 50%);
    --p-color-1: var(--color-text-primary);
    --color-border-secondary: hsl(212deg 12% 30% / 10%);
    --color-bg-primary-dark: #3a3e44;
    --color-green-primary: #28a745;
    --color-green-secondary: #28a745;
    color: var(--color-text-primary);
    background: var(--color-bg-canvas);
    .quick-access .result {
      background: hsl(0deg 0% 0% / 70%);
    }
    .loader .loader__mask {
      background: hsla(0, 0%, 0%, 0.7);
    }
    .loader .loader__text {
      color: #fff;
      font-weight: bold;
    }
    .loader .loader__spinner-container > div {
      --spinner-color: var(--color-brand-fill-primary);
    }
    .dot-button .dot {
      background: var(--color-brand-fill-primary);
      opacity: 0.7;
    }
    .table-action:hover .dot-button {
      background: hsl(0 0% 20% / 0.1);
    }
    .table-action:hover .dot {
      opacity: 1;
    }
    .table-action .filter-options {
      background: var(--color-bg-canvas-inset);
    }
    .table-action .filter-options > div:hover {
      background: var(--color-brand-fill-primary);
      color: #fff;
      font-weight: bold;
    }
    .popup__content .field input,
    .popup__content input.field,
    .popup__content .filter-dropdown,
    .popup__content .chip-button {
      background: var(--color-bg-canvas-inset);
      opacity: 0.8;
      box-shadow: 1px 3px 3px 0px rgb(0 0 0 / 15%);
    }
    .popup__content .filter-dropdown {
      box-shadow: 1px 3px 3px 0px rgb(0 0 0 / 15%);
    }
    .popup__content .field:hover {
      opacity: 1;
    }
    .sidebar-top-block {
      border-right: unset;
    }
    .sidebar-button:hover .sidebar-button__content {
      opacity: 0.8;
      color: var(--color-sidebar-button-highlight);
    }
    .sidebar-button:hover .sidebar-button__icon svg,
    .sidebar-button:hover .sidebar-button__icon svg path,
    .sidebar-button:hover .sidebar-button__icon svg g {
      fill: var(--color-sidebar-button-highlight);
    }
    .sidebar-button.active {
      background: rgb(0 0 0 / 05%);
    }
    button.primary {
      --color-button-text: #fff;
      font-weight: bold;
      box-shadow: -2px 2px 2px 0px hsl(0deg 0% 0% / 30%);
    }
    button.secondary {
      --color-button-text: #fff;
      font-weight: bold;
      box-shadow: -2px 2px 2px 0px hsl(0deg 0% 0% / 30%);
    }
    button.secondary,
    button.secondary .button-content .button-icon,
    button.secondary .button-content .button-icon__line {
      transition: all 0.3s cubic-bezier(0.55, 0.09, 0.68, 0.53);
    }
    button.secondary:hover {
      color: var(--color-button-text);
    }
    button.secondary:hover .button-content .button-icon {
      fill: var(--color-button-text);
      stroke: var(--color-button-text);
    }
    button.secondary:hover .button-content .button-icon__line {
      background: var(--color-button-text);
    }
    .statistic-card,
    .active-job-list,
    .line-graph-card,
    .header,
    .sidebar,
    .profile-dropdown-panel,
    .toast,
    .inset-container,
    .filter-box,
    .popup__content .exposed,
    .timeline-event-info,
    .timeline-event-info .description {
      border: none;
      box-shadow: 1px 3px 3px 0px rgb(0 0 0 / 15%);
    }
    .status.disabled {
      color: hsl(0deg 0% 30% / 60%);
    }
    .response,
    .api-doc {
      background: var(--color-bg-canvas);
      box-shadow: inset 0 0 8px 1px hsl(0deg 0% 0% / 20%);
    }
    .api-doc:hover path {
      fill: var(--color-brand-fill-primary);
    }
    .profile-dropdown-panel {
      box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
    }
    .line-graph-card {
      background: var(--color-bg-canvas-inset);
    }
    .toast .toast-bg {
      opacity: 0.1;
    }
    .popup__header {
      background: hsl(220deg 38% 85%);
      border-bottom: none;
    }
    .popup__header .popup__title {
      color: var(--color-text-primary);
    }
    .popup__content {
      background: var(--color-bg-canvas);
    }
    .popup__header svg,
    .popup__header g,
    .popup__header path {
      stroke: var(--color-text-primary);
      fill: var(--color-text-primary);
    }
    .dropdown-container,
    .dropdown-container label,
    .add-job__file-upload {
      color: var(--color-text-primary);
      opacity: 1;
    }
    .dropdown-container .value-label,
    .file-upload__label {
      background: var(--color-bg-canvas-inset);
    }
    .dropdown-box__options > *:hover,
    .collapsed .filter-options > div:hover,
    .calendar .row > button:hover,
    .action-buttons > *:hover,
    .filter-options > span.option {
      background: var(--color-brand-fill-primary);
      color: #fff;
    }
    *::-webkit-scrollbar {
      background-color: var(--color-bg-canvas);
    }
    *::-webkit-scrollbar-thumb {
      box-shadow: unset;
      background-color: hsl(0deg 0% 0% / 80%);
    }
    *::-webkit-scrollbar-track {
      box-shadow: unset;
      background-color: var(--color-bg-canvas);
    }
    .profile-dropdown-panel__logout-btn {
      background: var(--color-brand-fill-primary);
      cursor: pointer;
      font-weight: bold;
      color: #fff;
    }
    .profile-dropdown-panel__logout-btn:hover {
      background: var(--color-button-bg-hovered);
      color: #fff;
    }
    .profile-dropdown-panel__logout-btn:active {
      opacity: 0.8;
    }
    .profile-dropdown-panel > div:first-child {
      border-bottom: unset;
      background: #fff;
    }
    .filter-options,
    button.primary.tab-button {
      font-weight: normal;
    }
    .no-item {
      background: black;
      color: #fff;
    }
    button.primary.tab-button {
      box-shadow: unset;
      border-right: 1px solid hsl(0deg 0% 0% / 20%);
      color: var(--color-text-secondary);
    }
    button.primary.tab-button.active {
      background: #fff;
      color: var(--color-brand-text-primary);
    }
    button.primary.tab-button:hover {
      background: #fff;
      color: var(--color-brand-text-primary);
    }
    button.disabled {
      filter: grayscale(1) brightness(1);
    }
    .input-elem {
      background: none;
    }
    .input-area.with-icon,
    .active-job-list,
    .checkbox__checkbox,
    .printer-group,
    .printer-list-item {
      background: var(--color-bg-canvas-inset);
      border-radius: 10px;
      border: none;
      box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
    }
    .tab-content,
    .printer-group {
      .input-area.with-icon,
      .active-job-list,
      .checkbox__checkbox,
      .printer-group,
      .printer-list-item {
        background: var(--color-bg-canvas);
      }
    }
    .tab-panel {
      background: hsl(0deg 0% 95%);
    }
    .tab-content,
    .tab-panel,
    .printer-details-active-jobs,
    .desktop-view,
    .timeline-grid-container,
    .Card,
    .dropdown-list,
    .printer-info__printer-name input,
    .jobs-queue-table > div,
    .jobs-queue-table {
      border: none;
      box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
    }
    .dropdown-list > .dropdown-button {
      background: var(--color-bg-canvas-inset);
      color: var(--color-text-primary);
    }
    .printer-info__printer-name input,
    .dropdown-list > .dropdown-button:hover {
      background: var(--color-bg-canvas);
    }
    .dropdown-list > .dropdown-button svg {
      fill: var(--color-text-primary);
    }
    .dropdown-list > .dropdown-button.delete {
      color: var(--color-red-primary);
    }
    .dropdown-list > .dropdown-button.delete:hover {
      background: hsl(0deg 70% 60% / 15%);
    }
    .timeline-container {
      background: unset;
    }
    .timeline__header,
    .printer-header-title {
      background: whitesmoke;
      color: var(--color-text-secondary);
      font-weight: bold;
      box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
      border: none;
    }
    .timeline {
      background: #fff;
      margin-top: var(--header-height);
    }
    .timeline__header,
    .timeline-container {
      border: none;
    }
    .timeline__time {
      height: var(--header-height);
    }
    .timeline-inner-container > div {
      box-shadow: rgb(0 0 0 / 15%) 0px 1px 3px 1px;
      border: none;
    }
    .timeline-inner-container > div:first-child > div {
      background: var(--color-bg-canvas);
      border-color: hsl(0deg 0% 0% / 15%);
    }
    .timeline-inner-container .name {
      color: var(--color-text-secondary);
      font-weight: normal;
    }
    .td-collapsed-content {
      background: hsl(27deg 100% 99%);
      box-shadow: inset 0 0 8px 1px rgb(255 127 28 / 15%);
    }
    .page-number.active {
      border: none;
      background: var(--color-brand-fill-primary);
      color: #fff;
    }
    .sidebar-collapsible-menu {
      box-shadow: inset 0 0 8px 1px hsl(0deg 0% 0% / 20%);
      background: #fff;
      border: unset;
    }
    .sidebar-collapsible-menu button {
      background: transparent;
    }
    .sidebar-collapsible-menu button:hover {
      background: rgb(242 242 242 / 50%);
      color: var(--color-brand-fill-primary);
    }
    .sidebar-button__highlight {
      background: var(--color-brand-fill-primary);
    }
    .sidebar-collapsible-menu button.active .sidebar-button__content {
      color: #fff;
      font-weight: bold;
    }
    input.value {
      background: #fff;
      box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
    }
    .group-name-input {
      background: var(--color-bg-canvas-inset);
      border: none;
      box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
    }
    .filter-box {
      background: hsl(0 0% 0% / 0.02);
    }
    .printer-list-item.inactive {
      opacity: 0.8;
    }
    #dentaform {
      #Subtraction_1 {
        fill: var(--color-text-secondary);
      }
      #Path_25 {
        fill: var(--color-text-secondary);
        opacity: 0.3;
      }
      #Path_26 {
        fill: var(--color-text-secondary);
        opacity: 0.3;
      }
      #Rectangle_29 {
        fill: none;
        stroke: var(--color-text-secondary);
      }
      #power-button {
        stroke: var(--color-text-secondary);
        fill: none;
      }
    }
    .skeleton-shine {
      background: linear-gradient(
        to right,
        #00000000 30%,
        #0d0d0d10 50%,
        #00000000 70%
      );
      background-size: 200% auto;
    }
    #velox {
      #outline-shape,
      #screen,
      #right-line,
      #left-line,
      #power-button {
        fill: none;
        stroke: var(--color-text-secondary);
      }
      #top-door,
      #door,
      #top-left-stroke-1,
      #top-left-stroke-2,
      #top-right-stroke-2,
      #top-right-stroke-1,
      #bottom-left-stroke,
      #bottom-right-stroke {
        fill: var(--color-text-secondary);
      }
    }
    .calendar .row button.active,
    .collapsed .arrow-box,
    .popup__header__close-button:hover {
      background: var(--color-brand-fill-primary);
      color: #fff;
    }
    .month-item.active {
      color: #fff;
      background: var(--color-brand-fill-primary);
      font-weight: bold;
    }
    .add-field-btn {
      --p-color-1: var(--color-brand-fill-primary);
    }
    #printer-details-container {
      padding-bottom: 15px;
    }
    .chips-items {
      background: var(--color-bg-canvas-inset);
    }
    .chip-item {
      color: black;
      background: transparent;
      border: none;
      box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
    }
    .chip-item.active {
      background: var(--color-brand-fill-primary);
      color: #fff;
      font-weight: bold;
      border: none;
      opacity: 1;
    }
    .file-name.successful,
    .status.successful,
    .status.enabled {
      color: var(--color-green-secondary);
    }
    .file-name.cancelled,
    .file-name.failed,
    .status.cancelled,
    .status.failed {
      color: var(--color-red-secondary);
    }
    .dropdown-menu-button svg {
      fill: var(--color-text-primary);
    }
    .printer-details-change-name svg {
      fill: grey;
    }
  }
`
