export const GET_TEAM_PENDING = 'GET_TEAM_PENDING'
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS'
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE'
export const ADD_USER_PENDING = 'ADD_USER_PENDING'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE'
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'
export const ENABLE_DISABLE_USER_PENDING = 'ENABLE_DISABLE_USER_PENDING'
export const ENABLE_DISABLE_USER_SUCCESS = 'ENABLE_DISABLE_USER_SUCCESS'
export const ENABLE_DISABLE_USER_FAILURE = 'ENABLE_DISABLE_USER_FAILURE'
export const UPDATE_USER_GROUP_PENDING = 'UPDATE_USER_GROUP_PENDING'
export const UPDATE_USER_GROUP_SUCCESS = 'UPDATE_USER_GROUP_SUCCESS'
export const UPDATE_USER_GROUP_FAILURE = 'UPDATE_USER_GROUP_FAILURE'
