import React from 'react'
import styled from 'styled-components'
import { CheckIcon, CloseIcon } from 'backpack'

interface IPrintCount extends React.HTMLAttributes<HTMLDivElement> {
  total: number
  success: number
  failure: number
}

export default styled(({ total, success, failure, ...rest }: IPrintCount) => (
  <div {...rest}>
    {/* <div className="total" title={`Total prints: ${total}`}>
      {total}
    </div> */}
    <div className="success" title={`Successful prints: ${success}`}>
      <CheckIcon />
      {success}
    </div>
    <div className="failure" title={`Failed prints: ${failure}`}>
      <CloseIcon /> {failure}
    </div>
  </div>
))`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  background: rgb(15 155 143 / 10%);
  color: var(--color-text-secondary);
  opacity: 0.8;
  > * {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    line-height: 100%;
  }
  .success {
    color: hsl(120deg 80% 45%);
  }
  .success svg,
  .success path {
    fill: hsl(120deg 80% 45%);
  }
  .failure {
    color: hsl(0deg 80% 70%);
  }
  .failure svg,
  .failure path {
    fill: hsl(0deg 80% 70%);
  }
  .success svg {
    width: 14px;
    height: 10.44px;
  }
  .failure svg {
    width: 12px;
    height: 12px;
  }
`
