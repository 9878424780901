import { takeEvery, fork, put, select, call } from 'redux-saga/effects'
import {
  OVERVIEW_STATS_PENDING,
  OVERVIEW_GRAPH_STATS_PENDING
} from './constant'
import { IAuthState } from '../auth/types'
import {
  getOverviewStatsFailure,
  getOverviewStatsSuccess,
  getOverviewGraphStatsSuccess,
  getOverviewGraphStatsFailure
} from './actions'
import { getPrintStats, getPrintStatsOverDuration } from './api'
import { IPrintStats, IOverviewGraphStatsAction, IGraphStatsRes } from './types'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken

export function* overviewStatsHandler() {
  try {
    const accessToken = yield select(getToken)
    const printStats: IPrintStats = yield call(getPrintStats, accessToken)
    yield put(getOverviewStatsSuccess(printStats))
  } catch (err) {
    yield put(getOverviewStatsFailure('Failed to fetch overview'))
  }
}

export function* overviewGraphStatsHandler({
  payload
}: IOverviewGraphStatsAction) {
  const { duration, type } = payload
  try {
    const accessToken = yield select(getToken)
    const { printLogs }: { printLogs: IGraphStatsRes[] } = yield call(
      getPrintStatsOverDuration,
      accessToken,
      duration
    )
    yield put(getOverviewGraphStatsSuccess(printLogs, duration, type))
  } catch (err) {
    yield put(getOverviewGraphStatsFailure())
  }
}

function* watchOverviewStats() {
  yield takeEvery(OVERVIEW_STATS_PENDING, overviewStatsHandler)
}

function* watchOverviewGraphStats() {
  yield takeEvery(OVERVIEW_GRAPH_STATS_PENDING, overviewGraphStatsHandler)
}

export default [fork(watchOverviewStats), fork(watchOverviewGraphStats)]
