import { put, call, takeEvery, fork, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import {
  getOrders,
  getOrdersFailure,
  getOrdersSuccess,
  downloadCase,
  downloadCaseFailure,
  downloadCaseSuccess
} from './slice'
import {
  IDownloadCaseAction,
  IGetOrdersAction,
  IGetOrdersAPIResponse,
  IDownloadCaseAPIResponse
} from './types'
import { getOrdersAPI, downloadCaseAPI } from './api'
import { IAuthState } from '../auth/types'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken

export function* getOrdersHandler(action: PayloadAction<IGetOrdersAction>) {
  try {
    const token: string = yield select(getToken)

    const res: IGetOrdersAPIResponse = yield call(getOrdersAPI, { token })

    yield put(getOrdersSuccess({ orders: res?.confirmedOrders }))
  } catch (err) {
    yield put(getOrdersFailure({ error: (err as Error).message }))
  }
}

export function* downloadCaseHandler(
  action: PayloadAction<IDownloadCaseAction>
) {
  try {
    const token: string = yield select(getToken)
    const body = action.payload

    const res: IDownloadCaseAPIResponse = yield call(downloadCaseAPI, {
      ...body,
      token
    })

    yield put(
      downloadCaseSuccess({
        blob: res.blob,
        caseName: body.caseName,
        orderId: body.orderId
      })
    )
  } catch (err) {
    yield put(downloadCaseFailure({ error: (err as Error).message }))
  }
}

function* watchGetOrders() {
  yield takeEvery(getOrders.toString(), getOrdersHandler)
}

function* watchDownloadCase() {
  yield takeEvery(downloadCase.toString(), downloadCaseHandler)
}

const orderSaga = [fork(watchGetOrders), fork(watchDownloadCase)]

export default orderSaga
