import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useInView } from 'react-intersection-observer'

import { hideQuickAccess as hideQuickAccessAction } from '../redux/quickAccess/slice'
import { showAddJobForm } from '../redux/addJobForm/slice'
import { showStartJobForm } from '../redux/startJobForm/slice'
import { IPrinterState } from '../redux/printer/types'

import { QuickAccessIcon } from './SVGImages'

const QuickAccessOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  animation: fadeIn 0.3s ease forwards;
  &.hidden {
    animation: fadeOut 0.3s ease forwards;
  }
  .bg-mask {
    background: rgb(0 0 0 / 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    pointer-events: auto;
  }
  .content {
    margin-top: 50px;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 60px);
    pointer-events: none;
  }
  .content > * {
    pointer-events: auto;
  }
  .search-field input {
    user-select: none;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 12px;
    box-sizing: border-box;
    font-size: 18px;
    background: hsl(228deg 5% 84%);
    padding-left: 42px;
    position: relative;
    width: 100%;
  }
  .search-field {
    position: relative;
    width: 100%;
    max-width: 340px;
  }
  .search-access-icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    pointer-events: none;
  }
  .results {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 12px;
    align-items: center;
    max-height: calc(100% - 140px);
    overflow: auto;
    padding: 12px;
    box-sizing: border-box;
  }
  .result {
    padding: 15px;
    border-radius: 10px;
    background: hsl(0deg 0% 50% / 70%);
    border: 1px solid transparent;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    color: #fff;
    text-align: left;
    padding: 14px 24px;
    max-width: 340px;
    cursor: pointer;
    box-shadow: 1px 3px 3px 0px rgb(0 0 0 / 15%);
    transition: all 0.3s ease;
    opacity: 0.8;
  }
  .result:hover {
    background: hsl(27deg 59% 48%) !important;
    border: 1px solid white;
    transform: translateY(2px);
    box-shadow: 1px 1px 3px 0px rgb(0 0 0 / 15%);
    opacity: 1;
  }
  .result.highlight {
    background: hsl(27deg 59% 48%) !important;
    border: 1px solid white;
    opacity: 1;
  }
`

type Query = {
  label: string
  keyword?: 'printers'
  action: () => void
}

export function QuickAccessOverlay({ visible }: { visible: boolean }) {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState(0)
  const pushWithDispatch = (path: string) => dispatch(push(path))

  const inputRef = useRef<HTMLInputElement>(null)
  const highlightedResultRef = useRef<HTMLDivElement>()

  // Get printers
  const { printers } = useSelector(
    ({ printerData }: { printerData: IPrinterState }) => printerData
  )

  const defaultQueries = [
    {
      label: 'Add Job',
      action: () => dispatch(showAddJobForm())
    },
    {
      label: 'Start Print',
      action: () =>
        dispatch(showStartJobForm({ preselectedPrinterName: undefined }))
    },
    {
      label: 'View Overview',
      action: () => pushWithDispatch('/overview')
    },
    {
      label: 'View all Printers',
      action: () => pushWithDispatch('/printers')
    },
    {
      label: 'View Timeline',
      action: () => pushWithDispatch('/timeline')
    },
    {
      label: 'View Active Jobs',
      action: () => pushWithDispatch('/jobs/active')
    },
    {
      label: 'View Queued Jobs',
      action: () => pushWithDispatch('/jobs/queue')
    },
    {
      label: 'View Completed Jobs',
      action: () => pushWithDispatch('/jobs/completed')
    },
    {
      label: 'View TFAs',
      action: () => pushWithDispatch('/consumables/teflons')
    },
    {
      label: 'Manage Team',
      action: () => pushWithDispatch('/settings/users')
    },
    {
      label: 'View Api Docs',
      action: () => pushWithDispatch('/settings/api')
    }
  ] as Query[]

  function getQueries() {
    const queries = defaultQueries

    if (printers && printers.length > 0) {
      printers.forEach(p => {
        queries.push({
          label: `Go to ${p.customPrinterName}`,
          keyword: 'printers',
          action: () => pushWithDispatch(`/printers/${p.name}`)
        })
      })
    }
    return queries
  }

  const queries = getQueries()

  const onSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setSearchText(value)
    if (selectedRowIndex !== 0) setSelectedRowIndex(0)
  }

  const onClickBgMask = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { className } = e.currentTarget
    if (className.includes('bg-mask') || className.includes('results')) {
      dispatch(hideQuickAccessAction())
    }
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const results = getResults()
    if (results.length > 0) {
      callActionAndHide(results[selectedRowIndex].action)
    }
  }

  useEffect(() => {
    if (visible && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [visible])

  function getResults() {
    if (searchText.length < 3) return []
    const results = queries.filter(
      q =>
        q.label.toLowerCase().includes(searchText.toLowerCase()) ||
        (q.keyword &&
          q.keyword.toLowerCase().includes(searchText.toLowerCase()))
    )
    return results
  }

  function getHighlightedText(label: string) {
    if (searchText === '') return label

    const startIndex = label.toLowerCase().indexOf(searchText.toLowerCase())
    if (startIndex === -1) return label

    const prepend = label.substr(0, startIndex)
    const append = label.substr(startIndex + searchText.length, label.length)
    const markedText = label.substr(prepend.length, searchText.length)

    return (
      <span>
        {prepend}
        <mark>{markedText}</mark>
        {append}
      </span>
    )
  }

  const callActionAndHide = (action: () => void) => {
    dispatch(hideQuickAccessAction())
    action()
  }

  const results = getResults()

  // Keypress up down events
  const [inViewRef, inView] = useInView()
  const setRefs = useCallback(
    node => {
      // Ref's from useRef needs to have the node assigned to `current`
      highlightedResultRef.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef]
  )

  useEffect(() => {
    if (!inView && results.length > 1) {
      if (!inView && highlightedResultRef && highlightedResultRef.current) {
        highlightedResultRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }, [inView, results.length])

  const keydownHandler = useCallback(
    ({ key }: { key: string }) => {
      if (key === 'Escape') dispatch(hideQuickAccessAction())

      if (results.length <= 1) return
      if (key === 'ArrowUp') {
        if (selectedRowIndex > 0) {
          setSelectedRowIndex(selectedRowIndex - 1)
        }
      }

      if (key === 'ArrowDown') {
        if (selectedRowIndex < results.length - 1) {
          setSelectedRowIndex(selectedRowIndex + 1)
        }
      }
    },
    [results.length, selectedRowIndex, dispatch]
  )

  useEffect(() => {
    window.addEventListener('keydown', keydownHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', keydownHandler)
    }
  }, [keydownHandler])

  return (
    <QuickAccessOverlayContainer
      className={`quick-access ${!visible ? 'hidden' : ''}`}
    >
      <div className="bg-mask" onClick={onClickBgMask} />
      <form onSubmit={onSubmit} className="content">
        <div className="search-field">
          <QuickAccessIcon className="search-access-icon" />
          <input
            ref={inputRef}
            type="text"
            placeholder="Type the printer name..."
            value={searchText}
            onChange={onSearchTextChange}
            autoComplete="off"
          />
        </div>
        <div className="results" onClick={onClickBgMask}>
          {results.length > 0 &&
            results.map(({ label, action }, index) => (
              <div
                key={label}
                className={`result${
                  index === selectedRowIndex ? ' highlight' : ''
                }`}
                onClick={() => callActionAndHide(action)}
                ref={index === selectedRowIndex ? setRefs : undefined}
              >
                <span className="result-label">
                  {getHighlightedText(label)}
                </span>
              </div>
            ))}
        </div>
      </form>
    </QuickAccessOverlayContainer>
  )
}
