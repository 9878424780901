import { getFetchOpts, processUrl } from '../../utils/pre-fetch'
import { parseJsonResponse, parseBlobResponse } from '../../utils/post-fetch'
import { parseDentaformPastPrints, parseVeloxPastPrints } from '../printer/api'
import { IExportDates, IPastPrintFilter, IPrintFeedback } from './types'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function getFilterObject(filter: IPastPrintFilter) {
  return {
    ...filter,
    sortBy: filter.sortBy === 'createdAt' ? 'firstReportedAt' : filter.sortBy,
    fromDate: filter.fromDate.toISOString(),
    toDate: filter.toDate.toISOString(),
    printers:
      filter.printers && filter.printers.length
        ? filter.printers.join('$')
        : '',
    statuses:
      filter.statuses && filter.statuses.length
        ? filter.statuses.join('$')
        : '',
    page: filter.page || 1,
    limit: filter.limit || 10
  }
}

export function getAllPastPrintsDentaForm(
  token: string,
  filter: IPastPrintFilter
) {
  return fetch(
    processUrl(
      `${DROPLET_API_URL}/mystructo/get-dentaform-jobs`,
      getFilterObject(filter)
    ),
    getFetchOpts('get', {}, token)
  )
    .then(parseJsonResponse)
    .then(parseDentaformPastPrints)
}

export function getAllPastPrintsVelox(token: string, filter: IPastPrintFilter) {
  return fetch(
    processUrl(
      `${DROPLET_API_URL}/mystructo/get-velox-jobs`,
      getFilterObject(filter)
    ),
    getFetchOpts('get', {}, token)
  )
    .then(parseJsonResponse)
    .then(parseVeloxPastPrints)
}

export function postPrintFeedback(
  token: string,
  feedback: IPrintFeedback,
  jobId: string,
  printerName: string,
  printerType: string
) {
  const url = `${DROPLET_API_URL}/mystructo/${
    printerType === 'velox'
      ? 'create-velox-feedback'
      : 'create-dentaform-feedback'
  }`
  return fetch(
    url,
    getFetchOpts(
      'post',
      { ...feedback, printer: printerName, jobId, type: printerType },
      token
    )
  ).then(parseJsonResponse)
}

export function updatePrintFeedback(
  token: string,
  feedback: IPrintFeedback,
  jobId: string,
  printerName: string,
  printerType: string
) {
  const url = `${DROPLET_API_URL}/mystructo/${
    printerType === 'velox'
      ? 'update-velox-feedback'
      : 'update-dentaform-feedback'
  }`
  return fetch(
    url,
    getFetchOpts(
      'put',
      { ...feedback, printer: printerName, jobId, type: printerType },
      token
    )
  ).then(parseJsonResponse)
}

export function exportJobs(
  token: string,
  printersType: string,
  printers: string[],
  dates: IExportDates
) {
  const url = `${DROPLET_API_URL}/mystructo/${
    printersType === 'velox' ? 'export-velox-jobs' : 'export-dentaform-jobs'
  }`
  return fetch(url, getFetchOpts('post', { printers, dates }, token)).then(
    parseBlobResponse
  )
}

export function addNewJob(
  token: string,
  fileStoreKey: string,
  printerName: string
) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/reprint-file`,
    getFetchOpts('post', { fileStoreKey, printerName }, token)
  ).then(parseJsonResponse)
}
