import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IGetTimelineAction,
  ITimelineState,
  IGetTimelineSuccess
} from './types'

export const initialState: ITimelineState = {
  isGettingTimeline: false,
  printerEvents: []
}

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    getTimeline: (state, action: PayloadAction<IGetTimelineAction>) => {
      state.isGettingTimeline = true
    },
    getTimelineFailure: state => {
      state.isGettingTimeline = false
    },
    getTimelineSuccess: (state, action: PayloadAction<IGetTimelineSuccess>) => {
      const { printerEvents } = action.payload
      state.isGettingTimeline = false
      state.printerEvents = printerEvents
    }
  }
})

export const {
  getTimeline,
  getTimelineFailure,
  getTimelineSuccess
} = timelineSlice.actions

export default timelineSlice.reducer
