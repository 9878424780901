import React from 'react'
import { Backpack } from 'backpack'

import sagas from './sagas'
import ConfiguredStore from './ConfiguredStore'
import ProviderWrapper from './Provider'
import sagaMiddleware from './saga-middleware'
import App from './MainApp/MainApp'

sagaMiddleware.run(sagas)

const store = ConfiguredStore()

export default () => (
  <ProviderWrapper store={store}>
    <Backpack>
      <App />
    </Backpack>
  </ProviderWrapper>
)
