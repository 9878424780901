import { takeEvery, fork, call, put, delay, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { ToastType } from 'backpack'
import ReactGA from 'react-ga'

import {
  AUTH_LOGIN_PENDING,
  AUTH_LOGOUT_PENDING,
  AUTH_RENEW_TOKEN,
  AUTH_FORGOT_PASSWORD_PEDNING,
  AUTH_HIDE_BANNER_PENDING
} from './constants'
import {
  ILoginAction,
  IRenewTokenAction,
  IForgotPasswordAction,
  IUser,
  IAuthState
} from './types'
import {
  login,
  getUserProfile,
  renewToken,
  forgotPassword,
  updateUserMetaData,
  getCustomerInformation
} from './api'
import {
  loginFailure,
  loginSuccess,
  logoutSuccess,
  renewToken as renewTokenAction,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  hideBannerFailed,
  hideBannerSuccess,
  getCompanyInfomationSuccess
} from './actions'
import { addToast } from '../toasts/actions'
import { getLatestVersion } from '../app/actions'

import { setSession, removeSession, getSession } from '../../utils/session'

import { printerHandler } from '../printer/saga'
import { connectIOT } from '../iot/actions'
import { appSettingsHandler } from '../app/saga'

export const getLocation = ({ router }) => router.location
export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken

export function* setSessionAndScheduleRenewToken(
  accessToken: string,
  refreshToken: string,
  expiresIn: number
) {
  yield call(setSession, accessToken, refreshToken, expiresIn)
  yield fork(scheduleRenewToken)
}

export function* logoutHandler(redirectUrl = '') {
  yield call(removeSession)
  yield put(logoutSuccess())
  yield put(
    push(
      redirectUrl && typeof redirectUrl === 'string' && redirectUrl !== '/login'
        ? `/login?r=${redirectUrl}`
        : '/login'
    )
  )
}

export function* renewTokenHandler({ payload }: IRenewTokenAction) {
  const { fetchProfile } = payload
  const { token, refreshToken } = yield call(getSession)
  if (token && refreshToken) {
    try {
      const { access_token, expires_in } = yield call(
        renewToken,
        refreshToken,
        token
      )
      yield fork(
        setSessionAndScheduleRenewToken,
        access_token,
        refreshToken,
        expires_in
      )
      if (fetchProfile) {
        yield fork(getProfile, access_token, refreshToken)
        yield put(getLatestVersion())
      }
      // reconnect to iot with new access token
      yield put(connectIOT(access_token))
    } catch (err) {
      yield call(logoutHandler)
    }
  } else {
    const { pathname } = yield select(getLocation)
    yield call(logoutHandler, pathname)
  }
}

export function* scheduleRenewToken() {
  const { expiresAt, token, refreshToken } = yield call(getSession)
  if (expiresAt && token && refreshToken) {
    const expiryTime = parseInt(expiresAt, 10)
    const timeout = expiryTime - Date.now()
    // should refresh before 2 minutes of expiry
    const buffer = 60000 * 2
    if (timeout > 0) {
      yield delay(timeout - buffer)
    }
    yield put(renewTokenAction(false))
  } else {
    yield call(logoutHandler)
  }
}

export function* getProfile(accessToken: string, refreshToken: string) {
  try {
    const res = yield call(getUserProfile, accessToken)
    const { email, name, picture } = res
    const roles = res['https://structo3d.com/roles'] || []
    const userMetadata = res['https://structo3d.com/user_metadata'] || {
      hide_banner: false
    }

    console.log('res', res)
    const user: IUser = {
      email,
      name,
      picture,
      customerId: res['https://structo3d.com/customer_id'],
      isMainUser: roles.indexOf('main-user') !== -1,
      isSubUser: roles.indexOf('sub-user') !== -1,
      isProducer: roles.indexOf('producer') !== -1,
      showBanner: !userMetadata.hide_banner
    }
    ReactGA.set({ customerId: user.customerId })
    yield put(loginSuccess(user, accessToken, refreshToken))
    // Show Login welcome message
    yield put(
      addToast({
        title: 'Login Success',
        description: `Welcome back, ${name}`,
        type: ToastType.success,
        stayDuration: 3000
      })
    )
    const veloxPrinters: string[] = res['https://structo3d.com/velox'] || []
    // Trigger printer handler to get dentaform printers
    yield fork(printerHandler, veloxPrinters, accessToken, user.customerId)
    // get app settings
    yield fork(appSettingsHandler)
    // get customer information
    yield fork(getCustomerInformationHandler, accessToken)
  } catch (err) {
    yield put(loginFailure())
    yield put(
      addToast({
        title: `Login Failed`,
        description: err && err.toString(),
        type: ToastType.error
      })
    )
  }
}

export function* loginHandler({ payload }: ILoginAction) {
  const { username, password } = payload
  try {
    const { access_token, refresh_token, expires_in } = yield call(
      login,
      username,
      password
    )
    const { search } = yield select(getLocation)
    const params = new URLSearchParams(search)
    const redirectURL = params.get('r')
    yield put(push(redirectURL || '/'))
    yield call(getProfile, access_token, refresh_token)
    yield fork(
      setSessionAndScheduleRenewToken,
      access_token,
      refresh_token,
      expires_in
    )
    // set the handler to fetch the latest version every 6 hours
    yield put(getLatestVersion())
  } catch (err) {
    yield put(loginFailure(err, username))
    yield put(
      addToast({
        title: `Login Failed`,
        description: err && err.toString(),
        type: ToastType.error
      })
    )
  }
}

export function* forgotPasswordHandler({ payload }: IForgotPasswordAction) {
  const { email } = payload
  try {
    const message = yield call(forgotPassword, email)
    yield put(forgotPasswordSuccess())
    // trigger some notification for success
    yield put(
      addToast({
        title: `Email Sent`,
        description: message,
        type: ToastType.success
      })
    )
  } catch (err) {
    // trigger some notification for failure
    yield put(forgotPasswordFailure('Please try again later'))
  }
}

export function* hideBannerHandler() {
  try {
    const token = yield select(getToken)
    yield call(updateUserMetaData, token, {
      hide_banner: true
    })
    yield put(hideBannerSuccess())
  } catch (err) {
    yield put(hideBannerFailed())
  }
}

export function* getCustomerInformationHandler(accessToken) {
  try {
    const { companyName, address } = yield call(
      getCustomerInformation,
      accessToken
    )
    yield put(getCompanyInfomationSuccess(companyName, address))
  } catch (err) {}
}

function* watchLogin() {
  yield takeEvery(AUTH_LOGIN_PENDING, loginHandler)
}

function* watchLogout() {
  yield takeEvery(AUTH_LOGOUT_PENDING, logoutHandler)
}

function* watchRenewToken() {
  yield takeEvery(AUTH_RENEW_TOKEN, renewTokenHandler)
}

function* watchForgotPassword() {
  yield takeEvery(AUTH_FORGOT_PASSWORD_PEDNING, forgotPasswordHandler)
}

function* watchHideBanner() {
  yield takeEvery(AUTH_HIDE_BANNER_PENDING, hideBannerHandler)
}

export default [
  fork(watchLogin),
  fork(watchLogout),
  fork(watchRenewToken),
  fork(watchForgotPassword),
  fork(watchHideBanner)
]
