import { getFetchOpts } from '../../utils/pre-fetch'
import { parseJsonResponse } from '../../utils/post-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function getTimelineAPI(token: string, printers: string[], date: Date) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-printer-activity?printers=${printers.join(
      '$'
    )}&date=${date.toISOString()}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}
