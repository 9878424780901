import { put, call, takeEvery, fork, select } from 'redux-saga/effects'
import { generateKey, getAPIKey, getWebhookUrl, saveWebhookUrl } from './api'
import {
  generateKeySuccess,
  generateKeyFailure,
  saveWebhookUrlSuccess,
  saveWebhookUrlFailure,
  getAPIKeySuccess,
  getAPIKeyFailure,
  getWebhookUrlSuccess,
  getWebhookUrlFailure
} from './actions'
import {
  API_GENERATE_KEY,
  API_GET_KEY,
  API_GET_WEBHOOK_URL,
  API_SAVE_WEBHOOK_URL
} from './constants'
import { IAuthState } from '../auth/types'
import {
  IApiKeyRes,
  IGetAPIKeyRes,
  IWebhookAPIRes,
  IWebhookURLAction
} from './types'
import { addToast } from '../toasts/actions'
import { ToastType } from 'backpack'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken

export function* keyGenerationHandler() {
  try {
    const accessToken = yield select(getToken)
    const res: IApiKeyRes = yield call(generateKey, accessToken)
    yield put(generateKeySuccess(res))
  } catch (err) {
    yield put(generateKeyFailure())
    yield put(
      addToast({
        title: `Failed to generate API key`,
        description: `Please try again later.`,
        type: ToastType.error
      })
    )
  }
}

export function* getAPIKeyHandler() {
  try {
    const accessToken = yield select(getToken)
    const res: [IGetAPIKeyRes] = yield call(getAPIKey, accessToken)
    const apiKey = res.length ? new Array(32).fill('*').join('') : ''
    const lastGeneratedAt = res.length ? new Date(res[0].updatedAt) : undefined
    yield put(getAPIKeySuccess(apiKey, lastGeneratedAt))
  } catch (err) {
    yield put(getAPIKeyFailure())
  }
}

export function* saveWebhookUrlHandler({ payload }: IWebhookURLAction) {
  try {
    const accessToken = yield select(getToken)
    yield call(saveWebhookUrl, accessToken, payload.url)
    yield put(saveWebhookUrlSuccess(payload.url))
  } catch (err) {
    yield put(saveWebhookUrlFailure())
    yield put(
      addToast({
        title: `Failed to save webhook URL`,
        description: `Please verify the webhook URL. The webhook endpoint must respond with structo parameter`,
        type: ToastType.error
      })
    )
  }
}

export function* getWebhookUrlHandler() {
  try {
    const accessToken = yield select(getToken)
    const res: IWebhookAPIRes = yield call(getWebhookUrl, accessToken)
    yield put(getWebhookUrlSuccess(res.webhook))
  } catch (err) {
    console.log(err)
    yield put(getWebhookUrlFailure())
  }
}

function* watchKeyGenerate() {
  yield takeEvery(API_GENERATE_KEY, keyGenerationHandler)
}

function* watchSaveWebhookURL() {
  yield takeEvery(API_SAVE_WEBHOOK_URL, saveWebhookUrlHandler)
}

function* watchGetAPIKey() {
  yield takeEvery(API_GET_KEY, getAPIKeyHandler)
}

function* watchGetWebhookUrl() {
  yield takeEvery(API_GET_WEBHOOK_URL, getWebhookUrlHandler)
}
export default [
  fork(watchKeyGenerate),
  fork(watchSaveWebhookURL),
  fork(watchGetAPIKey),
  fork(watchGetWebhookUrl)
]
