import React, { useCallback } from 'react'
import { DelayedComponents } from 'backpack'
import { useDispatch, useSelector } from 'react-redux'

import WhatsNewPopup from './WhatsNewPopup'
import ReloadHeader from './ReloadHeader'
import { hideWhatsNew } from '../../redux/app/actions'
import { IAppState } from '../../redux/app/types'

const VersionChecker = () => {
  const dispatch = useDispatch()
  const showReloadOption = useSelector(
    ({ app }: { app: IAppState }) => app.showReloadOption
  )
  const showWhatsNew = useSelector(
    ({ app }: { app: IAppState }) => app.showWhatsNew
  )
  const onHidePopup = useCallback(() => dispatch(hideWhatsNew()), [dispatch])
  return (
    <>
      <DelayedComponents isMounted={showReloadOption}>
        <ReloadHeader />
      </DelayedComponents>
      <DelayedComponents isMounted={showWhatsNew}>
        <WhatsNewPopup onHidePopup={onHidePopup} />
      </DelayedComponents>
    </>
  )
}

export default VersionChecker
