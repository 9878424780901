import mqtt from 'mqtt'
import { select, call, put, fork, take, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

import { IPrinterState, IPrinter } from '../printer/types'
import { connectIOTFailed, connectIOTSuccess } from './actions'
import { IConnectIOTAction, IWriteAction, IPublish, IUIAction } from './types'

let mqttClient
const uiActions: IUIAction[] = []

export function connectIOT(accessToken: string) {
  if (mqttClient) {
    // closing previous connection
    mqttClient.end()
    // resetting the value
    mqttClient = undefined
  }
  return new Promise(resolve => {
    mqttClient = mqtt.connect(process.env.REACT_APP_IOT_URL, {
      username: 'auth0',
      password: accessToken,
      keepalive: 20
    })
    mqttClient.on('connect', () => {
      // console.time('mqtt')
      console.log('Connected to mqtt')
      resolve({})
    })
    mqttClient.on('close', () => {
      console.log('Disconnected')
      // console.timeEnd('mqtt')
    })
  })
}

export function subscribe(printerName: string) {
  // TODO: should handle publish from clinet also
  // const subscribers = ['p', 's']
  const topics = [`${printerName}/p/c/#`, `${printerName}/s/c/#`]
  topics.forEach(topic => {
    mqttClient.subscribe(topic, err => {
      if (!err) {
        console.log(`subscribe to ${topic}`)
      }
    })
  })
}

export function attachIOTListner() {
  // console.log('Subscribing to: ' + topic)
  return eventChannel(emit => {
    try {
      mqttClient.on('message', (topic, data) => {
        const parsedValue = JSON.parse(data.toString('utf8'))
        console.log('Received update from mqtt', topic)
        console.log('Received value', parsedValue)
        emit({
          value: parsedValue,
          topic
        })
        // checkForNotification(parsedValue, topic)
      })
    } catch (e) {
      console.log('Error when subscribing', e)
    }
    return () => {}
  })
}

export function getPrinterName(topic) {
  return topic.split('/')[0]
}

export const getPrinters = ({ printerData }: { printerData: IPrinterState }) =>
  printerData.printers

export function* read() {
  const channel = yield call(attachIOTListner)
  while (true) {
    let { value, topic } = yield take(channel)
    const { type, payload, aid } = value
    const printerName = getPrinterName(topic)
    if (aid) {
      const index = uiActions.findIndex(u => u.aid === aid)
      if (index !== -1) {
        const payloadToSend = payload.success
          ? { ...uiActions[index].onSuccess.payload, ...payload }
          : { ...uiActions[index].onError.payload, ...payload }
        yield put(
          payload.success
            ? { ...uiActions[index].onSuccess, payload: payloadToSend }
            : { ...uiActions[index].onError, payload: payloadToSend }
        )

        clearTimeout(uiActions[index].timerId)
        uiActions.splice(1, index)
      }
    } else {
      yield put({
        type: type === 'NOTIFY/NEW' ? type : `FROMIOT/${type}`,
        payload: { ...payload, printerName }
      })
    }
  }
}

export function* handleIotConnection({ payload }: IConnectIOTAction) {
  const { accessToken } = payload
  try {
    yield call(connectIOT, accessToken)
    // subscribe for all the printers
    const printers: IPrinter[] = yield select(getPrinters)
    if (printers && printers.length) {
      printers.forEach(printer => subscribe(printer.name))
    }
    yield fork(read)
    yield put(connectIOTSuccess())
  } catch (err) {
    yield put(connectIOTFailed('failed to connect to iot'))
  }
}

function uiActionTimeout(time: number, aid: string) {
  let timerId: number, rejectTimer: number
  return new Promise((resolve, reject) => {
    timerId = setTimeout(() => {
      clearTimeout(rejectTimer)
      const index = uiActions.findIndex(u => u.aid === aid)
      uiActions.splice(1, index)
      resolve({
        timerId
      })
    }, time)
    const uiAction = uiActions.find(u => u.aid === aid)
    if (uiAction) {
      uiAction.timerId = timerId
    }
    // this is to reject the promise incase the above timer is cleared before it is called
    rejectTimer = setTimeout(() => {
      reject({
        error: 'interval is cleared'
      })
    }, time + 1000)
  })
}

function* write({
  type,
  payload,
  aid,
  onSuccess,
  onError,
  onTimeout
}: IWriteAction) {
  try {
    const { printerName } = payload
    const topic = printerName + '/c/p'
    console.log(printerName)
    const toPublish: IPublish = {
      v: parseInt(process.env.REACT_APP_MESSAGE_FORMAT_VERSION || '1'),
      type: type.replace('TOSEND/', ''),
      payload
    }
    if (aid) {
      toPublish.aid = aid
    }
    mqttClient.publish(topic, JSON.stringify(toPublish))
    if (aid) {
      uiActions.push({
        aid,
        onSuccess,
        onError
      })
      const timeout = process.env.REACT_APP_UI_ACTION_TIMEOUT
        ? parseInt(process.env.REACT_APP_UI_ACTION_TIMEOUT)
        : 30 // value in seconds
      yield call(uiActionTimeout, timeout * 1000, aid)
      yield put(onTimeout)
    }
  } catch (err) {
    console.log('Failed to write')
  }
}

function* watchIotConnection() {
  yield takeEvery('IOT_CONNECT_PENDING', handleIotConnection)
}

function* watchSendActions() {
  yield takeEvery(
    [
      'TOSEND/JOB_DELETE',
      'TOSEND/PRINTER/JOB_DELETE',
      'TOSEND/PRINTER/REMOTE_PRINT_START'
    ],
    write
  )
}

export default [fork(watchIotConnection), fork(watchSendActions)]
