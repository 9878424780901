export const APP_SHOW_WHATS_NEW = 'APP_SHOW_WHATS_NEW'
export const APP_HIDE_WHATS_NEW = 'APP_HIDE_WHATS_NEW'
export const APP_SHOW_RELOAD_OPTION = 'APP_SHOW_RELOAD_OPTION'
export const APP_HIDE_RELOAD_OPTION = 'APP_HIDE_RELOAD_OPTION'
export const APP_GET_LATEST_VERSION = 'APP_GET_LATEST_VERSION'
export const APP_INITIALIZE = 'APP_INITIALIZE'
export const APP_GET_SETTINGS_SUCCESS = 'APP_GET_SETTINGS_SUCCESS'
export const APP_SHOW_INSTALL = 'APP_SHOW_INSTALL'
export const APP_HIDE_INSTALL = 'APP_HIDE_INSTALL'
export const APP_INSTALL = 'APP_INSTALL'
