import { Step } from 'react-joyride'
import {
  TOUR_START_TOUR,
  TOUR_END_TOUR,
  TOUR_NEXT_STEP,
  TOUR_PREVIOUS_STEP,
  TOUR_SET_STEP_INDEX,
  TOUR_SET_JOYRIDE_CALLBACK,
  TOUR_PAUSE_TOUR,
  TOUR_RESUME_TOUR,
  TOUR_SET_JOYRIDE_PROPS
} from './constants'
import { IJoyrideCallback, IJoyrideProps } from './types'

export interface StartTourProps {
  tourSteps: Step[]
  joyrideCallback?: IJoyrideCallback
  joyrideProps?: IJoyrideProps
}

export interface EndTourProps {
  reset?: boolean
}

export function startTour({
  tourSteps,
  joyrideCallback,
  joyrideProps
}: StartTourProps) {
  return {
    type: TOUR_START_TOUR,
    payload: {
      tourSteps,
      joyrideCallback,
      joyrideProps
    }
  }
}

export function endTour(reset = true as boolean) {
  return {
    type: TOUR_END_TOUR,
    payload: {
      reset
    }
  }
}

export function pauseTour() {
  return {
    type: TOUR_PAUSE_TOUR
  }
}

export function resumeTour() {
  return {
    type: TOUR_RESUME_TOUR
  }
}

export function nextTourStep() {
  return {
    type: TOUR_NEXT_STEP
  }
}

export function previousTourStep() {
  return {
    type: TOUR_PREVIOUS_STEP
  }
}

export function setTourStepIndex(index: number) {
  return {
    type: TOUR_SET_STEP_INDEX,
    payload: {
      index
    }
  }
}

export function setTourJoyrideCallback(joyrideCallback: IJoyrideCallback) {
  return {
    type: TOUR_SET_JOYRIDE_CALLBACK,
    payload: {
      joyrideCallback: joyrideCallback
    }
  }
}

export function setTourJoyrideProps({
  joyrideProps
}: {
  joyrideProps?: IJoyrideProps
}) {
  return {
    type: TOUR_SET_JOYRIDE_PROPS,
    payload: {
      joyrideProps: joyrideProps
    }
  }
}
