import React from 'react'
import styled from 'styled-components'

const StyledPageMask = styled.div`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgb(0 0 0 / 0.7);
  transition: all 0.3s ease;
  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
  &.visible ~ * {
    filter: blur(2px);
  }
  @media (min-width: 601px) {
    &.visible {
      opacity: 0;
      pointer-events: none;
    }
    &.visible ~ * {
      filter: unset;
    }
  }
`

export function PageMask({
  visible,
  onHideSidebar
}: {
  visible: boolean
  onHideSidebar: () => void
}) {
  return (
    <StyledPageMask
      className={visible ? 'visible' : ''}
      onClick={onHideSidebar}
    />
  )
}
