export const API_GENERATE_KEY = 'API_GENERATE_KEY'
export const API_GENERATE_KEY_SUCCESS = 'API_GENERATE_KEY_SUCCESS'
export const API_GENERATE_KEY_FAILED = 'API_GENERATE_KEY_FAILED'
export const API_GET_KEY = 'API_GET_KEY'
export const API_GET_KEY_SUCCESS = 'API_GET_KEY_SUCCESS'
export const API_GET_KEY_FAILURE = 'API_GET_KEY_FAILURE'
export const API_SAVE_WEBHOOK_URL = 'API_SAVE_WEBHOOK_URL'
export const API_SAVE_WEBHOOK_URL_SUCCESS = 'API_SAVE_WEBHOOK_URL_SUCCESS'
export const API_SAVE_WEBHOOK_URL_FAILURE = 'API_SAVE_WEBHOOK_URL_FAILURE'
export const API_GET_WEBHOOK_URL = 'API_GET_WEBHOOK_URL'
export const API_GET_WEBHOOK_URL_SUCCESS = 'API_GET_WEBHOOK_URL_SUCCESS'
export const API_GET_WEBHOOK_URL_FAILURE = 'API_GET_WEBHOOK_URL_FAILURE'
