import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGetTeflonPayload, IGetTeflonRes, ITeflonState } from './types'

export const initialState: ITeflonState = {
  isGettingTeflon: false,
  teflons: [],
  totalCount: 0
}

export const teflonSlice = createSlice({
  name: 'teflon',
  initialState,
  reducers: {
    getTeflons: (state, action: PayloadAction<IGetTeflonPayload>) => {
      state.isGettingTeflon = true
    },
    getTeflonsFailure: state => {
      state.isGettingTeflon = false
    },
    getTeflonsSuccess: (state, action: PayloadAction<IGetTeflonRes>) => {
      const { results, totalCount } = action.payload
      state.isGettingTeflon = false
      state.teflons = results
      state.totalCount = totalCount
    }
  }
})

export const {
  getTeflons,
  getTeflonsFailure,
  getTeflonsSuccess
} = teflonSlice.actions

export default teflonSlice.reducer
