import React from 'react'
import { Step } from 'react-joyride'

import { CircleStatusWithText } from './components/SVGImages'

export enum TourConfigTypes {
  overview = 'overview',
  mobileOverview = 'mobileOverview',
  addJob = 'addJob',
  jobs = 'jobs',
  printers = 'printers',
  printerDetails = 'printerDetails',
  printerInfo = 'printerInfo',
  userManagement = 'userManagement',
  apiManagement = 'apiManagement',
  timeline = 'timeline',
  consumables = 'consumables'
}

export const getOverviewTourConfig = (mobile: boolean) => {
  const overviewTourConfig = [
    {
      target: '[data-tut="tour__overview_1"]',
      content: `View all active print jobs.`,
      disableBeacon: true
    },
    {
      target: '[data-tut="tour__overview_2"]',
      content: `View key printing analytics for the past 30 days here.`
    },
    {
      target: '[data-tut="tour__overview_3"]',
      content: `Find out the number of completed jobs and the total resin consumption, by duration. You can filter by daily, weekly, or monthly.`
    }
  ]
  if (mobile) {
    overviewTourConfig.push(
      ...[
        {
          target: '[data-tut="mobile-Support"]',
          content: `Your one-click access to Structo support.`
        },
        {
          target: '[data-tut="mobile-Store"]',
          content: `Visit our webstore and website here.`
        }
      ]
    )
  } else {
    overviewTourConfig.push(
      ...[
        {
          target: '[title="Support"]',
          content: `Your one-click access to Structo support.`
        },
        {
          target: '[title="Store"]',
          content: `Visit our webstore and website here.`
        }
      ]
    )
  }
  overviewTourConfig.push(
    ...[
      {
        target: '[title="Add Job"]',
        content: `Click here to start a new print job from anywhere in MyStructo.`,
        disableBeacon: true
      },
      {
        target: '[title="jobs"]',
        content: `View all print data history including build time, number of layers and amount of resin used. Filter by active, queued and completed.`
      },
      {
        target: '[title="printers"]',
        content: `
    You can view, edit and add all Structo printers here.
    `
      },
      {
        target: '[title="settings"]',
        content: `
    You can configure settings, manage user accounts or access product documentation here.
    `
      }
    ]
  )

  return overviewTourConfig as Step[]
}

export const jobsTourConfig = [
  {
    target: '[data-tut="active-tab"]',
    content: `Click here to open the Active Job tab.`,
    disableBeacon: true
  },
  {
    target: '[data-tut="active-tab-list"]',
    content: `All active print jobs will be displayed here.`,
    title: 'Active Jobs'
  },
  {
    target: '[data-tut="active-tab-search"]',
    content: `Search for jobs by file name.`,
    title: 'Active Jobs'
  },
  {
    target: '[data-tut="queued-tab"]',
    content: `Click here to open the Queued Job tab.`,
    event: 'open-queued-tab'
  },
  {
    target: '[data-tut="queued-tab-list"]',
    content: `All queued print jobs will be displayed here.`,
    title: 'Queued Jobs'
  },
  {
    target: '[data-tut="queued-tab-search"]',
    content: `Search for jobs by file name.`,
    title: 'Queued Jobs'
  },
  {
    target: '[data-tut="queued-tab-filters"]',
    content: `You can filter list by printers, job status or printer models.`,
    title: 'Queued Jobs - Filters'
  },
  {
    target: '[data-tut="completed-tab"]',
    content: `Click here to open up the completed jobs tab.`,
    title: 'Completed Jobs'
  },
  {
    target: '[data-tut="completed-tab-list"]',
    content: `View records of all past print jobs.`,
    title: 'Completed Jobs'
  },
  {
    target: '[data-tut="completed-tab-list-item"]',
    content: `Click each row to expand to see more information.`,
    title: 'Completed Jobs'
  },
  {
    target: '[data-tut="completed-tab-list-item-action"]',
    content: `Click here for more actions. You can edit or add feedback for a past print job.`,
    title: 'Completed Jobs'
  },
  {
    target: '[data-tut="completed-tab-filters"]',
    content: `You can filter the list here by date range, printers, job status, or printer model.`,
    title: 'Completed Jobs'
  },
  {
    target: '[data-tut="completed-tab-export"]',
    content: `Click here to export all past print jobs.`,
    title: 'Completed Jobs'
  }
]

export const printerDetailsTourConfig = [
  {
    target: '[data-tut="printer-details"]',
    content: `View additional information about the printer.`,
    disableBeacon: true
  },
  {
    target: '[data-tut="printer-details-change-name"]',
    content: `Click the edit icon to change the printer name.`
  },
  {
    target: '[data-tut="printer-details-active-job"]',
    content: `View information about the active print job.`
  },
  {
    target: '[data-tut="queued-tab"]',
    content: `All other queued jobs will be displayed here.`,
    disableBeacon: true
  },
  {
    target: '[data-tut="completed-tab"]',
    content: `You can view all past completed print jobs on this printer here.`,
    disableBeacon: true
  },
  {
    target: '[data-tut="tour__printer-details--timeline"]',
    content: `View the printer activity timeline.`
  },
  {
    target: '[data-tut="tour__printer-details--graph"]',
    content: `Find out the number of completed jobs and the total resin consumption for this printer. You can filter by daily, weekly, or monthly.`
  }
]

export const printersTourConfig = [
  {
    target: '[data-tut="printers-overview"]',
    content: `View and manage your fleet of printers here.`,
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: '[data-tut="printers-search"]',
    content: `Search for printers by name here.`,
    placement: 'bottom-start'
  },
  {
    target: '[data-tut="printers-printer"]',
    content: `View general information about a printer at a glance.`
  },
  {
    target: '[data-tut="printers-printer-name"]',
    content: `Printer name`
  },
  {
    target: '[data-tut="printers-printer-file-name"]',
    content: `File name`
  },
  {
    target: '[data-tut="printers-printer-type"]',
    content: `Printer model`
  },
  {
    target: '[data-tut="printers-printer-status"]',
    content: `Displays the printer status, the print job progress, and the time remaining.`
  },
  {
    target: '[data-tut="printers-printer-status-indicator"]',
    content: (
      <div
        style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
      >
        <div>Displays the status of the printer.</div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            flexDirection: 'column',
            fontWeight: 'bold'
          }}
        >
          <CircleStatusWithText text={'Printing in progress'} />
          <CircleStatusWithText
            circleClassName="idle"
            text={'Available for printing'}
          />
          <CircleStatusWithText circleClassName="offline" text={'Offline'} />
        </div>
      </div>
    )
  },
  {
    target: '[data-tut="printers-printer"]',
    content: `Click the block to view more information.`,
    disableBeacon: true
  },
  ...printerDetailsTourConfig
]

export const UserManagementTourConfig = [
  {
    target: '[data-tut="users-tab-list"]',
    content: `You can see all the users in your team here.`,
    title: 'Manage Team',
    disableBeacon: true
  },
  {
    target: '[data-tut="users-tab-list-item"]',
    content: `View your team member information here.`,
    title: 'Manage Team'
  },
  {
    target: '[data-tut="users-tab-list-item-status"]',
    content: `The status indicates if the team member account is active.`,
    title: 'Manage Team'
  },
  {
    target: '[data-tut="users-tab-list-item-status-change"]',
    content: `Click here for more actions. You can enable or disable a team member.`,
    title: 'Manage Team'
  },
  {
    target: '[data-tut="users-tab-add-user"]',
    content: `You can add new user in your team here.`,
    title: 'Add User'
  },
  {
    target: '[data-tut="users-tab-add-user-form"]',
    content: `To add a new team member, fill in the new user's details here and click "Add new account".`,
    event: 'open-queued-tab'
  }
]

export const TimelineTourConfig = [
  {
    target: '[data-tut="timeline-table"]',
    content: `You can view the timelines of the printers and track their activities here.`,
    disableBeacon: true
  },
  {
    target: '[data-tut="timeline-printers-field"]',
    content: `You can change which printers appear in the timeline table by clicking and selecting the printers you want from the list.`
  },
  {
    target: '[data-tut="timeline-date-field"]',
    content: `You can change the date to view a timeline for a different date.`
  }
]

export const ConsumablesTourConfig = [
  {
    target: '[data-tut="consumables-table"]',
    content: `You can view information about all of the registered TFAs in this table. On a mobile device, scroll left to right to see more columns.`,
    disableBeacon: true
  },
  {
    target: '[data-tut="consumables-serial-key"]',
    content: `You can view the serial number of the consumable item that was used for validation in this column.`,
    disableBeacon: true
  },
  {
    target: '[data-tut="consumables-model"]',
    content: `You can view the model of the consumable in this column.`
  },
  {
    target: '[data-tut="consumables-first-used"]',
    content: `You can view the first time the consumable was used in the print cycle in this column.`
  },
  {
    target: '[data-tut="consumables-last-used"]',
    content: `You can view the most recent time the consumable was used in this column.`
  },
  {
    target: '[data-tut="consumables-total-prints"]',
    content: `You can view the total number of printer cycles the consumable has gone through in this column.`
  },
  {
    target: '[data-tut="consumables-printers"]',
    content: `You can view the consumable's most recent printer registration location in this column.`
  }
]

export const tourConfigs = {
  [TourConfigTypes.overview]: getOverviewTourConfig(false),
  [TourConfigTypes.mobileOverview]: getOverviewTourConfig(true),
  [TourConfigTypes.jobs]: jobsTourConfig,
  [TourConfigTypes.printers]: printersTourConfig,
  [TourConfigTypes.printerDetails]: printerDetailsTourConfig,
  [TourConfigTypes.userManagement]: UserManagementTourConfig,
  [TourConfigTypes.timeline]: TimelineTourConfig,
  [TourConfigTypes.consumables]: ConsumablesTourConfig
} as {
  [key: string]: Step[]
}
