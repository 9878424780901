import createReducer from '../../utils/create-reducer'
import { updatePrintStats, updateResinStats } from '../stats/reducer'
import { IGraphStatsRes } from '../stats/types'
import { ITeflon } from '../teflons/types'
import {
  PRINTERS_GET_LIST_PENDING,
  PRINTERS_GET_LIST_SUCCESS,
  PRINTERS_GET_LIST_FAILED,
  PRINTER_UPLOAD_JOB_PENDING,
  PRINTER_UPLOAD_JOB_SUCCESS,
  PRINTER_UPLOAD_JOB_FAILED,
  PRINTER_DELETE_JOB_PENDING,
  PRINTER_DELETE_JOB_SUCCESS,
  PRINTER_DELETE_JOB_FAILED,
  PRINTER_CHANGE_PRINTER_NAME_PENDING,
  PRINTER_CHANGE_PRINTER_NAME_SUCCESS,
  PRINTER_CHANGE_PRINTER_NAME_FAILED,
  PRINTER_GET_PENDING_FILES_SUCCESS,
  PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS,
  GET_JOBS_FROM_IOT_SUCCESS,
  PRINTER_GRAPH_STATS_PENDING,
  PRINTER_GRAPH_STATS_SUCCESS,
  PRINTER_GRAPH_STATS_FAILED,
  PRINTER_GET_LATEST_TEFLON_SUCCESS,
  PRINTER_MULTIPLE_UPLOAD_JOB_PENDING,
  PRINTER_MULTIPLE_UPLOAD_JOB_SUCCESS,
  PRINTER_MULTIPLE_UPLOAD_JOB_FAILURE,
  PRINTER_GET_LATEST_LCD_SUCCESS,
  PRINTER_CREATE_GROUP,
  PRINTER_CREATE_GROUP_SUCCESS,
  PRINTER_CREATE_GROUP_FAILURE,
  PRINTER_UPDATE_GROUP,
  PRINTER_UPDATE_GROUP_SUCCESS,
  PRINTER_UPDATE_GROUP_FAILURE,
  PRINTER_GET_GROUPS_SUCCESS,
  PRINTER_DELETE_GROUP,
  PRINTER_DELETE_GROUP_FAILURE,
  PRINTER_DELETE_GROUP_SUCCESS,
  PRINTER_START_REMOTE_PRINT,
  PRINTER_START_REMOTE_PRINT_SUCCESS,
  PRINTER_START_REMOTE_PRINT_FAILURE
} from './constant'
import {
  IPrinterState,
  IPrinter,
  IJob,
  IStatusAt,
  ISendActionPayload,
  IQueuedJobsFilter,
  IPendingFilesRes,
  ILCD
} from './types'

export const printerTypes = Object.freeze({
  dentaform: 'dentaform',
  velox: 'velox',
  mixed: 'mixed'
})

export const initialStates: IPrinterState = {
  errorMessage: undefined,
  printers: [],
  fetchingPrinters: false,
  isUploadingFile: false,
  uploadingFileName: undefined,
  uploadingToPrinter: undefined,
  queuedJobs: [],
  printersType: printerTypes.velox,
  isChangingName: false,
  fetchingPrinterPrintGraphStats: false,
  fetchingPrinterResinGraphStats: false,
  uploadingFiles: [],
  groups: [],
  isCreatingGroup: false,
  isUpdatingGroup: false,
  isDeletingGroup: false,
  isStartingRemotePrint: false
}

function calculateOverAllProgress(
  activeJob: IJob,
  statusChangesToPrintingAt: IStatusAt | undefined
) {
  const {
    estimatedPrintTime,
    estimatedWashTime,
    estimatedCureTime,
    estimatedRinseTime
  } = activeJob

  const timeNowInSeconds = Date.now() / 1000
  const timeElapsed = statusChangesToPrintingAt
    ? timeNowInSeconds - statusChangesToPrintingAt.seconds
    : timeNowInSeconds
  const totalTime =
    (estimatedPrintTime || 0) +
    (estimatedWashTime || 0) +
    (estimatedCureTime || 0) +
    (estimatedRinseTime || 0)
  const progress = (timeElapsed / totalTime) * 100
  return Number((progress > 100 ? 100 : progress).toFixed(2))
}

export function populatePrinter(jobs: IJob[], printer: IPrinter) {
  const { name, isVelox, customPrinterName, isOnline } = printer
  return jobs.map(j => {
    return {
      ...j,
      printer: {
        name: name,
        customPrinterName: customPrinterName,
        isVelox,
        isOnline
      }
    }
  })
}

export function getjobStatus(status: string, isActive: boolean) {
  const st = status.toLowerCase()
  if (isActive) {
    return status
  }
  if (st === 'ready' || st === 'queue-ready') {
    return 'ready'
  } else if (st === 'queue-failed') {
    return 'failed'
  } else {
    return 'processing'
  }
}

export function getPendingJobs(
  printer: IPrinter,
  fileName: string,
  status = 'processing'
) {
  let jobs: IJob[] = []
  const { pendingFiles, name, customPrinterName, isVelox, isOnline } = printer
  if (pendingFiles && pendingFiles.length && status === 'processing') {
    let filteredFiles = fileName
      ? pendingFiles.filter(j => j === fileName)
      : pendingFiles
    if (filteredFiles && filteredFiles.length) {
      jobs = filteredFiles.map((pf, index) => {
        return {
          id: `pending-file-${index}`,
          file: {
            name: pf
          },
          status: 'processing',
          printer: {
            name,
            customPrinterName,
            isVelox,
            isOnline
          }
        } as IJob
      })
    }
  }
  return jobs
}

export default createReducer(initialStates, {
  [PRINTERS_GET_LIST_PENDING]: (state: IPrinterState) => ({
    ...state,
    fetchingPrinters: true
  }),

  [PRINTERS_GET_LIST_SUCCESS]: (
    state: IPrinterState,
    { printers, printersType }: { printers: IPrinter[]; printersType: string }
  ) => ({
    ...state,
    fetchingPrinters: false,
    printers: [...printers],
    printersType
  }),

  [PRINTERS_GET_LIST_FAILED]: (
    state: IPrinterState,
    { message }: { message: string }
  ) => ({
    ...state,
    fetchingPrinters: false,
    errorMessage: message
  }),

  'FROMIOT/PRINTER_ALIVE': (
    state: IPrinterState,
    {
      printerName,
      isOnline,
      updatedAt
    }: { printerName: string; isOnline: boolean; updatedAt: number }
  ) => {
    const newPrinters = state.printers.map(p => {
      if (p.name === printerName) {
        return {
          ...p,
          isOnline,
          updatedAt
        }
      }
      return { ...p }
    })
    const onlinePrinters = newPrinters.filter(p => p.isOnline)
    const offlinePrinters = newPrinters.filter(p => !p.isOnline)
    const updatedState = {
      ...state,
      printers: [...onlinePrinters, ...offlinePrinters]
    }
    return updatedState
  },

  [GET_JOBS_FROM_IOT_SUCCESS]: (
    state: IPrinterState,
    { printerName, jobs }: { printerName: string; jobs: IJob[] }
  ) => {
    return {
      ...state,
      printers: state.printers.map(p => {
        if (p.name === printerName) {
          const activeJob = jobs.find(j => j.isActive)
          if (p.isVelox) {
            if (activeJob) {
              activeJob.progress = calculateOverAllProgress(
                activeJob,
                p.statusChangesToPrintingAt || activeJob.startedAt
              )
            }
          }
          const updatedJobs = jobs.map(j => {
            let buildTime: number = 0
            if (p.isVelox) {
              buildTime =
                (j.estimatedPrintTime || 0) +
                (j.estimatedRinseTime || 0) +
                (j.estimatedWashTime || 0) +
                (j.estimatedCureTime || 0)
            }
            return {
              ...j,
              file: {
                ...j.file,
                name: decodeURIComponent(j.file.name)
              },
              buildTime: j.buildTime || buildTime * 1000,
              progress: j.progress ? Math.round(j.progress) : undefined,
              status: getjobStatus(j.status, j.isActive)
            }
          })
          return {
            ...p,
            jobs: [...updatedJobs]
          }
        }
        return { ...p }
      })
    }
  },

  'FROMIOT/PRINTER/STATUS': (
    state: IPrinterState,
    {
      printerName,
      status,
      statusLastUpdatedAt
    }: {
      printerName: string
      status: string
      statusLastUpdatedAt: {
        seconds: number
      }
    }
  ) => {
    return {
      ...state,
      printers: state.printers.map(p => {
        if (p.name === printerName) {
          return {
            ...p,
            status,
            statusLastUpdatedAt,
            previousStatus: p.status,
            statusChangesToPrintingAt:
              status === 'printing' ? statusLastUpdatedAt : undefined
          }
        }
        return { ...p }
      })
    }
  },

  'PRINTER/CUSTOM_NAME': (
    state: IPrinterState,
    {
      printerName,
      customPrinterName
    }: { printerName: string; customPrinterName: string }
  ) => {
    return {
      ...state,
      printers: state.printers.map(p => {
        if (p.name === printerName) {
          return {
            ...p,
            customPrinterName
          }
        }
        return { ...p }
      })
    }
  },

  [PRINTER_UPLOAD_JOB_PENDING]: (
    state: IPrinterState,
    { file, printerName }: { file: File; printerName: string }
  ) => ({
    ...state,
    uploadingFiles: [],
    isUploadingFile: true,
    uploadingFileName: file.name,
    uploadingToPrinter: printerName
  }),

  [PRINTER_UPLOAD_JOB_SUCCESS]: (state: IPrinterState) => ({
    ...state,
    isUploadingFile: false
  }),

  [PRINTER_UPLOAD_JOB_FAILED]: (
    state: IPrinterState,
    { message }: { message: string }
  ) => ({
    ...state,
    isUploadingFile: false,
    errorMessage: message
  }),

  [PRINTER_DELETE_JOB_PENDING]: (
    state: IPrinterState,
    { jobId, printerName }: ISendActionPayload
  ) => ({
    ...state,
    printers: state.printers.map(printer =>
      printer.name !== printerName
        ? printer
        : {
            ...printer,
            jobs: printer.jobs
              ? printer.jobs.map(j => {
                  if (j.id === jobId) {
                    return {
                      ...j,
                      isDeleting: true
                    }
                  }
                  return j
                })
              : []
          }
    )
  }),

  [PRINTER_DELETE_JOB_SUCCESS]: (
    state: IPrinterState,
    { jobId, printerName }: ISendActionPayload
  ) => ({
    ...state,
    printers: state.printers.map(printer =>
      printer.name !== printerName
        ? printer
        : {
            ...printer,
            jobs: printer.jobs
              ? printer.jobs.reduce((acc: IJob[], curr) => {
                  if (curr.id !== jobId) acc.push(curr)
                  return acc
                }, [])
              : []
          }
    )
  }),

  [PRINTER_DELETE_JOB_FAILED]: (
    state: IPrinterState,
    { jobId, printerName }: ISendActionPayload
  ) => ({
    ...state,
    printers: state.printers.map(printer =>
      printer.name !== printerName
        ? printer
        : {
            ...printer,
            jobs: printer.jobs
              ? printer.jobs.map(j => {
                  if (j.id === jobId) {
                    return {
                      ...j,
                      isDeleting: false
                    }
                  }
                  return j
                })
              : []
          }
    )
  }),

  [PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS]: (
    state: IPrinterState,
    { filter }: { filter?: IQueuedJobsFilter }
  ) => {
    const { printersType, printers = [], fileName, status } =
      filter || ({} as IQueuedJobsFilter)
    const printerList = state.printers

    let jobs: IJob[] = []
    let printersToLook = printerList
    if (printersType && printersType === printerTypes.dentaform) {
      printersToLook = printerList.filter(p => !p.isVelox)
    } else if (printersType && printersType === printerTypes.velox) {
      printersToLook = printerList.filter(p => p.isVelox)
    }

    if (printers && printers.length) {
      printersToLook = printersToLook.filter(p => printers.indexOf(p.name) > -1)
    }

    // if fileName is in filter
    if (fileName && status) {
      printersToLook.forEach(p => {
        const pendingJobs = getPendingJobs(p, fileName, status)
        jobs = [...pendingJobs, ...jobs]
        if (p.jobs) {
          let filteredJobs: IJob[] = p.jobs.filter(
            j => j.file.name === fileName
          )
          filteredJobs = filteredJobs.filter(
            j => (j.status && j.status.toLowerCase()) === status.toLowerCase()
          )
          filteredJobs = populatePrinter(filteredJobs, p)
          jobs = jobs.concat(filteredJobs)
        }
      })
    } else {
      if (fileName) {
        printersToLook.forEach(p => {
          const pendingJobs = getPendingJobs(p, fileName)
          jobs = [...pendingJobs, ...jobs]
          if (p.jobs) {
            let filteredJobs: IJob[] = p.jobs.filter(
              j => j.file.name === fileName
            )
            filteredJobs = populatePrinter(filteredJobs, p)
            jobs = jobs.concat(filteredJobs)
          }
        })
      } else if (status) {
        printersToLook.forEach(p => {
          const pendingJobs = getPendingJobs(p, '', status)
          jobs = [...pendingJobs, ...jobs]
          if (p.jobs) {
            let filteredJobs = p.jobs.filter(
              j => (j.status && j.status.toLowerCase()) === status.toLowerCase()
            )
            filteredJobs = populatePrinter(filteredJobs, p)
            jobs = jobs.concat(filteredJobs)
          }
        })
      } else {
        printersToLook.forEach(p => {
          const pendingJobs = getPendingJobs(p, '')
          jobs = [...pendingJobs, ...jobs]
          if (p.jobs) {
            const filteredJobs = populatePrinter(p.jobs, p)
            jobs = jobs.concat(filteredJobs)
          }
        })
      }
    }
    return {
      ...state,
      queuedJobs: jobs.filter(j => !j.isActive && j.status !== 'CANCELLED')
    }
  },

  [PRINTER_CHANGE_PRINTER_NAME_PENDING]: (
    state: IPrinterState,
    { printerName }: { printerName: string }
  ) => ({
    ...state,
    isChangingName: true,
    printers: state.printers.map(printer =>
      printer.name !== printerName
        ? printer
        : {
            ...printer,
            isChangingName: true
          }
    )
  }),

  [PRINTER_CHANGE_PRINTER_NAME_SUCCESS]: (
    state: IPrinterState,
    { printerName, customName }: { printerName: string; customName: string }
  ) => ({
    ...state,
    isChangingName: false,
    printers: state.printers.map(printer =>
      printer.name !== printerName
        ? printer
        : {
            ...printer,
            isChangingName: false,
            customPrinterName: customName
          }
    )
  }),

  [PRINTER_CHANGE_PRINTER_NAME_FAILED]: (
    state: IPrinterState,
    { printerName }: { printerName: string }
  ) => ({
    ...state,
    isChangingName: false,
    printers: state.printers.map(printer =>
      printer.name !== printerName
        ? printer
        : {
            ...printer,
            isChangingName: false
          }
    )
  }),

  [PRINTER_GET_PENDING_FILES_SUCCESS]: (
    state: IPrinterState,
    { filesData }: { filesData: IPendingFilesRes[] }
  ) => ({
    ...state,
    printers: state.printers.map(printer => {
      const fileData = filesData.find(f => f.printer === printer.name)
      if (fileData) {
        const files = fileData.pendingFiles.map(pf => decodeURIComponent(pf))
        return {
          ...printer,
          pendingFiles: files
        }
      }
      return printer
    })
  }),

  [PRINTER_GRAPH_STATS_PENDING]: (
    state: IPrinterState,
    { type }: { type: string }
  ) => ({
    ...state,
    fetchingPrinterPrintGraphStats: type ? type === 'print' : true,
    fetchingPrinterResinGraphStats: type ? type === 'resin' : true
  }),

  [PRINTER_GRAPH_STATS_SUCCESS]: (
    state: IPrinterState,
    {
      printerName,
      stats,
      duration,
      type
    }: {
      printerName: string
      stats: IGraphStatsRes[]
      duration: string
      type?: string
    }
  ) => ({
    ...state,
    fetchingPrinterPrintGraphStats: false,
    fetchingPrinterResinGraphStats: false,
    printers: state.printers.map(printer => {
      if (printer.name === printerName) {
        let updatedPrintStats = printer.graphStats
          ? { ...printer.graphStats.print }
          : undefined
        let updatedResinStats = printer.graphStats
          ? { ...printer.graphStats.resin }
          : undefined
        if (type && type === 'print') {
          updatedPrintStats = updatePrintStats(duration, stats)
        } else if (type && type === 'resin') {
          updatedResinStats = updateResinStats(duration, stats)
        } else {
          updatedPrintStats = updatePrintStats(duration, stats)
          updatedResinStats = updateResinStats(duration, stats)
        }
        return {
          ...printer,
          graphStats: {
            ...printer.graphStats,
            resin: updatedResinStats,
            print: updatedPrintStats
          }
        }
      }
      return printer
    })
  }),

  [PRINTER_GRAPH_STATS_FAILED]: (state: IPrinterState) => ({
    ...state,
    fetchingPrinterPrintGraphStats: false,
    fetchingPrinterResinGraphStats: false
  }),

  [PRINTER_GET_LATEST_TEFLON_SUCCESS]: (
    state: IPrinterState,
    { printerName, teflon }: { printerName: string; teflon: ITeflon }
  ) => ({
    ...state,
    printers: state.printers.map(p => {
      if (p.name === printerName && Object.keys(teflon).length > 1) {
        return {
          ...p,
          latestUsedTeflon: teflon
        }
      }
      return { ...p }
    })
  }),

  [PRINTER_MULTIPLE_UPLOAD_JOB_PENDING]: (
    state: IPrinterState,
    { files, printerName }: { files: File[]; printerName: string }
  ) => ({
    ...state,
    uploadingToPrinter: printerName,
    uploadingFiles: files.map(f => ({
      name: f.name,
      uploadInProgress: true,
      uploadSuccess: undefined
    }))
  }),

  [PRINTER_MULTIPLE_UPLOAD_JOB_SUCCESS]: (
    state: IPrinterState,
    { fileName }: { fileName: string }
  ) => ({
    ...state,
    uploadingFiles: state.uploadingFiles.map(f => {
      if (f.name === fileName) {
        return {
          ...f,
          uploadInProgress: false,
          uploadSuccess: true
        }
      }
      return { ...f }
    })
  }),

  [PRINTER_MULTIPLE_UPLOAD_JOB_FAILURE]: (
    state: IPrinterState,
    { fileName }: { fileName: string }
  ) => ({
    ...state,
    uploadingFiles: state.uploadingFiles.map(f => {
      if (f.name === fileName) {
        return { ...f, uploadInProgress: false, uploadSuccess: false }
      }
      return { ...f }
    })
  }),

  [PRINTER_GET_LATEST_LCD_SUCCESS]: (
    state: IPrinterState,
    { printerName, lcd }: { printerName: string; lcd: ILCD }
  ) => ({
    ...state,
    printers: state.printers.map(p => {
      if (p.name === printerName && Object.keys(lcd).length > 1) {
        return {
          ...p,
          latestUsedLCD: lcd
        }
      }
      return { ...p }
    })
  }),

  [PRINTER_CREATE_GROUP]: (state: IPrinterState) => ({
    ...state,
    isCreatingGroup: true
  }),

  [PRINTER_CREATE_GROUP_SUCCESS]: (
    state: IPrinterState,
    { name, printers }: { name: string; printers: string[] }
  ) => ({
    ...state,
    isCreatingGroup: false,
    printers: state.printers.map(p => {
      const found = printers.some(pName => pName === p.name)
      if (found) {
        return {
          ...p,
          groups: p.groups ? p.groups.concat(name) : [name]
        }
      }
      return { ...p }
    }),
    groups: [...state.groups, name]
  }),

  [PRINTER_CREATE_GROUP_FAILURE]: (state: IPrinterState) => ({
    ...state,
    isCreatingGroup: false
  }),

  [PRINTER_UPDATE_GROUP]: (state: IPrinterState) => ({
    ...state,
    isUpdatingGroup: true
  }),

  [PRINTER_UPDATE_GROUP_SUCCESS]: (
    state: IPrinterState,
    {
      name,
      printers,
      updatedName
    }: { name: string; printers?: string[]; updatedName?: string }
  ) => {
    const groups = [...state.groups]
    if (updatedName) {
      const index = groups.indexOf(name)
      groups[index] = updatedName
    }
    return {
      ...state,
      isUpdatingGroup: false,
      printers: state.printers.map(p => {
        if (printers && printers.length) {
          // if printer list is there
          const found = printers && printers.some(pName => pName === p.name)
          const gName = updatedName || name
          if (found) {
            const printerGroups = p.groups || []
            const index = printerGroups.indexOf(name)
            if (index !== -1) {
              printerGroups[index] = gName
            } else {
              printerGroups.push(gName)
            }
            return {
              ...p,
              groups: [...printerGroups]
            }
          } else {
            const printerGroups = p.groups || []
            const index = printerGroups.indexOf(name)
            if (index !== -1) {
              printerGroups.splice(index, 1)
            }
            return {
              ...p,
              groups: [...printerGroups]
            }
          }
        } else {
          // no printer list
          const printerGroups = p.groups || []
          const gName = updatedName || name
          const index = printerGroups.indexOf(name)
          if (index !== -1) {
            printerGroups[index] = gName
          }
          return { ...p, groups: [...printerGroups] }
        }
      }),
      groups
    }
  },

  [PRINTER_UPDATE_GROUP_FAILURE]: (state: IPrinterState) => ({
    ...state,
    isUpdatingGroup: false
  }),

  [PRINTER_GET_GROUPS_SUCCESS]: (
    state: IPrinterState,
    { groups }: { groups: string[] }
  ) => ({
    ...state,
    groups
  }),

  [PRINTER_DELETE_GROUP]: (state: IPrinterState) => ({
    ...state,
    isDeletingGroup: true
  }),

  [PRINTER_DELETE_GROUP_SUCCESS]: (
    state: IPrinterState,
    { name }: { name: string }
  ) => {
    const groups = [...state.groups]
    const index = groups.indexOf(name)
    if (index !== -1) {
      groups.splice(index, 1)
    }
    return {
      ...state,
      isDeletingGroup: false,
      groups,
      printers: state.printers.map(p => {
        const printerGroups = p.groups ? [...p.groups] : []
        const gIndex = printerGroups.indexOf(name)
        if (gIndex !== -1) {
          printerGroups.splice(gIndex, 1)
        }
        return {
          ...p,
          groups: printerGroups
        }
      })
    }
  },

  [PRINTER_DELETE_GROUP_FAILURE]: (state: IPrinterState) => ({
    ...state,
    isDeletingGroup: false
  }),

  [PRINTER_START_REMOTE_PRINT]: (state: IPrinterState) => ({
    ...state,
    isStartingRemotePrint: true
  }),

  [PRINTER_START_REMOTE_PRINT_SUCCESS]: (state: IPrinterState) => ({
    ...state,
    isStartingRemotePrint: false
  }),

  [PRINTER_START_REMOTE_PRINT_FAILURE]: (state: IPrinterState) => ({
    ...state,
    isStartingRemotePrint: false
  })
})
