import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IShowOperationLogsFormAction, IOperationLogsFormState } from './types'

export const initialState: IOperationLogsFormState = {
  visible: false,
  editingFields: undefined
}

export const operationLogsFormSlice = createSlice({
  name: 'operationLogsForm',
  initialState,
  reducers: {
    showOperationLogsForm: (
      state,
      action: PayloadAction<IShowOperationLogsFormAction | undefined>
    ) => {
      state.visible = true
      if (action && action.payload) {
        state.editingFields = action.payload.editingFields
      }
    },
    hideOperationLogsForm: state => {
      state.visible = false
      state.editingFields = undefined
    }
  }
})

export const {
  showOperationLogsForm,
  hideOperationLogsForm
} = operationLogsFormSlice.actions

export default operationLogsFormSlice.reducer
