import React from 'react'
import { Title } from 'backpack'
import { Step } from 'react-joyride'
import styled from 'styled-components'

import { TourButton } from './TourButton'
import { IJoyrideProps, IJoyrideCallback } from '../redux/tour/types'

const StyledTitle = styled(Title)`
  transition: all 0.25s ease;
  opacity: 1;
  &.hidden {
    opacity: 0;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.25s ease;
  &.hidden {
    opacity: 0;
  }
`

interface ITitle extends React.HTMLAttributes<HTMLDivElement> {
  tourCallback?: IJoyrideCallback
  tourSteps?: Step[]
  tourProps?: IJoyrideProps
  siblingNode?: React.ReactNode
  hidden?: boolean
}

export default function({
  tourCallback,
  tourSteps,
  tourProps,
  children,
  siblingNode,
  hidden,
  ...rest
}: ITitle) {
  if (tourSteps) {
    return (
      <TitleContainer className={`${hidden ? 'hidden' : ''}`} {...rest}>
        <StyledTitle style={{ marginBottom: '0' }}>{children}</StyledTitle>
        <TourButton
          tourSteps={tourSteps}
          joyrideProps={tourProps}
          joyrideCallback={tourCallback}
        />
        {siblingNode || null}
      </TitleContainer>
    )
  } else {
    return (
      <StyledTitle className={`${hidden ? 'hidden' : ''}`} {...rest}>
        {children}
      </StyledTitle>
    )
  }
}
