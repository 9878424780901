import React, { Component } from 'react'
import styled from 'styled-components'
import {
  Popup,
  isObjectEqual,
  Button,
  DelayedComponents,
  Loader
} from 'backpack'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { FilterDropdown, FilterDateRangeTextbox, FilterChips } from '../Filters'
import { formatDate } from '../Filters/Filters'
import { IPrinter, IPrinterState } from '../../redux/printer/types'
import { hideExportJobForm } from '../../redux/exportJobForm/slice'
import { IExportDates, IPastPrintsState } from '../../redux/past-prints/types'
import { exportJobs } from '../../redux/past-prints/actions'
import { IExportJobFormState } from '../../redux/exportJobForm/types'

const StyledForm = styled.form`
  width: 100%;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
  }
  .row > .label {
    margin-right: 20px;
    opacity: 0.45;
    display: inline-block;
    min-width: 100px;
    font-size: var(--medium);
  }
  button {
    width: fit-content;
    margin-left: auto;
  }
  .field {
    background: hsl(0deg 0% 0% / 50%);
    border-radius: 10px;
    opacity: 0.6;
    transition: opacity 0.2s ease;
  }
  .field:hover {
    opacity: 1;
  }
  @media (max-width: 480px) {
    .row {
      flex-direction: column;
      align-items: flex-start;
    }
    .label {
      margin: 8px 0;
    }
    .field {
      text-align: center;
      opacity: 0.8;
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`

const StyledPopup = styled(({ children, ...rest }) => (
  <Popup children={children} {...rest} />
))`
  .popup__content {
    overflow: visible;
  }
  .popup__inner-container {
    min-width: 350px;
  }
  .value {
    color: var(--p-color-1);
    box-sizing: border-box;
    padding: 10px;
    font-weight: bold;
  }
  @media (max-width: 480px) {
    .popup__content {
      overflow: auto;
    }
    .popup__inner-container {
      min-width: unset;
      width: 100%;
    }
    .value {
      background: var(--color-bg-canvas);
      border-radius: 10px;
      width: 100%;
      text-align: center;
      cursor: default;
    }
  }
`

export interface ExportJobsPopupStates {
  filterDateRange: {
    from: Date
    to: Date
  }
  filterPrinters: string[] | undefined
  filterPrinterType: string | null
}

export interface ExportJobsPopupProps {
  printers: IPrinter[]
  isMounted?: boolean
  isFetchingPrinter?: boolean
  exportJobsInProgress?: boolean
  printersType: string
  printerName?: string
  actions: {
    hideExportJobForm: typeof hideExportJobForm
    exportJobs: typeof exportJobs
  }
}

class ExportJobsPopup extends Component<
  ExportJobsPopupProps,
  ExportJobsPopupStates
> {
  constructor(props: ExportJobsPopupProps) {
    super(props)

    const filteredPrinters = props.printers
      .filter(a => !a.isVelox)
      .map(a => a.name)

    const fromDate = new Date()
    fromDate.setMonth(0)

    this.state = {
      filterDateRange: {
        from: fromDate,
        to: new Date()
      },
      filterPrinters: filteredPrinters,
      filterPrinterType: 'dentaform'
    }
  }

  componentDidUpdate(prevProps: ExportJobsPopupProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.state.filterPrinters === undefined &&
      !isObjectEqual(this.props.printers, [])
    ) {
      this.setState({
        filterPrinters: this.getPrinterOptions().map(a => a.value)
      })
    }
    if (prevProps.exportJobsInProgress && !this.props.exportJobsInProgress) {
      this.props.actions.hideExportJobForm()
    }
  }

  getPrinterOptions = () => {
    const { filterPrinterType } = this.state
    const printerOptions = this.props.printers
      .filter(
        p =>
          (filterPrinterType === 'velox' && p.isVelox) ||
          (filterPrinterType === 'dentaform' && !p.isVelox)
      )
      .map(p => ({ label: p.customPrinterName, value: p.name }))
      .sort((a, b) => a.label.localeCompare(b.label))

    return printerOptions
  }

  setFilterPrinters = (values: string[]) => {
    const { filterPrinters } = this.state

    if (
      Array.isArray(filterPrinters) &&
      isObjectEqual(
        values.sort((a, b) => a.localeCompare(b)),
        this.getPrinterOptions().sort((a, b) => a.label.localeCompare(b.label))
      )
    ) {
      this.setState({ filterPrinters: undefined })
    } else {
      this.setState({ filterPrinters: values })
    }
  }

  setFilterPrinterType = (value: string | null) => {
    this.setState(prevState => ({
      filterPrinterType: value,
      filterPrinters:
        prevState.filterPrinterType !== value
          ? undefined
          : prevState.filterPrinters
    }))
  }

  getPrinterTypes = () => {
    const { printersType } = this.props
    const types = [
      {
        label: 'Dentaform',
        value: 'dentaform'
      }
    ]
    if (printersType === 'mixed') {
      types.push({
        label: 'Velox',
        value: 'velox'
      })
    }
    return types
  }

  setDateRange = (from: Date, to: Date) =>
    this.setState({ filterDateRange: { from, to } })

  exportJobs = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const { printerName } = this.props
    const { filterPrinterType, filterPrinters } = this.state
    const { from, to } = this.state.filterDateRange

    const dateArray = {} as IExportDates
    // Extract years into IExportDates
    for (let i = from.getFullYear(); i <= to.getFullYear(); i++) {
      dateArray[i] = []

      const isCurrentYearFromYear = i === from.getFullYear()
      const isCurrentYearToYear = i === to.getFullYear()

      for (
        let x = isCurrentYearFromYear ? from.getMonth() : 0;
        x <= (isCurrentYearToYear ? to.getMonth() : 11);
        x++
      ) {
        dateArray[i].push(x + 1)
      }
    }

    if (filterPrinterType !== null && filterPrinters !== undefined) {
      this.props.actions.exportJobs(
        filterPrinterType,
        printerName ? [printerName] : filterPrinters,
        dateArray
      )
    }
  }

  render() {
    const { filterDateRange, filterPrinterType, filterPrinters } = this.state
    const {
      isMounted,
      isFetchingPrinter,
      exportJobsInProgress,
      printerName
    } = this.props

    return (
      <StyledPopup
        title={`Export Jobs`}
        onClose={this.props.actions.hideExportJobForm}
        visible={isMounted}
        className={`${
          isFetchingPrinter || exportJobsInProgress ? 'disabled' : ''
        }`}
      >
        <DelayedComponents isMounted={Boolean(exportJobsInProgress)}>
          <Loader visible={exportJobsInProgress} text={'Exporting Jobs ...'} />
        </DelayedComponents>
        <StyledForm onSubmit={e => e.preventDefault()}>
          {!printerName && (
            <div className="row">
              <span className="label">Printer Model: </span>
              <FilterDropdown
                className="field"
                onChange={this.setFilterPrinterType}
                options={this.getPrinterTypes()}
                value={filterPrinterType}
              />
            </div>
          )}
          <div className="row" style={{ marginTop: '0' }}>
            <span className="label">{`${
              printerName ? 'Printer' : 'Printers'
            }: `}</span>
            {printerName ? (
              <div className="value">{printerName}</div>
            ) : (
              <FilterChips
                className="field"
                fetching={isFetchingPrinter}
                itemLabel={'Printer'}
                selectedOptions={filterPrinters}
                options={this.getPrinterOptions()}
                onSave={this.setFilterPrinters}
              />
            )}
          </div>
          <div className="row">
            <span className="label">Date Range: </span>
            <div className="value">{`${formatDate(
              filterDateRange.from,
              true
            )} - ${formatDate(filterDateRange.to, true)}`}</div>
          </div>
          <FilterDateRangeTextbox
            onChangeDateRange={this.setDateRange}
            dateRange={filterDateRange}
            daysDisabled
            exposed={true}
          />

          <Button
            style={{ marginTop: '20px', zIndex: 0 }}
            type="submit"
            label="Export Jobs"
            fullWidth
            disabled={isFetchingPrinter || exportJobsInProgress}
            onClick={this.exportJobs}
          />
        </StyledForm>
      </StyledPopup>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        hideExportJobForm,
        exportJobs
      },
      dispatch
    )
  }
})

const getIsFetchingPrinter = (state: IPrinterState) => state.fetchingPrinters
const getPrinterList = (state: IPrinterState) => state.printers
const getPrinterDataErrorMessage = (state: IPrinterState) => state.errorMessage
const getPrintersType = (state: IPrinterState) => state.printersType

const printerDataSelector = createSelector(
  [
    getIsFetchingPrinter,
    getPrinterList,
    getPrinterDataErrorMessage,
    getPrintersType
  ],
  (isFetchingPrinter, printers, printerDataErrorMessage, printersType) => ({
    isFetchingPrinter,
    printers,
    printerDataErrorMessage,
    printersType
  })
)

const getExportJobsInProgress = (state: IPastPrintsState) =>
  state.exportJobsInProgress

const pastPrintsSelector = createSelector(
  [getExportJobsInProgress],
  exportJobsInProgress => ({ exportJobsInProgress })
)

const getExportJobFormPrinterName = (state: IExportJobFormState) =>
  state.printerName

const exportJobFormSelector = createSelector(
  [getExportJobFormPrinterName],
  printerName => ({
    printerName
  })
)

const mapStateToProps = ({ printerData, pastPrints, exportJobForm }) => {
  const { printers, isFetchingPrinter, printersType } = printerDataSelector(
    printerData
  )

  const { exportJobsInProgress } = pastPrintsSelector(pastPrints)
  const { printerName } = exportJobFormSelector(exportJobForm)

  return {
    printers,
    isFetchingPrinter,
    printersType,
    exportJobsInProgress,
    printerName
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportJobsPopup)
