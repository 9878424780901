import { getFetchUrlEncodedOptions, getFetchOpts } from '../../utils/pre-fetch'
import { parseJsonResponse, parseTextResponse } from '../../utils/post-fetch'

const APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
const APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
const APP_AUTH0_CLIENT_SECRET = process.env.REACT_APP_AUTH0_CLIENT_SECRET
const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function login(username: string, password: string) {
  const body = {
    grant_type: 'password',
    audience: APP_AUTH0_AUDIENCE,
    client_id: APP_AUTH0_CLIENT_ID,
    client_secret: APP_AUTH0_CLIENT_SECRET,
    scope: 'offline_access openid', // to offline_access is required to return refresh token
    username,
    password
  }
  return fetch(
    `${APP_AUTH0_DOMAIN}/oauth/token`,
    getFetchUrlEncodedOptions('post', body)
  ).then(parseJsonResponse)
}

export function getUserProfile(token: string) {
  return fetch(
    `${APP_AUTH0_DOMAIN}/userinfo`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function renewToken(refreshToken: string, token: string) {
  const body = {
    grant_type: 'refresh_token',
    client_id: APP_AUTH0_CLIENT_ID,
    client_secret: APP_AUTH0_CLIENT_SECRET,
    refresh_token: refreshToken
  }
  return fetch(
    `${APP_AUTH0_DOMAIN}/oauth/token`,
    getFetchUrlEncodedOptions('post', body, token)
  ).then(parseJsonResponse)
}

export function forgotPassword(email: string) {
  const body = {
    client_id: APP_AUTH0_CLIENT_ID,
    connection: 'Username-Password-Authentication',
    email
  }
  return fetch(
    `${APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    getFetchOpts('post', body)
  ).then(parseTextResponse)
}

export function updateUserMetaData(token: string, body: any) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/update-auth-user`,
    getFetchOpts('put', body, token)
  ).then(parseJsonResponse)
}

export function getCustomerInformation(token: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-customer-info`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}
