import { getFetchOpts } from '../../utils/pre-fetch'
import { parseJsonResponse } from '../../utils/post-fetch'
import {
  CreateOperationLogAction,
  DeleteOperationLogAction,
  GetOEEOverviewAction,
  GetOperationLogsAction,
  GetThroughputOverviewAction,
  UpdateOperationLogAction
} from './types'
import { convertLocaleDateToUTCDate } from '../../utils/date'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

interface ICreateOperationLogAPI extends CreateOperationLogAction {
  token: string
}

interface IUpdateOperationLogAction extends UpdateOperationLogAction {
  token: string
}

interface IDeleteOperationLogAction extends DeleteOperationLogAction {
  token: string
}

export function createOperationLogAPI({
  printer,
  reportedAt,
  totalProductionTime,
  plannedDownTime,
  unPlannedDownTime,
  totalProductionUnits,
  idealCycleTime,
  rejectedProductionUnits,
  token
}: ICreateOperationLogAPI) {
  const body = {
    printer,
    totalProductionTime: Number(totalProductionTime),
    plannedDownTime: Number(plannedDownTime),
    unPlannedDownTime: Number(unPlannedDownTime),
    idealCycleTime: Number(idealCycleTime),
    totalProductionUnits: Number(totalProductionUnits),
    rejectedProductionUnits: Number(rejectedProductionUnits),
    reportedAt: convertLocaleDateToUTCDate(reportedAt).toISOString()
  }

  return fetch(
    `${DROPLET_API_URL}/mystructo/create-printer-operation-log`,
    getFetchOpts('post', body, token)
  ).then(parseJsonResponse)
}

export function updateOperationLogAPI({
  printer,
  reportedAt,
  totalProductionTime,
  plannedDownTime,
  unPlannedDownTime,
  totalProductionUnits,
  idealCycleTime,
  rejectedProductionUnits,
  token
}: IUpdateOperationLogAction) {
  const body = {
    printer,
    totalProductionTime: Number(totalProductionTime),
    plannedDownTime: Number(plannedDownTime),
    unPlannedDownTime: Number(unPlannedDownTime),
    idealCycleTime: Number(idealCycleTime),
    totalProductionUnits: Number(totalProductionUnits),
    rejectedProductionUnits: Number(rejectedProductionUnits),
    reportedAt: convertLocaleDateToUTCDate(reportedAt).toISOString()
  }

  return fetch(
    `${DROPLET_API_URL}/mystructo/update-printer-operation-log`,
    getFetchOpts('post', body, token)
  ).then(parseJsonResponse)
}

export function deleteOperationLogAPI({
  printer,
  reportedAt,
  token
}: IDeleteOperationLogAction) {
  const body = {
    printer,
    reportedAt: convertLocaleDateToUTCDate(reportedAt).toISOString()
  }

  return fetch(
    `${DROPLET_API_URL}/mystructo/delete-printer-operation-log`,
    getFetchOpts('delete', body, token)
  ).then(parseJsonResponse)
}

interface IGetOperationLogsAPI extends GetOperationLogsAction {
  token: string
}

export function getOperationLogsAPI({
  page = 1,
  limit = 20,
  fromDate,
  toDate,
  printers,
  token
}: IGetOperationLogsAPI) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-printer-operation-log?page=${page}&limit=${limit}&fromDate=${convertLocaleDateToUTCDate(
      fromDate
    ).toISOString()}&toDate=${convertLocaleDateToUTCDate(
      toDate
    ).toISOString()}&printers=${printers.join('$')}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

interface IGetOEEOverviewAPI extends GetOEEOverviewAction {
  token: string
}

export function getOEEOverviewAPI({
  printer,
  fromDate,
  toDate,
  token
}: IGetOEEOverviewAPI) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-oee-overview?printer=${printer}&fromDate=${convertLocaleDateToUTCDate(
      fromDate
    ).toISOString()}&toDate=${convertLocaleDateToUTCDate(
      toDate
    ).toISOString()}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

interface IGetThroughputAPI extends GetThroughputOverviewAction {
  token: string
}

export function getThroughputOverviewAPI({
  printer,
  fromDate,
  toDate,
  token
}: IGetThroughputAPI) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-throughput-overview?printer=${printer}&fromDate=${convertLocaleDateToUTCDate(
      fromDate
    ).toISOString()}&toDate=${convertLocaleDateToUTCDate(
      toDate
    ).toISOString()}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}
