import createReducer from '../../utils/create-reducer'
import {
  TOUR_START_TOUR,
  TOUR_END_TOUR,
  TOUR_NEXT_STEP,
  TOUR_PREVIOUS_STEP,
  TOUR_SET_STEP_INDEX,
  TOUR_SET_JOYRIDE_CALLBACK,
  TOUR_PAUSE_TOUR,
  TOUR_RESUME_TOUR,
  TOUR_SET_JOYRIDE_PROPS
} from './constants'
import { TourConfigTypes, tourConfigs } from '../../TourConfigs'
import { ITourState, IJoyrideCallback, IJoyrideProps } from './types'
import { StartTourProps } from './actions'

export const initialStates = {
  tourSteps: tourConfigs[TourConfigTypes.overview],
  tourStepIndex: 0,
  isTourOpen: false,
  joyrideCallback: undefined,
  joyrideProps: undefined
}

export default createReducer(initialStates, {
  [TOUR_START_TOUR]: (state: ITourState, startTourProps: StartTourProps) => ({
    ...state,
    tourSteps: startTourProps.tourSteps,
    tourStepIndex: 0,
    isTourOpen: true,
    joyrideCallback: startTourProps.joyrideCallback,
    joyrideProps: startTourProps.joyrideProps
  }),

  [TOUR_END_TOUR]: (state: ITourState, { reset }: { reset: boolean }) => ({
    ...state,
    tourStepIndex: !reset ? 0 : state.tourStepIndex,
    isTourOpen: false,
    joyrideCallback: !reset ? state.joyrideCallback : undefined
  }),

  [TOUR_NEXT_STEP]: (state: ITourState) => ({
    ...state,
    tourStepIndex: state.tourStepIndex + 1
  }),

  [TOUR_PREVIOUS_STEP]: (state: ITourState) => ({
    ...state,
    tourStepIndex:
      state.tourStepIndex > 0 ? state.tourStepIndex + 1 : state.tourStepIndex
  }),

  [TOUR_SET_STEP_INDEX]: (state: ITourState, { index }: { index: number }) => ({
    ...state,
    tourStepIndex: index
  }),

  [TOUR_SET_JOYRIDE_CALLBACK]: (
    state: ITourState,
    { joyrideCallback }: { joyrideCallback: IJoyrideCallback }
  ) => ({
    ...state,
    joyrideCallback: joyrideCallback
  }),

  [TOUR_PAUSE_TOUR]: (state: ITourState) => ({
    ...state,
    isTourOpen: false
  }),

  [TOUR_RESUME_TOUR]: (state: ITourState) => ({
    ...state,
    isTourOpen: true
  }),

  [TOUR_SET_JOYRIDE_PROPS]: (
    state: ITourState,
    { joyrideProps }: { joyrideProps?: IJoyrideProps }
  ) => ({
    ...state,
    joyrideProps: joyrideProps
  })
})
