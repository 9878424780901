import { getFetchOpts, getFetchUploadOpts } from '../../utils/pre-fetch'
import { parseJsonResponse } from '../../utils/post-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function getDentaformPrinters(token: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/owned-printers`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function changePrinterName(
  token: string,
  name: string,
  customName: string,
  isVelox = false
) {
  const url = isVelox
    ? `${DROPLET_API_URL}/mystructo/velox-custom-name`
    : `${DROPLET_API_URL}/mystructo/dentaform-custom-name`
  return fetch(url, getFetchOpts('put', { name, customName }, token)).then(
    parseJsonResponse
  )
}

export function getUploadUrl(
  token: string,
  printerName: string,
  fileName: string
) {
  const apiUrl = `${DROPLET_API_URL}/upload-link/${printerName}/${encodeURIComponent(
    fileName
  )}`
  return fetch(apiUrl, getFetchOpts('get', {}, token)).then(parseJsonResponse)
}

export function uploadFile(
  url: string,
  token: string,
  file: File,
  extraParams: any
) {
  const options = getFetchUploadOpts(file, 'file', null, token)
  // creating new formData here as sequence of keys also matters in case of S3
  const formData = new FormData()
  const keys = Object.keys(extraParams)
  keys.forEach(key => formData.append(key, extraParams[key]))
  formData.append('file', file)
  options.body = formData
  delete options.headers.Authorization // remove Authorization header as it is thowing error
  return fetch(url, options)
}

export function parseDentaformPastPrints(res) {
  const logs = res.printerLogs || []
  return new Promise(resolve => {
    const printerLogs = logs.map(printerLog => {
      const { firstReportedAt, printStart } = printerLog
      return {
        ...printerLog,
        firstReportedAt: new Date(firstReportedAt),
        printStart: {
          ...printStart,
          fileName: printStart ? decodeURIComponent(printStart.fileName) : '',
          estimatedDuration: printStart
            ? printStart.estimatedDuration * 1000
            : 0
        }
      }
    })
    resolve({
      ...res,
      printerLogs
    })
  })
}

export function parseVeloxPastPrints(res) {
  const logs = res.printerLogs || []
  return new Promise(resolve => {
    const printerLogs = logs.map(printerLog => {
      const {
        reportedAt,
        fileName,
        estimatedDuration,
        preheatStrokeDurationSec,
        printResolution,
        totalLayers,
        totalVolume,
        completedLayers,
        completedVolume,
        isSuccess,
        isCancelled,
        printingProfile,
        printer,
        job,
        printFeedback
      } = printerLog
      return {
        firstReportedAt: new Date(reportedAt),
        printStart: {
          fileName: decodeURIComponent(fileName),
          estimatedDuration: estimatedDuration * 1000,
          preheatDuration: preheatStrokeDurationSec,
          printResolution,
          totalLayers,
          totalVolume,
          resin: {
            name: printingProfile ? printingProfile.resinType : '-'
          }
        },
        printEnd: {
          completedLayers,
          completedVolume,
          isCancelled
        },
        printReport: {
          isSuccess
        },
        printer,
        jobId: job,
        printFeedback
      }
    })
    resolve({
      ...res,
      printerLogs
    })
  })
}

export function getPendingFiles(
  token: string,
  dentaformPrinters: string[] = [],
  veloxPrinters: string[] = []
) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/list-pending-files?dentaformPrinters=${dentaformPrinters.join(
      '$'
    )}&veloxPrinters=${veloxPrinters.join('$')}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function getLatestTeflon(token: string, printerName: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-latest-teflon?printer=${printerName}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function getLatestLCD(token: string, printerName: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-latest-lcd?printer=${printerName}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function createGroup(token: string, name: string, printers: string[]) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/create-printer-group`,
    getFetchOpts('post', { name, printers }, token)
  ).then(parseJsonResponse)
}

export function updatePrinterGroup(
  token: string,
  name: string,
  printers?: string[],
  updatedName?: string
) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/update-printer-group`,
    getFetchOpts('post', { name, printers, updateName: updatedName }, token)
  ).then(parseJsonResponse)
}

export function getGroups(token: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-printer-groups`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function deleteGroup(token: string, name: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/delete-printer-group`,
    getFetchOpts('delete', { name }, token)
  ).then(parseJsonResponse)
}
