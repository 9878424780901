import {
  AUTH_LOGIN_PENDING,
  AUTH_LOGIN_FAILED,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_PENDING,
  AUTH_LOGOUT_SUCCESS,
  AUTH_RENEW_TOKEN,
  AUTH_FORGOT_PASSWORD_PEDNING,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_FAILED,
  AUTH_HIDE_BANNER_FAILED,
  AUTH_HIDE_BANNER_PENDING,
  AUTH_HIDE_BANNER_SUCCESS,
  AUTH_GET_COMPANY_INFO_SUCCESS
} from './constants'
import { IAddress, IUser } from './types'

export function login(username: string, password: string) {
  return {
    type: AUTH_LOGIN_PENDING,
    payload: { username, password }
  }
}

export function loginSuccess(
  user: IUser,
  accessToken: string,
  refreshToken: string
) {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: { user, accessToken, refreshToken }
  }
}

export function loginFailure(
  message = 'Failed to login',
  username = 'not known'
) {
  return {
    type: AUTH_LOGIN_FAILED,
    payload: { message, username }
  }
}

export function logout() {
  return {
    type: AUTH_LOGOUT_PENDING
  }
}

export function logoutSuccess() {
  return {
    type: AUTH_LOGOUT_SUCCESS
  }
}

export function renewToken(fetchProfile = true) {
  return {
    type: AUTH_RENEW_TOKEN,
    payload: { fetchProfile }
  }
}

export function forgotPassword(email: string) {
  return {
    type: AUTH_FORGOT_PASSWORD_PEDNING,
    payload: { email }
  }
}

export function forgotPasswordSuccess() {
  return {
    type: AUTH_FORGOT_PASSWORD_SUCCESS
  }
}

export function forgotPasswordFailure(message = ' Failed to sent email') {
  return {
    type: AUTH_FORGOT_PASSWORD_FAILED,
    payload: { message }
  }
}

export function hideBanner() {
  return {
    type: AUTH_HIDE_BANNER_PENDING
  }
}

export function hideBannerSuccess() {
  return {
    type: AUTH_HIDE_BANNER_SUCCESS
  }
}

export function hideBannerFailed() {
  return {
    type: AUTH_HIDE_BANNER_FAILED
  }
}

export function getCompanyInfomationSuccess(
  companyName: string,
  companyAddress: IAddress
) {
  return {
    type: AUTH_GET_COMPANY_INFO_SUCCESS,
    payload: { companyName, companyAddress }
  }
}
