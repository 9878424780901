import React from 'react'
import { DelayedComponents, PopupDialog } from 'backpack'
import { useSelector, useDispatch } from 'react-redux'

import { IPopupAppState } from '../../redux/popup/types'
import { dismissPopupDialog as dismissPopupDialogAction } from '../../redux/popup/slice'
import { hideFeedbackForm as hideFeedbackFormAction } from '../../redux/feedbackForm/slice'
import { hideAddJobForm } from '../../redux/addJobForm/slice'
import { RootState } from '../../store'

import { AddJobPopup } from '../../components/AddJobPopup'
import { FeedbackPopup } from '../../components/FeedbackPopup'
import { ExportJobsPopup } from '../../components/ExportJobsPopup'
import { PrinterGroupPopup } from '../../components/PrinterGroupPopup'
import { EditUserGroupPopup } from '../../components/EditUserGroupPopup'
import { StartJobPopup } from '../../components/StartJobPopup'
import OperationLogsForm from '../../routes/OEE/OperationLogsForm'

export function PopupManager() {
  const dispatch = useDispatch()

  const { popupDialog } = useSelector(
    ({ popup }: { popup: IPopupAppState }) => popup
  )

  function onPopupYes() {
    if (popupDialog && popupDialog.onYesCallback) {
      popupDialog.onYesCallback()
      dismissPopupDialog()
    }
  }

  function dismissPopupDialog() {
    dispatch(dismissPopupDialogAction())
  }

  // Overlay Form Controller
  const {
    addJobFormVisible,
    feedbackFormVisible,
    exportJobFormVisible,
    printerGroupFormVisible,
    editUserGroupFormVisible,
    startJobFormVisible,
    operationLogsFormVisible
  } = useSelector(
    ({
      addJobForm,
      feedbackForm,
      exportJobForm,
      printerGroupForm,
      editUserGroupForm,
      startJobForm,
      operationLogsForm
    }: RootState) => ({
      addJobFormVisible: addJobForm.visible,
      feedbackFormVisible: feedbackForm.visible,
      exportJobFormVisible: exportJobForm.visible,
      printerGroupFormVisible: printerGroupForm.visible,
      editUserGroupFormVisible: editUserGroupForm.visible,
      startJobFormVisible: startJobForm.visible,
      operationLogsFormVisible: operationLogsForm.visible
    })
  )
  const closeAddJobForm = () => dispatch(hideAddJobForm())
  const hideFeedbackForm = () => dispatch(hideFeedbackFormAction())

  return (
    <>
      <DelayedComponents isMounted={Boolean(popupDialog)}>
        <PopupDialog
          visible={Boolean(popupDialog)}
          title={popupDialog ? popupDialog.title : ''}
          desc={popupDialog ? popupDialog.message : ''}
          onYesCallback={onPopupYes}
          onNoCallback={dismissPopupDialog}
          yesButtonColor={popupDialog ? popupDialog.yesButtonColor : undefined}
          yesText={popupDialog ? popupDialog.yesText : undefined}
        />
      </DelayedComponents>
      <DelayedComponents isMounted={addJobFormVisible!}>
        <AddJobPopup onHidePopup={closeAddJobForm} />
      </DelayedComponents>
      <DelayedComponents isMounted={feedbackFormVisible!}>
        <FeedbackPopup onHidePopup={hideFeedbackForm} />
      </DelayedComponents>
      <DelayedComponents isMounted={exportJobFormVisible!}>
        <ExportJobsPopup />
      </DelayedComponents>
      <DelayedComponents isMounted={printerGroupFormVisible!}>
        <PrinterGroupPopup visible={Boolean(printerGroupFormVisible)} />
      </DelayedComponents>
      <DelayedComponents isMounted={editUserGroupFormVisible!}>
        <EditUserGroupPopup visible={Boolean(editUserGroupFormVisible)} />
      </DelayedComponents>
      <DelayedComponents isMounted={Boolean(startJobFormVisible)}>
        <StartJobPopup visible={Boolean(startJobFormVisible)} />
      </DelayedComponents>
      <DelayedComponents isMounted={Boolean(operationLogsFormVisible)}>
        <OperationLogsForm />
      </DelayedComponents>
    </>
  )
}

export default PopupManager
