import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import {
  PlusIcon,
  OverviewIcon,
  JobsIcon,
  PrintersIcon,
  StatisticsIcon,
  ReportsIcon,
  SettingsIcon,
  Separator,
  Button,
  SidebarButton,
  SidebarSubButton,
  SidebarSubButtonGroup
} from 'backpack'
import { useDispatch } from 'react-redux'

import { StyledAnchor } from '../Header'
import { ILink } from '../Header/Header'
import SidebarPWAPromoter from './SidebarPWAPromoter'
import { ThemeButton } from './components'
import { PlayIcon } from '../SVGImages'
import { showStartJobForm } from '../../redux/startJobForm/slice'

const SidebarButtonGroup = styled.div`
  overflow-x: hidden;
  margin-top: -10px;
  padding-top: 10px;
  max-width: 100%;
`

interface SidebarSubMenuItem {
  routeName: string
  onClick?: () => void
  active?: boolean
  label: string
}

export interface SidebarMenuItem {
  routeName: string
  icon: ReactNode
  active?: boolean
  onClick?: () => void
  submenu?: SidebarSubMenuItem[]
}

export interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  sidebarMenuItems: SidebarMenuItem[]
  activeRouteName?: string
  visible?: boolean
  active?: boolean
  onAddJob?: () => void
  onHideSidebar?: () => void
  links?: ILink[]
  onInstallPWAPromo?: () => void
  onIgnorePWAPromo?: () => void
  onThemeSelect?: () => void
  isNightMode?: boolean
  showPWAPromo?: boolean
}

const Sidebar = styled(
  ({
    sidebarMenuItems,
    activeRouteName,
    visible,
    active,
    onAddJob,
    onHideSidebar,
    className,
    links,
    onInstallPWAPromo,
    onIgnorePWAPromo,
    showPWAPromo,
    onThemeSelect,
    isNightMode
  }: SidebarProps) => {
    const dispatch = useDispatch()
    const [collapsedMenu, setCollapsedMenu] = useState({})

    const getSelectedPrinterName = () => {
      let selectedPrinterName = undefined as undefined | string
      if (activeRouteName && activeRouteName.split('/')[0] === 'printers') {
        return activeRouteName.split('/')[1]
      }
      return selectedPrinterName
    }

    const showStartJobPopup = () => {
      const selectedPrinterName = getSelectedPrinterName()
      dispatch(
        showStartJobForm({ preselectedPrinterName: selectedPrinterName })
      )
    }

    function onClickSidebar({
      callback,
      routeName,
      hasSub
    }: {
      callback?: () => void
      routeName: string
      hasSub?: boolean
    }) {
      if (onHideSidebar && !hasSub) onHideSidebar()

      if (hasSub) {
        setCollapsedMenu({
          ...collapsedMenu,
          [routeName]: !collapsedMenu[routeName]
        })
      } else {
        setCollapsedMenu({})
      }

      if (callback && !hasSub) {
        callback()
      }
    }

    const [route, childRoute] = !activeRouteName
      ? [undefined, undefined]
      : activeRouteName.split('/')

    return (
      <div
        className={`${className} sidebar${(visible && ' visible') ||
          ''}${(active && ' active') || ''}`}
      >
        <Button
          style={{ fontSize: 'var(--large)', minWidth: '160px' }}
          icon={<PlusIcon />}
          label={'Add Job'}
          onClick={onAddJob}
        />
        <br />
        <Button
          style={{ fontSize: 'var(--large)', minWidth: '160px' }}
          icon={<PlayIcon />}
          label={'Start Print'}
          onClick={showStartJobPopup}
          secondary
        />
        <Separator height="30px" />
        <SidebarButtonGroup>
          {sidebarMenuItems &&
            sidebarMenuItems.map(({ routeName, icon, onClick, submenu }) => {
              const hasSubMenuSelected =
                submenu &&
                submenu.find(
                  s =>
                    s.routeName === activeRouteName ||
                    s.routeName === childRoute
                )
              return (
                <React.Fragment key={routeName}>
                  <SidebarButton
                    key={`sidebar-menu-item-${routeName}`}
                    className={'sidebar-button'}
                    active={
                      Boolean(submenu)
                        ? collapsedMenu[routeName] || hasSubMenuSelected
                        : route === routeName
                    }
                    onClick={() =>
                      onClickSidebar({
                        callback: onClick,
                        routeName: routeName,
                        hasSub: Boolean(submenu)
                      })
                    }
                    icon={icon}
                    label={routeName}
                  />
                  {submenu && (
                    <SidebarSubButtonGroup
                      key={'sidebar-collapsible-menu'}
                      className="sidebar-collapsible-menu"
                      active={collapsedMenu[routeName] || hasSubMenuSelected}
                    >
                      {submenu.map((sub: SidebarSubMenuItem, index) => (
                        <SidebarSubButton
                          label={sub.label}
                          key={`sidebar-child-menu-${sub.routeName}`}
                          active={
                            childRoute === sub.routeName ||
                            activeRouteName === sub.routeName
                          }
                          onClick={() =>
                            onClickSidebar({
                              callback: sub.onClick,
                              routeName: sub.routeName
                            })
                          }
                        />
                      ))}
                    </SidebarSubButtonGroup>
                  )}
                </React.Fragment>
              )
            })}
        </SidebarButtonGroup>
        <SidebarPWAPromoter
          visible={Boolean(showPWAPromo)}
          onIgnore={onIgnorePWAPromo}
          onInstall={onInstallPWAPromo}
        />
        <div className="sidebar-sub-menu">
          <ThemeButton onClick={onThemeSelect} isNightMode={isNightMode} />
          <div className="links">
            {links &&
              links.map(({ url, label }) => (
                <StyledAnchor
                  data-tut={`mobile-${label}`}
                  key={url}
                  title={label}
                  href={url}
                  target="_blank"
                >
                  {label}
                </StyledAnchor>
              ))}
          </div>
        </div>
      </div>
    )
  }
)`
  width: 210px;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--color-bg-canvas-inset);
  box-sizing: border-box;
  padding-top: calc(var(--header-mobile-height) + 20px);
  pointer-events: auto;
  transition: all 0.3s ease;
  transform: translateX(-100%);
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  border-right: 1px solid var(--color-border-secondary);
  box-sizing: border-box;
  padding-bottom: 74px;
  &.visible.active {
    transform: translateX(0%);
    opacity: 1;
  }
  .sidebar-sub-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
  }
  .links {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    width: 100%;
    justify-content: space-evenly;
  }
  .links > * {
    flex: 1;
    text-align: center;
    flex-basis: 40%;
    margin: 0;
    padding: 12px;
  }
  @media (min-width: 601px) {
    padding-top: calc(var(--header-height) + 20px);
    &.visible {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  @media (max-width: 600px) {
    .sidebar-sub-menu {
      display: flex;
    }
  }
`

const SidebarMenuItems = [
  {
    routeName: 'overview',
    onClick: () => {},
    icon: <OverviewIcon />
  },
  {
    routeName: 'jobs',
    onClick: () => {},
    icon: <JobsIcon />
  },
  {
    routeName: 'printers',
    onClick: () => {},
    icon: <PrintersIcon />
  },
  {
    routeName: 'statistics',
    onClick: () => {},
    icon: <StatisticsIcon />
  },
  {
    routeName: 'reports',
    onClick: () => {},
    icon: <ReportsIcon />
  },
  {
    routeName: 'settings',
    onClick: () => {},
    icon: <SettingsIcon />
  }
]

const SidebarSample = ({
  visible,
  active,
  activeRouteName
}: {
  visible?: boolean
  active?: boolean
  activeRouteName?: string
}) => (
  <Sidebar
    visible={visible || true}
    active={active || false}
    sidebarMenuItems={SidebarMenuItems}
    activeRouteName={activeRouteName || 'overview'}
  />
)

export { Sidebar, SidebarSample }
