import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import {
  BurgerMenuIcon,
  LogoIcon,
  ProfileIcon,
  Container,
  AlignHorizontal,
  AlignVertical,
  Spacing
} from 'backpack'

import { IUser } from '../../redux/auth/types'
import { ThemeButton } from '../Sidebar'
import { QuickAccessIcon } from '../SVGImages'
import { showQuickAccess } from '../../redux/quickAccess/slice'

export type ILink = {
  label: string
  url: string
}

const QuickSearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease;
  user-select: none;
  padding: 6px 12px;
  border-radius: 10px;
  &:hover {
    opacity: 1;
    background: hsl(0deg 0% 50% / 20%);
  }
  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-text-primary);
  }
  @media (max-width: 720px) {
    .quick-access-text {
      display: none;
    }
  }
  @media (max-width: 600px) {
    margin-right: 12px;
  }
`

function QuickSearch() {
  const dispatch = useDispatch()

  function onClick() {
    dispatch(showQuickAccess())
  }

  return (
    <QuickSearchContainer onClick={onClick}>
      <QuickAccessIcon />
      <span className="quick-access-text">Search</span>
    </QuickSearchContainer>
  )
}

const HeaderContainer = styled(Container)`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 var(--padding-xl);
  background: var(--color-header-bg);
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 40%);
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(-100%);
  overflow: visible;
  width: 100%;
  justify-content: flex-end;
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  .sidebar-top-block {
    transition: all 0.3s ease;
    position: absolute;
    background: hsl(225deg 10% 12% / 50%);
    left: 0;
    top: 0;
    width: 210px;
    height: 100%;
    transform: translateX(-100%);
    opacity: 0;
    border-right: 1px solid var(--color-border-secondary);
    background: var(--color-bg-canvas-inset);
    box-sizing: border-box;
  }
  .profile-wrapper {
    position: relative;
  }
  &.sidebar-active .sidebar-top-block {
    transform: translateX(0%);
    opacity: 1;
  }
  .links {
    margin-right: 30px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  @media (min-width: 601px) {
    &.visible .sidebar-top-block {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  @media (max-width: 600px) {
    .links {
      display: none;
    }
  }
`

const ProfileDropdownContainer = styled.div`
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-10%);
  user-select: none;
  background: var(--color-bg-canvas-inset);
  width: 354px;
  border-radius: 10px;
  border: 1px solid var(--color-border-primary);
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 60%);
  &.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
  .button {
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    font-size: var(--medium);
    background: var(--color-bg-canvas-inset);
    color: var(--color-text-primary);
    cursor: pointer;
  }
  .button:hover {
    background: var(--p-color-1);
    color: var(--color-text-primary);
  }
  @media (max-width: 480px) {
    position: fixed;
    width: calc(100% - 20px);
    left: 10px;
  }
`

const StyledBurgerMenu = styled(BurgerMenuIcon)`
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  @media (max-width: 600px) {
    pointer-events: auto;
    opacity: 1;
    position: absolute;
    left: 20px;
    cursor: pointer;
  }
`

const StyledLogoIcon = styled(LogoIcon)`
  position: absolute;
  transition: all 0.3s ease;
  cursor: pointer;
  left: 30px;
  z-index: 1;
  width: 120px;
  @media (max-width: 600px) {
    width: 82px;
    left: 210px;
    transform: translateX(calc(-100% - 60px));
  }
`

const StyledProfileIcon = styled(ProfileIcon)`
  cursor: pointer;
  pointer-events: auto;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`

export const StyledAnchor = styled.a`
  color: var(--color-text-primary);
  opacity: 0.7;
  transition: opacity 0.3s ease;
  margin: 10px;
  text-decoration: auto;
  text-transform: capitalize;
  &:hover {
    opacity: 1;
  }
`

interface IUserRoleBlock extends React.HTMLAttributes<HTMLDivElement> {
  role?: string
  name?: string
}

const UserRoleBlock = styled(({ role, name, ...props }: IUserRoleBlock) => (
  <div {...props}>
    <span className="role" title={role}>
      {role || ' '}
    </span>
    <span className="name" title={name}>
      {name || ' '}
    </span>
  </div>
))`
  font-size: var(--medium);
  letter-spacing: 1px;
  z-index: 1;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  text-align: right;
  max-width: 100px;
  white-space: nowrap;
  user-select: none;
  .role {
    font-weight: bold;
    margin-bottom: 3px;
  }
  .name {
    opacity: 0.7;
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 600px) {
    font-size: var(--small);
    .role {
      margin-bottom: 1px;
    }
  }
`

interface IUserInfo extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  value: string | React.ReactNode
  valueOnNewLine?: boolean
}

const UserInfoRow = styled(
  ({ className, valueOnNewLine, label, value }: IUserInfo) => (
    <div className={`${className}${valueOnNewLine ? ' value-new-line' : ''}`}>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </div>
  )
)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2px 0;
  .label {
    color: var(--color-text-secondary);
  }
  .value {
    font-weight: bold;
    color: var(--color-text-primary);
  }
  &.value-new-line {
    flex-direction: column;
  }
  &.value-new-line .value {
    text-align: right;
    margin: 4px 0;
  }
`

const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  box-sizing: border-box;
  background: var(--color-bg-canvas);
  border-bottom: 1px solid var(--color-border-primary);
`

export interface IHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean
  onSidebarClick?: () => void
  onLogoClick?: () => void
  onLogout?: () => void
  sidebarActive?: boolean
  links?: ILink[]
  name?: string
  role?: string
  user: IUser | null
  onThemeSelect?: () => void
  isNightMode?: boolean
}

interface IHeaderStates {
  profilePanelCollapse?: boolean
}

class Header extends React.Component<IHeaderProps, IHeaderStates> {
  dropdownRef = React.createRef<HTMLDivElement>()
  profileIconRef = React.createRef<HTMLDivElement>()
  constructor(props: IHeaderProps) {
    super(props)
    this.state = {
      profilePanelCollapse: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick)
  }

  handleDocumentClick = event => {
    if (
      this.dropdownRef &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target) &&
      this.profileIconRef &&
      this.profileIconRef.current &&
      !this.profileIconRef.current.contains(event.target)
    ) {
      if (this.state.profilePanelCollapse) {
        this.setState({ profilePanelCollapse: false })
      }
    }
  }

  onProfileClick = () =>
    this.setState(prevState => ({
      profilePanelCollapse: !prevState.profilePanelCollapse
    }))

  logout = () => {
    this.setState({ profilePanelCollapse: false }, this.props.onLogout)
  }

  render() {
    const {
      visible,
      onSidebarClick,
      sidebarActive,
      links,
      name,
      role,
      user,
      onThemeSelect,
      isNightMode
    } = this.props
    const { profilePanelCollapse } = this.state

    return (
      <HeaderContainer
        height="var(--header-height)"
        mobileHeight="var(--header-mobile-height)"
        targetMobileWidth="600px"
        layoutHorizontally={true}
        alignContentVertical={AlignVertical.center}
        alignContentHorizontal={AlignHorizontal.spaceBetween}
        spacing={Spacing.none}
        className={`header${visible ? ' visible' : ''}${
          sidebarActive ? ' sidebar-active' : ''
        }`}
      >
        <div className="sidebar-top-block" />
        <StyledBurgerMenu
          active={sidebarActive}
          onClick={onSidebarClick}
          tabIndex={-1}
        />
        <StyledLogoIcon
          onClick={this.props.onLogoClick}
          className={sidebarActive ? ' sidebar-active' : ''}
        />
        <QuickSearch />
        <div className="links">
          <ThemeButton onClick={onThemeSelect} isNightMode={isNightMode} />
          {links &&
            links.map(({ label, url }) => (
              <StyledAnchor key={url} title={label} target="_blank" href={url}>
                {label}
              </StyledAnchor>
            ))}
        </div>
        {(name || role) && <UserRoleBlock name={name} role={role} />}
        <div className="profile-wrapper">
          <div
            className="profile-icon"
            onClick={this.onProfileClick}
            ref={this.profileIconRef}
          >
            <StyledProfileIcon />
          </div>
          <ProfileDropdownContainer
            className={`profile-dropdown-panel${
              profilePanelCollapse ? ' active' : ''
            }`}
            ref={this.dropdownRef}
          >
            {user && (
              <UserInfoContainer>
                {user.companyName && (
                  <UserInfoRow label={'Company'} value={user.companyName} />
                )}
                {user.companyAddress && user.companyAddress.line1 && (
                  <UserInfoRow
                    valueOnNewLine
                    label={'Address'}
                    value={
                      <>
                        {user.companyAddress.line1 && (
                          <div>{user.companyAddress.line1}</div>
                        )}
                        {user.companyAddress.line2 && (
                          <div>{user.companyAddress.line2}</div>
                        )}
                      </>
                    }
                  />
                )}
                {user.companyAddress && user.companyAddress.country && (
                  <UserInfoRow
                    label={'Country'}
                    value={user.companyAddress.country.toUpperCase()}
                  />
                )}
                {user.companyAddress && user.companyAddress.state && (
                  <UserInfoRow
                    label={'State'}
                    value={user.companyAddress.state}
                  />
                )}
                {user.companyAddress && user.companyAddress.city && (
                  <UserInfoRow
                    label={'City'}
                    value={user.companyAddress.city}
                  />
                )}
              </UserInfoContainer>
            )}
            <div
              onClick={this.logout}
              className="button profile-dropdown-panel__logout-btn"
            >
              Log Out
            </div>
          </ProfileDropdownContainer>
        </div>
      </HeaderContainer>
    )
  }
}

export { Header }
