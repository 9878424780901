import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IOrdersState,
  IGetOrdersActionFailure,
  IGetOrdersActionSuccess,
  IDownloadCaseAction,
  IDownloadCaseActionSuccess,
  IDownloadCaseActionFailure
} from './types'

export const initialState: IOrdersState = {
  isFetchingOrders: false,
  orders: [],
  fetchOrdersError: undefined,
  isDownloadingCase: false,
  caseFileInfo: undefined,
  downloadCaseError: undefined
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    getOrders: state => {
      state.isFetchingOrders = true
      state.fetchOrdersError = undefined
    },
    getOrdersSuccess: (
      state,
      action: PayloadAction<IGetOrdersActionSuccess>
    ) => {
      state.isFetchingOrders = false
      state.orders = action.payload.orders
    },
    getOrdersFailure: (
      state,
      action: PayloadAction<IGetOrdersActionFailure>
    ) => {
      state.isFetchingOrders = false
      state.fetchOrdersError = action.payload.error
    },
    downloadCase: (state, action: PayloadAction<IDownloadCaseAction>) => {
      state.isDownloadingCase = true
      state.downloadCaseError = undefined
    },
    downloadCaseSuccess: (
      state,
      action: PayloadAction<IDownloadCaseActionSuccess>
    ) => {
      state.isDownloadingCase = false
      state.caseFileInfo = {
        caseName: action.payload.caseName,
        orderId: action.payload.orderId,
        blob: action.payload.blob
      }
    },
    downloadCaseFailure: (
      state,
      action: PayloadAction<IDownloadCaseActionFailure>
    ) => {
      state.isDownloadingCase = false
      state.downloadCaseError = action.payload.error
    }
  }
})

export const {
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  downloadCase,
  downloadCaseSuccess,
  downloadCaseFailure
} = orderSlice.actions

export default orderSlice.reducer
