import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { DelayedComponents, Loader } from 'backpack'

import { StyledChipsPopup, StyledSmallButton } from '../Filters/Filters'
import { SearchInput } from '../SearchInput'
import { IPrinterGroupFormState } from '../../redux/printerGroupForm/types'
import { hidePrinterGroupForm } from '../../redux/printerGroupForm/slice'
import { updateGroup, createGroup } from '../../redux/printer/actions'
import { IPrinterState } from '../../redux/printer/types'
import usePrevious from '../../hooks/usePrevious'

const CustomInputTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  box-sizing: border-box;
  width: 100%;
  color: var(--color-text-primary);
  label {
    flex: 1;
  }
  input {
    background: hsl(0deg 0% 0% / 40%);
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-primary);
    border-radius: 10px;
    transition: all 0.3s ease;
    color: var(--color-text-primary);
    height: 40px;
    padding: 0 15px;
    width: 200px;
    text-align: center;
  }
  input:focus {
    opacity: 1;
    border-color: var(--color-border-primary-hovered);
  }
`

export const StyledPrinterGroupPopup = styled(({ children, ...rest }) => (
  <StyledChipsPopup children={children} {...rest} />
))``

interface IPrinterGroupPopup {
  visible: boolean
}

export const PrinterGroupPopup = ({ visible }: IPrinterGroupPopup) => {
  const dispatch = useDispatch()
  const { groupName, printers, availablePrinters, isCreateGroup } = useSelector(
    ({ printerGroupForm }: { printerGroupForm: IPrinterGroupFormState }) =>
      printerGroupForm
  )

  const { isCreatingGroup, isUpdatingGroup } = useSelector(
    ({ printerData }: { printerData: IPrinterState }) => printerData
  )

  const [newGroupName, setNewGroupName] = useState(groupName)
  const [search, setSearch] = useState('')

  const defaultSelectedPrinter = new Set(printers)

  const [selectedChips, setSelectedChips] = useState(defaultSelectedPrinter)

  const prevProp = usePrevious({ isCreatingGroup, isUpdatingGroup })

  useEffect(() => {
    //@ts-ignore
    if (
      (prevProp && prevProp.isUpdatingGroup && !isUpdatingGroup) ||
      (prevProp && prevProp.isCreatingGroup && !isCreatingGroup)
    ) {
      // process here
      dispatch(hidePrinterGroupForm())
    }
  }, [isCreatingGroup, isUpdatingGroup, dispatch, prevProp])

  const toggleChipSelection = (value: string) => {
    const newSet = selectedChips
    newSet.has(value) ? newSet.delete(value) : newSet.add(value)
    setSelectedChips(new Set(newSet))
  }

  const onCloseCallback = () => dispatch(hidePrinterGroupForm())

  function saveOption() {
    const arrOptions = Array.from(selectedChips)
    if (!isCreateGroup) {
      dispatch(
        updateGroup(
          groupName,
          arrOptions,
          newGroupName === groupName ? undefined : newGroupName
        )
      )
    } else {
      dispatch(createGroup(newGroupName, arrOptions))
    }
  }

  return (
    <StyledPrinterGroupPopup
      title={isCreateGroup ? 'Create a new group' : `${groupName} printers`}
      onClose={onCloseCallback}
      visible={visible}
      width={'500px'}
      className="printer-group-popup"
    >
      <DelayedComponents
        isMounted={Boolean(isCreatingGroup || isUpdatingGroup)}
      >
        <Loader
          visible={isCreatingGroup || isUpdatingGroup}
          text={`${isCreatingGroup ? 'Creating group' : 'Updating group'} ...`}
        />
      </DelayedComponents>
      <CustomInputTextContainer>
        <label>Group Name:</label>
        <input
          className="group-name-input"
          type="text"
          value={newGroupName}
          onChange={e => setNewGroupName(e.currentTarget.value)}
        />
      </CustomInputTextContainer>
      <SearchInput
        placeholder={`Search printers...`}
        value={search}
        onChange={e => setSearch(e.currentTarget.value)}
      />
      <span className="separator-line" />
      <div className="chips-items">
        {(availablePrinters.length > 0 &&
          availablePrinters.map(({ label, value }) => {
            if (label.includes(search) || selectedChips.has(value)) {
              return (
                <span
                  key={value}
                  className={`chip-item${
                    selectedChips.has(value) ? ' active' : ''
                  }`}
                  onClick={() => toggleChipSelection(value)}
                >
                  {value}
                </span>
              )
            } else return null
          })) ||
          null}
      </div>
      <div className="action-buttons">
        <StyledSmallButton
          onClick={() => setSelectedChips(new Set([]))}
          label={'Clear All'}
          className="secondary"
        />
        <StyledSmallButton
          onClick={() =>
            setSelectedChips(
              new Set(availablePrinters.map(option => option.value))
            )
          }
          label={'Select All'}
          className="secondary"
        />
        <StyledSmallButton
          onClick={saveOption}
          label={'Save'}
          active
          disabled={newGroupName === ''}
        />
      </div>
    </StyledPrinterGroupPopup>
  )
}
