import React, { useState } from 'react'
import styled from 'styled-components'
import { Input, Button, Loader, DelayedComponents } from 'backpack'
import { useSelector, useDispatch } from 'react-redux'

import { TableActions, TableRow, TableCell } from '../../../components/Table'
import { THeader, JobListTable, LoadingRow } from '../../Jobs/components'
import { ISubUser } from '../../../redux/team/types'
import { IPrinterState, IPrinter } from '../../../redux/printer/types'
import Title from '../../../components/Title'
import { SearchInput } from '../../../components/SearchInput'
import { getPrintersNoInGroups } from '../../Printers/PrinterGroups'
import { showPrinterGroupForm } from '../../../redux/printerGroupForm/slice'
import { showUserGroupForm } from '../../../redux/editUserGroupForm/slice'

const UsersTable = styled(JobListTable)`
  width: inherit;
  overflow-x: auto;
  overflow: visible;
  max-height: 100%;
  flex: 1;
  transition: all 0.25s ease;
  .status {
    opacity: 0.8;
    color: var(--color-text-primary);
    text-transform: uppercase;
  }
  .status.enabled {
    color: #20f562;
  }
  .status.disabled {
    color: hsl(0deg 0% 100% / 60%);
  }
  tbody td:nth-child(4) {
    opacity: 1;
  }
  .loading-row {
    height: 56px;
  }
  .user-actions .filter-options > * {
    text-align: center;
    font-weight: bold;
  }
  .user-actions .filter-options > *:last-child {
    color: var(--p-color-red);
  }
  @media (max-width: 480px) {
    thead th:nth-child(2),
    thead th:nth-child(3),
    tbody td:nth-child(2),
    tbody td:nth-child(3) {
      display: table-cell;
    }
  }
`

interface IUsersList extends React.HTMLAttributes<HTMLDivElement> {
  team?: ISubUser[]
  isGettingTeam?: boolean
  onEnableDisable: (email: string, blocked: boolean) => void
  onDelete: (email: string, name: string) => void
}

export function UsersList({
  team,
  isGettingTeam,
  onEnableDisable,
  onDelete,
  ...rest
}: IUsersList) {
  const dispatch = useDispatch()

  const processing =
    isGettingTeam ||
    (team &&
      team.find(
        ({ isDeletingUser, isEnableDisableInProgress }) =>
          isDeletingUser || isEnableDisableInProgress
      ))

  function editGroups(id: string) {
    dispatch(showUserGroupForm({ userId: id }))
  }

  return (
    <UsersTable {...rest}>
      <div data-tut="users-tab-list" className="desktop-view overflow-visible">
        <table>
          <thead>
            <tr>
              <THeader label="Name" />
              <THeader label="Email" />
              <THeader label="Groups" />
              <THeader label="Status" />
              <THeader label="" width={60} />
            </tr>
          </thead>
          <tbody>
            {!isGettingTeam && team && team.length === 0 ? (
              <tr>
                <td colSpan={4} className="no-jobs">
                  No users found
                </td>
              </tr>
            ) : null}
            {/* {fetching && getSkeletonLoader()} */}
            {processing && (
              <React.Fragment>
                <LoadingRow colSpan={5} />
                <LoadingRow colSpan={5} />
                <LoadingRow colSpan={5} />
              </React.Fragment>
            )}

            {!processing &&
              team &&
              team.map(
                (
                  { name, email, blocked, allPrinters, groups, id }: ISubUser,
                  index
                ) => {
                  const actions = [
                    {
                      text: !allPrinters ? 'Edit groups' : 'Assign groups',
                      callback: () => editGroups(id)
                    },
                    {
                      text: blocked ? 'Enable' : 'Disable',
                      callback: () => onEnableDisable(email, blocked)
                    },
                    {
                      text: 'Remove',
                      callback: () => onDelete(email, name)
                    }
                  ]

                  return (
                    <React.Fragment key={email}>
                      <TableRow
                        data-tut={
                          index === 0 ? 'users-tab-list-item' : undefined
                        }
                      >
                        <TableCell>{name || ' - '}</TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell
                          title={allPrinters ? 'All' : groups!.join(',')}
                        >
                          {allPrinters ? 'All' : `${groups!.length} groups`}
                        </TableCell>
                        <TableCell
                          className={`status ${
                            blocked ? 'disabled' : 'enabled'
                          }`}
                          data-tut={
                            index === 0
                              ? 'users-tab-list-item-status'
                              : undefined
                          }
                        >
                          {blocked ? 'Disabled' : 'Enabled'}
                        </TableCell>
                        <TableCell
                          className="action-column"
                          data-tut={
                            index === 0
                              ? 'users-tab-list-item-status-change'
                              : undefined
                          }
                        >
                          <TableActions
                            className="user-actions"
                            actions={actions}
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                }
              )}
          </tbody>
        </table>
      </div>
    </UsersTable>
  )
}

const CreateUserFormContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: none;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - 40px);
  max-width: 1000px;
  padding: 20px;
  top: var(--large);
  box-sizing: border-box;
  border: 1px solid var(--color-border-primary-hovered);
  background: var(--color-bg-canvas-inset);
  form,
  .title {
    transition: all 0.3s ease;
    transform: translateX(10%);
    opacity: 0;
    width: 100%;
    margin-right: auto;
  }
  &.visible {
    pointer-events: auto;
    opacity: 1;
  }
  &.visible form,
  &.visible .title {
    transform: translateX(0);
    opacity: 1;
  }
  .button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .button-row > *:last-child {
    margin-left: 20px;
  }
  .field.errored input {
    border: 1px solid var(--p-color-red);
    opacity: 0.8;
  }
  .error {
    font-size: var(--medium);
    margin-bottom: 10px;
    color: var(--p-color-red);
    display: block;
  }
  @media (max-width: 480px) {
    .button-row {
      flex-direction: column-reverse;
    }
    .button-row > *:last-child {
      margin: 20px 0;
    }
  }
`

const StyledForm = styled.form``

const PrintersAccess = styled.div`
  width: 100%;
  user-select: none;
  label {
    margin-bottom: 15px;
    display: block;
  }
  .chips-items {
    display: block;
    background: var(--color-bg-primary-dark);
    border: 1px solid var(--color-border-secondary);
    border-radius: 10px;
    padding: 5px;
    justify-content: flex-start;
    max-height: 350px;
    min-height: 200px;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
  }
  .chip-item {
    border-radius: 100px;
    padding: 5px 10px;
    margin: 4px;
    box-sizing: border-box;
    opacity: 0.6;
    font-size: var(--medium);
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s ease;
    background: black;
    color: var(--color-text-primary);
    border: 1px solid var(--p-color-1);
  }
  .chip-item.active {
    background: var(--p-color-1);
    color: black;
  }
  .chip-item:hover {
    opacity: 1;
    transform: scale(1.05);
  }
  .create-group {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }
  .create-group button {
    background: var(--color-brand-fill-primary);
    padding: 15px;
    border-radius: 55px;
    font-weight: bold;
    font-family: 'D-DIN';
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;
    margin: 15px 0;
  }
  .create-group button:hover {
    opacity: 0.8;
  }
  .create-group button:active {
    opacity: 1;
    transform: scale(0.9);
  }
`

const StyledPrinterBlock = styled.div`
  width: 130px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-bg-canvas);
  border: 1px solid var(--color-border-primary-hovered);
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: var(--x-large);
  border-radius: 15px;
  margin: 15px;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s ease;
  &:hover {
    color: var(--color-brand-text-primary);
    border: 1px solid var(--color-brand-fill-primary);
    opacity: 1;
  }
  &.active {
    color: var(--color-brand-fill-primary);
    opacity: 1;
    border: 1px solid var(--color-brand-fill-primary);
  }
`

const PrinterAccessChoicesContainer = styled.div`
  display: flex;
  justify-content: center;
`

interface IPrinterAccessChoices {
  value: string
  onSelectAll: () => void
  onSelectGroup: () => void
}

function PrinterAccessChoices({
  value,
  onSelectAll,
  onSelectGroup
}: IPrinterAccessChoices) {
  return (
    <PrinterAccessChoicesContainer>
      <StyledPrinterBlock
        className={value === 'all' ? 'active' : ''}
        onClick={onSelectAll}
      >
        All
      </StyledPrinterBlock>
      <StyledPrinterBlock
        className={value === 'groups' ? 'active' : ''}
        onClick={onSelectGroup}
      >
        Groups
      </StyledPrinterBlock>
    </PrinterAccessChoicesContainer>
  )
}

interface IPrinterAccessFields {
  selectedGroups: Set<string>
  accessType: string
  onSelectAccessType: (type: string) => void
  onSelectGroups: (groups: Set<string>) => void
}

export function PrinterAccessFields({
  selectedGroups,
  accessType,
  onSelectAccessType,
  onSelectGroups
}: IPrinterAccessFields) {
  const dispatch = useDispatch()

  const { printers } = useSelector(
    ({ printerData }: { printerData: IPrinterState }) => printerData
  )

  const [searchText, setSearchText] = useState('')

  const toggleChipSelection = (value: string) => {
    const newSet = selectedGroups
    newSet.has(value) ? newSet.delete(value) : newSet.add(value)
    onSelectGroups(new Set(newSet))
  }

  // Get Printer groups from printers
  const getPrinterGroups = () => {
    // Create mapping to reduce number of iterations required to create groups with populated printers
    const printerGroupsObject = {}
    const printerInfos = {}
    for (let i = 0; i < printers.length; i++) {
      printerInfos[printers[i].name] = printers[i]
      if (printers[i].groups !== undefined && printers[i].groups) {
        printers[i].groups!.forEach(groupName => {
          // group doesn't exist in object
          if (!printerGroupsObject[groupName]) {
            printerGroupsObject[groupName] = [printers[i].name]
          } else {
            printerGroupsObject[groupName] = [
              ...printerGroupsObject[groupName],
              printers[i].name
            ]
          }
        })
      }
    }

    const groupPrintersInfo = [] as {
      name: string
      printers: IPrinter[]
    }[]
    Object.keys(printerGroupsObject).forEach(key => {
      groupPrintersInfo.push({
        name: key,
        printers: printerGroupsObject[key]!.map(name => printerInfos[name])
      })
    })

    return groupPrintersInfo
  }

  const getAllPrinterNames = () =>
    printers.map(p => ({
      label: p.customPrinterName || p.name,
      value: p.name
    }))
  const printerNames = printers ? getAllPrinterNames() : []
  const printerGroups = printers ? getPrinterGroups() : []

  // //@ts-ignore
  const availablePrinters = getPrintersNoInGroups(printerGroups, printerNames)

  function selectAll() {
    onSelectAccessType('all')
  }

  const selectGroups = () => onSelectAccessType('groups')

  const createGroup = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(
      showPrinterGroupForm({
        groupName: '',
        printers: [],
        availablePrinters: availablePrinters,
        isCreateGroup: true
      })
    )
  }
  return (
    <PrintersAccess>
      <label>Accessible Groups:</label>
      <PrinterAccessChoices
        value={accessType}
        onSelectAll={selectAll}
        onSelectGroup={selectGroups}
      />
      {accessType === 'groups' ? (
        <div className="group-choices">
          <div className="create-group">
            <button onClick={createGroup}>Create a new group</button>
          </div>
          <SearchInput
            placeholder={`Search groups...`}
            value={searchText}
            onChange={e => setSearchText(e.currentTarget.value)}
          />
          <span className="separator-line" />
          <div className="chips-items">
            {(printerGroups.length > 0 &&
              printerGroups
                .map(a => ({ label: a.name, value: a.name }))
                .map(({ label, value }) => {
                  if (label.includes(searchText) || selectedGroups.has(value)) {
                    return (
                      <span
                        key={value}
                        className={`chip-item${
                          selectedGroups.has(value) ? ' active' : ''
                        }`}
                        onClick={() => toggleChipSelection(value)}
                      >
                        {value}
                      </span>
                    )
                  } else return null
                })) ||
              null}
          </div>
        </div>
      ) : null}
    </PrintersAccess>
  )
}

interface StyledInputProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string
  disabled?: boolean
  type?: string
  value?: string
  icon?: React.ReactNode
  errored?: boolean
}

const StyledInput = styled(
  ({ className, errored, ...rest }: StyledInputProps) => (
    <Input className={`${className}${errored ? ' errored' : ''}`} {...rest} />
  )
)`
  &.errored input {
    border: 1px solid var(--p-color-red);
    opacity: 0.8;
  }
  &.errored .input-line {
    opacity: 0 !important;
  }
`

interface ICreateUserForm {
  visible?: boolean
  submitting?: boolean
  onSubmit: (
    firstName: string,
    lastTime: string,
    email: string,
    groups?: string[],
    allPrinters?: boolean
  ) => void
  onCancel: () => void
}

export function CreateUserForm({
  visible,
  submitting = false,
  onSubmit,
  onCancel
}: ICreateUserForm) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [errored, setErrored] = useState<string | boolean>(false)

  function clearState() {
    setFirstName('')
    setLastName('')
    setEmail('')
  }

  function ValidateEmail(mail: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true
    }
    return false
  }

  // Printer access
  const [accessType, setAccessType] = useState('all')
  const defaultSelectedGroups = new Set([]) as Set<string>
  const [selectedGroups, setSelectedGroups] = useState(defaultSelectedGroups)

  function submit(e) {
    e.preventDefault()
    if (firstName === '' || lastName === '' || email === '') {
      setErrored('Please make sure all fields are filled correctly.')
    } else if (!ValidateEmail(email)) {
      setErrored('Invalid email format!')
    } else {
      setErrored(false)

      const accessibleGroups = Array.from(selectedGroups)
      const allPrintersAccessible = accessType === 'all'
      onSubmit(
        firstName,
        lastName,
        email,
        accessibleGroups,
        allPrintersAccessible
      )
      clearState()
    }
  }

  return (
    <CreateUserFormContainer className={visible ? 'visible' : ''}>
      <DelayedComponents isMounted={Boolean(submitting)}>
        <Loader visible={submitting} text={'Adding new team member ...'} />
      </DelayedComponents>
      <Title>Add new team member</Title>
      <StyledForm onSubmit={submit} data-tut="users-tab-add-user-form">
        {errored && <span className="error">{errored}</span>}
        <StyledInput
          label="First Name"
          value={firstName}
          onChange={e => setFirstName(e.currentTarget.value)}
          errored={Boolean(errored && firstName === '')}
        />
        <StyledInput
          label="Last Name"
          value={lastName}
          onChange={e => setLastName(e.currentTarget.value)}
          errored={Boolean(errored && lastName === '')}
        />
        <StyledInput
          label="Email Address"
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
          errored={Boolean(errored && (email === '' || !ValidateEmail(email)))}
        />
        <PrinterAccessFields
          selectedGroups={selectedGroups}
          onSelectGroups={setSelectedGroups}
          onSelectAccessType={setAccessType}
          accessType={accessType}
        />
        <div className="button-row">
          <Button
            className="cancel-btn"
            label="Cancel"
            secondary
            disabled={submitting}
            onClick={onCancel}
          />
          <Button type="submit" label="Add new account" disabled={submitting} />
        </div>
      </StyledForm>
    </CreateUserFormContainer>
  )
}
