import { parseJsonResponse } from '../../utils/post-fetch'
import { getFetchOpts } from '../../utils/pre-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function getLatestVersion() {
  return fetch('/version.json').then(parseJsonResponse)
}

export function getAppSettings(token: string) {
  const payload = getFetchOpts('get', {}, token)
  return fetch(`${DROPLET_API_URL}/mystructo/customer/settings`, payload).then(
    parseJsonResponse
  )
}
