import isEqual from 'lodash.isequal'
import createReducer from '../../utils/create-reducer'
import {
  JOBS_GET_LIST_PENDING,
  JOBS_GET_LIST_SUCCESS,
  JOBS_GET_LIST_FAILED,
  PRINT_FEEDBACK_PENDING,
  PRINT_FEEDBACK_SUCCESS,
  PRINT_FEEDBACK_FAILED,
  EXPORT_JOBS,
  EXPORT_JOBS_SUCCESS,
  EXPORT_JOBS_FAILURE,
  ADD_NEW_JOB,
  ADD_NEW_JOB_SUCCESS,
  ADD_NEW_JOB_FAILURE
} from './constant'

import { IPastPrintFilter, IPastPrintsState, IPrintFeedback } from './types'

import { IPrintLog } from '../printer/types'
import { PRINTER_CHANGE_PRINTER_NAME_SUCCESS } from '../printer/constant'

export const initialStates: IPastPrintsState = {
  errorMessage: undefined,
  data: [],
  totalCount: 0,
  fetchingPastPrints: false,
  filter: undefined,
  hasFiltersChanged: false,
  feedbackInProgress: false,
  exportJobsInProgress: false,
  addNewJobInProgress: false
}

export default createReducer(initialStates, {
  [JOBS_GET_LIST_PENDING]: (
    state: IPastPrintsState,
    { filter }: { filter: IPastPrintFilter }
  ) => {
    const areFiltersEqual = isEqual(state.filter || {}, filter)
    const hasPrinterNameFilter = !!filter.printers
    return {
      ...state,
      fetchingPastPrints: hasPrinterNameFilter || !areFiltersEqual,
      hasFiltersChanged: hasPrinterNameFilter || !areFiltersEqual,
      filter
    }
  },

  [JOBS_GET_LIST_SUCCESS]: (
    state: IPastPrintsState,
    { pastPrints, totalCount }: { pastPrints: IPrintLog[]; totalCount: number }
  ) => ({
    ...state,
    data: [...pastPrints],
    totalCount,
    fetchingPastPrints: false
  }),

  [JOBS_GET_LIST_FAILED]: (
    state: IPastPrintsState,
    { message }: { message: string }
  ) => ({
    ...state,
    fetchingPastPrints: false,
    errorMessage: message
  }),

  [PRINT_FEEDBACK_PENDING]: (state: IPastPrintsState) => ({
    ...state,
    feedbackInProgress: true
  }),

  [PRINT_FEEDBACK_SUCCESS]: (
    state: IPastPrintsState,
    { jobId, feedback }: { jobId: string; feedback: IPrintFeedback }
  ) => ({
    ...state,
    feedbackInProgress: false,
    data: state.data.map(d => {
      if (d.jobId === jobId) {
        return { ...d, printFeedback: feedback }
      } else {
        return d
      }
    })
  }),

  [PRINT_FEEDBACK_FAILED]: (state: IPastPrintsState) => ({
    ...state,
    feedbackInProgress: false
  }),

  // Update the current printer logs when printer name changes
  [PRINTER_CHANGE_PRINTER_NAME_SUCCESS]: (
    state: IPastPrintsState,
    { printerName, customName }: { printerName: string; customName: string }
  ) => ({
    ...state,
    feedbackInProgress: false,
    data: state.data.map(d => {
      if (d.printer && d.printer === printerName) {
        return { ...d, customPrinterName: customName }
      } else {
        return d
      }
    })
  }),

  [EXPORT_JOBS]: (state: IPastPrintsState) => ({
    ...state,
    exportJobsInProgress: true
  }),

  [EXPORT_JOBS_SUCCESS]: (state: IPastPrintsState) => ({
    ...state,
    exportJobsInProgress: false
  }),

  [EXPORT_JOBS_FAILURE]: (state: IPastPrintsState) => ({
    ...state,
    exportJobsInProgress: false
  }),

  [ADD_NEW_JOB]: (state: IPastPrintsState) => ({
    ...state,
    addNewJobInProgress: true
  }),

  [ADD_NEW_JOB_SUCCESS]: (state: IPastPrintsState) => ({
    ...state,
    addNewJobInProgress: false
  }),

  [ADD_NEW_JOB_FAILURE]: (state: IPastPrintsState) => ({
    ...state,
    addNewJobInProgress: false
  })
})
