import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IPopupAppState,
  IAddPopupDialogAction,
  IAddPopupAction,
  IPopup,
  IDismissPopupAction
} from './types'

export const initialState: IPopupAppState = {
  popupDialog: undefined,
  popups: []
}

export const userPreferenceSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    addPopupDialog: (state, action: PayloadAction<IAddPopupDialogAction>) => {
      state.popupDialog = action.payload
    },
    dismissPopupDialog: (state, action: PayloadAction) => {
      state.popupDialog = undefined
    },
    addPopup: (state, action: PayloadAction<IAddPopupAction>) => {
      state.popups = [
        ...state.popups,
        {
          ...action.payload,
          id: action.payload.id
        }
      ]
    },
    dismissPopup: (state, action: PayloadAction<IDismissPopupAction>) => {
      state.popups = state.popups.reduce((acc: IPopup[], curr) => {
        if (curr.id !== action.payload.id) acc.push(curr)
        return acc
      }, [])
    }
  }
})

export const {
  addPopupDialog,
  dismissPopupDialog,
  addPopup,
  dismissPopup
} = userPreferenceSlice.actions

export default userPreferenceSlice.reducer
