import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteIcon, EditIcon } from 'backpack'

import { PrinterListItem, IPrinterListItem } from './PrinterList'
import { showPrinterGroupForm } from '../../redux/printerGroupForm/slice'
import { addPopupDialog } from '../../redux/popup/slice'
import { deleteGroup } from '../../redux/printer/actions'
import { IAuthState } from '../../redux/auth/types'

const PrinterGroupsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
`

const PrinterGroupContainer = styled(({ className, ...rest }) => (
  <div className={`${className} printer-group`} {...rest} />
))`
  max-width: 100%;
  display: grid;
  flex-direction: row;
  justify-content: flex-start;
  padding: 80px 15px 15px 15px;
  background: var(--color-bg-canvas);
  border-radius: 10px;
  grid-gap: 20px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #fff;
  grid-template-columns: repeat(auto-fit, minmax(130px, 185px));
  place-content: center;
  margin: 15px;
  flex: 1;
  .group-name {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%);
    color: var(--color-brand-text-primary);
    font-weight: bold;
    font-size: var(--large);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
  }
  @media (max-width: 480px) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    margin: 15px 0;
    padding: 80px 8px 8px 8px;
    grid-gap: 10px;
  }
`

const GroupDropdownButton = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 70px;
  height: 70px;
  transition: all 0.2s ease;
  z-index: 1;
  user-select: none;
  svg {
    fill: #fff;
    height: 40px;
    width: auto;
  }
  .dropdown-menu-button {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    background: transparent;
    border-radius: 100%;
  }
  .dropdown-menu-button:hover {
    background: hsl(0deg 0% 15% / 50%);
  }
  .dropdown-menu-button:active {
    transform: scale(0.9);
    background: var(--color-brand-text-primary) !important;
  }
  .dropdown-list {
    opacity: 0;
    position: absolute;
    top: calc(100% + -20px);
    right: 20px;
    width: 150px;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    box-shadow: 0px 1px 6px 2px hsl(0deg 0% 0% / 40%);
    border-radius: 10px;
    border: 1px solid var(--color-border-secondary);
  }
  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background: var(--color-bg-canvas);
    color: var(--color-text-primary);
    cursor: pointer;
    font-weight: bold;
    font-size: var(--medium);
    height: 50px;
    padding: 12px;
    justify-content: flex-start;
    gap: 14px;
  }
  .dropdown-button svg {
    height: 80%;
    width: auto;
  }
  .dropdown-button.edit svg {
    stroke: var(--color-text-primary);
  }
  .dropdown-button.delete {
    color: hsl(0, 82%, 58%);
    background: hsl(0deg 82% 4%);
  }
  .dropdown-button.delete path {
    fill: hsl(0, 82%, 58%);
  }
  .dropdown-list > *:first-child {
    border-radius: 10px 10px 0 0;
  }
  .dropdown-list > *:last-child {
    border-radius: 0 0 10px 10px;
  }
  .dropdown-button:hover {
    background: var(--color-bg-canvas-inset);
  }
  .dropdown-button:active {
    filter: brightness(1.5);
  }
  .dropdown-button.delete:hover {
    background: hsl(0deg 82% 9%);
  }
  &.dropdown-visible .dropdown-list {
    pointer-events: auto;
    opacity: 1;
  }
`

interface IPrinterGroup extends React.HTMLAttributes<HTMLDivElement> {
  groupInfo: PrinterGroupInfo
  columns: number
  searchText: string
  onPrinterClick: (printerName: string) => void
  allPrintersNotInGroups: {
    label: string
    value: string
  }[]
}

interface EditPopupProps {
  options: {
    label: string
    value: string
  }[]
  selectedOptions: string[]
}

function PrinterGroup({
  groupInfo,
  onPrinterClick,
  columns,
  searchText,
  allPrintersNotInGroups
}: IPrinterGroup) {
  const node = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const { user } = useSelector(({ auth }: { auth: IAuthState }) => auth)

  const [dropdownVisible, toggleDropdownVisible] = useState(false)

  const { printers, name } = groupInfo

  const handleClick = e => {
    //@ts-ignore
    if (node !== null && node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    toggleDropdownVisible(false)
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  function getOptions() {
    const selectedOptions = groupInfo.printers.map(p => ({
      label: p.customPrinterName || p.name,
      value: p.name
    }))
    const availableOptions = [...allPrintersNotInGroups, ...selectedOptions]

    return {
      selectedOptions: selectedOptions.map(o => o.value),
      availableOptions
    }
  }

  function onDelete() {
    dispatch(
      addPopupDialog({
        title: `Delete printer group ${groupInfo.name}?`,
        message: `Are you sure you want to delete ${groupInfo.name} from the printer group list?`,
        onYesCallback: () => dispatch(deleteGroup(groupInfo.name)),
        yesText: `Yes, delete ${groupInfo.name}`,
        yesButtonColor: 'var(--color-red-primary)'
      })
    )
    toggleDropdownVisible(false)
  }

  function onEdit() {
    const { availableOptions, selectedOptions } = getOptions()
    dispatch(
      showPrinterGroupForm({
        groupName: groupInfo.name,
        printers: selectedOptions,
        availablePrinters: availableOptions
      })
    )
    toggleDropdownVisible(false)
  }

  return (
    <PrinterGroupContainer style={{ width: `${columns * 165}px` }}>
      {(user && user.isMainUser && (
        <GroupDropdownButton
          ref={node}
          className={`${dropdownVisible ? 'dropdown-visible' : ''}`}
        >
          <div
            className="dropdown-menu-button"
            onClick={() => toggleDropdownVisible(!dropdownVisible)}
          >
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
          </div>
          <div className={`dropdown-list`}>
            <div className="dropdown-button edit" onClick={onEdit}>
              <EditIcon />
              Edit Group
            </div>
            <div className="dropdown-button delete" onClick={onDelete}>
              <DeleteIcon />
              Delete Group
            </div>
          </div>
        </GroupDropdownButton>
      )) ||
        null}
      <div className="group-name">{name}</div>
      {(printers.length > 0 &&
        printers.map(p => {
          return (
            <PrinterListItem
              key={p.name}
              onClick={() => onPrinterClick(p.name!)}
              searchText={searchText}
              {...p}
            />
          )
        })) ||
        null}
    </PrinterGroupContainer>
  )
}

interface PrinterGroupInfo {
  name: string
  printers: IPrinterListItem[]
}

interface IPrinterGroups {
  groups: PrinterGroupInfo[]
  hidden?: boolean
  columns: number
  searchText: string
  onPrinterClick: (printerName: string) => void
  allPrinterNames: {
    label: string
    value: string
  }[]
}

export function getPrintersNoInGroups(
  groups: PrinterGroupInfo[],
  allPrinterNames: {
    label: string
    value: string
  }[]
) {
  // printer.jobs.reduce((acc: IJob[], curr) => {
  //   if (curr.id !== jobId) acc.push(curr)
  //   return acc
  // }, [])
  const allPrintersInGroupsNameMap = {}
  groups.forEach(g => {
    for (let i = 0; i < g.printers.length; i++) {
      if (g.printers[i].name)
        allPrintersInGroupsNameMap[String(g.printers[i].name)] = true
    }
  })
  const allPrintersNotInGroups = allPrinterNames.filter(
    p => !allPrintersInGroupsNameMap[p.value]
  )

  return allPrintersNotInGroups
}

export function PrinterGroups({
  allPrinterNames,
  groups,
  columns,
  searchText,
  onPrinterClick
}: IPrinterGroups) {
  const allPrintersNotInGroups = getPrintersNoInGroups(groups, allPrinterNames)

  return (
    <PrinterGroupsContainer>
      {(groups.length > 0 &&
        groups.map(g => (
          <PrinterGroup
            key={g.name}
            columns={columns}
            searchText={searchText}
            groupInfo={g}
            onPrinterClick={onPrinterClick}
            allPrintersNotInGroups={allPrintersNotInGroups}
          />
        ))) ||
        null}
    </PrinterGroupsContainer>
  )
}
