import React from 'react'
import styled from 'styled-components'
import { Popup } from 'backpack'

export const StartJobForm = styled.form`
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 8px;
    font-size: var(--medium);
    color: var(--color-label-text);
  }
  .show-advance-option {
    font-family: 'D-DIN';
    font-weight: bold;
    margin: 8px auto;
    cursor: pointer;
    color: var(--color-brand-fill-primary);
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  .show-advance-option:hover {
    opacity: 1;
  }
  .show-advance-option:active {
    opacity: 0.7;
    transform: translateY(2px);
  }
  @media (max-width: 480px) {
    min-height: unset;
  }
`

export const StyledStartJobPopup = styled(({ children, ...rest }) => (
  <Popup children={children} {...rest} />
))`
  z-index: 99999999;
`

export const JobsQueueContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .jobs-queue-table {
    display: flex;
    flex-direction: column;
    background: var(--color-bg-canvas-inset);
    border-radius: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--color-border-primary);
  }
  .jobs-queue-table .grid-table {
    display: grid;
    grid-template-columns: 1fr 120px;
    box-sizing: border-box;
    height: 45px;
    place-content: center;
    padding: 0 15px;
  }
  .jobs-queue-table .content {
    overflow: auto;
    display: grid;
    flex-direction: column;
    max-height: calc(45px * 4);
    box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 15%);
    border-radius: 0 0 10px 10px;
  }
  .jobs-queue-table .content > *:last-child:not(:first-child) {
    border-radius: 0 0 10px 10px;
  }
  .jobs-queue-table .grid-table > *:last-child {
    text-align: right;
    text-transform: capitalize;
  }
  .jobs-queue-table .grid-table.header {
    background: black;
    border-radius: 10px 10px 0 0;
    color: #fff;
  }
  .jobs-queue-table > .header > * {
    font-weight: bold;
  }
  .jobs-queue-table .grid-table.next {
    color: var(--color-brand-fill-primary);
    background: var(--color-bg-canvas);
    border-left: 6px solid var(--color-brand-fill-primary);
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  .no-jobs {
    height: 50px;
    display: flex;
    place-items: center;
    place-content: center;
  }
`
