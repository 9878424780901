import React, { Component } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Loader } from 'backpack'

interface Props extends RouteProps {
  isUserLoggedIn: boolean
}

class PrivateRoute extends Component<Props> {
  render() {
    const { isUserLoggedIn, ...rest } = this.props

    if (!isUserLoggedIn) {
      return <Loader text="Validating user..." visible />
    }
    return <Route {...rest} />
  }
}

export default PrivateRoute
