import { AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILED } from './redux/auth/constants'
import { JOBS_GET_LIST_PENDING } from './redux/past-prints/constant'
import {
  PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS,
  PRINTER_UPLOAD_JOB_FAILED,
  PRINTER_UPLOAD_JOB_SUCCESS
} from './redux/printer/constant'

interface IEventTrackingAction {
  category: string
  eventAction: string
  label: string
  eventDataFromPayload?: string
  eventDataFromState?: string
}

interface IEvents {
  [name: string]: IEventTrackingAction
}

const events: IEvents = {
  [AUTH_LOGIN_SUCCESS]: {
    category: 'auth',
    eventAction: 'login',
    label: 'username #1',
    eventDataFromPayload: 'username',
    eventDataFromState: ''
  },
  [AUTH_LOGIN_FAILED]: {
    category: 'auth',
    eventAction: 'auth login failed',
    label: 'username #1',
    eventDataFromPayload: 'username',
    eventDataFromState: ''
  },
  PRINTER_SEARCH: {
    category: 'printer search',
    eventAction: 'search',
    label: 'searchText #1'
  },
  [PRINTER_UPLOAD_JOB_SUCCESS]: {
    category: 'upload file',
    eventAction: 'upload file success',
    label: 'printer #1',
    eventDataFromPayload: 'printerName',
    eventDataFromState: ''
  },
  [PRINTER_UPLOAD_JOB_FAILED]: {
    category: 'upload file',
    eventAction: 'upload file failed',
    label: 'printer #1',
    eventDataFromPayload: 'printerName',
    eventDataFromState: ''
  },
  [PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS]: {
    category: 'All queued jobs',
    eventAction: 'Get all queued jobs success',
    label: 'applied filter #1',
    eventDataFromPayload: 'filter',
    eventDataFromState: ''
  },
  [PRINTER_GET_ALL_QUEUED_JOBS_SUCCESS]: {
    category: 'All queued jobs',
    eventAction: 'Get all queued jobs success',
    label: 'applied filter #1',
    eventDataFromPayload: 'filter',
    eventDataFromState: ''
  },
  [JOBS_GET_LIST_PENDING]: {
    category: 'All past prints',
    eventAction: 'Get all past prints success',
    label: 'applied filter #1',
    eventDataFromPayload: 'filter',
    eventDataFromState: ''
  }
}

export default events
