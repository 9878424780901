import React from 'react'
import styled from 'styled-components'
import { DeleteIcon } from 'backpack'

const FileUploadContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  transition: all 0.3s ease;
  background: hsla(0, 0%, 0%, 0.1);
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-border-primary);
  box-sizing: border-box;
  opacity: 0.7;
  &.uneditable {
    pointer-events: none;
    opacity: 1;
  }
  .file-upload__label {
    flex: 1;
    padding: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: var(--large);
    background: var(--color-bg-primary-dark);
    border-radius: inherit;
  }
  .file-upload__label.has-file {
    opacity: 1;
    color: var(--color-brand-text-primary);
  }
  .file-upload__button {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    transition: all 0.3s ease;
    background: unset;
    opacity: 1;
    border-radius: 0 4px 4px 0;
    color: var(--color-text-primary);
  }
  &:hover .file-upload__button {
    color: var(--color-brand-fill-primary);
  }
  &:hover {
    opacity: 1;
    border-color: var(--color-brand-fill-primary);
  }
  .file-upload__button:hover {
    opacity: 1;
  }
  .file-upload__button:focus {
    outline: unset;
  }
  @media (max-width: 600px) {
    font-size: var(--medium);
    min-height: 40px;
    .file-upload__button {
      padding: 0 10px;
    }
  }
`

const HiddenFileInput = styled.input`
  display: none;
`

const FileListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--small);
  .file-list {
    display: flex;
    flex-direction: column;
    max-height: 130px;
    overflow: auto;
    padding: var(--small);
    box-sizing: border-box;
    background: var(--color-bg-canvas);
    width: 100%;
    line-height: 1.3;
    border-radius: 10px;
  }
  .file-list > *:last-child,
  .file-list > *:first-child {
    margin: 0;
  }
  .clear-files {
    color: var(--p-color-red);
    padding: 4px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s ease;
    margin: 8px;
  }
  .clear-files:hover {
    opacity: 1;
  }
`

interface IFileListItem extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  onDelete?: () => void
  errored?: boolean
}

const FileListItem = styled(
  ({ className, name, onDelete, errored, ...rest }: IFileListItem) => (
    <div className={className} {...rest}>
      <div className="name">{name}</div>
      {onDelete && (
        <div className="delete" onClick={onDelete}>
          <DeleteIcon />
        </div>
      )}
    </div>
  )
)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 4px 0;
  color: var(--color-brand-fill-primary);
  .delete {
    transition: opacity 0.2s ease;
    opacity: 0.7;
    cursor: pointer;
  }
  .delete:hover {
    opacity: 1;
  }
`

interface FileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  extensions?: string[]
  name?: string
  onFileSelected: (files: FileList | null) => void
  files?: FileList | null
  uneditableValue?: string
  multiple?: boolean
  maxFiles?: number
}

interface FileUploadState {
  files: FileList | null
}

class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
  constructor(props: FileUploadProps) {
    super(props)
    this.state = {
      files: null
    }
  }
  private inputFile = React.createRef<HTMLInputElement>()

  triggerFileUpload = () => {
    const node = this.inputFile.current
    if (node) {
      const clickEvent = new MouseEvent('click', {
        bubbles: false,
        cancelable: true
      })
      node.dispatchEvent(clickEvent)
    }
  }
  handleFileSelected = (files: FileList | null) => {
    this.setState({ files }, () => {
      this.props.onFileSelected(this.state.files)
    })
  }
  getAllowedExtensions = () => {
    const { extensions } = this.props
    if (!extensions || (extensions && extensions!.length === 0))
      return undefined
    const allowedExtensions = extensions!.reduce(
      (acc: string, curr: string, index) => {
        acc += `${index !== 0 ? ',' : ''}.${curr}`
        return acc
      },
      ''
    )
    return allowedExtensions
  }
  // remove single file doesn't work for FileList because it's readonly, it will wipe out all
  removeFile = (fileName: string) => {
    const { files } = this.state
    if (files) {
      const newFileList = {}
      for (let i = 0; i < files.length; i++) {
        if (files[i].name !== fileName) {
          newFileList[Object.keys(newFileList).length] = files[i]
        }
      }
      newFileList['length'] = Object.keys(newFileList).length
      //@ts-ignore
      this.setState({ files: newFileList }, () => {
        //@ts-ignore
        this.props.onFileSelected(newFileList)
      })
    }
  }
  clearFiles = () => {
    this.setState({ files: null }, () => {
      this.props.onFileSelected(null)
    })
  }

  render() {
    const files = this.props.files || this.state.files
    const { label, name, className, uneditableValue } = this.props
    const hasNoFiles = files === null || files.length === 0

    let shownText: String | undefined = undefined
    if (uneditableValue) shownText = uneditableValue
    if (files && files.length === 1) shownText = files[0].name
    if (files && files.length > 1) shownText = `${files.length} files selected`

    return (
      <>
        <FileUploadContainer
          className={`${className}${uneditableValue ? ' uneditable' : ''}`}
          onClick={this.triggerFileUpload}
        >
          <HiddenFileInput
            ref={this.inputFile}
            className="file-upload__file"
            name={name || 'file-upload'}
            onChange={e => this.handleFileSelected(e.target.files)}
            type="file"
            tabIndex={-1}
            accept={this.getAllowedExtensions()}
            disabled={Boolean(uneditableValue)}
            multiple={this.props.multiple}
          />
          <div
            className={`file-upload__label ${
              !hasNoFiles || uneditableValue ? ' has-file' : ''
            }`}
          >
            {shownText || label || ''}
          </div>
          <button className="file-upload__button" type="button">
            Select a file
          </button>
        </FileUploadContainer>
        {files && files.length > 1 ? (
          <FileListContainer className="selected-file-list">
            <div className="file-list">
              {Object.keys(files).map(objIndex => {
                const fileProps = files[objIndex]
                if (typeof fileProps === 'number') return null
                return (
                  <FileListItem
                    key={`${objIndex}-${fileProps.name}`}
                    name={fileProps.name}
                    onDelete={() => this.removeFile(fileProps.name)}
                  />
                )
              })}
            </div>
            <div className="clear-files" onClick={this.clearFiles}>
              Clear All
            </div>
          </FileListContainer>
        ) : null}
      </>
    )
  }
}

export { FileUpload }
