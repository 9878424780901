import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Popup } from 'backpack'

import latestChangelog from '../../changelogs/2.7.0.md'

const StyledPopup = styled(({ children, ...rest }) => (
  <Popup children={children} {...rest} />
))`
  font-family: D-DIN;
  .popup__content {
    overflow: auto;
    max-height: 85vh;
  }
  a {
    color: var(--p-color-1);
  }
  strong {
    font-weight: bold;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: D-DIN;
  }
  h1 {
    line-height: 30px;
  }
  h2 {
    color: var(--p-color-1);
    margin-bottom: 12px;
    margin-top: 32px;
  }
  p {
    opacity: 0.7;
    letter-spacing: 0.2px;
    margin-bottom: 25px;
  }
  ol,
  ul {
    padding-left: 1.8em;
    margin: 0.5em 0 1em 0;
    list-style: square;
  }
  ul li {
    margin: 6px 0;
  }
  em {
    margin: 0 !important;
    margin-right: 2px !important;
    padding: 1px !important;
  }
`

interface IWhatsNewProps {
  isMounted?: boolean
  onHidePopup: () => void
}

export default function({ isMounted, onHidePopup }: IWhatsNewProps) {
  const [markDown, setMarkdown] = useState('')

  useEffect(() => {
    fetch(latestChangelog)
      .then(response => response.text())
      .then(text => {
        setMarkdown(text)
      })
  }, [])

  return (
    <StyledPopup
      title="WHAT'S NEW"
      visible={isMounted}
      width={'500px'}
      onClose={onHidePopup}
    >
      <ReactMarkdown children={markDown} />
    </StyledPopup>
  )
}
