import createReducer from '../../utils/create-reducer'
import {
  OVERVIEW_STATS_PENDING,
  OVERVIEW_STATS_SUCCESS,
  OVERVIEW_STATS_FAILED,
  OVERVIEW_GRAPH_STATS_PENDING,
  OVERVIEW_GRAPH_STATS_SUCCESS,
  OVERVIEW_GRAPH_STATS_FAILED
} from './constant'
import {
  IStatsState,
  IPrintStats,
  IGraphStatsRes,
  IResinGraphStats,
  IPrintGraphStats,
  IResinGraphStatsRes,
  IPrintGraphStatsRes
} from './types'

export const initialStates: IStatsState = {
  errorMessage: undefined,
  printStats: {
    completedPrints: 0,
    successfullPrints: 0,
    resinConsumed: 0, // in ml
    averageResinUsed: 0, // in ml
    veloxMostUsedResin: '',
    dentaformMostUsedResin: ''
  },
  graphStats: {
    resin: {
      duration: 'day', // day month or week
      data: []
    },
    print: {
      duration: 'day', // day month or week
      data: []
    }
  },
  fetchingOverviewStats: false,
  fetchingOverviewPrintGraphStats: false,
  fetchingOverviewResinGraphStats: false
}

function getLabel(
  duration: string,
  reportedAt: string,
  durationNumber: number
) {
  const date = new Date(reportedAt)
  const options = { year: 'numeric', month: 'long' }
  if (duration === 'week') {
    return `week ${durationNumber}, ${date.toLocaleDateString('en-US', {
      year: 'numeric'
    })}`
  } else if (duration === 'month') {
    return date.toLocaleDateString('en-US', options)
  } else {
    return date.toLocaleDateString('en-US', { ...options, day: 'numeric' })
  }
}

function getXValue(
  duration: string,
  reportedAt: string,
  durationNumber: number
) {
  const date = new Date(reportedAt)
  if (duration === 'week') {
    return `w${durationNumber}`
  } else if (duration === 'month') {
    return `${('0' + (date.getMonth() + 1)).slice(-2)}/${date
      .getFullYear()
      .toString()
      .substr(2, 3)}`
  } else {
    return `${('0' + date.getDate()).slice(-2)}/${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}`
  }
}

export function updateResinStats(duration: string, data: IGraphStatsRes[]) {
  const sortedData = data.sort((a, b) => {
    return new Date(a.reportedAt).getTime() - new Date(b.reportedAt).getTime()
  })
  const resin: IResinGraphStats = {
    duration,
    data: sortedData
      .map(d => {
        const r: IResinGraphStatsRes = {
          resinConsumption: d.resinConsumption,
          [duration]: d[duration],
          label: getLabel(duration, d.reportedAt, d[duration]),
          xValue: getXValue(duration, d.reportedAt, d[duration])
        }
        return r
      })
      .sort(r => r[duration])
  }
  return resin
}

export function updatePrintStats(duration: string, data: IGraphStatsRes[]) {
  const sortedData = data.sort((a, b) => {
    return new Date(a.reportedAt).getTime() - new Date(b.reportedAt).getTime()
  })
  const print: IPrintGraphStats = {
    duration,
    data: sortedData.map(d => {
      const r: IPrintGraphStatsRes = {
        printCount: d.printCount,
        [duration]: d[duration],
        label: getLabel(duration, d.reportedAt, d[duration]),
        xValue: getXValue(duration, d.reportedAt, d[duration])
      }
      return r
    })
  }
  return print
}

export default createReducer(initialStates, {
  [OVERVIEW_STATS_PENDING]: (state: IStatsState) => ({
    ...state,
    fetchingOverviewStats: true,
    errorMessage: undefined
  }),

  [OVERVIEW_STATS_SUCCESS]: (
    state: IStatsState,
    { printStats }: { printStats: IPrintStats }
  ) => ({
    ...state,
    fetchingOverviewStats: false,
    printStats: {
      ...state.printStats,
      ...printStats
    },
    errorMessage: undefined
  }),

  [OVERVIEW_STATS_FAILED]: (
    state: IStatsState,
    { message }: { message: string }
  ) => ({
    ...state,
    fetchingOverviewStats: false,
    errorMessage: message
  }),

  [OVERVIEW_GRAPH_STATS_PENDING]: (
    state: IStatsState,
    { type }: { type: string }
  ) => ({
    ...state,
    fetchingOverviewPrintGraphStats: type ? type === 'print' : true,
    fetchingOverviewResinGraphStats: type ? type === 'resin' : true,
    errorMessage: undefined
  }),

  [OVERVIEW_GRAPH_STATS_SUCCESS]: (
    state: IStatsState,
    {
      stats,
      duration,
      type
    }: { stats: IGraphStatsRes[]; duration: string; type?: string }
  ) => {
    const {
      graphStats: { resin, print }
    } = state
    let updatedResinStats = { ...resin }
    let updatedPrintStats = { ...print }
    if (type && type === 'print') {
      updatedPrintStats = updatePrintStats(duration, stats)
    } else if (type && type === 'resin') {
      updatedResinStats = updateResinStats(duration, stats)
    } else {
      updatedPrintStats = updatePrintStats(duration, stats)
      updatedResinStats = updateResinStats(duration, stats)
    }
    return {
      ...state,
      graphStats: {
        ...state.graphStats,
        resin: updatedResinStats,
        print: updatedPrintStats
      },
      fetchingOverviewPrintGraphStats: false,
      fetchingOverviewResinGraphStats: false,
      errorMessage: undefined
    }
  },

  [OVERVIEW_GRAPH_STATS_FAILED]: (
    state: IStatsState,
    { message }: { message: string }
  ) => ({
    ...state,
    fetchingOverviewPrintGraphStats: false,
    fetchingOverviewResinGraphStats: false,
    errorMessage: message
  })
})
