import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAddJobFormState, IShowAddJobFormAction } from './types'

export const initialState: IAddJobFormState = {
  visible: false,
  preselectedFile: undefined,
  preselectedPrinterName: undefined,
  fileStoreKey: undefined,
  cloneFileName: undefined
}

export const addJobFormSlice = createSlice({
  name: 'addJobForm',
  initialState,
  reducers: {
    showAddJobForm: (
      state,
      action: PayloadAction<IShowAddJobFormAction | undefined>
    ) => {
      state.visible = true
      if (action && action.payload) {
        const {
          preselectedFile,
          preselectedPrinterName,
          fileStoreKey,
          cloneFileName
        } = action.payload
        state.preselectedFile = preselectedFile
        state.preselectedPrinterName = preselectedPrinterName
        state.fileStoreKey = fileStoreKey
        state.cloneFileName = cloneFileName
      }
    },
    hideAddJobForm: state => {
      state.visible = false
      state.preselectedFile = undefined
      state.preselectedPrinterName = undefined
      state.fileStoreKey = undefined
      state.cloneFileName = undefined
    }
  }
})

export const { showAddJobForm, hideAddJobForm } = addJobFormSlice.actions

export default addJobFormSlice.reducer
