import { put, call, select, takeEvery, fork } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { ToastType } from 'backpack'

import {
  createOperationLog,
  createOperationLogFailure,
  createOperationLogSuccess,
  getOperationLogs,
  getOperationLogsFailure,
  getOperationLogsSuccess,
  updateOperationLog,
  updateOperationLogFailure,
  updateOperationLogSuccess,
  deleteOperationLog,
  deleteOperationLogFailure,
  deleteOperationLogSuccess,
  getOEEOverview,
  getOEEOverviewFailure,
  getOEEOverviewSuccess,
  getThroughputOverviewSuccess,
  getThroughputOverviewFailure,
  getThroughputOverview
} from './slice'
import { hideOperationLogsForm } from '../operationLogsForm/slice'
import { addToast } from '../toasts/actions'
import { IAuthState } from '../auth/types'
import {
  CreateOperationLogAction,
  GetOperationLogsAction,
  UpdateOperationLogAction,
  DeleteOperationLogAction,
  GetOEEOverviewAction,
  GetThroughputOverviewAction
} from './types'
import {
  createOperationLogAPI,
  getOperationLogsAPI,
  updateOperationLogAPI,
  deleteOperationLogAPI,
  getOEEOverviewAPI,
  getThroughputOverviewAPI
} from './api'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken

export function* createOperationLogHandler(
  action: PayloadAction<CreateOperationLogAction>
) {
  try {
    const payloadProps = action.payload

    const token: string = yield select(getToken)

    yield call(createOperationLogAPI, { ...payloadProps, token })

    yield put(createOperationLogSuccess({ ...payloadProps }))
    yield put(
      addToast({
        title: `Add log success!`,
        description: 'Operation log has been added successfully!',
        type: ToastType.success,
        stayDuration: 4000
      })
    )

    yield put(hideOperationLogsForm())
  } catch (e) {
    yield put(createOperationLogFailure())
    yield put(
      addToast({
        title: `Create operation log failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* updateOperationLogHandler(
  action: PayloadAction<UpdateOperationLogAction>
) {
  try {
    const payloadProps = action.payload

    const token: string = yield select(getToken)

    yield call(updateOperationLogAPI, { ...payloadProps, token })

    yield put(updateOperationLogSuccess({ ...payloadProps }))
    yield put(
      addToast({
        title: `Update log success!`,
        description: 'Operation log has been successfully updated!',
        type: ToastType.success,
        stayDuration: 4000
      })
    )
    yield put(hideOperationLogsForm())
  } catch (e) {
    yield put(updateOperationLogFailure())
    yield put(
      addToast({
        title: `Update operation log failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* deleteOperationLogHandler(
  action: PayloadAction<DeleteOperationLogAction>
) {
  try {
    const payloadProps = action.payload

    const token: string = yield select(getToken)

    yield call(deleteOperationLogAPI, { ...payloadProps, token })

    yield put(deleteOperationLogSuccess({ ...payloadProps }))
    yield put(
      addToast({
        title: `Delete operation log success!`,
        description: 'Operation log has been successfully deleted!',
        type: ToastType.success,
        stayDuration: 4000
      })
    )
  } catch (e) {
    yield put(deleteOperationLogFailure())
    yield put(
      addToast({
        title: `Delete operation log failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* getOperationLogsHandler(
  action: PayloadAction<GetOperationLogsAction>
) {
  try {
    const payloadProps = action.payload

    const token: string = yield select(getToken)

    const res = yield call(getOperationLogsAPI, { ...payloadProps, token })

    yield put(
      getOperationLogsSuccess({
        operationLogs: res.operationLogs,
        operationLogsTotalCount: res.totalCount
      })
    )
  } catch (e) {
    yield put(getOperationLogsFailure())
    yield put(
      addToast({
        title: `Get operation logs failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* getOEEOverviewHandler(
  action: PayloadAction<GetOEEOverviewAction>
) {
  try {
    const payloadProps = action.payload

    const token: string = yield select(getToken)

    const res = yield call(getOEEOverviewAPI, { ...payloadProps, token })

    yield put(getOEEOverviewSuccess({ ...res }))
  } catch (e) {
    yield put(getOEEOverviewFailure())
    yield put(
      addToast({
        title: `Get OEE Overview failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* getThroughputOverviewHandler(
  action: PayloadAction<GetThroughputOverviewAction>
) {
  try {
    const payloadProps = action.payload

    const token: string = yield select(getToken)

    const res = yield call(getThroughputOverviewAPI, { ...payloadProps, token })

    yield put(getThroughputOverviewSuccess({ ...res }))
  } catch (e) {
    yield put(getThroughputOverviewFailure())
    yield put(
      addToast({
        title: `Get throughput overview failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

function* watchCreateOperationLog() {
  yield takeEvery(createOperationLog.toString(), createOperationLogHandler)
}

function* watchUpdateOperationLog() {
  yield takeEvery(updateOperationLog.toString(), updateOperationLogHandler)
}

function* watchDeleteOperationLog() {
  yield takeEvery(deleteOperationLog.toString(), deleteOperationLogHandler)
}

function* watchGetOperationLogs() {
  yield takeEvery(getOperationLogs.toString(), getOperationLogsHandler)
}

function* watchGetOEEOverview() {
  yield takeEvery(getOEEOverview.toString(), getOEEOverviewHandler)
}

function* watchGetThroughputOverview() {
  yield takeEvery(
    getThroughputOverview.toString(),
    getThroughputOverviewHandler
  )
}

export default [
  fork(watchCreateOperationLog),
  fork(watchUpdateOperationLog),
  fork(watchDeleteOperationLog),
  fork(watchGetOperationLogs),
  fork(watchGetOEEOverview),
  fork(watchGetThroughputOverview)
]
