import {
  GET_TEAM_PENDING,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  ADD_USER_PENDING,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  DELETE_USER_PENDING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  ENABLE_DISABLE_USER_PENDING,
  ENABLE_DISABLE_USER_FAILURE,
  ENABLE_DISABLE_USER_SUCCESS,
  UPDATE_USER_GROUP_PENDING,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAILURE
} from './constant'
import { ISubUser } from './types'

export function getTeam() {
  return {
    type: GET_TEAM_PENDING
  }
}

export function getTeamSuccess(team: ISubUser[]) {
  return {
    type: GET_TEAM_SUCCESS,
    payload: { team }
  }
}

export function getTeamFailed(message = 'Failed to get team') {
  return {
    type: GET_TEAM_FAILURE,
    payload: { message }
  }
}

export function addSubUser(
  firstName: string,
  lastName: string,
  email: string,
  groups?: string[],
  allPrinters?: boolean
) {
  return {
    type: ADD_USER_PENDING,
    payload: { firstName, lastName, email, groups, allPrinters }
  }
}

export function addSubUserSuccess(user: ISubUser) {
  return {
    type: ADD_USER_SUCCESS,
    payload: { user }
  }
}

export function addSubUserFailed(message = 'failed to add sub user') {
  return {
    type: ADD_USER_FAILURE,
    payload: { message }
  }
}

export function deleteUser(email: string) {
  return {
    type: DELETE_USER_PENDING,
    payload: { email }
  }
}

export function deleteUserSuccess(email: string) {
  return {
    type: DELETE_USER_SUCCESS,
    payload: { email }
  }
}

export function deleteUserFailed(
  email: string,
  message = 'failed to delete sub user'
) {
  return {
    type: DELETE_USER_FAILURE,
    payload: { email, message }
  }
}

export function enableDisableUser(email: string, blocked: boolean) {
  return {
    type: ENABLE_DISABLE_USER_PENDING,
    payload: { email, blocked }
  }
}

export function enableDisableUserSuccess(email: string, blocked: boolean) {
  return {
    type: ENABLE_DISABLE_USER_SUCCESS,
    payload: { email, blocked }
  }
}

export function enableDisableUserFailed(
  email: string,
  message = 'enable disable api failed'
) {
  return {
    type: ENABLE_DISABLE_USER_FAILURE,
    payload: { email, message }
  }
}

export function updateUserGroup(
  id: string,
  groups?: string[],
  allPrinters?: boolean
) {
  return {
    type: UPDATE_USER_GROUP_PENDING,
    payload: { id, groups, allPrinters }
  }
}

export function updateUserGroupSuccess(
  id: string,
  groups?: string[],
  allPrinters?: boolean
) {
  return {
    type: UPDATE_USER_GROUP_SUCCESS,
    payload: { id, groups, allPrinters }
  }
}

export function updateUserGroupFailure() {
  return {
    type: UPDATE_USER_GROUP_FAILURE
  }
}
