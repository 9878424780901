import {
  put,
  call,
  select,
  takeEvery,
  fork,
  takeLatest,
  delay
} from 'redux-saga/effects'
import { ToastType } from 'backpack'

import {
  getDentaformPrinters,
  getUploadUrl,
  uploadFile,
  changePrinterName,
  getPendingFiles,
  getLatestTeflon,
  getLatestLCD,
  createGroup,
  updatePrinterGroup,
  getGroups,
  deleteGroup
} from './api'
import {
  getPrintersSuccess,
  getPrinters,
  uploadFileSuccess,
  uploadFileFailed,
  deleteJobSuccess,
  deleteJobFailed,
  changePrinterNameSuccess,
  changePrinterNameFailure,
  getPendingFilesSuccess,
  getPendingFilesFailure,
  getPendingFiles as getPendingFilesAction,
  getAllQueuedJobsSuccess,
  getJobsFromIOTSuccess,
  getPrinterGraphStatsSuccess,
  getPrinterGraphStatsFailure,
  getLatestTeflonSuccess,
  getLatestTeflonFailure,
  multipleFileUploadSuccess,
  multipleFileUploadFailure,
  getLatestLCDSuccess,
  getLatestLCDFailure,
  createGroupSuccess,
  createGroupFailure,
  updateGroupSuccess,
  updateGroupFailure,
  getGroupsSuccess,
  getGroupsFailure,
  deleteGroupSuccess,
  deleteGroupFailure,
  startRemotePrintSuccess,
  startRemotePrintFailure
} from './actions'
import { addToast } from '../toasts/actions'
import { connectIOT } from '../iot/actions'
import {
  IPrinterRes,
  IPrinter,
  IUploadFileAction,
  IPrinterState,
  ISendAction,
  IChangePrinterNameAction,
  IPrinterStatusAction,
  IPrinterPendingFIlesAction,
  IJobListAction,
  IGetAllQueedJobsAction,
  IQueuedJobsFilter,
  IPrinterGraphStatsAction,
  IGetLatestTeflonAction,
  IMultipleFileUploadAction,
  IGetLatestLCDAction,
  ILCD,
  ICreatePrinterGroupAction,
  IUpdatePrinterGroupAction,
  IGroupResponse,
  IDeletePrinterGroupAction,
  IRemotePrintAction,
  IRemotePrintSuccessAction,
  IRemotePrintFailureAction
} from './types'
import {
  PRINTER_UPLOAD_JOB_PENDING,
  PRINTER_DELETE_JOB_PENDING,
  PRINTER_CHANGE_PRINTER_NAME_PENDING,
  PRINTER_GET_PENDING_FILES,
  PRINTER_GRAPH_STATS_PENDING,
  PRINTER_GET_LATEST_TEFLON,
  PRINTER_MULTIPLE_UPLOAD_JOB_PENDING,
  PRINTER_GET_LATEST_LCD,
  PRINTER_CREATE_GROUP,
  PRINTER_UPDATE_GROUP,
  PRINTER_GET_GROUPS,
  PRINTER_DELETE_GROUP,
  PRINTER_START_REMOTE_PRINT
} from './constant'
import { IAuthState } from '../auth/types'
import { push } from 'connected-react-router'
import { IGraphStatsRes } from '../stats/types'
import { getPrintStatsOverDuration } from '../stats/api'
import { ITeflon } from '../teflons/types'
import { isNewerVersion } from '../../utils/compare-version'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken
export const getPrinterList = ({
  printerData
}: {
  printerData: IPrinterState
}) => printerData.printers

export const getPathname = state => state.router.location.pathname

export function validateFile(file: File, isVelox = false) {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject({
        message: 'File is invalid.'
      })
    }
    if (isVelox && !file.name.match(/(?:[\S ])+(?:spj|stl)/)) {
      return reject({
        message: 'File is invalid.'
      })
    } else if (!isVelox && !file.name.match(/(?:[\S ])+(?:spj)/)) {
      return reject({
        message: 'File is invalid.'
      })
    }
    return resolve(true)
  })
}

export function* printerSucccessHandler(
  printers: IPrinterRes[],
  accessToken: string,
  printersType: string
) {
  yield put(getPrintersSuccess(printers, printersType))
  yield put(connectIOT(accessToken))
}

export function* printerHandler(
  veloxPrinters: string[],
  token: string,
  customerId?: string
) {
  let printersType = ''
  const vp = veloxPrinters.map(p => ({
    name: p,
    isVelox: true,
    customPrinterName: p
  }))
  printersType = vp.length ? 'velox' : ''
  if (customerId) {
    // get printer groups
    yield fork(getPrinterGroups)
    // get dentaform printers sets
    yield put(getPrinters())
    try {
      const { printers } = yield call(getDentaformPrinters, token)
      const dp = printers.map(
        ({
          name,
          customName,
          version,
          groups,
          printCount
        }: {
          name: string
          customName: string
          version?: string
          groups: string[]
          printCount?: {
            total: number
            success: number
            failure: number
          }
        }) => ({
          name,
          customPrinterName: customName || name,
          version,
          isVelox: false,
          groups,
          printCount: printCount
        })
      )
      printersType = dp.length
        ? printersType
          ? 'mixed'
          : 'dentaform'
        : printersType
      yield call(printerSucccessHandler, [...vp, ...dp], token, printersType)
    } catch (err) {
      yield call(printerSucccessHandler, vp, token, printersType)
    }
  } else {
    yield call(printerSucccessHandler, vp, token, printersType)
  }
}

export function* fileUploadHandler({ payload }: IUploadFileAction) {
  const { file, printerName } = payload
  try {
    const printers: IPrinter[] = yield select(getPrinterList)
    const printer = printers.find((p: IPrinter) => p.name === printerName)
    if (printer) {
      yield call(validateFile, file, printer.isVelox)
      const token: string = yield select(getToken)
      const { url, fields } = yield call(
        getUploadUrl,
        token,
        printerName,
        file.name
      )
      if (url) {
        yield call(uploadFile, url, token, file, fields)
        yield put(getPendingFilesAction([printerName]))
        yield put(uploadFileSuccess(printerName))
        // notify for file upload success here
        yield put(
          addToast({
            title: `File uploaded successfully (${printerName})`,
            description: `${file.name} has been uploaded to ${printerName} successfully`,
            type: ToastType.success
          })
        )
        // redirect to the printer details page after successfull upload of a file
        yield put(push(`/printers/${printerName}`))
      }
    }
  } catch (e) {
    yield put(uploadFileFailed(e.message, printerName))
    // notify for file upload failure here
    yield put(
      addToast({
        title: `File upload failed`,
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* deleteJobHandler({ payload }: ISendAction) {
  const printerList: IPrinter[] = yield select(getPrinterList)
  let type = 'TOSEND/JOB_DELETE'
  if (printerList) {
    const selectedPrinter = printerList.find(
      p => p.name === payload.printerName
    )
    if (
      selectedPrinter &&
      selectedPrinter.version &&
      (selectedPrinter.version === '1.9.0' ||
        isNewerVersion('1.9.0', selectedPrinter.version))
    ) {
      type = 'TOSEND/PRINTER/JOB_DELETE'
    }
  }
  yield put({
    type: type,
    payload,
    aid: new Date().getTime().toString(),
    onSuccess: {
      type: 'IOT_DELETE_JOB_SUCCESS',
      payload
    },
    onError: {
      type: 'IOT_DELETE_JOB_FAILED',
      payload
    },
    onTimeout: {
      type: 'IOT_DELETE_JOB_TIMEOUT',
      payload
    }
  })
}

export function* deleteJobSuccessHandler({ payload }: ISendAction) {
  const { jobId, printerName } = payload
  yield put(deleteJobSuccess(jobId, printerName))
  yield put(
    addToast({
      title: `Delete Job (${printerName})`,
      description: 'Job has been deleted successfully.',
      type: ToastType.success
    })
  )
}

export function* deleteJobFailedHandler({ payload }: ISendAction) {
  const { jobId, printerName } = payload
  yield put(deleteJobFailed(jobId, printerName))
  // need to show the error message here
  // yield put(
  //   addToast({
  //     title: `Delete Job(${printerName})`,
  //     description: 'Unable to delete job.',
  //     type: ToastType.error
  //   })
  // )
}

export function* changePrinterNameHandler({
  payload
}: IChangePrinterNameAction) {
  const { printerName, customName } = payload
  try {
    const token: string = yield select(getToken)
    const printerList: IPrinter[] = yield select(getPrinterList)
    const printer = printerList.find(p => p.name === printerName)
    yield call(
      changePrinterName,
      token,
      printerName,
      customName,
      printer?.isVelox
    )
    yield put(changePrinterNameSuccess(printerName, customName))
    yield put(
      addToast({
        title: 'Change Name Success',
        description: `Printer name updated to ${customName}`,
        type: ToastType.success
      })
    )
  } catch (err) {
    yield put(changePrinterNameFailure(printerName))
    yield put(
      addToast({
        title: 'Change Name Failed',
        description: 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* printerStatusHandler({ payload }: IPrinterStatusAction) {
  const { status, printerName } = payload
  const printerList: IPrinter[] = yield select(getPrinterList)
  const printer = printerList.find(p => p.name === printerName)
  const veloxPrintCompleted = printer?.isVelox && status === 'pending-removal'
  const dentaformPrintCompleted =
    !printer?.isVelox && status === 'PRINT-COMPLETED'
  if (printer && printer.previousStatus && status !== printer.previousStatus) {
    if (veloxPrintCompleted || dentaformPrintCompleted) {
      const activeJob = printer.jobs
        ? printer.jobs.find(j => j.isActive)
        : undefined
      yield put(
        addToast({
          title: `Printer Status(${printerName})`,
          description: `${printerName} just completed printing ${
            activeJob ? decodeURIComponent(activeJob.file.name) : ''
          }`,
          type: ToastType.success
        })
      )
    }
  }
}

export function* pendingFilesHandler({ payload }: IPrinterPendingFIlesAction) {
  const { printers } = payload
  try {
    const token: string = yield select(getToken)
    const printerList: IPrinter[] = yield select(getPrinterList)
    const dentaformPrinters = printerList
      .filter(p => printers.indexOf(p.name) !== -1 && !p.isVelox)
      .map(p => p.name)
    const veloxPrinters = printerList
      .filter(p => printers.indexOf(p.name) !== -1 && p.isVelox)
      .map(p => p.name)
    const res = yield call(
      getPendingFiles,
      token,
      dentaformPrinters,
      veloxPrinters
    )
    // const res = [{ printer: 'nitid-pine', pendingFiles: ['abc.spj'] }]
    yield put(getPendingFilesSuccess(res))
  } catch (err) {
    yield put(getPendingFilesFailure(err))
  }
}

export function* jobListHandler({ payload }: IJobListAction) {
  try {
    const pathname = yield select(getPathname)
    if (pathname && pathname.includes('printers')) {
      const pathNames = pathname.split('/')
      if (pathNames.length >= 3 && pathNames[2] === payload.printerName) {
        // IF printer details page is open while job list is getting updated then fetch fresh pending job list
        yield put(getPendingFilesAction([payload.printerName]))
      }
    }
  } finally {
    yield put(getJobsFromIOTSuccess(payload.printerName, payload.jobs))
  }
}

export function* getAllQueedJobsHandler({ payload }: IGetAllQueedJobsAction) {
  // Get all Queed jobs consist of 2 parts
  // 1. Get all Pending files for all or selected printers
  // 2. Combine pending files with all the queed jobs
  let { printers = [], printersType = 'dentaform' } =
    payload.filter || ({} as IQueuedJobsFilter)
  // Select all the printers to fetch pending files if printer list is empty in filters
  let printerList: IPrinter[] = yield select(getPrinterList)
  if (printers.length === 0) {
    const isVelox = printersType?.toLowerCase() === 'velox'
    printerList = printerList.filter(p => p.isVelox === isVelox)
    printers = printerList.map(p => p.name)
  }
  const token = yield select(getToken)
  // call api to fetch pending files
  try {
    const dentaformPrinters = printerList
      .filter(p => printers.indexOf(p.name) !== -1 && !p.isVelox)
      .map(p => p.name)
    const veloxPrinters = printerList
      .filter(p => printers.indexOf(p.name) !== -1 && p.isVelox)
      .map(p => p.name)
    const res = yield call(
      getPendingFiles,
      token,
      dentaformPrinters,
      veloxPrinters
    )
    yield put(getPendingFilesSuccess(res))
  } finally {
    yield put(getAllQueuedJobsSuccess(payload.filter))
  }
}

export function* printerGraphStatsHandler({
  payload
}: IPrinterGraphStatsAction) {
  const { printerName, duration, type } = payload
  try {
    const accessToken = yield select(getToken)
    const printerList: IPrinter[] = yield select(getPrinterList)
    const printer = printerList.find(p => p.name === printerName)
    if (printer) {
      const veloxPrinter = printer.isVelox ? [printerName] : []
      const dentaformPrinter = printer.isVelox ? [] : [printerName]
      const { printLogs }: { printLogs: IGraphStatsRes[] } = yield call(
        getPrintStatsOverDuration,
        accessToken,
        duration,
        veloxPrinter,
        dentaformPrinter
      )
      yield put(
        getPrinterGraphStatsSuccess(printerName, printLogs, duration, type)
      )
    }
  } catch (err) {
    yield put(getPrinterGraphStatsFailure())
    yield put(
      addToast({
        title: 'Fetching stats failed',
        description: 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* getLatestTeflonHandler({ payload }: IGetLatestTeflonAction) {
  const { printerName } = payload
  try {
    const accessToken = yield select(getToken)
    const teflon: ITeflon = yield call(
      getLatestTeflon,
      accessToken,
      printerName
    )
    yield put(getLatestTeflonSuccess(printerName, teflon))
  } catch (err) {
    yield put(getLatestTeflonFailure())
  }
}

export function* forkedUploadFile(
  file: File,
  printerName: string,
  token: string
) {
  try {
    const { url, fields } = yield call(
      getUploadUrl,
      token,
      printerName,
      file.name
    )
    if (url) {
      yield call(uploadFile, url, token, file, fields)
      yield put(multipleFileUploadSuccess(file.name))
    } else {
      yield put(multipleFileUploadFailure(file.name))
    }
  } catch (err) {
    yield put(multipleFileUploadFailure(file.name))
  }
}

export function* multipleFileUploadHandler({
  payload
}: IMultipleFileUploadAction) {
  const { printerName, files } = payload
  const accessToken = yield select(getToken)
  for (let i = 0; i < files.length; i++) {
    yield fork(forkedUploadFile, files[i], printerName, accessToken)
  }
  // after some delay calls pending files action for the printer
  yield delay(3000)
  yield put(getPendingFilesAction([printerName]))
}

export function* getLatestLCDHandler({ payload }: IGetLatestLCDAction) {
  const { printerName } = payload
  try {
    const accessToken = yield select(getToken)
    const lcd: ILCD = yield call(getLatestLCD, accessToken, printerName)
    yield put(getLatestLCDSuccess(printerName, lcd))
  } catch (err) {
    yield put(getLatestLCDFailure())
  }
}

export function* createPrinterGroupHandler({
  payload
}: ICreatePrinterGroupAction) {
  const { name, printers } = payload
  try {
    const accessToken = yield select(getToken)
    yield call(createGroup, accessToken, name, printers)
    yield put(createGroupSuccess(name, printers))
    yield put(
      addToast({
        title: 'Create Group Success',
        description: `Printer group ${name} is create successfully.`,
        type: ToastType.success
      })
    )
  } catch (e) {
    yield put(createGroupFailure())
    yield put(
      addToast({
        title: 'Create Group Failure',
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* updatePrinterGroupHandler({
  payload
}: IUpdatePrinterGroupAction) {
  const { name, printers, updatedName } = payload
  try {
    const accessToken = yield select(getToken)
    yield call(updatePrinterGroup, accessToken, name, printers, updatedName)
    yield put(updateGroupSuccess(name, printers, updatedName))
    yield put(
      addToast({
        title: 'Update Group Success',
        description: `Printer group is updated successfully.`,
        type: ToastType.success
      })
    )
  } catch (e) {
    yield put(updateGroupFailure())
    yield put(
      addToast({
        title: 'Updated Group Failure',
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* getPrinterGroups() {
  try {
    const accessToken = yield select(getToken)
    const groups: IGroupResponse[] = yield call(getGroups, accessToken)
    const groupNames = groups.map(g => g.name)
    yield put(getGroupsSuccess(groupNames))
  } catch (e) {
    yield put(getGroupsFailure())
  }
}

export function* deletePrinterGroupHandler({
  payload
}: IDeletePrinterGroupAction) {
  try {
    const { name } = payload
    const accessToken = yield select(getToken)
    yield call(deleteGroup, accessToken, name)
    yield put(deleteGroupSuccess(name))
    yield put(
      addToast({
        title: 'Delete Group Success',
        description: `Printer group ${name} is deleted successfully.`,
        type: ToastType.success
      })
    )
  } catch (e) {
    yield put(deleteGroupFailure())
    yield put(
      addToast({
        title: 'Delete Group Failure',
        description: e.message || 'Please try again later',
        type: ToastType.error
      })
    )
  }
}

export function* remotePrintHandler({ payload }: IRemotePrintAction) {
  yield put({
    type: 'TOSEND/PRINTER/REMOTE_PRINT_START',
    payload,
    aid: new Date().getTime().toString(),
    onSuccess: {
      type: 'IOT_REMOTE_PRINT_START_SUCCESS',
      payload
    },
    onError: {
      type: 'IOT_REMOTE_PRINT_START_FAILED',
      payload
    },
    onTimeout: {
      type: 'IOT_REMOTE_PRINT_START_TIMEOUT',
      payload
    }
  })
}

export function* remotePrintSuccessHandler({
  payload
}: IRemotePrintSuccessAction) {
  const { printerName, fileName } = payload
  yield put(startRemotePrintSuccess())
  yield put(
    addToast({
      title: `Remote print (${printerName})`,
      description: `Printer ${printerName} has started printing file ${fileName}`,
      type: ToastType.success
    })
  )
}

export function* remotePrintFailedHandler({
  payload
}: IRemotePrintFailureAction) {
  const { printerName, errorMessages = [] } = payload
  yield put(startRemotePrintFailure())
  const errors = errorMessages.map(e => e.msg)
  yield put(
    addToast({
      title: `Remote print failed for (${printerName})`,
      description:
        errors && errors.length ? errors.join(', ') : 'Remote print failure',
      type: ToastType.error
    })
  )
}

function* watchDeletePrinterGroup() {
  yield takeEvery(PRINTER_DELETE_GROUP, deletePrinterGroupHandler)
}

function* watchPrinterGroups() {
  yield takeEvery(PRINTER_GET_GROUPS, getPrinterGroups)
}

function* watchCreatePrinterGroup() {
  yield takeEvery(PRINTER_CREATE_GROUP, createPrinterGroupHandler)
}

function* watchUpdatePrinterGroup() {
  yield takeEvery(PRINTER_UPDATE_GROUP, updatePrinterGroupHandler)
}

function* watchFileUpload() {
  yield takeEvery(PRINTER_UPLOAD_JOB_PENDING, fileUploadHandler)
}

function* watchDeleteJob() {
  yield takeEvery(PRINTER_DELETE_JOB_PENDING, deleteJobHandler)
}

function* watchDeleteJobSuccess() {
  yield takeEvery('IOT_DELETE_JOB_SUCCESS', deleteJobSuccessHandler)
}

function* watchDeleteJobFailed() {
  yield takeEvery(
    ['IOT_DELETE_JOB_FAILED', 'IOT_DELETE_JOB_TIMEOUT'],
    deleteJobFailedHandler
  )
}

function* watchChangePrinterName() {
  yield takeEvery(PRINTER_CHANGE_PRINTER_NAME_PENDING, changePrinterNameHandler)
}

function* watchPrinterStatus() {
  yield takeLatest('FROMIOT/PRINTER/STATUS', printerStatusHandler)
}

function* watchPendingFiles() {
  yield takeLatest(PRINTER_GET_PENDING_FILES, pendingFilesHandler)
}

function* watchJobList() {
  yield takeEvery('FROMIOT/JOBS/LIST', jobListHandler)
}

function* watchGetAllQueedJobs() {
  yield takeEvery('PRINTER_GET_ALL_QUEUED_JOBS', getAllQueedJobsHandler)
}

function* watchPrinterGraphStats() {
  yield takeEvery(PRINTER_GRAPH_STATS_PENDING, printerGraphStatsHandler)
}

function* watchGetLatestTeflon() {
  yield takeEvery(PRINTER_GET_LATEST_TEFLON, getLatestTeflonHandler)
}

function* watchMultipleFileUpload() {
  yield takeEvery(
    PRINTER_MULTIPLE_UPLOAD_JOB_PENDING,
    multipleFileUploadHandler
  )
}

function* watchGetLatestLCD() {
  yield takeEvery(PRINTER_GET_LATEST_LCD, getLatestLCDHandler)
}

function* watchRemotePrint() {
  yield takeEvery(PRINTER_START_REMOTE_PRINT, remotePrintHandler)
}

function* watchRemotePrintSuccess() {
  yield takeEvery('IOT_REMOTE_PRINT_START_SUCCESS', remotePrintSuccessHandler)
}

function* watchRemotePrintFailed() {
  yield takeEvery(
    ['IOT_REMOTE_PRINT_START_FAILED', 'IOT_REMOTE_PRINT_START_TIMEOUT'],
    remotePrintFailedHandler
  )
}

export default [
  fork(watchFileUpload),
  fork(watchDeleteJob),
  fork(watchDeleteJobSuccess),
  fork(watchDeleteJobFailed),
  fork(watchChangePrinterName),
  fork(watchPrinterStatus),
  fork(watchPendingFiles),
  fork(watchJobList),
  fork(watchGetAllQueedJobs),
  fork(watchPrinterGraphStats),
  fork(watchGetLatestTeflon),
  fork(watchMultipleFileUpload),
  fork(watchGetLatestLCD),
  fork(watchCreatePrinterGroup),
  fork(watchUpdatePrinterGroup),
  fork(watchPrinterGroups),
  fork(watchDeletePrinterGroup),
  fork(watchRemotePrint),
  fork(watchRemotePrintSuccess),
  fork(watchRemotePrintFailed)
]
