import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Button, ToastType } from 'backpack'

import {
  StyledStartJobPopup,
  StartJobForm,
  JobsQueueContainer
} from './components'
import { IPrinterState, IJob } from '../../redux/printer/types'
import { hideStartJobForm } from '../../redux/startJobForm/slice'
import { IStartJobFormState } from '../../redux/startJobForm/types'
import { startRemotePrint } from '../../redux/printer/actions'
import { addToast } from '../../redux/toasts/actions'

function JobsQueue({ jobs }: { jobs: IJob[] }) {
  return (
    <JobsQueueContainer>
      <div className="jobs-queue-table">
        <div className="grid-table header">
          <span>File Name</span>
          <span>Status</span>
        </div>
        <div className="content">
          {jobs.length === 0 && (
            <div className="no-jobs">Please add a job to start print</div>
          )}
          {jobs.map((j, index) => (
            <div
              key={j.id}
              className={`grid-table job${index === 0 ? ' next' : ''}`}
            >
              <span>{j.file.name}</span>
              <span>{j.status}</span>
            </div>
          ))}
        </div>
      </div>
    </JobsQueueContainer>
  )
}

export default function({ visible }: { visible: boolean }) {
  const dispatch = useDispatch()

  const [showAdvanceOptions, setShowAdvanceOptions] = useState(false)

  const { printers } = useSelector(
    ({ printerData }: { printerData: IPrinterState }) => printerData
  )

  const { preselectedPrinterName } = useSelector(
    ({ startJobForm }: { startJobForm: IStartJobFormState }) => startJobForm
  )

  // const prevProp = usePrevious({ isStartingRemotePrint })

  // Close popup after start remote print gets a response
  // useEffect(() => {
  //   //@ts-ignore
  //   if (prevProp && prevProp.isStartingRemotePrint && !isStartingRemotePrint) {
  //     // process here
  //     dispatch(hideStartJobForm())
  //   }
  // }, [isStartingRemotePrint, dispatch, prevProp])

  const [selectedPrinter, setSelectedPrinter] = useState(
    preselectedPrinterName || ''
  )
  const [preheatDuration, setPreheatDuration] = useState('default')

  function closePopup() {
    dispatch(hideStartJobForm())
  }

  function startPrint(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const printerInfo = getSelectedPrinterInfo()

    const preheatDurationValue =
      preheatDuration === 'default' || !showAdvanceOptions
        ? undefined
        : Number(preheatDuration)

    dispatch(startRemotePrint(selectedPrinter, preheatDurationValue))
    dispatch(
      addToast({
        title: `Start remote print request has been sent to ${printerInfo?.customPrinterName}`,
        type: ToastType.success,
        stayDuration: 5000
      })
    )
    dispatch(hideStartJobForm())
  }

  function getPrinterDropdownOptions() {
    if (!printers || printers.length === 0) return []

    const filteredPrinters = printers.filter(p => p.isOnline)
    // const filteredPrinters = printers
    const mappedPrinters = filteredPrinters.map(
      ({ name, customPrinterName }) => ({
        label: customPrinterName || name,
        value: name
      })
    )
    return mappedPrinters
  }

  function getSelectedPrinterInfo() {
    if (selectedPrinter === '') return printers ? printers[0] : null

    const selectedPrinterInfo = printers.find(p => p.name === selectedPrinter)
    return selectedPrinterInfo
  }

  function getQueuedJobs() {
    const selectedPrinterInfo = getSelectedPrinterInfo()
    const jobsInQueue =
      selectedPrinterInfo && selectedPrinterInfo.jobs
        ? selectedPrinterInfo.jobs.filter(
            j => !j.isActive && String(j.status).toLocaleLowerCase() === 'ready'
          )
        : undefined
    return jobsInQueue
  }

  const preheatOptions = [
    {
      label: 'Select duration',
      value: 'default'
    },
    {
      label: '1 mins',
      value: '60'
    },
    {
      label: '3 mins',
      value: '180'
    },
    {
      label: '5 mins',
      value: '300'
    }
  ]

  const printerOptions = getPrinterDropdownOptions()

  const hasPrintersAvailable = printerOptions.length > 0

  const jobsInQueue = getQueuedJobs()

  return (
    <StyledStartJobPopup
      title={'Start Print'}
      onClose={closePopup}
      visible={visible}
      width={'500px'}
      className="start-job-popup"
    >
      {/* <DelayedComponents isMounted={Boolean(isStartingRemotePrint)}>
        <Loader
          visible={isStartingRemotePrint}
          text={`Starting remote print ...`}
        />
      </DelayedComponents> */}
      <StartJobForm
        className={!hasPrintersAvailable ? 'disabled' : ''}
        onSubmit={startPrint}
        noValidate={true}
      >
        <Dropdown
          label="Select Printer:"
          value={
            hasPrintersAvailable
              ? selectedPrinter || printerOptions[0].value
              : 'no-printers'
          }
          onSelectOption={setSelectedPrinter}
          options={
            hasPrintersAvailable
              ? printerOptions
              : [{ label: 'No Printers available', value: 'no-printers' }]
          }
        />
        {showAdvanceOptions && (
          <Dropdown
            label="Preheat Duration:"
            value={preheatDuration}
            onSelectOption={setPreheatDuration}
            options={preheatOptions}
          />
        )}
        <span
          className="show-advance-option"
          onClick={() => setShowAdvanceOptions(!showAdvanceOptions)}
        >
          {showAdvanceOptions
            ? 'Hide advanced options'
            : 'Show advanced options'}
        </span>
        {jobsInQueue && (
          <label style={{ marginTop: '8px', fontWeight: 'bold' }}>
            Jobs Queued
          </label>
        )}
        {jobsInQueue && <JobsQueue jobs={jobsInQueue} />}
        <Button
          style={{ marginTop: 'auto' }}
          type="submit"
          label={`Start Print`}
          fullWidth
          disabled={
            !hasPrintersAvailable || !(jobsInQueue && jobsInQueue.length)
          }
        />
      </StartJobForm>
    </StyledStartJobPopup>
  )
}
