import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPrinterGroupFormState, ShowPrinterGroupFormAction } from './types'

export const initialState: IPrinterGroupFormState = {
  visible: false,
  groupName: '',
  printers: [],
  availablePrinters: [],
  isCreateGroup: false
}

export const printerGroupFormSlice = createSlice({
  name: 'printerGroupForm',
  initialState,
  reducers: {
    showPrinterGroupForm: (
      state,
      action: PayloadAction<ShowPrinterGroupFormAction>
    ) => {
      state.visible = true
      state.groupName = action.payload.groupName
      state.printers = action.payload.printers
      state.availablePrinters = action.payload.availablePrinters
      state.isCreateGroup = Boolean(action.payload.isCreateGroup)
    },
    hidePrinterGroupForm: state => {
      state.visible = false
      state.groupName = ''
      state.printers = []
      state.availablePrinters = []
      state.isCreateGroup = false
    }
  }
})

export const {
  showPrinterGroupForm,
  hidePrinterGroupForm
} = printerGroupFormSlice.actions

export default printerGroupFormSlice.reducer
