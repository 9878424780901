import { useSelector, useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'

import { RootState } from '../store'

function useSearchParams() {
  const dispatch = useDispatch()
  const { pathname, search } = useSelector(({ router }: RootState) => ({
    pathname: router.location.pathname,
    search: router.location.search
  }))
  const params = new URLSearchParams(search)

  const updateSearchURL = () => {
    const latestParams = params.toString()
    dispatch(replace(`${pathname}?${latestParams}`))
  }

  const setParams = (key: string, value: string) => {
    params.set(key, value)
    updateSearchURL()
  }
  const getParams = (key: string) => params.get(key)

  const deleteParams = (key: string) => {
    params.delete(key)
    updateSearchURL()
  }

  return { getParams, setParams, deleteParams }
}

export default useSearchParams
