import React, { useState, useEffect, useCallback, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouterState } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import {
  PageContainer,
  AlignHorizontal,
  AlignVertical,
  OverviewIcon,
  PrintersIcon,
  DelayedComponents,
  ToastManager,
  JobsIcon,
  SettingsIcon,
  ResinIcon
} from 'backpack'

import { version } from '../../package.json'

// Components & Routes
import { MainAppContainer } from './components'
import GuidedTour from './components/GuidedTour'
import PopupManager from './components/PopupManager'
import { PrivateRoute } from '../routes/PrivateRoute'
import { Filters } from '../components/Filters/FiltersContext'
import { VersionChecker } from '../components/VersionChecker'
import SuspenseLoader from '../components/SuspenseLoader'

import { PageMask } from '../components/PageMask'

import { Header } from '../components/Header'
import { TimelineIcon } from '../components/SVGImages'
import { Sidebar } from '../components/Sidebar'
import { QuickAccessOverlay } from '../components/QuickAccessOverlay'
import { AnalyticsIcon } from '../components/SVGImages'

// Types
import { IAuthState } from '../redux/auth/types'
import { IToastsState } from '../redux/toasts/types'
import { File } from '../redux/addJobForm/types'
import { IAppState } from '../redux/app/types'
import { IUserPreferenceState, ITheme } from '../redux/user-preference/types'
import { IQuickAccessState } from '../redux/quickAccess/types'

// Actions
import {
  showWhatsNew as showWhatsNewAction,
  appInstall as appInstallAction,
  hideAppInstallOption as hideAppInstallOptionAction
} from '../redux/app/actions'
import { logout as logoutAction } from '../redux/auth/actions'
import { dismissToast as dismissToastAction } from '../redux/toasts/actions'
import { showAddJobForm as showAddJobFormAction } from '../redux/addJobForm/slice'
import {
  getTheme as getThemeAction,
  setTheme as setThemeAction
} from '../redux/user-preference/slice'
import { showQuickAccess } from '../redux/quickAccess/slice'

// Routes
const Login = React.lazy(() => import('../routes/Login'))
const Overview = React.lazy(() => import('../routes/Overview'))
const Printers = React.lazy(() => import('../routes/Printers'))
const PrinterDetails = React.lazy(() =>
  import('../routes/Printers/PrinterDetails')
)
const Jobs = React.lazy(() => import('../routes/Jobs'))
const Users = React.lazy(() => import('../routes/Settings/Users'))
const Docs = React.lazy(() => import('../routes/Settings/Docs'))
const Api = React.lazy(() => import('../routes/Settings/Api'))
const Consumables = React.lazy(() => import('../routes/Consumables'))
const Timeline = React.lazy(() => import('../routes/Timeline'))
const OEE = React.lazy(() => import('../routes/OEE'))
const OperationLogs = React.lazy(() => import('../routes/OEE/OperationLogs'))
const Throughput = React.lazy(() => import('../routes/OEE/Throughput'))
const Orders = React.lazy(() => import('../routes/Orders'))

interface ISidebarSubMenuItem {
  routeName: string
  onClick?: () => void
  label: string
}

const ExternalLinks = [
  // {
  //   label: 'Store',
  //   url: 'https://www.structo3d.com'
  // },
  {
    label: 'Support',
    url: 'https://support.structo3d.com'
  }
]

export function MainApp() {
  let dropRef = React.createRef<HTMLDivElement>()
  const dispatch = useDispatch()

  // Appstate
  const { settings, showAppInstallOption } = useSelector(
    ({ app }: { app: IAppState }) => app
  )

  // Auth
  const { user } = useSelector(({ auth }: { auth: IAuthState }) => auth)

  const isUserLoggedIn = Boolean(user)

  const logout = () => dispatch(logoutAction())
  const getUsername: { (): string | undefined; (): string | undefined } = () =>
    user && user.name
      ? user.name.substring(0, user.name.lastIndexOf('@')).toUpperCase()
      : undefined

  // Sidebar

  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [dragging, setDragging] = useState(false)

  const hideSidebar = () => setSidebarVisible(false)
  const showSidebar = () => setSidebarVisible(true)
  const goHome = () => dispatch(push('/'))
  const pushWithDispatch = (path: string) => dispatch(push(path))

  const getSettingsSubmenu = () => {
    const settingsSubMenu = [
      {
        label: 'Docs',
        routeName: '',
        onClick: () =>
          window.open(
            'https://support.structo3d.com/hc/en-us/articles/4415647962393-MyStructo-2-7-User-Manual'
          )
      },
      {
        label: `What's New`,
        routeName: '/settings/changelogs',
        onClick: () => dispatch(showWhatsNewAction())
      }
    ] as ISidebarSubMenuItem[]

    if (user && user.isMainUser) {
      if (settings && settings.apiKey) {
        settingsSubMenu.unshift({
          label: 'Integration API',
          routeName: 'settings/api',
          onClick: () => pushWithDispatch('/settings/api')
        })
      }

      settingsSubMenu.unshift({
        label: 'Users',
        routeName: 'settings/users',
        onClick: () => pushWithDispatch('/settings/users')
      })
    }
    return settingsSubMenu
  }

  const getConsumablesSubMenu = () => {
    const settingsSubMenu = [
      {
        label: 'TFAs',
        routeName: 'consumables/teflons',
        onClick: () => pushWithDispatch('/consumables/teflons')
      }
    ] as ISidebarSubMenuItem[]

    return settingsSubMenu
  }

  const getAnalyticsSubMenu = () => {
    return [
      {
        label: 'OEE',
        routeName: 'analytics/oee',
        onClick: () => pushWithDispatch('/analytics/oee')
      },
      {
        label: 'Operation Logs',
        routeName: 'analytics/logs',
        onClick: () => pushWithDispatch('/analytics/logs')
      },
      {
        label: 'Throughput',
        routeName: 'analytics/throughput',
        onClick: () => pushWithDispatch('/analytics/throughput')
      }
    ]
  }

  const getSidebarMenuItems = () => {
    let sideBarMenuItems = [
      {
        routeName: 'overview',
        onClick: () => pushWithDispatch('/overview'),
        icon: <OverviewIcon />
      }
    ] as {
      routeName: string
      onClick?: () => void
      icon: React.ReactNode
      submenu?: any
    }[]

    if (user && user.isProducer) {
      // //@ts-ignore
      const ordersButton = {
        routeName: 'orders',
        onClick: () => pushWithDispatch('/orders'),
        icon: <JobsIcon />
      }
      sideBarMenuItems.push(ordersButton)
    }

    sideBarMenuItems = [
      ...sideBarMenuItems,
      {
        routeName: 'jobs',
        onClick: () => pushWithDispatch('/jobs/active'),
        icon: <JobsIcon />
      },
      {
        routeName: 'printers',
        onClick: () => pushWithDispatch('/printers'),
        icon: <PrintersIcon />
      },
      {
        routeName: 'analytics',
        icon: <AnalyticsIcon />,
        submenu: getAnalyticsSubMenu()
      },
      {
        routeName: 'timeline',
        onClick: () => pushWithDispatch('/timeline'),
        icon: <TimelineIcon />
      },
      {
        routeName: 'consumables',
        icon: <ResinIcon />,
        submenu: getConsumablesSubMenu()
      },
      {
        routeName: 'settings',
        icon: <SettingsIcon />,
        submenu: getSettingsSubmenu()
      }
    ]

    return sideBarMenuItems
  }

  // Router
  const { location } = useSelector(
    ({ router }: { router: RouterState }) => router
  )

  const getActiveRoute = () => {
    const { pathname } = location
    let activeRouteName = pathname.substr(
      pathname.split('/')[0].length + 1,
      pathname.length
    )
    activeRouteName = activeRouteName === '' ? 'overview' : activeRouteName
    return activeRouteName
  }

  // Toast
  const { toasts } = useSelector(
    ({ toasts }: { toasts: IToastsState }) => toasts
  )

  function dismissToast(id: string) {
    dispatch(dismissToastAction(id))
  }

  const getSelectedPrinterName = () => {
    const activeRoute = getActiveRoute()
    let selectedPrinterName = undefined as undefined | string
    if (activeRoute.split('/')[0] === 'printers') {
      selectedPrinterName = location.pathname.split('/')[2]
    }
    return selectedPrinterName
  }

  const showAddJobForm = () => {
    dispatch(
      showAddJobFormAction({
        preselectedPrinterName: getSelectedPrinterName()
      })
    )
  }

  // Drag Drop

  useEffect(() => {
    const showAddJobFormWithPreselectedFile = (preselectedFile: File) => {
      dispatch(
        showAddJobFormAction({
          preselectedPrinterName: getSelectedPrinterName(),
          preselectedFile
        })
      )
    }

    const handleDragIn = e => {
      e.preventDefault()
      e.stopPropagation()
    }
    const handleDragOut = e => {
      e.preventDefault()
      e.stopPropagation()
      setDragging(true)
    }
    const handleDrag = e => {
      e.preventDefault()
      e.stopPropagation()
    }
    const handleDrop = e => {
      e.preventDefault()
      e.stopPropagation()
      setDragging(false)
      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length > 0
      ) {
        showAddJobFormWithPreselectedFile(e.dataTransfer.files)
        //e.dataTransfer.clearData() not allowed in firfox to call clearData on Drop handler
      }
    }

    dispatch(getThemeAction())
    let div = dropRef.current
    window.addEventListener('dragenter', e => {
      e.preventDefault()
      e.stopPropagation()
      if (
        e.dataTransfer &&
        e.dataTransfer.items &&
        e.dataTransfer.items.length > 0
      ) {
        setDragging(true)
      }
    })
    if (div) {
      div.addEventListener('dragenter', handleDragIn)
      div.addEventListener('dragleave', handleDragOut)
      div.addEventListener('dragover', handleDrag)
      div.addEventListener('drop', handleDrop)
    }
    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn)
        div.removeEventListener('dragleave', handleDragOut)
        div.removeEventListener('dragover', handleDrag)
        div.removeEventListener('drop', handleDrop)
      }
    }
  })

  // Quick Access

  const { visible: quickAccessVisible } = useSelector(
    ({ quickAccess }: { quickAccess: IQuickAccessState }) => quickAccess
  )

  const quickSearchKeyDownHandler = useCallback(
    ({ key }: { key: string }) => {
      // Don't show quick access if user pressed / while focusing on an input field
      if (quickAccessVisible || document.activeElement?.tagName === 'INPUT')
        return
      if (key === '/') dispatch(showQuickAccess())
    },
    [quickAccessVisible, dispatch]
  )

  useEffect(() => {
    window.addEventListener('keydown', quickSearchKeyDownHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', quickSearchKeyDownHandler)
    }
  }, [quickSearchKeyDownHandler])

  // Theme
  const { theme } = useSelector(
    ({ userPreference }: { userPreference: IUserPreferenceState }) =>
      userPreference
  )

  const toggleTheme = () => {
    dispatch(
      setThemeAction({
        theme: theme === ITheme.dark ? ITheme.light : ITheme.dark
      })
    )
  }

  // PWA
  const appInstall = () => dispatch(appInstallAction())
  const hideAppInstallOption = () => dispatch(hideAppInstallOptionAction())

  return (
    <MainAppContainer
      alignContentHorizontal={AlignHorizontal.left}
      alignContentVertical={AlignVertical.top}
      main
      className={theme === ITheme.light ? 'light' : ''}
    >
      <DelayedComponents isMounted={Boolean(quickAccessVisible)}>
        <QuickAccessOverlay visible={Boolean(quickAccessVisible)} />
      </DelayedComponents>
      <div className="customer-info-overlay">{`v${version}`}</div>
      <GuidedTour
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
      />
      <div className={`dropzone${dragging ? ' dragging' : ''}`} ref={dropRef}>
        <div className="dropzone-message">Drop to upload your files</div>
        <div className="dropzone-filetypes">
          File extensions allowed:{' '}
          <span className="dropzone-filetypes__values">
            *.spj, *.spj2, *.stl
          </span>
        </div>
      </div>
      <Filters />
      {isUserLoggedIn && <VersionChecker />}
      <ToastManager onDismissToastAction={dismissToast} toasts={toasts} />
      <PopupManager />
      <Header
        onSidebarClick={sidebarVisible ? hideSidebar : showSidebar}
        onLogoClick={goHome}
        sidebarActive={sidebarVisible}
        visible={isUserLoggedIn}
        onLogout={logout}
        links={ExternalLinks}
        user={user}
        name={getUsername()}
        role={
          user
            ? user.isProducer
              ? 'PRODUCER'
              : user.isMainUser
              ? 'MAIN'
              : user.isSubUser
              ? 'SUB'
              : undefined
            : undefined
        }
        onThemeSelect={toggleTheme}
        isNightMode={theme && theme === ITheme.dark}
      />
      <Sidebar
        onThemeSelect={toggleTheme}
        isNightMode={theme && theme === ITheme.dark}
        visible={isUserLoggedIn}
        onHideSidebar={hideSidebar}
        active={sidebarVisible}
        sidebarMenuItems={getSidebarMenuItems()}
        activeRouteName={getActiveRoute()}
        onAddJob={showAddJobForm}
        links={ExternalLinks}
        showPWAPromo={showAppInstallOption}
        onInstallPWAPromo={appInstall}
        onIgnorePWAPromo={hideAppInstallOption}
      />
      <PageContainer
        sidebarActive={sidebarVisible && isUserLoggedIn}
        dashboardVisible={isUserLoggedIn}
        id="page-container"
      >
        <PageMask visible={sidebarVisible} onHideSidebar={hideSidebar} />
        <Switch location={location}>
          <Route path="/login">
            {isUserLoggedIn ? (
              <Redirect to="/overview" />
            ) : (
              routeProps => (
                <Suspense fallback={<SuspenseLoader />}>
                  <Login {...routeProps} />
                </Suspense>
              )
            )}
          </Route>
          <PrivateRoute exact path="/orders" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Orders />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/analytics/oee"
            isUserLoggedIn={isUserLoggedIn}
          >
            <Suspense fallback={<SuspenseLoader />}>
              <OEE />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/analytics/logs"
            isUserLoggedIn={isUserLoggedIn}
          >
            <Suspense fallback={<SuspenseLoader />}>
              <OperationLogs />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/analytics/throughput"
            isUserLoggedIn={isUserLoggedIn}
          >
            <Suspense fallback={<SuspenseLoader />}>
              <Throughput />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/jobs/:activeTab" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Jobs />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute
            path="/consumables/:activeTab"
            isUserLoggedIn={isUserLoggedIn}
          >
            <Suspense fallback={<SuspenseLoader />}>
              <Consumables />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/settings/users" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Users />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/settings/docs" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Docs />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/settings/api" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Api />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/printers" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Printers />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute
            path="/printers/:printerName"
            isUserLoggedIn={isUserLoggedIn}
          >
            <Suspense fallback={<SuspenseLoader />}>
              <PrinterDetails />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/timeline" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Timeline />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/overview" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Overview />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/" isUserLoggedIn={isUserLoggedIn}>
            <Suspense fallback={<SuspenseLoader />}>
              <Overview />
            </Suspense>
          </PrivateRoute>
        </Switch>
      </PageContainer>
    </MainAppContainer>
  )
}

export default MainApp
