export function addDays(date: Date, days: number) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export function addTime(date: Date, time: number) {
  const result = new Date(date)
  result.setTime(result.getTime() + time)
  return result
}

export function convertLocaleDateToUTCDate(date: Date) {
  const newDate = new Date(date)
  newDate.setUTCHours(
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  )
  newDate.setUTCDate(date.getDate())
  return newDate
}
