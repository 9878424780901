import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { reducers as authReducers } from './redux/auth'
import { reducers as printerReducers } from './redux/printer'
import { reducers as statsReducers } from './redux/stats'
import { reducers as toastReducers } from './redux/toasts'
import { reducers as popupReducers } from './redux/popup'
import { reducers as pastPrintsReducers } from './redux/past-prints'
import { reducers as teamReducers } from './redux/team'
import { reducers as addJobFormReducers } from './redux/addJobForm'
import { reducers as feedbackFormReducers } from './redux/feedbackForm'
import { reducers as exportJobFormReducers } from './redux/exportJobForm'
import { reducers as tourReducers } from './redux/tour'
import { reducers as appReducers } from './redux/app'
import { reducers as apiReducers } from './redux/api-key'
import { reducers as teflonsReducers } from './redux/teflons'
import { reducers as timelineReducers } from './redux/printer-timeline'
import { reducers as printerGroupFormReducers } from './redux/printerGroupForm'
import { reducers as editUserGroupFormReducers } from './redux/editUserGroupForm'
import { reducers as userPreferenceReducers } from './redux/user-preference'
import { reducers as startJobFormReducers } from './redux/startJobForm'
import { reducers as quickAccessReducers } from './redux/quickAccess'
import { reducers as operationLogsFormReducers } from './redux/operationLogsForm'
import { reducers as oeeReducers } from './redux/oee'
import { reducers as orderReducers } from './redux/order'

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    // rest of your reducers
    ...authReducers,
    ...printerReducers,
    ...statsReducers,
    ...toastReducers,
    ...popupReducers,
    ...pastPrintsReducers,
    ...teamReducers,
    ...addJobFormReducers,
    ...feedbackFormReducers,
    ...exportJobFormReducers,
    ...tourReducers,
    ...appReducers,
    ...apiReducers,
    ...teflonsReducers,
    ...timelineReducers,
    ...printerGroupFormReducers,
    ...editUserGroupFormReducers,
    ...userPreferenceReducers,
    ...startJobFormReducers,
    ...quickAccessReducers,
    ...operationLogsFormReducers,
    ...oeeReducers,
    ...orderReducers
  })

export default createRootReducer
