import { isNewerVersion } from './compare-version'

export function setSession(
  accessToken: string,
  refreshToken: string,
  expiresIn: number
) {
  const expiresAt = expiresIn * 1000 + new Date().getTime()
  localStorage.setItem('access_token', accessToken)
  localStorage.setItem('refresh_token', refreshToken)
  localStorage.setItem('expires_at', expiresAt.toString())
}

export function removeSession() {
  // Clear Access Token and Refresh Token from local storage
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('expires_at')
}

export function getSession() {
  const expiresAt = localStorage.getItem('expires_at')
  const token = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')
  return { expiresAt, token, refreshToken }
}

export function setWhatsNewKey(seen = true, version: string) {
  localStorage.setItem('seen_whats_new', seen.toString())
  localStorage.setItem('seen_version', version)
}

export function getWhatsNewKey(latestVersion: string) {
  const seen = localStorage.getItem('seen_whats_new')
  const seenVersion = localStorage.getItem('seen_version')
  if (seenVersion && isNewerVersion(seenVersion, latestVersion)) {
    return false
  }
  return seen ? Boolean(seen) : false
}

export function setTheme(theme: string) {
  localStorage.setItem('user_theme', theme)
}

export function getTheme() {
  return localStorage.getItem('user_theme')
}
