import { takeEvery, fork, call, put, select } from 'redux-saga/effects'
import {
  GET_TEAM_PENDING,
  ADD_USER_PENDING,
  DELETE_USER_PENDING,
  ENABLE_DISABLE_USER_PENDING,
  UPDATE_USER_GROUP_PENDING
} from './constant'
import {
  getTeamFailed,
  getTeamSuccess,
  addSubUserFailed,
  addSubUserSuccess,
  deleteUserFailed,
  deleteUserSuccess,
  enableDisableUserSuccess,
  enableDisableUserFailed,
  updateUserGroupSuccess,
  updateUserGroupFailure
} from './actions'
import {
  getTeam,
  addNewUser,
  deleteUser,
  enableDisableUser,
  updateUserGroup
} from './api'
import { IAuthState } from '../auth/types'
import {
  ISubUser,
  IAddSubUserAction,
  IDeleteSubUserAction,
  IEnableDisableSubUserAction,
  IUpdateUserGroupAction,
  ISubUserRes
} from './types'
import { addToast } from '../toasts/actions'
import { ToastType } from 'backpack'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken

export function* getTeamHandler() {
  try {
    const token: string = yield select(getToken)
    const { subUsers } = yield call(getTeam, token)
    const users: ISubUser[] = subUsers.map((s: ISubUserRes) => {
      return {
        ...s,
        id: s.user_id,
        allPrinters: s.allPrinters || false
      }
    })
    yield put(getTeamSuccess(users))
  } catch (err) {
    yield put(getTeamFailed('Failed to fetch team'))
    yield put(
      addToast({
        title: `Failed to fetch team. Try again later`,
        description: err && err.toString(),
        type: ToastType.error
      })
    )
  }
}

export function* addSubUserHandler({ payload }: IAddSubUserAction) {
  const { firstName, lastName, email, groups, allPrinters } = payload
  try {
    const token = yield select(getToken)
    const { userId } = yield call(
      addNewUser,
      token,
      firstName,
      lastName,
      email,
      groups,
      allPrinters
    )
    const name = `${firstName} ${lastName}`
    const user: ISubUser = {
      id: userId,
      name,
      email,
      blocked: false,
      groups,
      allPrinters: allPrinters || false
    }
    yield put(addSubUserSuccess(user))
  } catch (err) {
    yield put(addSubUserFailed('Failed to add sub user'))
    yield put(
      addToast({
        title: `Add sub user failed`,
        description:
          err.message || 'Add sub user failed. Please try again later.',
        type: ToastType.error
      })
    )
  }
}

export function* deletSubUserHandler({ payload }: IDeleteSubUserAction) {
  const { email } = payload
  try {
    const token = yield select(getToken)
    yield call(deleteUser, email, token)
    yield put(deleteUserSuccess(email))
  } catch (err) {
    yield put(deleteUserFailed(email, 'delete user failed'))
    yield put(
      addToast({
        title: `Delete sub user failed`,
        description:
          err.message || 'Delete sub user failed. Please try again later.',
        type: ToastType.error
      })
    )
  }
}

export function* enableDisableSubUser({
  payload
}: IEnableDisableSubUserAction) {
  const { email, blocked } = payload
  try {
    const token = yield select(getToken)
    yield call(enableDisableUser, blocked, email, token)
    yield put(enableDisableUserSuccess(email, blocked))
    yield put(
      addToast({
        title: `${blocked ? 'Disable' : 'Enable'} success`,
        description: `${blocked ? 'Disable' : 'Enable'} sub user success.`,
        type: ToastType.success
      })
    )
  } catch (err) {
    yield put(enableDisableUserFailed(email, 'enable disable user failed'))
    yield put(
      addToast({
        title: `${blocked ? 'Disable' : 'Enable'} sub user failed`,
        description:
          err.message ||
          `${
            blocked ? 'Disable' : 'Enable'
          } sub user failed. Please try again later.`,
        type: ToastType.error
      })
    )
  }
}

export function* updateUserGroupHandler({ payload }: IUpdateUserGroupAction) {
  const { id, groups, allPrinters } = payload
  try {
    const token = yield select(getToken)
    yield call(updateUserGroup, token, id, groups, allPrinters)
    yield put(updateUserGroupSuccess(id, groups, allPrinters))
    yield put(
      addToast({
        title: `Update user group success`,
        description: `Sub user group has been updated successfully.`,
        type: ToastType.success
      })
    )
  } catch (err) {
    yield put(updateUserGroupFailure())
    yield put(
      addToast({
        title: `Update user group failed`,
        description: err.message || `Please try again later.`,
        type: ToastType.error
      })
    )
  }
}

function* watchGetTeam() {
  yield takeEvery(GET_TEAM_PENDING, getTeamHandler)
}

function* watchAddUser() {
  yield takeEvery(ADD_USER_PENDING, addSubUserHandler)
}

function* watchDeleteUser() {
  yield takeEvery(DELETE_USER_PENDING, deletSubUserHandler)
}

function* watchEnableDisableUser() {
  yield takeEvery(ENABLE_DISABLE_USER_PENDING, enableDisableSubUser)
}

function* watchUpdateUserGropup() {
  yield takeEvery(UPDATE_USER_GROUP_PENDING, updateUserGroupHandler)
}

export default [
  fork(watchGetTeam),
  fork(watchAddUser),
  fork(watchDeleteUser),
  fork(watchEnableDisableUser),
  fork(watchUpdateUserGropup)
]
