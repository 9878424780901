import React from 'react'
import styled from 'styled-components'

export default styled(({ children, ...rest }) => (
  <div {...rest}>{children || `Loading...`}</div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  animation: fadeInOutPartial 2.2s infinite ease forwards;
`
