import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IUserPreferenceState,
  ITheme,
  IGetThemeSuccess,
  ISetThemeAction
} from './types'

export const initialState: IUserPreferenceState = {
  theme: ITheme.dark
}

export const userPreferenceSlice = createSlice({
  name: 'userPreference',
  initialState,
  reducers: {
    getTheme: (state, action: PayloadAction) => {},
    getThemeSuccess: (state, action: PayloadAction<IGetThemeSuccess>) => {
      state.theme = action.payload.theme
    },
    setTheme: (state, action: PayloadAction<ISetThemeAction>) => {
      state.theme = action.payload.theme
    }
  }
})

export const {
  getTheme,
  getThemeSuccess,
  setTheme
} = userPreferenceSlice.actions

export default userPreferenceSlice.reducer
