import createReducer from '../../utils/create-reducer'
import {
  GET_TEAM_PENDING,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  ADD_USER_PENDING,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  DELETE_USER_PENDING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  ENABLE_DISABLE_USER_PENDING,
  ENABLE_DISABLE_USER_SUCCESS,
  ENABLE_DISABLE_USER_FAILURE,
  UPDATE_USER_GROUP_PENDING,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAILURE
} from './constant'
import { ITeamsState, ISubUser } from './types'

export const initialStates: ITeamsState = {
  isGettingTeam: false,
  team: [],
  errorMessage: undefined,
  isAddingUser: false,
  isUpdatingUserGroup: false
}

export default createReducer(initialStates, {
  [GET_TEAM_PENDING]: (state: ITeamsState) => ({
    ...state,
    isGettingTeam: true
  }),

  [GET_TEAM_SUCCESS]: (state: ITeamsState, { team }: { team: ISubUser[] }) => ({
    ...state,
    isGettingTeam: false,
    team: [...team]
  }),

  [GET_TEAM_FAILURE]: (
    state: ITeamsState,
    { message }: { message: string }
  ) => ({
    ...state,
    isGettingTeam: false,
    errorMessage: message
  }),

  [ADD_USER_PENDING]: (state: ITeamsState) => ({
    ...state,
    isAddingUser: true
  }),

  [ADD_USER_SUCCESS]: (state: ITeamsState, { user }: { user: ISubUser }) => ({
    ...state,
    isAddingUser: false,
    team: [...state.team, user]
  }),

  [ADD_USER_FAILURE]: (
    state: ITeamsState,
    { message }: { message: string }
  ) => ({
    ...state,
    isAddingUser: false,
    errorMessage: message
  }),

  [DELETE_USER_PENDING]: (
    state: ITeamsState,
    { email }: { email: string }
  ) => ({
    ...state,
    team: state.team.map(t => {
      if (t.email === email) {
        t.isDeletingUser = true
      }
      return { ...t }
    })
  }),

  [DELETE_USER_SUCCESS]: (
    state: ITeamsState,
    { email }: { email: string }
  ) => ({
    ...state,
    team: state.team.filter(t => t.email !== email)
  }),

  [DELETE_USER_FAILURE]: (
    state: ITeamsState,
    { email, message }: { email: string; message: string }
  ) => ({
    ...state,
    team: state.team.map(t => {
      if (t.email === email) {
        t.isDeletingUser = false
      }
      return { ...t }
    }),
    errorMessage: message
  }),

  [ENABLE_DISABLE_USER_PENDING]: (
    state: ITeamsState,
    { email }: { email: string }
  ) => ({
    ...state,
    team: state.team.map(t => {
      if (t.email === email) {
        t.isEnableDisableInProgress = true
      }
      return { ...t }
    })
  }),

  [ENABLE_DISABLE_USER_SUCCESS]: (
    state: ITeamsState,
    { email, blocked }: { email: string; blocked: boolean }
  ) => ({
    ...state,
    team: state.team.map(t => {
      if (t.email === email) {
        t.blocked = blocked
        t.isEnableDisableInProgress = false
      }
      return { ...t }
    })
  }),

  [ENABLE_DISABLE_USER_FAILURE]: (
    state: ITeamsState,
    { email, message }: { email: string; message: string }
  ) => ({
    ...state,
    team: state.team.map(t => {
      if (t.email === email) {
        t.isEnableDisableInProgress = false
      }
      return { ...t }
    }),
    errorMessage: message
  }),

  [UPDATE_USER_GROUP_PENDING]: (state: ITeamsState) => ({
    ...state,
    isUpdatingUserGroup: true
  }),

  [UPDATE_USER_GROUP_SUCCESS]: (
    state: ITeamsState,
    {
      id,
      groups,
      allPrinters
    }: { id: string; groups?: string[]; allPrinters?: boolean }
  ) => ({
    ...state,
    isUpdatingUserGroup: false,
    team: state.team.map(t => {
      if (t.id === id) {
        t.groups = groups
        t.allPrinters = allPrinters || false
      }
      return { ...t }
    })
  }),

  [UPDATE_USER_GROUP_FAILURE]: (state: ITeamsState) => ({
    ...state,
    isUpdatingUserGroup: false
  })
})
