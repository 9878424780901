export const JOBS_GET_LIST_PENDING = 'JOB_GET_LIST_PENDING'
export const JOBS_GET_LIST_SUCCESS = 'JOB_GET_LIST_SUCCESS'
export const JOBS_GET_LIST_FAILED = 'JOB_GET_LIST_FAILED'
export const PRINT_FEEDBACK_PENDING = 'PRINT_FEEDBACK_PENDING'
export const PRINT_FEEDBACK_SUCCESS = 'PRINT_FEEDBACK_SUCCESS'
export const PRINT_FEEDBACK_FAILED = 'PRINT_FEEDBACK_FAILED'
export const EXPORT_JOBS = 'EXPORT_JOBS'
export const EXPORT_JOBS_SUCCESS = 'EXPORT_JOBS_SUCCESS'
export const EXPORT_JOBS_FAILURE = 'EXPORT_JOBS_FAILURE'
export const ADD_NEW_JOB = 'ADD_NEW_JOB'
export const ADD_NEW_JOB_SUCCESS = 'ADD_NEW_JOB_SUCCESS'
export const ADD_NEW_JOB_FAILURE = 'ADD_NEW_JOB_FAILURE'
