import { getFetchOpts } from '../../utils/pre-fetch'
import { parseJsonResponse } from '../../utils/post-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function getTeflonsApi(token: string, page: number, limit: number) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/get-teflons?page=${page}&limit=${limit}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}
