import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'backpack'
import { useSelector, useDispatch } from 'react-redux'

import { IPrinterState } from '../../redux/printer/types'
import {
  FiltersBox,
  FilterDateRangeDropdown,
  FilterDropdown
} from '../../components/Filters'
import { getOEEOverview } from '../../redux/oee/slice'
import { RootState } from '../../store'
import useSearchParams from '../../hooks/useSearchParams'

export const OEESharedStyle = css`
  --color-progressbar-bg: var(--color-bg-mask);
  --color-availability: #97d8ff;
  --color-performance: #ff8a8a;
  --color-quality: #c181ff;
  --color-oee-1: #ff321a;
  --color-oee-2: #ff621e;
  --color-oee-3: #ffae2c;
  --color-oee-4: #ffcd29;
  --color-oee-5: #ffff36;
  --color-oee-6: #daf500;
  --color-oee-7: #70c801;
`

export const OEEContainer = styled(props => <Container {...props} full />)`
  ${OEESharedStyle}
  height: auto;
  min-height: 100%;
`

export const OEEContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  width: 100%;
  gap: 12px;
  border: 1px solid var(--color-border-primary);
  border-radius: 10px;
  background: var(--color-bg-canvas-inset);
  flex: 1;
  overflow: auto;
`

export type DateValues = {
  from: Date
  to: Date
}

export const dateTimes = [
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'Yesterday',
    value: 'yesterday'
  },
  {
    label: 'Past week',
    value: 'week'
  },
  {
    label: 'Past month',
    value: 'month'
  },
  {
    label: 'Past year',
    value: 'year'
  },
  {
    label: 'Custom range...',
    value: 'custom'
  }
]

interface IOEEFilter extends React.HTMLAttributes<HTMLDivElement> {}

export function OEEFilter(props: IOEEFilter) {
  const dispatch = useDispatch()
  const { getParams, setParams } = useSearchParams()

  const startDate = getParams('date_range.start')
  const endDate = getParams('date_range.end')

  const customDateValueFromURL =
    startDate && endDate
      ? {
          from: new Date(
            Number(startDate.split('-')[0]),
            Number(startDate.split('-')[1]) - 1,
            Number(startDate.split('-')[2])
          ),
          to: new Date(
            Number(endDate.split('-')[0]),
            Number(endDate.split('-')[1]) - 1,
            Number(endDate.split('-')[2])
          )
        }
      : null

  // Printer selector
  const printers = useSelector(
    ({ printerData }: { printerData: IPrinterState }) => printerData.printers
  )

  const getPrinterOptions = () =>
    printers
      .map(({ name, customPrinterName }) => ({
        label: customPrinterName || name,
        value: name
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

  const printerOptions = getPrinterOptions()

  const [selectedPrinter, setSelectedPrinter] = useState<string>(
    getParams('printer') || printerOptions[0]?.value
  )

  const onSelectPrinter = (printer: string) => {
    setSelectedPrinter(printer)
    setParams('printer', printer)
    fetchOEE({ selectedPrinterValue: printer })
  }

  // Date Range
  const [filterDateTime, setFilterDateTime] = useState<string | null>(
    customDateValueFromURL ? 'custom' : 'today'
  )
  const [customDateValues, setCustomDateValues] = useState<
    DateValues | undefined
  >(customDateValueFromURL || getCustomDateValuesFromInput('today'))

  function getCustomDateValuesFromInput(value?: string) {
    const inputValue = value || filterDateTime

    const now = new Date()
    let to = new Date(now.getTime())
    let from = new Date(now.getTime())

    switch (inputValue) {
      case 'today':
        break
      case 'yesterday':
        from.setDate(from.getDate() - 1)
        to.setDate(to.getDate() - 1)
        break
      case 'week':
        from.setDate(from.getDate() - 7)
        break
      case 'month':
        from.setMonth(from.getMonth() - 1)
        break
      case 'year':
        from.setFullYear(from.getFullYear() - 1)
        break
      case 'custom':
        if (customDateValues) {
          from = customDateValues.from
          to = customDateValues.to
        } else {
          from.setDate(from.getDate() - 1)
        }
        break
      default:
        from.setFullYear(from.getDate() - 1)
    }

    from.setHours(0, 0, 0, 0)
    to.setHours(23, 59, 59, 0)

    return {
      from,
      to
    }
  }

  const getDateTimeString = () => {
    const { from, to } = getCustomDateValuesFromInput()

    return `${from.getDate()}/${from.getMonth() +
      1}/${from.getFullYear()} - ${to.getDate()}/${to.getMonth() +
      1}/${to.getFullYear()}`
  }

  const setDateTime = (value: string) => {
    const { from, to } = getCustomDateValuesFromInput(value)
    setFilterDateTime(value)
    setCustomDateValues({ from, to })
    setParams(
      'date_range.start',
      `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`
    )
    setParams(
      'date_range.end',
      `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`
    )
    fetchOEE({ customDateValues: { from, to } })
  }

  const setDateRange = (from: Date, to: Date) =>
    setCustomDateValues({ from, to })

  interface IFetchOperationLogs {
    customDateValues?: {
      from: Date
      to: Date
    }
    selectedPrinterValue?: string
  }

  function fetchOEE({
    customDateValues,
    selectedPrinterValue
  }: IFetchOperationLogs) {
    const { from, to } = customDateValues || getCustomDateValuesFromInput()
    const printer = selectedPrinterValue || selectedPrinter
    dispatch(
      getOEEOverview({
        fromDate: from,
        toDate: to,
        printer: printer
      })
    )
  }

  const callbackFetchOEE = useCallback(fetchOEE, [])

  // Fetch Operation Logs on did mount
  useEffect(() => {
    callbackFetchOEE({})
  }, [callbackFetchOEE])

  return (
    <FiltersBox visible={true} data-tut="oee-filters" {...props}>
      <FilterDropdown
        onChange={onSelectPrinter}
        options={getPrinterOptions()}
        value={selectedPrinter}
      />
      <FilterDateRangeDropdown
        onChange={setDateTime}
        customDateRangeValue={customDateValues}
        onDateRangeSave={setDateRange}
        options={dateTimes}
        value={filterDateTime}
        fixedValue={getDateTimeString()}
      />
    </FiltersBox>
  )
}

const OEEMeterContainer = styled.div`
  width: 220px;
  height: 220px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -28px;
  svg.needle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg) scale(0.9);
    transition: all 0.5s ease;
    width: 110%;
  }
  text {
    font-family: Montserrat Semibold;
    font-size: 24px;
    fill: var(--color-text-primary);
  }
  text.percentage-number {
    font-size: 48px;
  }
  text.title {
    font-size: 36px;
    fill: var(--color-text-secondary);
  }
`

interface IOEEMeter {
  progress?: number
}

export function OEEMeter({ progress }: IOEEMeter) {
  const minDegree = 44
  const maxDegree = 316
  const diff = maxDegree - minDegree
  let needleRotation = progress ? (diff / 100) * progress : 0

  return (
    <OEEMeterContainer>
      <svg viewBox="0 0 303 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M45.7875 257.212C24.8796 236.305 10.6411 209.666 4.8726 180.666C-0.89589 151.666 2.06471 121.606 13.38 94.2888C24.6953 66.9713 43.8571 43.6226 68.4423 27.1953C93.0274 10.768 121.932 2 151.5 2C181.068 2 209.973 10.768 234.558 27.1953C259.143 43.6226 278.305 66.9713 289.62 94.2888C300.935 121.606 303.896 151.666 298.127 180.666C292.359 209.666 278.12 236.305 257.212 257.212L214.927 214.927C227.472 202.383 236.015 186.4 239.476 169C242.938 151.6 241.161 133.564 234.372 117.173C227.583 100.783 216.086 86.7735 201.335 76.9172C186.584 67.0608 169.241 61.8 151.5 61.8C133.759 61.8 116.416 67.0608 101.665 76.9172C86.9143 86.7735 75.4172 100.783 68.628 117.173C61.8388 133.564 60.0625 151.6 63.5236 169C66.9846 186.4 75.5277 202.383 88.0725 214.927L45.7875 257.212Z"
          fill="#70C801"
        />
        <path
          d="M45.7875 257.212C27.9011 239.326 14.8455 217.194 7.84286 192.887C0.840229 168.58 0.119549 142.894 5.74828 118.233C11.377 93.572 23.1711 70.7424 40.0265 51.8812C56.8819 33.02 78.2475 18.7439 102.123 10.3894C125.999 2.03494 151.604 -0.124833 176.542 4.11229C201.48 8.34941 224.935 18.8449 244.712 34.6162C264.488 50.3876 279.94 70.9191 289.62 94.2889C299.3 117.659 302.892 143.102 300.06 168.239L240.636 161.543C242.335 146.462 240.18 131.195 234.372 117.173C228.564 103.151 219.293 90.8325 207.427 81.3697C195.561 71.9069 181.488 65.6096 166.525 63.0674C151.563 60.5251 136.199 61.821 121.874 66.8337C107.549 71.8464 94.7292 80.412 84.6159 91.7287C74.5026 103.045 67.4262 116.743 64.049 131.54C60.6717 146.337 61.1041 161.748 65.3057 176.332C69.5073 190.916 77.3406 204.196 88.0725 214.927L45.7875 257.212Z"
          fill="#DAF500"
        />
        <path
          d="M45.7875 257.212C30.9065 242.331 19.3349 224.476 11.8298 204.815C4.32468 185.154 1.05519 164.129 2.23519 143.117C3.41519 122.106 9.0181 101.58 18.6774 82.8822C28.3366 64.1849 41.8346 47.7375 58.2883 34.6162C74.7419 21.4949 93.7805 11.9952 114.158 6.73862C134.536 1.48208 155.795 0.587114 176.542 4.11228C197.29 7.63745 217.059 15.5033 234.558 27.1953C252.056 38.8873 266.889 54.1419 278.085 71.9612L227.451 103.777C220.733 93.0851 211.834 83.9324 201.335 76.9172C190.836 69.902 178.974 65.1825 166.525 63.0674C154.077 60.9523 141.322 61.4892 129.095 64.6432C116.868 67.7971 105.445 73.4969 95.573 81.3697C85.7008 89.2425 77.602 99.1109 71.8064 110.329C66.0109 121.548 62.6491 133.863 61.9411 146.47C61.2331 159.078 63.1948 171.692 67.6979 183.489C72.2009 195.286 79.1439 205.999 88.0725 214.927L45.7875 257.212Z"
          fill="#FFFF36"
        />
        <path
          d="M45.7875 257.212C21.8392 233.264 6.73205 201.894 2.94002 168.239C-0.851999 134.584 6.89582 100.638 24.9147 71.9612C42.9337 43.2843 70.1558 21.5754 102.123 10.3894C134.091 -0.796483 168.909 -0.796479 200.877 10.3894L181.126 66.8337C161.946 60.1221 141.055 60.1221 121.874 66.8337C102.693 73.5452 86.3602 86.5705 75.5488 103.777C64.7375 120.983 60.0888 141.35 62.364 161.543C64.6392 181.736 73.7035 200.558 88.0725 214.927L45.7875 257.212Z"
          fill="#FFCD29"
        />
        <path
          d="M45.7875 257.212C27.9011 239.326 14.8455 217.194 7.84286 192.887C0.840231 168.58 0.119549 142.894 5.74828 118.233C11.377 93.5721 23.1711 70.7424 40.0265 51.8812C56.8819 33.02 78.2475 18.7439 102.123 10.3894L121.874 66.8337C107.548 71.8464 94.7291 80.412 84.6159 91.7287C74.5026 103.045 67.4262 116.743 64.049 131.54C60.6717 146.337 61.1041 161.748 65.3057 176.332C69.5073 190.916 77.3406 204.196 88.0725 214.927L45.7875 257.212Z"
          fill="#FFAE2C"
        />
        <path
          d="M45.7875 257.212C21.8392 233.264 6.73205 201.894 2.94002 168.239C-0.851999 134.584 6.89582 100.638 24.9147 71.9612L75.5488 103.777C64.7375 120.983 60.0888 141.35 62.364 161.543C64.6392 181.736 73.7035 200.558 88.0725 214.927L45.7875 257.212Z"
          fill="#FF621E"
        />
        <path
          d="M45.7875 257.212C21.8392 233.264 6.73205 201.894 2.94002 168.239L62.364 161.543C64.6392 181.736 73.7035 200.558 88.0725 214.927L45.7875 257.212Z"
          fill="#FF321A"
        />
        <mask
          id="path-8-outside-1_35:709"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="303"
          height="261"
          fill="black"
        >
          <rect fill="white" width="303" height="261" />
          <path d="M45.7875 257.212C24.8796 236.305 10.6411 209.666 4.8726 180.666C-0.89589 151.666 2.06471 121.606 13.38 94.2888C24.6953 66.9713 43.8571 43.6226 68.4423 27.1953C93.0274 10.768 121.932 2 151.5 2C181.068 2 209.973 10.768 234.558 27.1953C259.143 43.6226 278.305 66.9713 289.62 94.2888C300.935 121.606 303.896 151.666 298.127 180.666C292.359 209.666 278.12 236.305 257.212 257.212L214.927 214.927C227.472 202.383 236.015 186.4 239.476 169C242.938 151.6 241.161 133.564 234.372 117.173C227.583 100.783 216.086 86.7735 201.335 76.9172C186.584 67.0608 169.241 61.8 151.5 61.8C133.759 61.8 116.416 67.0608 101.665 76.9172C86.9143 86.7735 75.4172 100.783 68.628 117.173C61.8388 133.564 60.0625 151.6 63.5236 169C66.9846 186.4 75.5277 202.383 88.0725 214.927L45.7875 257.212Z" />
        </mask>
        <path
          d="M45.7875 257.212C24.8796 236.305 10.6411 209.666 4.8726 180.666C-0.89589 151.666 2.06471 121.606 13.38 94.2888C24.6953 66.9713 43.8571 43.6226 68.4423 27.1953C93.0274 10.768 121.932 2 151.5 2C181.068 2 209.973 10.768 234.558 27.1953C259.143 43.6226 278.305 66.9713 289.62 94.2888C300.935 121.606 303.896 151.666 298.127 180.666C292.359 209.666 278.12 236.305 257.212 257.212L214.927 214.927C227.472 202.383 236.015 186.4 239.476 169C242.938 151.6 241.161 133.564 234.372 117.173C227.583 100.783 216.086 86.7735 201.335 76.9172C186.584 67.0608 169.241 61.8 151.5 61.8C133.759 61.8 116.416 67.0608 101.665 76.9172C86.9143 86.7735 75.4172 100.783 68.628 117.173C61.8388 133.564 60.0625 151.6 63.5236 169C66.9846 186.4 75.5277 202.383 88.0725 214.927L45.7875 257.212Z"
          stroke="var(--color-text-primary)"
          strokeWidth="4"
          mask="url(#path-8-outside-1_35:709)"
        />
        <text
          className="percentage-number"
          x="49.4%"
          y="72.7%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {progress?.toFixed(0)}
        </text>
        <text
          className="percentage-symbol"
          x="49.4%"
          y="83.7%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {'%'}
        </text>
        <text
          className="title"
          x="49.4%"
          y="96%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          OEE
        </text>
      </svg>
      <svg
        width="300"
        height="300"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="needle"
        style={{
          transform: `translate(-50%, -50%) scale(0.9) rotate(${needleRotation +
            minDegree}deg)`
        }}
      >
        <path
          d="M149.008 268.899L133.617 152.695C132.305 142.793 140.011 134 150 134C159.988 134 167.694 142.793 166.382 152.695L150.991 268.899C150.837 270.057 149.162 270.057 149.008 268.899Z"
          fill="#363636"
          stroke="var(--color-text-primary)"
          strokeWidth="2"
        />
      </svg>
    </OEEMeterContainer>
  )
}

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  .text-portion {
    display: flex;
    text-transform: capitalize;
    justify-content: space-between;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 16px;
    color: var(--color-text-primary);
  }
  .progress-bar {
    height: 12px;
    width: 100%;
    border-radius: 5px;
    position: relative;
  }
  .progress-bar > div {
    height: inherit;
    border-radius: inherit;
    position: absolute;
    left: 0;
    z-index: 0;
    background: var(--color-progressbar-bg);
    width: 100%;
    max-width: 100%;
    transition: width 0.5s ease;
  }
  .progress-bar > div.fill {
    z-index: 1;
  }
`

interface IProgress {
  name: string
  progress: number
  color: string
}

const Progress = ({ name, progress, color }: IProgress) => (
  <ProgressContainer>
    <div className="text-portion">
      <span className="text-portion__name">{name}</span>
      <span className="text-portion__number">
        {progress ? `${progress.toFixed(2)}%` : '-'}
      </span>
    </div>
    <div className="progress-bar">
      <div
        className="fill"
        style={{ width: `${progress}%`, backgroundColor: color }}
      />
      <div className="bg" />
    </div>
  </ProgressContainer>
)

const APQProgressContainer = styled.div`
  width: 100%;
  max-width: 400px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

interface IAPQProgress {
  availability: number
  performance: number
  quality: number
}

function APQProgress({ availability, performance, quality }: IAPQProgress) {
  return (
    <APQProgressContainer>
      <Progress
        name="availability"
        progress={availability}
        color={`var(--color-availability)`}
      />
      <Progress
        name="performance"
        progress={performance}
        color={`var(--color-performance)`}
      />
      <Progress
        name="quality"
        progress={quality}
        color={`var(--color-quality)`}
      />
    </APQProgressContainer>
  )
}

const OverviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  gap: 36px;
  padding: 0;
  margin-bottom: 36px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

interface IOEEOverviewProgress {
  oee: number
  availability: number
  performance: number
  quality: number
}

export function OEEOverview() {
  const oeeOverview = useSelector(({ oee }: RootState) => oee.oeeOverview)

  return (
    <OverviewWrapper>
      <OEEMeter progress={oeeOverview ? oeeOverview.oee : 0} />
      <APQProgress
        availability={oeeOverview ? oeeOverview.availability : 0}
        performance={oeeOverview ? oeeOverview.performance : 0}
        quality={oeeOverview ? oeeOverview.quality : 0}
      />
    </OverviewWrapper>
  )
}

const BreakdownItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 344px;
  width: 100%;
  align-items: center;
  gap: 12px;
`

const BreakdownProperties = styled.div`
  border: 1px solid var(--color-border-primary);
  box-sizing: border-box;
  padding: 12px;
  border-radius: 10px;
  background: var(--color-bg-canvas);
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  font-size: 14px;
  flex: 1;
  .label {
    color: var(--color-text-primary);
    opacity: 0.7;
  }
`

const BreakdownPropertiesFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface BreakdownPropertiesField {
  label: string
  value: string
  color?: string
}

const BreakdownPropertiesField = ({
  label,
  value,
  color
}: BreakdownPropertiesField) => (
  <BreakdownPropertiesFieldContainer>
    <span className="label">{label}</span>
    <span className="value">{value}</span>
  </BreakdownPropertiesFieldContainer>
)

const BreakdownProgressContainer = styled.div`
  margin-bottom: -60px;
  text {
    font-family: Montserrat Semibold;
    font-size: 24px;
    fill: var(--color-text-primary);
  }
  text.title {
    font-size: 20px;
  }
`

interface IBreakdownProgress extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  progress: number
  color: string
}

function BreakdownProgress({ name, progress, color }: IBreakdownProgress) {
  const minDegree = 180
  const maxDegree = 360
  const diff = maxDegree - minDegree
  const arcRotation = progress ? (diff / 100) * progress : 0
  let totalRotation = arcRotation + minDegree
  totalRotation = totalRotation > 360 ? 360 : totalRotation
  return (
    <BreakdownProgressContainer>
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 201"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.333252 100.394C0.333253 87.2548 2.91121 74.2439 7.91993 62.1046C12.9286 49.9653 20.27 38.9352 29.5249 29.6442C38.7799 20.3531 49.767 12.9831 61.8591 7.95479C73.9513 2.92652 86.9115 0.338499 99.9999 0.338501C113.088 0.338503 126.049 2.92652 138.141 7.9548C150.233 12.9831 161.22 20.3531 170.475 29.6442C179.73 38.9352 187.071 49.9653 192.08 62.1046C197.089 74.2439 199.667 87.2548 199.667 100.394L179.733 100.394C179.733 89.8827 177.671 79.474 173.664 69.7625C169.657 60.0511 163.784 51.227 156.38 43.7942C148.976 36.3614 140.186 30.4653 130.513 26.4427C120.839 22.4201 110.471 20.3497 99.9999 20.3497C89.5292 20.3497 79.161 22.4201 69.4873 26.4427C59.8136 30.4653 51.0239 36.3613 43.6199 43.7942C36.216 51.227 30.3429 60.0511 26.3359 69.7625C22.329 79.474 20.2666 89.8827 20.2666 100.394L0.333252 100.394Z"
          fill="var(--color-progressbar-bg)"
        />
        <path
          d="M0.333252 100.394C0.333253 87.2548 2.91121 74.2439 7.91993 62.1046C12.9286 49.9653 20.27 38.9352 29.5249 29.6442C38.7799 20.3531 49.767 12.9831 61.8591 7.95479C73.9513 2.92652 86.9115 0.338499 99.9999 0.338501C113.088 0.338503 126.049 2.92652 138.141 7.9548C150.233 12.9831 161.22 20.3531 170.475 29.6442C179.73 38.9352 187.071 49.9653 192.08 62.1046C197.089 74.2439 199.667 87.2548 199.667 100.394L179.733 100.394C179.733 89.8827 177.671 79.474 173.664 69.7625C169.657 60.0511 163.784 51.227 156.38 43.7942C148.976 36.3614 140.186 30.4653 130.513 26.4427C120.839 22.4201 110.471 20.3497 99.9999 20.3497C89.5292 20.3497 79.161 22.4201 69.4873 26.4427C59.8136 30.4653 51.0239 36.3613 43.6199 43.7942C36.216 51.227 30.3429 60.0511 26.3359 69.7625C22.329 79.474 20.2666 89.8827 20.2666 100.394L0.333252 100.394Z"
          // fill="#97D8FF"
          fill={`url(#${name.toLowerCase()})`}
        />
        <text
          className="percentage-number"
          x="49.4%"
          y="41%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {`${progress.toFixed(1) || '0'}%`}
        </text>
        <text
          className="title"
          x="50%"
          y="59.5%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {name}
        </text>
        <pattern id={name.toLowerCase()} width="1" height="1">
          <path
            style={{
              transformOrigin: 'center',
              transform: `rotate(${totalRotation}deg)`,
              transition: 'transform 0.5s ease'
            }}
            d="M0.333252 100.394C0.333253 87.2548 2.91121 74.2439 7.91993 62.1046C12.9286 49.9653 20.27 38.9352 29.5249 29.6442C38.7799 20.3531 49.767 12.9831 61.8591 7.95479C73.9513 2.92652 86.9115 0.338499 99.9999 0.338501C113.088 0.338503 126.049 2.92652 138.141 7.9548C150.233 12.9831 161.22 20.3531 170.475 29.6442C179.73 38.9352 187.071 49.9653 192.08 62.1046C197.089 74.2439 199.667 87.2548 199.667 100.394L179.733 100.394C179.733 89.8827 177.671 79.474 173.664 69.7625C169.657 60.0511 163.784 51.227 156.38 43.7942C148.976 36.3614 140.186 30.4653 130.513 26.4427C120.839 22.4201 110.471 20.3497 99.9999 20.3497C89.5292 20.3497 79.161 22.4201 69.4873 26.4427C59.8136 30.4653 51.0239 36.3613 43.6199 43.7942C36.216 51.227 30.3429 60.0511 26.3359 69.7625C22.329 79.474 20.2666 89.8827 20.2666 100.394L0.333252 100.394Z"
            fill={color}
          ></path>
        </pattern>
      </svg>
    </BreakdownProgressContainer>
  )
}

const OverviewBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 36px;
  }
`

export function OverviewBreakdown() {
  const oeeOverview = useSelector(({ oee }: RootState) => oee.oeeOverview)

  const availability = oeeOverview?.availability || 0
  const totalProductionTime = oeeOverview?.totalProductionTime || 0
  const plannedDownTime = oeeOverview?.plannedDownTime || 0
  const unPlannedDownTime = oeeOverview?.unPlannedDownTime || 0
  const actualProductionTime =
    totalProductionTime - plannedDownTime - unPlannedDownTime

  const performance = oeeOverview?.performance || 0
  const totalProductionUnits = oeeOverview?.totalProductionUnits || 0
  const rejectedProductionUnits = oeeOverview?.rejectedProductionUnits || 0
  const usableProductionUnits = totalProductionUnits - rejectedProductionUnits

  const quality = oeeOverview?.quality || 0

  return (
    <OverviewBreakdownWrapper>
      <BreakdownItem>
        <BreakdownProgress
          name="Availability"
          progress={availability}
          color="var(--color-availability)"
        />
        <BreakdownProperties>
          <BreakdownPropertiesField
            label="Total Production Time"
            value={`${totalProductionTime} mins`}
          />
          <BreakdownPropertiesField
            label="Actual Production Time"
            value={`${actualProductionTime} mins`}
          />
          <BreakdownPropertiesField
            label="Planned Downtime"
            value={`${plannedDownTime} mins`}
          />
          <BreakdownPropertiesField
            label="Unplanned Downtime"
            value={`${unPlannedDownTime} mins`}
          />
        </BreakdownProperties>
      </BreakdownItem>

      <BreakdownItem>
        <BreakdownProgress
          name="Performance"
          progress={performance}
          color="var(--color-performance)"
        />
        <BreakdownProperties>
          <BreakdownPropertiesField
            label="Total Production Units"
            value={String(totalProductionUnits)}
          />
          <BreakdownPropertiesField
            label="Actual Production Time"
            value={`${actualProductionTime} mins`}
          />
        </BreakdownProperties>
      </BreakdownItem>

      <BreakdownItem>
        <BreakdownProgress
          name="Quality"
          progress={quality}
          color="var(--color-quality)"
        />
        <BreakdownProperties>
          <BreakdownPropertiesField
            label="Total Production Units"
            value={String(totalProductionUnits)}
          />
          <BreakdownPropertiesField
            label="Usable Production Units"
            value={String(usableProductionUnits)}
          />
          <BreakdownPropertiesField
            label="Rejected Production Units"
            value={String(rejectedProductionUnits)}
          />
        </BreakdownProperties>
      </BreakdownItem>
    </OverviewBreakdownWrapper>
  )
}

export const availability = (
  totalProductionTime: number,
  plannedDownTime: number,
  unPlannedDownTime: number
) => {
  const actualProductionTime =
    totalProductionTime - plannedDownTime - unPlannedDownTime
  const plannedProductionTime = totalProductionTime - plannedDownTime
  return (actualProductionTime / plannedProductionTime) * 100
}

export const quality = (
  rejectedProductionUnits: number,
  totalProductionUnits: number
) => {
  return (
    ((totalProductionUnits - rejectedProductionUnits) / totalProductionUnits) *
    100
  )
}

export const performance = (operationLog: ILog) => {
  const {
    idealCycleTime,
    totalProductionTime,
    plannedDownTime,
    unPlannedDownTime,
    totalProductionUnits
  } = operationLog
  const actualProductionTime =
    totalProductionTime - plannedDownTime - unPlannedDownTime
  return ((idealCycleTime * totalProductionUnits) / actualProductionTime) * 100
}

export interface ILog {
  totalProductionTime: number // in minutes
  plannedDownTime: number // in minutes
  unPlannedDownTime: number // in minutes
  totalProductionUnits: number
  rejectedProductionUnits: number
  idealCycleTime: number
}

export const getOperationStats = (operationLog: ILog) => {
  const {
    totalProductionTime,
    plannedDownTime,
    unPlannedDownTime,
    rejectedProductionUnits,
    totalProductionUnits
  } = operationLog
  const avail = availability(
    totalProductionTime,
    plannedDownTime,
    unPlannedDownTime
  )
  const qual = quality(rejectedProductionUnits, totalProductionUnits)
  const perf = performance(operationLog)

  return {
    availability: avail,
    quality: qual,
    performance: perf,
    oee: (avail / 100) * (qual / 100) * (perf / 100) * 100
  }
}

export const PopoverContainer = styled.div`
  margin: 0 20px;
  box-sizing: border-box;
  padding: 12px;
  background: var(--color-bg-canvas);
  box-shadow: 1px 3px 3px 0px rgb(0 0 0 / 15%);
  border: 1px solid var(--color-border-primary);
  border-radius: 10px;
  line-height: 120%;
`
