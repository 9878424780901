import React from 'react'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import {
  Popup,
  Loader,
  BackArrowIcon,
  PlusIcon,
  Button,
  SmileyIcon,
  SadIcon,
  FeedbackIcon
} from 'backpack'

import { printFeedback } from '../../redux/past-prints/actions'
import { IPastPrintsState, IPrintFeedback } from '../../redux/past-prints/types'
import { IFeedbackFormState } from '../../redux/feedbackForm/types'

const YesButton = styled(({ className, highlight, ...rest }) => (
  <div className={`${className}${highlight ? ' highlighted' : ''}`} {...rest}>
    <SmileyIcon />
    <span className="text">YES</span>
  </div>
))`
  transition: all 0.3s ease;
  background: transparent;
  opacity: 0.6;
  &:hover,
  &.highlighted {
    background: #20f562;
    opacity: 0.8;
  }
  .text {
    color: #20f562;
    mix-blend-mode: difference;
  }
  svg {
    mix-blend-mode: difference;
  }
`

const NoButton = styled(({ className, ...rest }) => (
  <div className={className} {...rest}>
    <SadIcon />
    <span className="text">NO</span>
  </div>
))`
  transition: all 0.3s ease;
  background: transparent;
  opacity: 0.6;
  &:hover {
    background: #c33;
    opacity: 0.8;
  }
  .text {
    color: #c33;
    mix-blend-mode: difference;
  }
  svg {
    mix-blend-mode: difference;
  }
`

const FeedbackFormContainer = styled.div`
  width: 100%;
  .description {
    opacity: 0.7;
  }
  .form-header {
    display: flex;
    position: relative;
    place-content: center;
    place-items: center;
    height: 30px;
  }
  .back-btn {
    position: absolute;
    left: 0;
    cursor: pointer;
    padding: 10px;
    transition: all 0.3s ease;
    opacity: 0.7;
  }
  .back-btn:hover {
    transform: scale(1.05);
    opacity: 1;
  }
  .back-btn svg {
    height: 20px;
    width: 15px;
  }
  .add-field-btn {
    margin-top: 10px;
    align-self: center;
    display: flex;
    align-items: center;
    color: var(--p-color-1);
    font-size: var(--medium);
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.7;
  }
  .add-field-btn: hover {
    opacity: 1;
  }
  .add-field-btn svg {
    stroke: var(--p-color-1);
    margin-left: 5px;
    height: 12px;
  }
  .save-btn {
    margin: auto;
    margin-top: 15px;
  }
  .content-wrapper {
    width: 100%;
  }
  .first-page {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    border-radius: 5px;
  }
  .feedback-icon {
    margin: 50px;
    width: 94px;
    height: 94px;
  }
  .feedback-icon path {
    fill: var(--color-text-secondary);
  }
  .desc {
    font-family: D-DIN EXP;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  .yes-no-button {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
  }
  .yes-no-button .text {
    font-size: var(--x-large);
    font-family: D-DIN EXP;
    margin-left: 10px;
  }
  .form-page {
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;
    transform: translateX(10px);
  }
  .first-page {
    transition: all 0.3s ease;
  }
  &.show-form .first-page {
    transorm: translateX(-50px);
    opacity: 0;
    pointer-events: none;
  }
  &.show-form .form-page {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
  }
`

const StyledForm = styled.form`
  width: 100%;
  min-height: inherit;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  label,
  .field {
    font-family: D-DIN;
  }
  .field label {
    opacity: 0.4;
  }
  .field {
    font-size: var(--medium);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  > *.field:nth-last-child(1) {
    margin-bottom: unset;
  }
  .value {
    color: var(--p-color-1);
    font-weight: bold;
    font-family: D-DIN Exp;
    display: flex;
    place-content: center;
    place-items: center;
    background: rgb(0 0 0 / 0.4);
    width: 45px;
    height: 25px;
    border-radius: 5px;
    text-align: center;
    border: unset;
  }
  input.value {
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  input.value:focus {
    opacity: 1;
  }
  input.value:focus ~ label {
    opacity: 1;
  }
  input.value[type='number']::-webkit-inner-spin-button,
  input.value[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input.label-value {
    background: hsl(0 0% 0% / 0.4);
    border: none;
    border-radius: 5px;
    height: 27px;
    color: var(--p-color-1);
    padding: 0 10px;
    box-sizing: border-box;
    font-family: D-DIN;
  }
  label {
    transition: all 0.3s ease;
  }
  label.highlight {
    color: var(--p-color-red);
    opacity: 1;
  }
  .field.errored input {
    border: 1px solid var(--p-color-red);
    opacity: 0.8;
  }
  .error {
    font-size: var(--medium);
    margin-bottom: 10px;
    color: var(--p-color-red);
  }
`

interface IInputField {
  label: string
  name: string
  value: number
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  errored?: boolean
  highlight?: boolean
  onChangeLabel?: (e: React.FormEvent<HTMLInputElement>) => void
}

const InputField = ({
  label,
  name,
  value,
  onChange,
  onChangeLabel,
  highlight,
  errored
}: IInputField) => (
  <div className={`field${errored ? ' errored' : ''}`}>
    {onChangeLabel ? (
      <input
        type="text"
        className="label-value"
        value={label}
        onChange={onChangeLabel}
      />
    ) : (
      <label className={highlight ? 'highlight' : ''}>{label}</label>
    )}
    <input
      type="number"
      className="value"
      name={name}
      value={value}
      onChange={onChange}
      min={0}
    />
  </div>
)

interface IFeedbackPopupProps {
  isMounted?: boolean
  onHidePopup: () => void
  isSavingFeedback?: boolean
  feedback?: IPrintFeedback
  jobId?: string
  printerName?: string
  actions: {
    printFeedback: typeof printFeedback
  }
}

type Field = {
  label: string
  name: string
  value: number
  custom?: boolean
}

interface FeedbackPopupStates {
  // fields: Field[]
  noSelected: boolean
  numberOfModels: number
  numberOfModelsWithIssues: number
  modelsWithLine: number
  modelsWithSplits: number
  modelsFellOffFromPlatform: number
  customFieldVisible: boolean
  customFieldLabel: string
  customFieldValue: number
  errored?: string
}

// const defaultFields = [
//   {
//     label: 'Total number of models',
//     name: 'numberOfModels',
//     value: 0
//   },
//   {
//     label: 'Models with issues',
//     name: 'numberOfModelsWithIssues',
//     value: 0
//   },
//   {
//     label: 'Models with lines',
//     name: 'modelsWithLine',
//     value: 0
//   },
//   {
//     label: 'Models with splits',
//     name: 'modelsWithSplits',
//     value: 0
//   },
//   {
//     label: 'Models fell of the platform',
//     name: 'modelsFellOffFromPlatform',
//     value: 0
//   }
// ]

class FeedbackPopup extends React.Component<
  IFeedbackPopupProps,
  FeedbackPopupStates
> {
  constructor(props: IFeedbackPopupProps) {
    super(props)

    const existingFeedback = props.feedback || ({} as IPrintFeedback)
    this.state = {
      noSelected:
        typeof existingFeedback.isSuccess === 'boolean'
          ? !existingFeedback.isSuccess
          : false,
      numberOfModels: existingFeedback.totalModels || 0,
      numberOfModelsWithIssues: 0,
      modelsWithLine: existingFeedback.modelsWithLine || 0,
      modelsWithSplits: existingFeedback.modelsWithSplits || 0,
      modelsFellOffFromPlatform:
        existingFeedback.modelsFellOffFromPlatform || 0,
      customFieldVisible: existingFeedback.otherIssueText ? true : false,
      customFieldLabel: existingFeedback.otherIssueText || '',
      customFieldValue: existingFeedback.otherIssueModels || 0,
      errored: undefined
    }
  }

  componentDidUpdate = (prevProps: IFeedbackPopupProps) => {
    if (!this.props.isSavingFeedback && prevProps.isSavingFeedback) {
      this.props.onHidePopup()
    }
  }

  // addMoreFields = () => {
  //   this.setState(prevState => ({
  //     fields: [
  //       ...prevState.fields,
  //       { label: '', name: 'otherIssues', value: 0, custom: true }
  //     ]
  //   }))
  // }

  // removeLastField = () => {
  //   const arr = [...this.state.fields]
  //   arr.pop()
  //   this.setState({
  //     fields: arr
  //   })
  // }

  onChangeFieldLabel = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ customFieldLabel: e.currentTarget.value })
  }

  onChangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    //@ts-ignore
    this.setState({ [name]: Number(value) })
  }

  showCustomField = () => this.setState({ customFieldVisible: true })

  hideCustomField = () => this.setState({ customFieldVisible: false })

  onYesClick = () => {
    const { jobId, printerName, feedback } = this.props

    this.props.actions.printFeedback(
      { isSuccess: true, isUpdate: Boolean(feedback) },
      jobId!,
      printerName!
    )
    // this.props.onHidePopup()
  }

  onNoClick = () => {
    this.setState({ noSelected: true })
  }

  onBack = () => this.setState({ noSelected: false })

  // isUpdate?: boolean
  // success: boolean
  // totalModels?: number
  // modelsWithLine?: number
  // modelsWithSplits?: number
  // modelsFellOffFromPlatform?: number
  // otherIssueText?: string
  // otherIssueModels?: number

  onNoFormSubmit = () => {
    const {
      customFieldVisible,
      customFieldValue,
      customFieldLabel,
      numberOfModels,
      // numberOfModelsWithIssues,
      modelsFellOffFromPlatform,
      modelsWithLine,
      modelsWithSplits
    } = this.state
    const { jobId, printerName, feedback } = this.props

    if (numberOfModels === 0) {
      this.setState({ errored: 'Number of printed models required!' })
      return
    }

    if (this.state.errored) {
      this.setState({ errored: undefined })
    }

    this.props.actions.printFeedback(
      {
        isSuccess: false,
        totalModels: numberOfModels,
        modelsWithLine,
        modelsWithSplits,
        modelsFellOffFromPlatform,
        otherIssueText: customFieldVisible ? customFieldLabel : undefined,
        otherIssueModels: customFieldVisible ? customFieldValue : undefined,
        isUpdate: Boolean(feedback)
      },
      jobId!,
      printerName!
    )
  }

  render() {
    // const { fields } = this.state
    const {
      numberOfModels,
      modelsFellOffFromPlatform,
      modelsWithLine,
      modelsWithSplits,
      // numberOfModelsWithIssues,
      customFieldLabel,
      customFieldValue,
      customFieldVisible,
      noSelected,
      errored
    } = this.state
    const { isMounted, onHidePopup, isSavingFeedback, feedback } = this.props

    // const hasCustomField = this.state.fields.find(f => f.custom)

    const feedbackInfo = feedback || ({} as IPrintFeedback)

    return (
      <Popup
        title="Print Feedback"
        onClose={!isSavingFeedback ? onHidePopup : () => {}}
        visible={isMounted}
        width={'412px'}
      >
        <Loader
          style={{ borderRadius: '5px' }}
          visible={isSavingFeedback}
          text={'Saving Feedback ...'}
        />
        <FeedbackFormContainer className={noSelected ? 'show-form' : ''}>
          <div className="first-page">
            <FeedbackIcon className="feedback-icon" />
            <div className="desc">Is the print quality satisfactory?</div>
            <div className="buttons">
              <YesButton
                highlight={feedbackInfo.isSuccess}
                className="yes-no-button"
                onClick={this.onYesClick}
              />
              <NoButton className="yes-no-button" onClick={this.onNoClick} />
            </div>
          </div>
          <div className="form-page">
            <div className="form-header">
              <span className="back-btn" onClick={this.onBack}>
                <BackArrowIcon />
              </span>
              <span className="description">
                Please fill in the fields below
              </span>
            </div>
            <StyledForm onSubmit={this.onNoFormSubmit} noValidate={true}>
              {errored && <span className="error">{errored}</span>}
              <InputField
                label={'Total number of models'}
                name={'numberOfModels'}
                value={numberOfModels}
                onChange={this.onChangeValue}
                errored={Boolean(errored)}
              />
              <InputField
                label={'Models with lines'}
                name={'modelsWithLine'}
                value={modelsWithLine}
                onChange={this.onChangeValue}
                highlight={modelsWithLine !== 0}
              />
              <InputField
                label={'Models with splits'}
                name={'modelsWithSplits'}
                value={modelsWithSplits}
                onChange={this.onChangeValue}
                highlight={modelsWithSplits !== 0}
              />
              <InputField
                label={'Models fell of the platform'}
                name={'modelsFellOffFromPlatform'}
                value={modelsFellOffFromPlatform}
                onChange={this.onChangeValue}
                highlight={modelsFellOffFromPlatform !== 0}
              />
              {customFieldVisible && (
                <InputField
                  label={customFieldLabel}
                  name={'customFieldValue'}
                  value={customFieldValue}
                  onChange={this.onChangeValue}
                  highlight={customFieldValue !== 0}
                  onChangeLabel={this.onChangeFieldLabel}
                />
              )}
              {/* {fields.map(({ label, value, custom }) => (
              <div className="field">
                {!custom ? (
                  <label>{label}</label>
                ) : (
                  <input type="text" className="label-value" value={label} />
                )}
                <input type="number" className="value" value={value} />
              </div>
            ))} */}
              {customFieldVisible ? (
                <span className="add-field-btn" onClick={this.hideCustomField}>
                  Remove other issues
                </span>
              ) : (
                <span className="add-field-btn" onClick={this.showCustomField}>
                  Add other issues <PlusIcon />
                </span>
              )}
            </StyledForm>
            <Button
              onClick={this.onNoFormSubmit}
              type="submit"
              secondary
              className="save-btn"
            >
              Save
            </Button>
          </div>
        </FeedbackFormContainer>
      </Popup>
    )
  }
}

const getIsSavingFeedback = (state: IPastPrintsState) =>
  state.feedbackInProgress

const pastPrintsSelector = createSelector(
  [getIsSavingFeedback],
  savingFeedback => ({
    savingFeedback
  })
)

const getFeedbackFormFeedback = (state: IFeedbackFormState) => state.feedback
const getFeedbackFormJobId = (state: IFeedbackFormState) => state.jobId
const getFeedbackPrinterName = (state: IFeedbackFormState) => state.printerName

const feedbackFormSelector = createSelector(
  [getFeedbackFormFeedback, getFeedbackFormJobId, getFeedbackPrinterName],
  (feedbackFormFeedback, feedbackFormJobId, feedbackPrinterName) => ({
    feedbackFormFeedback,
    feedbackFormJobId,
    feedbackPrinterName
  })
)

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        printFeedback
      },
      dispatch
    )
  }
})

const mapStateToProps = ({ pastPrints, feedbackForm }) => {
  const { savingFeedback } = pastPrintsSelector(pastPrints)
  const {
    feedbackFormFeedback,
    feedbackFormJobId,
    feedbackPrinterName
  } = feedbackFormSelector(feedbackForm)
  return {
    isSavingFeedback: savingFeedback,
    feedback: feedbackFormFeedback,
    jobId: feedbackFormJobId,
    printerName: feedbackPrinterName
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPopup)
