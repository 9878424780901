import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { createSelector } from 'reselect'

import {
  IPrinterState,
  IPrinter,
  IUploadingFiles
} from '../../redux/printer/types'
import { uploadFile, multipleFileUpload } from '../../redux/printer/actions'
import AddJobForm from './AddJobForm'
import { IAddJobFormState, File } from '../../redux/addJobForm/types'
import { addToast } from '../../redux/toasts/actions'
import { IToast } from '../../redux/toasts/types'
import { IPastPrintsState } from '../../redux/past-prints/types'

type Files = FileList | null

interface IAddJobStates {}

interface IAddJobPopupProps {
  isMounted?: boolean
  onHidePopup: () => void
  printers: IPrinter[]
  isUploadingFile?: boolean
  addNewJobInProgress?: boolean
  uploadingFiles: IUploadingFiles[]
  addJobFormPreselectedFile?: File
  addJobFormPreselectedPrinterName?: string
  fileStoreKey?: string
  cloneFileName?: string
  toasts: IToast[]
  actions: {
    uploadFile: typeof uploadFile
    multipleFileUpload: typeof multipleFileUpload
    addToast: typeof addToast
  }
}

class AddJobPopup extends React.Component<IAddJobPopupProps, IAddJobStates> {
  constructor(props: IAddJobPopupProps) {
    super(props)
    this.state = {}
  }

  componentDidUpdate = (prevProps: IAddJobPopupProps) => {
    if (
      (!this.props.isUploadingFile && prevProps.isUploadingFile) ||
      (!this.props.addNewJobInProgress && prevProps.addNewJobInProgress)
    ) {
      this.props.onHidePopup()
    }
  }

  addJob = (file: Files, printerName: string) => {
    if (!file) return
    if (file.length === 1) {
      this.props.actions.uploadFile(file[0], printerName)
    } else if (file.length > 1) {
      const files = [] as Files[]
      for (let i = 0; i < file.length; i++) {
        //@ts-ignore
        files.push(file[i])
      }
      //@ts-ignore
      this.props.actions.multipleFileUpload(files, printerName)
      this.setState({ uploadingFiles: files })
    }
  }

  getPrinterList = () => {
    const { printers } = this.props
    if (!printers || (printers && printers.length === 0)) return []

    const mappedPrinters = printers.map(
      ({ name, customPrinterName, isVelox, isOnline }) => ({
        name,
        customPrinterName,
        isVelox,
        isOnline
      })
    )
    return mappedPrinters
  }

  render() {
    const {
      isMounted,
      onHidePopup,
      isUploadingFile,
      uploadingFiles
    } = this.props

    const printerList = this.getPrinterList()

    const submitting =
      isUploadingFile ||
      Boolean(
        uploadingFiles.length && uploadingFiles.find(uf => uf.uploadInProgress)
      )

    return (
      <AddJobForm
        uploadingFiles={uploadingFiles}
        selectedPrinterName={this.props.addJobFormPreselectedPrinterName}
        preselectedFile={this.props.addJobFormPreselectedFile}
        fileStoreKey={this.props.fileStoreKey}
        cloneFileName={this.props.cloneFileName}
        printerList={printerList}
        isMounted={isMounted}
        onClose={onHidePopup}
        onAddJob={this.addJob}
        submitting={submitting}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        uploadFile,
        multipleFileUpload,
        push,
        goBack,
        addToast
      },
      dispatch
    )
  }
})

const getPrinters = (state: IPrinterState) => state.printers
const getIsUploadingFile = (state: IPrinterState) => state.isUploadingFile
const getUploadingFiles = (state: IPrinterState) => state.uploadingFiles
const getPrinterDataErrorMessage = (state: IPrinterState) => state.errorMessage

const printerDataSelector = createSelector(
  [
    getPrinters,
    getIsUploadingFile,
    getPrinterDataErrorMessage,
    getUploadingFiles
  ],
  (printers, isUploadingFile, printerDataErrorMessage, uploadingFiles) => ({
    printers,
    isUploadingFile,
    printerDataErrorMessage,
    uploadingFiles
  })
)

const getAddJobFormPreselectedFile = (state: IAddJobFormState) =>
  state.preselectedFile
const getAddJobFormPreselectedPrinterName = (state: IAddJobFormState) =>
  state.preselectedPrinterName
const getAddJobFormFileStoreKey = (state: IAddJobFormState) =>
  state.fileStoreKey
const getAddJobFormCloneFileName = (state: IAddJobFormState) =>
  state.cloneFileName

const addJobFormSelector = createSelector(
  [
    getAddJobFormPreselectedFile,
    getAddJobFormPreselectedPrinterName,
    getAddJobFormFileStoreKey,
    getAddJobFormCloneFileName
  ],
  (
    addJobFormPreselectedFile,
    addJobFormPreselectedPrinterName,
    fileStoreKey,
    cloneFileName
  ) => ({
    addJobFormPreselectedFile,
    addJobFormPreselectedPrinterName,
    fileStoreKey,
    cloneFileName
  })
)

const getAddNewJobInProgress = (state: IPastPrintsState) =>
  state.addNewJobInProgress

const pastPrintSelector = createSelector(
  [getAddNewJobInProgress],
  addNewJobInProgress => ({ addNewJobInProgress })
)

const mapStateToProps = ({ printerData, addJobForm, toasts, pastPrints }) => {
  const {
    printers,
    isUploadingFile,
    printerDataErrorMessage,
    uploadingFiles
  } = printerDataSelector(printerData)

  const {
    addJobFormPreselectedFile,
    addJobFormPreselectedPrinterName,
    fileStoreKey,
    cloneFileName
  } = addJobFormSelector(addJobForm)

  const { addNewJobInProgress } = pastPrintSelector(pastPrints)

  return {
    printers,
    isUploadingFile,
    addJobFormPreselectedFile,
    addJobFormPreselectedPrinterName,
    fileStoreKey,
    uploadingFiles,
    cloneFileName,
    toasts: toasts.toasts,
    addNewJobInProgress,
    errorMessages: {
      printerData: printerDataErrorMessage
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddJobPopup)
