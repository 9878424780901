import createReducer from '../../utils/create-reducer'
import { IAddress, IAuthState, ILoginSuccess, IMessage } from './types'
import {
  AUTH_LOGIN_PENDING,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED,
  AUTH_LOGOUT_SUCCESS,
  AUTH_FORGOT_PASSWORD_PEDNING,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_FAILED,
  AUTH_HIDE_BANNER_SUCCESS,
  AUTH_GET_COMPANY_INFO_SUCCESS
} from './constants'

export const initialStates = {
  // login related only
  isAuthenticating: false,
  accessToken: undefined,
  refreshToken: undefined,
  errorMessage: undefined,
  // general
  user: null,
  forgotPasswordInProgress: false,
  successMessage: undefined
}

export default createReducer(initialStates, {
  [AUTH_LOGIN_PENDING]: (state: IAuthState) => ({
    ...state,
    isAuthenticating: true
  }),

  [AUTH_LOGIN_SUCCESS]: (
    state: IAuthState,
    { user, accessToken, refreshToken }: ILoginSuccess
  ) => ({
    ...state,
    isAuthenticating: false,
    user,
    refreshToken,
    accessToken
  }),

  [AUTH_LOGIN_FAILED]: (state: IAuthState, { message }: IMessage) => ({
    ...state,
    isAuthenticating: false,
    errorMessage: message
  }),

  [AUTH_LOGOUT_SUCCESS]: (state: IAuthState) => ({
    ...state,
    isAuthenticating: false,
    user: null,
    refreshToken: undefined,
    accessToken: undefined
  }),

  [AUTH_FORGOT_PASSWORD_PEDNING]: (state: IAuthState) => ({
    ...state,
    forgotPasswordInProgress: true
  }),

  [AUTH_FORGOT_PASSWORD_SUCCESS]: (state: IAuthState) => ({
    ...state,
    forgotPasswordInProgress: false
  }),

  [AUTH_FORGOT_PASSWORD_FAILED]: (
    state: IAuthState,
    { message }: IMessage
  ) => ({
    ...state,
    errorMessage: message,
    forgotPasswordInProgress: false
  }),

  [AUTH_HIDE_BANNER_SUCCESS]: (state: IAuthState) => ({
    ...state,
    user: {
      ...state.user,
      showBanner: false
    }
  }),

  [AUTH_GET_COMPANY_INFO_SUCCESS]: (
    state: IAuthState,
    {
      companyName,
      companyAddress
    }: { companyName: string; companyAddress: IAddress }
  ) => ({
    ...state,
    user: {
      ...state.user,
      companyName,
      companyAddress
    }
  })
})
