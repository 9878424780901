import { getFetchOpts } from '../../utils/pre-fetch'
import { parseJsonResponse } from '../../utils/post-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function getPrintStats(token: string) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/print-stats`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

export function getPrintStatsOverDuration(
  token: string,
  duration?: string,
  veloxPrinters?: string[],
  dentaformPrinters?: string[]
) {
  const veloxPrinterNames = veloxPrinters ? veloxPrinters.join('$') : ''
  const dentaformPrinterNames = dentaformPrinters
    ? dentaformPrinters.join('$')
    : ''
  return fetch(
    `${DROPLET_API_URL}/mystructo/print-stats/${duration}?veloxPrinterNames=${veloxPrinterNames}&dentaformPrinterNames=${dentaformPrinterNames}`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}
