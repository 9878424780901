import { put, call, takeEvery, fork } from 'redux-saga/effects'
import {
  getTheme as getThemeAction,
  getThemeSuccess,
  setTheme as setThemeAction
} from './slice'
import { getTheme, setTheme } from '../../utils/session'
import { ISetThemeAction, ITheme } from './types'
import { PayloadAction } from '@reduxjs/toolkit'

export function* getThemeHandler() {
  try {
    const res = yield call(getTheme)
    if (res) {
      const theme = res === ITheme.light ? ITheme.light : ITheme.dark
      yield put(getThemeSuccess({ theme }))
    }
  } catch (e) {}
}

export function* setThemeHandler(action: PayloadAction<ISetThemeAction>) {
  try {
    yield call(setTheme, action.payload.theme)
  } catch (e) {}
}

function* watchGetTheme() {
  yield takeEvery(getThemeAction.toString(), getThemeHandler)
}

function* watchSetTheme() {
  yield takeEvery(setThemeAction.toString(), setThemeHandler)
}

export default [fork(watchGetTheme), fork(watchSetTheme)]
