import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFeedbackFormState, ShowFeedbackFormAction } from './types'

export const initialState: IFeedbackFormState = {
  visible: false,
  prefilledFields: undefined,
  printerName: undefined,
  jobId: undefined
}

export const feedbackFormSlice = createSlice({
  name: 'feedbackForm',
  initialState,
  reducers: {
    showFeedbackForm: (
      state,
      action: PayloadAction<ShowFeedbackFormAction>
    ) => {
      state.visible = true
      state.prefilledFields = action.payload.prefilledFields
      state.jobId = action.payload.jobId
      state.printerName = action.payload.printerName
      state.feedback = action.payload.feedback
    },
    hideFeedbackForm: state => {
      state.visible = false
      state.prefilledFields = undefined
      state.feedback = undefined
      state.jobId = undefined
    }
  }
})

export const { showFeedbackForm, hideFeedbackForm } = feedbackFormSlice.actions

export default feedbackFormSlice.reducer
