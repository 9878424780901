import { all } from 'redux-saga/effects'
import { sagas as authSagas } from './redux/auth'
import { sagas as iotSagas } from './redux/iot'
import { sagas as statsSaga } from './redux/stats'
import { sagas as printerSaga } from './redux/printer'
import { sagas as pastPrintsSaga } from './redux/past-prints'
import { sagas as teamSaga } from './redux/team'
import { sagas as appSaga } from './redux/app'
import { sagas as apiSaga } from './redux/api-key'
import { sagas as teflonsSaga } from './redux/teflons'
import { sagas as timelineSaga } from './redux/printer-timeline'
import { sagas as userPreferenceSaga } from './redux/user-preference'
import { sagas as oeeSaga } from './redux/oee'
import { sagas as orderSaga } from './redux/order'

// Main Saga
export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...iotSagas,
    ...statsSaga,
    ...printerSaga,
    ...pastPrintsSaga,
    ...teamSaga,
    ...appSaga,
    ...apiSaga,
    ...teflonsSaga,
    ...timelineSaga,
    ...userPreferenceSaga,
    ...oeeSaga,
    ...orderSaga
  ])
}
