import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RouterState } from 'connected-react-router'
import Joyride, { CallBackProps, STATUS, EVENTS, ACTIONS } from 'react-joyride'

import { ITourState } from '../../redux/tour/types'
import {
  setTourStepIndex as setTourStepIndexAction,
  endTour as endTourAction,
  pauseTour as pauseTourAction,
  resumeTour as resumeTourAction
} from '../../redux/tour/actions'

interface IGuidedTour {
  sidebarVisible: boolean
  setSidebarVisible: (sidebarVisible: boolean) => void
}

function GuidedTour({ sidebarVisible, setSidebarVisible }: IGuidedTour) {
  const dispatch = useDispatch()
  // Get Location
  const { location } = useSelector(
    ({ router }: { router: RouterState }) => router
  )
  // Joyride
  const {
    joyrideCallback,
    tourStepIndex,
    tourSteps,
    isTourOpen,
    joyrideProps
  } = useSelector(({ tour }: { tour: ITourState }) => tour)

  function handleJoyrideCallback(data: CallBackProps) {
    const { action, index, type, status } = data

    // Need to set our running state to false, so we can restart if we click start again.
    if (sidebarVisible) setSidebarVisible(false)

    if (
      ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED] as string[]).includes(
        status
      ) ||
      action === ACTIONS.CLOSE
    ) {
      dispatch(endTourAction())
      const modifiedElems = document.querySelectorAll(
        '[style="overflow: initial;"]'
      )
      if (modifiedElems.length > 0) {
        modifiedElems.forEach(elem => {
          elem.setAttribute('style', '')
        })
      }
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      if (stepIndex !== tourStepIndex) {
        if (
          (location.pathname === '/' || location.pathname === '/overview') &&
          index === 2 &&
          window.innerWidth < 600
        ) {
          dispatch(pauseTourAction())
          setSidebarVisible(true)
          setTimeout(() => {
            dispatch(setTourStepIndexAction(stepIndex))
            dispatch(resumeTourAction())
          }, 500)
          return
        }
        dispatch(setTourStepIndexAction(stepIndex))
      }
    }
  }

  return (
    <Joyride
      callback={joyrideCallback || handleJoyrideCallback}
      run={isTourOpen}
      steps={tourSteps}
      stepIndex={tourStepIndex}
      scrollToFirstStep={true}
      continuous={true}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: '#9655ed'
        }
      }}
      disableScrollParentFix={!isTourOpen}
      {...joyrideProps}
      locale={{ last: 'Done' }}
    />
  )
}

export default GuidedTour
