import {
  put,
  call,
  takeEvery,
  fork,
  delay,
  takeLatest,
  select
} from 'redux-saga/effects'
import { getAppSettings, getLatestVersion } from './api'
import {
  showReloadOption,
  showWhatsNew,
  getLatestVersion as getLatestVersionAction,
  getAppSettingsSuccess,
  hideAppInstallOption
} from './actions'
import { version } from '../../../package.json'
import { isNewerVersion } from '../../utils/compare-version'
import { getWhatsNewKey, setWhatsNewKey } from '../../utils/session'
import {
  APP_GET_LATEST_VERSION,
  APP_INITIALIZE,
  APP_INSTALL
} from './constants'
import { renewToken } from '../auth/actions'
import { getTheme } from '../user-preference/slice'
import { IAuthState } from '../auth/types'
import { IAppSettingsRes, IAppState } from './types'

export const getToken = ({ auth }: { auth: IAuthState }) => auth.accessToken
export const getInstallEvent = ({ app }: { app: IAppState }) =>
  app.deferredAppInstallPrompt

export function* lastestVersionHandler() {
  try {
    const res = yield call(getLatestVersion)
    const newVersion = yield call(isNewerVersion, version, res.version)
    if (newVersion) {
      // show reload option
      yield put(showReloadOption())
    } else {
      // check for the key in local storage to show the whats new section
      const seen = yield call(getWhatsNewKey, res.version)
      const releaseDate = new Date(res.lastCommitDate)
      const currentDate = new Date()
      const diffTime = Math.abs(currentDate.valueOf() - releaseDate.valueOf())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      if (!seen && diffDays < 7) {
        // if seen is false and release is less then 7 days old, show whats new section automatically
        yield put(showWhatsNew())
      }
      yield call(setWhatsNewKey, true, res.version)
    }
  } catch (err) {
    // failed to get the version file
    console.log(err)
  } finally {
    // check this in every 6 hours
    yield delay(1000 * 60 * 60 * 6)
    yield put(getLatestVersionAction())
  }
}

export function* appSettingsHandler() {
  try {
    const accessToken = yield select(getToken)
    const res: IAppSettingsRes = yield call(getAppSettings, accessToken)
    yield put(getAppSettingsSuccess(res))
  } catch (err) {
    console.log(err)
  }
}

export function* appInitHandler() {
  yield put(getTheme())
  yield put(renewToken())
}

export function* appInstallHandler() {
  try {
    const deferredAppInstallPrompt = yield select(getInstallEvent)
    yield put(hideAppInstallOption())
    deferredAppInstallPrompt.prompt()
  } catch (err) {
    console.log(err)
  }
}

function* watchGetLatestVersion() {
  yield takeLatest(APP_GET_LATEST_VERSION, lastestVersionHandler)
}

function* watchAppInit() {
  yield takeEvery(APP_INITIALIZE, appInitHandler)
}

function* watchAppInstall() {
  yield takeLatest(APP_INSTALL, appInstallHandler)
}

export default [
  fork(watchGetLatestVersion),
  fork(watchAppInit),
  fork(watchAppInstall)
]
