import { getFetchOpts } from '../../utils/pre-fetch'
import { parseJsonResponse } from '../../utils/post-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

export function getTeam(token: string) {
  const apiUrl = `${DROPLET_API_URL}/mystructo/list-auth-sub-users`
  return fetch(apiUrl, getFetchOpts('get', {}, token)).then(parseJsonResponse)
}

export function addNewUser(
  token: string,
  firstName: string,
  lastName: string,
  email: string,
  groups?: string[],
  allPrinters?: boolean
) {
  const apiUrl = `${DROPLET_API_URL}/mystructo/create-auth-sub-user`
  return fetch(
    apiUrl,
    getFetchOpts(
      'post',
      { firstName, lastName, email, groups, allPrinters },
      token
    )
  ).then(parseJsonResponse)
}

export function deleteUser(email: string, token: string) {
  const apiUrl = `${DROPLET_API_URL}/mystructo/delete-auth-sub-user`
  return fetch(apiUrl, getFetchOpts('delete', { email }, token)).then(
    parseJsonResponse
  )
}

export function enableDisableUser(
  blocked: boolean,
  email: string,
  token: string
) {
  const apiUrl = `${DROPLET_API_URL}/mystructo/block-auth-sub-user`
  return fetch(apiUrl, getFetchOpts('put', { email, blocked }, token)).then(
    parseJsonResponse
  )
}

export function updateUserGroup(
  token: string,
  subUserId: string,
  groups?: string[],
  allPrinters?: boolean
) {
  const apiUrl = `${DROPLET_API_URL}/mystructo/update-user-group`
  return fetch(
    apiUrl,
    getFetchOpts('post', { subUserId, groups, allPrinters }, token)
  ).then(parseJsonResponse)
}
