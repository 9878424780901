import {
  IOT_CONNECT_SUCCESS,
  IOT_CONNECT_PENDING,
  IOT_CONNECT_FAILED
} from "./constant";

export function connectIOT(accessToken: string) {
  return {
    type: IOT_CONNECT_PENDING,
    payload: { accessToken }
  };
}

export function connectIOTSuccess() {
  return {
    type: IOT_CONNECT_SUCCESS
  };
}

export function connectIOTFailed(message = "Fetch to connect to mqtt") {
  return {
    type: IOT_CONNECT_FAILED
  };
}
