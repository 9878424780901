import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IOEEState,
  GetOperationLogsAction,
  GetOperationLogsSuccessAction,
  CreateOperationLogAction,
  CreateOperationLogSuccessAction,
  UpdateOperationLogAction,
  UpdateOperationLogSuccessAction,
  DeleteOperationLogAction,
  DeleteOperationLogSuccessAction,
  OperationLog,
  GetOEEOverviewAction,
  GetOEEOverviewSuccessAction,
  GetThroughputOverviewSuccessAction,
  GetThroughputOverviewAction
} from './types'
import { getOperationStats } from '../../routes/OEE/components'

export const initialState: IOEEState = {
  isGettingOperationLogs: false,
  isCreatingOperationLog: false,
  isDeletingOperationLog: false,
  isGettingOEEOverview: false,
  isUpdatingOperationLog: false,
  isGettingThroughputOverview: false,
  operationLogs: [],
  operationLogsTotalCount: 0,
  oeeOverview: undefined,
  throughputData: []
}

export const oeeSlice = createSlice({
  name: 'oee',
  initialState,
  reducers: {
    getOEEOverview: (state, action: PayloadAction<GetOEEOverviewAction>) => {
      state.isGettingOEEOverview = true
    },
    getOEEOverviewSuccess: (
      state,
      action: PayloadAction<GetOEEOverviewSuccessAction>
    ) => {
      state.isGettingOEEOverview = false
      state.oeeOverview = { ...action.payload }
    },
    getOEEOverviewFailure: state => {
      state.isGettingOEEOverview = false
    },
    createOperationLog: (
      state,
      action: PayloadAction<CreateOperationLogAction>
    ) => {
      state.isCreatingOperationLog = true
    },
    createOperationLogSuccess: (
      state,
      action: PayloadAction<CreateOperationLogSuccessAction>
    ) => {
      state.isCreatingOperationLog = false
      const { oee, availability, performance, quality } = getOperationStats({
        ...action.payload
      })
      state.operationLogs = [
        {
          ...action.payload,
          reportedAt: action.payload.reportedAt.toISOString(),
          stats: {
            oee,
            availability,
            performance,
            quality
          }
        },
        ...state.operationLogs
      ]
    },
    createOperationLogFailure: state => {
      state.isCreatingOperationLog = false
    },
    updateOperationLog: (
      state,
      action: PayloadAction<UpdateOperationLogAction>
    ) => {
      state.isUpdatingOperationLog = true
    },
    updateOperationLogSuccess: (
      state,
      action: PayloadAction<UpdateOperationLogSuccessAction>
    ) => {
      state.isUpdatingOperationLog = false

      const { oee, availability, performance, quality } = getOperationStats({
        ...action.payload
      })
      let newOperationLogs = [...state.operationLogs]
      newOperationLogs = newOperationLogs.reduce(
        (acc: OperationLog[], curr: OperationLog) => {
          if (
            curr.reportedAt !== action.payload.reportedAt.toISOString() ||
            curr.printer !== action.payload.printer
          )
            acc.push(curr)
          else {
            acc.push({
              ...action.payload,
              reportedAt: action.payload.reportedAt.toISOString(),
              stats: { oee, availability, performance, quality }
            })
          }
          return acc
        },
        []
      )
      state.operationLogs = newOperationLogs
    },
    updateOperationLogFailure: state => {
      state.isUpdatingOperationLog = false
    },
    deleteOperationLog: (
      state,
      action: PayloadAction<DeleteOperationLogAction>
    ) => {
      state.isDeletingOperationLog = true
    },
    deleteOperationLogSuccess: (
      state,
      action: PayloadAction<DeleteOperationLogSuccessAction>
    ) => {
      state.isDeletingOperationLog = false
      state.operationLogs = state.operationLogs.reduce(
        (acc: OperationLog[], curr: OperationLog) => {
          if (
            curr.reportedAt === action.payload.reportedAt.toISOString() &&
            curr.printer === action.payload.printer
          ) {
            return acc
          } else {
            acc.push(curr)
          }

          return acc
        },
        []
      )
    },
    deleteOperationLogFailure: state => {
      state.isDeletingOperationLog = false
    },
    getOperationLogs: (
      state,
      action: PayloadAction<GetOperationLogsAction>
    ) => {
      state.isGettingOperationLogs = true
    },
    getOperationLogsSuccess: (
      state,
      action: PayloadAction<GetOperationLogsSuccessAction>
    ) => {
      state.isGettingOperationLogs = false
      state.operationLogs = action.payload.operationLogs
      state.operationLogsTotalCount = action.payload.operationLogsTotalCount
    },
    getOperationLogsFailure: state => {
      state.isGettingOperationLogs = false
    },
    getThroughputOverview: (
      state,
      action: PayloadAction<GetThroughputOverviewAction>
    ) => {
      state.isGettingThroughputOverview = true
    },
    getThroughputOverviewSuccess: (
      state,
      action: PayloadAction<GetThroughputOverviewSuccessAction>
    ) => {
      state.isGettingThroughputOverview = false
      state.throughputData = action.payload.throughputData
    },
    getThroughputOverviewFailure: state => {
      state.isGettingThroughputOverview = false
    }
  }
})

export const {
  createOperationLog,
  createOperationLogFailure,
  createOperationLogSuccess,
  deleteOperationLog,
  deleteOperationLogFailure,
  deleteOperationLogSuccess,
  getOperationLogs,
  getOperationLogsFailure,
  getOperationLogsSuccess,
  updateOperationLog,
  updateOperationLogFailure,
  updateOperationLogSuccess,
  getOEEOverview,
  getOEEOverviewFailure,
  getOEEOverviewSuccess,
  getThroughputOverview,
  getThroughputOverviewFailure,
  getThroughputOverviewSuccess
} = oeeSlice.actions

export default oeeSlice.reducer
