import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEditUserGroupState, ShowUserGroupFormAction } from './types'

export const initialState: IEditUserGroupState = {
  visible: false,
  userId: undefined
}

export const editUserGroupFormSlice = createSlice({
  name: 'editUserGroupForm',
  initialState,
  reducers: {
    showUserGroupForm: (
      state,
      action: PayloadAction<ShowUserGroupFormAction>
    ) => {
      state.userId = action.payload.userId
      state.visible = true
    },
    hideUserGroupForm: state => {
      state.visible = false
      state.userId = undefined
    }
  }
})

export const {
  showUserGroupForm,
  hideUserGroupForm
} = editUserGroupFormSlice.actions

export default editUserGroupFormSlice.reducer
