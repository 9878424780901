import {
  API_GENERATE_KEY,
  API_GENERATE_KEY_FAILED,
  API_GENERATE_KEY_SUCCESS,
  API_GET_KEY,
  API_GET_KEY_FAILURE,
  API_GET_KEY_SUCCESS,
  API_GET_WEBHOOK_URL,
  API_GET_WEBHOOK_URL_FAILURE,
  API_GET_WEBHOOK_URL_SUCCESS,
  API_SAVE_WEBHOOK_URL,
  API_SAVE_WEBHOOK_URL_FAILURE,
  API_SAVE_WEBHOOK_URL_SUCCESS
} from './constants'
import { IApiKeyRes } from './types'

export function generateKey() {
  return {
    type: API_GENERATE_KEY
  }
}

export function generateKeySuccess(res: IApiKeyRes) {
  return {
    type: API_GENERATE_KEY_SUCCESS,
    payload: { apiKey: res.apiKey }
  }
}

export function generateKeyFailure() {
  return {
    type: API_GENERATE_KEY_FAILED
  }
}

export function getAPIKey() {
  return {
    type: API_GET_KEY
  }
}

export function getAPIKeySuccess(apiKey: string, lastGeneratedAt?: Date) {
  return {
    type: API_GET_KEY_SUCCESS,
    payload: { apiKey, lastGeneratedAt }
  }
}

export function getAPIKeyFailure() {
  return {
    type: API_GET_KEY_FAILURE
  }
}

export function saveWebhookUrl(url: string) {
  return {
    type: API_SAVE_WEBHOOK_URL,
    payload: { url }
  }
}

export function saveWebhookUrlSuccess(url: string) {
  return {
    type: API_SAVE_WEBHOOK_URL_SUCCESS,
    payload: { url }
  }
}

export function saveWebhookUrlFailure() {
  return {
    type: API_SAVE_WEBHOOK_URL_FAILURE
  }
}

export function getWebhookUrl() {
  return {
    type: API_GET_WEBHOOK_URL
  }
}

export function getWebhookUrlSuccess(url: string) {
  return {
    type: API_GET_WEBHOOK_URL_SUCCESS,
    payload: { url }
  }
}

export function getWebhookUrlFailure() {
  return {
    type: API_GET_WEBHOOK_URL_FAILURE
  }
}
