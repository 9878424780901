import { IGetOrdersAction } from './types'
import { parseJsonResponse } from '../../utils/post-fetch'
import { getFetchOpts } from '../../utils/pre-fetch'

const DROPLET_API_URL = process.env.REACT_APP_DROPLET_URL

interface DropletAPI {
  token: string
}

export function getOrdersAPI({ token }: IGetOrdersAction & DropletAPI) {
  return fetch(
    `${DROPLET_API_URL}/weprint/get-confirmed-orders`,
    getFetchOpts('get', {}, token)
  ).then(parseJsonResponse)
}

interface IDownloadFileAction {
  orderId: string
  caseName: string
}

export function downloadCaseAPI({
  orderId,
  caseName,
  token
}: IDownloadFileAction & DropletAPI) {
  return fetch(
    `${DROPLET_API_URL}/mystructo/download-file?orderId=${orderId}&caseName=${caseName}`,
    getFetchOpts('get', {}, token)
  )
    .then(res => res.blob())
    .then(blob => ({ blob: blob }))
}
