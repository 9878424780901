import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, DelayedComponents, Loader } from 'backpack'
import { useDispatch, useSelector } from 'react-redux'

import { StyledChipsPopup } from '../Filters/Filters'
import { hideUserGroupForm } from '../../redux/editUserGroupForm/slice'
import { IEditUserGroupState } from '../../redux/editUserGroupForm/types'
import { PrinterAccessFields } from '../../routes/Settings/Users/components'
import { updateUserGroup } from '../../redux/team/actions'
import { ITeamsState } from '../../redux/team/types'
import usePrevious from '../../hooks/usePrevious'

export const StyledPrinterGroupPopup = styled(({ children, ...rest }) => (
  <StyledChipsPopup children={children} {...rest} />
))`
  .button-row {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  button {
    width: 100%;
    max-width: 100%;
    height: 45px;
  }
`

interface IEditUserGroupPopup {
  visible: boolean
}

export const EditUserGroupPopup = ({ visible }: IEditUserGroupPopup) => {
  const dispatch = useDispatch()

  const { userId } = useSelector(
    ({ editUserGroupForm }: { editUserGroupForm: IEditUserGroupState }) =>
      editUserGroupForm
  )

  const { team, isUpdatingUserGroup } = useSelector(
    ({ teamData }: { teamData: ITeamsState }) => teamData
  )

  function getUserInfo() {
    const user = team.find(user => user.id === userId)
    return (
      user || {
        groups: undefined,
        allPrinters: undefined
      }
    )
  }

  const { groups, allPrinters } = getUserInfo()!

  // Printer access
  const [accessType, setAccessType] = useState(allPrinters ? 'all' : 'groups')
  const defaultSelectedGroups = new Set(groups || []) as Set<string>
  const [selectedGroups, setSelectedGroups] = useState(defaultSelectedGroups)

  const prevProp = usePrevious({ isUpdatingUserGroup })

  useEffect(() => {
    //@ts-ignore
    if (prevProp && prevProp.isUpdatingUserGroup && !isUpdatingUserGroup) {
      // process here
      dispatch(hideUserGroupForm())
    }
  }, [isUpdatingUserGroup, dispatch, prevProp])

  function closePopup() {
    dispatch(hideUserGroupForm())
  }

  function submit() {
    if (userId) {
      dispatch(
        updateUserGroup(
          userId,
          Array.from(selectedGroups),
          accessType === 'all'
        )
      )
    }
  }

  return (
    <StyledPrinterGroupPopup
      title={'Assign groups'}
      onClose={closePopup}
      visible={visible}
      width={'500px'}
    >
      <DelayedComponents isMounted={Boolean(isUpdatingUserGroup)}>
        <Loader
          visible={isUpdatingUserGroup}
          text={'Updating user groups ...'}
        />
      </DelayedComponents>
      <PrinterAccessFields
        selectedGroups={selectedGroups}
        onSelectGroups={setSelectedGroups}
        onSelectAccessType={setAccessType}
        accessType={accessType}
      />
      <div className="button-row">
        <Button
          type="submit"
          label="Update user groups"
          onClick={submit}
          disabled={isUpdatingUserGroup}
        />
      </div>
    </StyledPrinterGroupPopup>
  )
}
