import createReducer from '../../utils/create-reducer'
import {
  API_GENERATE_KEY,
  API_GENERATE_KEY_FAILED,
  API_GENERATE_KEY_SUCCESS,
  API_GET_KEY,
  API_GET_KEY_FAILURE,
  API_GET_KEY_SUCCESS,
  API_GET_WEBHOOK_URL,
  API_GET_WEBHOOK_URL_FAILURE,
  API_GET_WEBHOOK_URL_SUCCESS,
  API_SAVE_WEBHOOK_URL,
  API_SAVE_WEBHOOK_URL_FAILURE,
  API_SAVE_WEBHOOK_URL_SUCCESS
} from './constants'
import { IApiKeyState } from './types'

export const initialStates = {
  apiKey: undefined,
  keyGeneretationInProgress: false,
  savingWebhookURL: false,
  webhookURL: undefined,
  isWebhookURLSuccess: false,
  errorMessage: undefined,
  retrievingAPIKey: false,
  retrievingWebhookUrl: false,
  lastGeneratedAt: undefined
} as IApiKeyState

export default createReducer(initialStates, {
  [API_GENERATE_KEY]: (state: IApiKeyState) => ({
    ...state,
    keyGeneretationInProgress: true
  }),

  [API_GENERATE_KEY_FAILED]: (state: IApiKeyState) => ({
    ...state,
    keyGeneretationInProgress: false
  }),

  [API_GENERATE_KEY_SUCCESS]: (
    state: IApiKeyState,
    { apiKey }: { apiKey: string }
  ) => ({
    ...state,
    keyGeneretationInProgress: false,
    apiKey,
    lastGeneratedAt: new Date()
  }),

  [API_SAVE_WEBHOOK_URL]: (state: IApiKeyState) => ({
    ...state,
    savingWebhookURL: true
  }),

  [API_SAVE_WEBHOOK_URL_FAILURE]: (state: IApiKeyState) => ({
    ...state,
    savingWebhookURL: false
  }),

  [API_SAVE_WEBHOOK_URL_SUCCESS]: (
    state: IApiKeyState,
    { url }: { url: string }
  ) => ({
    ...state,
    savingWebhookURL: false,
    webhookURL: url
  }),

  [API_GET_KEY]: (state: IApiKeyState) => ({
    ...state,
    retrievingAPIKey: true
  }),

  [API_GET_KEY_FAILURE]: (state: IApiKeyState) => ({
    ...state,
    retrievingAPIKey: false
  }),

  [API_GET_KEY_SUCCESS]: (
    state: IApiKeyState,
    { apiKey, lastGeneratedAt }: { apiKey: string; lastGeneratedAt: Date }
  ) => ({
    ...state,
    retrievingAPIKey: false,
    apiKey,
    lastGeneratedAt
  }),

  [API_GET_WEBHOOK_URL]: (state: IApiKeyState) => ({
    ...state,
    retrievingWebhookUrl: true
  }),

  [API_GET_WEBHOOK_URL_FAILURE]: (state: IApiKeyState) => ({
    ...state,
    retrievingWebhookUrl: false
  }),

  [API_GET_WEBHOOK_URL_SUCCESS]: (
    state: IApiKeyState,
    { url }: { url: string }
  ) => ({
    ...state,
    retrievingWebhookUrl: false,
    webhookURL: url
  })
})
