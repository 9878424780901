import React, { useState } from 'react'
import styled from 'styled-components'
import { PlusIcon, Button, Popup } from 'backpack'
import { Popover } from 'react-tiny-popover'

import { OEESharedStyle } from '../components'
import { DatePicker, FilterDropdown } from '../../../components/Filters'
import { PopoverContainer } from '../components'
import { QuestionMark } from '../../../components/SVGImages'

export const StyledPopup = styled(({ children, ...rest }) => (
  <Popup children={children} {...rest} />
))`
  align-items: flex-start;
  .popup__inner-container {
    top: 36px;
  }
  .popup__content {
    max-height: calc(100vh - 150px);
    background: var(--color-bg-canvas-inset) !important;
  }
`

export const OperationLogsFormContainer = styled.form`
  ${OEESharedStyle}
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 18px;
  @media (max-width: 700px) {
    flex-direction: column-reverse;
  }
`

export const SubmitButton = styled(Button)`
  margin: 24px auto 0 auto;
  width: 240px;
`

export const StyledPlusIcon = styled(PlusIcon)`
  path {
    stroke: var(--color-text-primary);
  }
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  max-width: 500px;
  align-items: flex-end;
  .tooltip {
    display: flex;
    align-items: center;
  }
  @media (max-width: 700px) {
    max-width: unset;
  }
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  position: relative;
  .field-label {
    font-size: 14px;
    color: var(--color-text-primary);
  }
  input {
    padding: 10px;
    box-sizing: border-box;
    background: var(--color-bg-canvas);
    border-radius: 10px;
    border: 1px solid var(--color-border-primary);
    width: 120px;
    color: var(--color-text-primary);
    text-align: center;
  }
  input:focus {
    border: 1px solid var(--color-text-secondary);
  }
  input:disabled,
  input.disabled {
    border-color: transparent;
    opacity: 0.6;
    pointer-events: none;
  }
`

interface IField extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  tooltip?: string
}

export const Field = React.forwardRef<HTMLInputElement, IField>(
  ({ label, children, tooltip, ...rest }, ref) => {
    if (tooltip) {
      const [isPopoverOpen, setIsPopoverOpen] = useState(false)

      return (
        <FieldContainer>
          <div className="tooltip">
            <span className="field-label">{children || `${label}:`}</span>
            <Popover
              isOpen={isPopoverOpen}
              positions={['top']}
              align={'start'}
              padding={10}
              onClickOutside={() => setIsPopoverOpen(false)}
              content={() => <PopoverContainer>{tooltip}</PopoverContainer>}
            >
              <div
                style={{ display: 'inline-block' }}
                onClick={() => setIsPopoverOpen(true)}
              >
                <QuestionMark style={{ marginLeft: '10px', width: '18px' }} />
              </div>
            </Popover>
          </div>
          <input {...rest} ref={ref} />
        </FieldContainer>
      )
    }

    return (
      <FieldContainer>
        <span className="field-label">{children || `${label}:`}</span>
        <input {...rest} ref={ref} />
      </FieldContainer>
    )
  }
)

const OEESectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: inherit;
  border-radius: 10px;
  background: var(--color-bg-canvas);
  padding: 16px;
  width: 334px;
  box-sizing: border-box;
  @media (max-width: 700px) {
    width: 100%;
    gap: 12px;
  }
`

const OEEItemProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  .oee-item__text {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .oee-item__progress-bar {
    transition: width 0.3s ease;
    height: 5px;
    border-radius: 100px;
    max-width: 100%;
  }
`

export const StyledDatePicker = styled(DatePicker)`
  overflow: hidden;
  transition: all 0.5s ease;
  margin-top: -12px;
  max-height: 0px;
  &.active {
    max-height: 300px;
    margin-top: 0;
  }
`

export const StyledFilterDropdown = styled(FilterDropdown)`
  padding: 12px;
  box-sizing: border-box;
  background: var(--color-bg-canvas);
  border-radius: 12px;
  height: 40px;
  color: var(--color-text-primary);
  text-align: center;
  display: flex;
  border: 1px solid var(--color-border-primary);
  z-index: 1;
  .dropdown__mobile {
    left: 0;
    height: inherit;
    top: 0;
  }
  .filter-options {
    right: 5px;
    top: calc(100% + -5px);
  }
`

interface IOEEItemProgress {
  name: string
  progress: number
  color: string
  fontSize?: number
}

function OEEItemProgress({
  name,
  progress,
  color,
  fontSize
}: IOEEItemProgress) {
  return (
    <OEEItemProgressContainer>
      <div
        className="oee-item__text"
        style={{ fontSize: `${fontSize || 16}px`, color: color }}
      >
        <span>{name}</span>
        <span>{`${!isNaN(progress) ? Number(progress).toFixed(2) : 0}%`}</span>
      </div>
      <div
        className="oee-item__progress-bar"
        style={{
          background: color,
          width: `${!isNaN(progress) ? progress : 0}%`
        }}
      />
    </OEEItemProgressContainer>
  )
}

interface IOEESection {
  oee: number
  availability: number
  performance: number
  quality: number
}

export function OEESection({
  oee,
  availability,
  performance,
  quality
}: IOEESection) {
  return (
    <OEESectionContainer>
      <OEEItemProgress
        name="OEE"
        progress={oee}
        color={'var(--color-text-primary)'}
        fontSize={36}
      />
      <OEEItemProgress
        name="Availability"
        progress={availability}
        color={'var(--color-availability)'}
      />
      <OEEItemProgress
        name="Performance"
        progress={performance}
        color={'var(--color-performance)'}
      />
      <OEEItemProgress
        name="Quality"
        progress={quality}
        color={'var(--color-quality)'}
      />
    </OEESectionContainer>
  )
}
